import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
import materialsApis from "../../../api/master/materials";
interface SelectOptions {
  label: string;
  value: string;
}
const GeneralInformation: React.FC = (props: any) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const FullFormDetails = watch();
  const materials = watch("materials");
  useEffect(() => {
    if (materials?.length > 0) {
      findCategoryByMaterialTypeId(materials);
    }
  }, [materials]);

  const [categories, setCategories] = useState<SelectOptions[]>([]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState<
    SelectOptions[]
  >([]);
  const [disableCategory, setDisableCategory] = useState(true);

  const fetchIndustryType = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchMaterialTyepesByCategory();
      if (status) {
        setIndustryTypeOptions(
          data.map((obj: any) => ({ label: obj.name, value: obj.material_id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  useEffect(() => {
    fetchIndustryType();
  }, []);

  const findCategoryByMaterialTypeId = async (materialTypeId: any) => {
    const idsString = materialTypeId && materialTypeId.join(",");
    try {
      const { data, status, message } =
        await materialsApis.findCategoryByMaterialType(idsString);
      if (status) {
        setCategories(
          data.map((obj: any) => ({ label: obj.category, value: obj.id }))
        );
        setDisableCategory(false);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  return (
    <>
      <form
        className="content clearfix"
        onSubmit={handleSubmit(() => {
          props.next();
        })}
      >
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="referenceID"
              label="Supplier ID"
              type="text"
              pattern={/^[a-zA-Z0-9./_-]+$/}
              required={true}
              // maxLength={10}
              placeholder="Enter Supplier ID"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="supplierName"
              label="Supplier Name(Legal Name)"
              disabled={FullFormDetails?.gstinVerified == "true"}
              // disabled={action === "view"}
              required={true}
              pattern={/^[A-Za-z\s!@#$%^&*(),.?":{}|<>/&.-]+$/}
              type="text"
              // maxLength={50}
              placeholder="Enter Supplier Name"
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[^A-Za-z\s.&/-]/g, ""); // Removes invalid characters
              }}
            />
          </Col>
          <Col lg="4">
            <FormMultiSelect
              name="materials"
              label="Material Type"
              options={industryTypeOptions}
              required={true}
              placeholder="Select Material Type"
              onBlur={(event, values) => {
                setValue("categories", []);
              }}
            />
            {/* <label className="form-label">
              Material Type<span className="mandatory">*</span>
            </label>
            <RSelect
              isMulti
              name="industryType"
              // disabled={action === "view"}
              label="Material Type"
              options={industryTypeOptions}
              required={true}
              placeholder="-Select-"
              onChange={(value: any) =>
                handleMultiSelectChange(value, "materials")
              }
              closeMenuOnSelect={false}
            /> */}
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col lg="4">
            <FormMultiSelect
              name="categories"
              label="Supply Categories"
              required={true}
              options={categories}
              disabled={disableCategory}
              placeholder="Select Supply Categories"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="tradeName"
              label="Trade Name"
              required={true}
              type="text"
              placeholder="Enter Trade Name"
              pattern={/^[A-Za-z\s]+$/}
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Removes invalid characters
              }}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="brandName"
              label="Brand Name"
              required={true}
              type="text"
              placeholder="Enter Brand Name"
            />
          </Col>
          {/* <Col lg="4">
            <FormInput
              name="minSupply"
              label="Min.Supply"
              // required={true}
              type="number"
              // disabled={action === "view"}
              placeholder="Enter Min.Supply"
            />
          </Col> */}
          <Col lg="4">
            <FormInput
              name="deliveryLeadDays"
              label="Delivery Lead Days"
              required={true}
              type="number"
              // disabled={action === "view"}
              placeholder="Enter Delivery Lead Days"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="paymentTerms"
              label="Payment Terms"
              required={true}
              options={[
                { label: "Advance", value: "Advance" },
                { label: "Credit", value: "Credit" },
              ]}
              placeholder="Enter Payment Terms"
            />
          </Col>
          {watch("paymentTerms") == "Credit" && (
            <Col lg="4">
              <FormInput
                name="creditDays"
                label="Credit Days"
                required={true}
                type="number"
                placeholder="Enter Credit Days"
              />
            </Col>
          )}

          {/* <Col lg="4">
            <FormMultiSelect
              name="supplyStates"
              label="Supply State"
              options={states}
              placeholder="Select Supply State"
            />
          </Col> */}
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default React.memo(GeneralInformation);

import React, { useEffect, FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Content from "../../../layout/content/Content";
import { Card, Row, Col, Spinner } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../components/Component";
import { Tabs, Descriptions, Button, Flex, Spin } from "antd";
import type { TabsProps } from "antd";

import Snapshot from "./Snapshot";
import QuotationComparison from "./QuotationComparison";
import BAFOAnalysis from "./BAFOAnalysis";
import quotationApis from "../../../api/master/quotations";
import dayjs from "dayjs";
import FormatDate from "../../masters/DateFormater";
import Swal from "sweetalert2";
import { set } from "date-fns";

const RFQFullView: FC = () => {
  const location = useLocation();
  const rfq = location.state?.rfq;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(); // Track active tab
  const [countsData, setCountsData]: any = useState();
  const [supplierQuotations, setSupplierQuotations]: any = useState<any[]>([]);
  const [initialMaterials, setInitialMaterials] = useState<any[]>([]);
  const [creationDate, setCreationDate] = useState<string | null>(null);
  const [quotationHeader, setQuotationHeader] = useState<any>({});
  const [isLoading,setIsLoading] = useState(true);
  const [disableExport, setDisableExport] = useState(false);
  console.log("RFQ", rfq);

  useEffect(() => {
    console.log("location", location.state);
    if (location.state?.from === "qc") {
      setActiveTab("2");
    } else {
      setActiveTab("1");
    }
  }, [rfq]);

  const handleTabChange = (key: string) => {
    setActiveTab(key); // Update active tab state
  };

  const exportToExcel = async () => {
    try {
      const { response, status } = await quotationApis.qcExcelExport(
        rfq.quotationId
      );
      if (status) {
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", "QuotationComparison.xlsx");
        // document.body.appendChild(link);
        // link.click();
        const bufferData = response.buffer.data;
        const filename = response.filename || "download.xlsx";
        const mimeType =
          response.mimeType ||
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

        // Convert the buffer data (array) to a Uint8Array
        const uint8Array = new Uint8Array(bufferData);

        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: mimeType });

        // Create a download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // Use the filename from the API response
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
      setDisableExport(false);
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const handleDataUpdate = async () => {
    const data = await getSupplierQuotations();
    if (data) {
      console.log("data::::DaTSA", data, data?.quotationHeader?.createdAt);
      setCountsData(data.counts);
      setCreationDate(
        data?.quotationHeader?.createdAt
          ? FormatDate(data?.quotationHeader?.createdAt)
          : "N/A"
      );
      setSupplierQuotations(data.supplierHeaders);
      setQuotationHeader(data.quotationHeader);
      const data2 = data?.materials.map((item: any, index: any) => {
        return {
          ...item,
          key: index + 1,
        };
      });
      setInitialMaterials(data2);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleDataUpdate(); // Fetch data on initial load
  }, []);

  const getSupplierQuotations = async () => {
    try {
      const { response, status } = await quotationApis.quoteComparision(
        rfq.quotationId
      );
      if (status) {
        return response.data;
      }
    } catch (error: any) {
      // handle the error
    }
  };

  if (!rfq) {
    return <div>No RFQ data available</div>;
  }
  const items: TabsProps["items"] = [
    { key: "1", label: "Snapshot", children: <Snapshot rfq={rfq} /> },
    {
      key: "2",
      label: "Quotation Comparison",
      children: (
        <QuotationComparison
          rfq={rfq}
          initialMaterialsData={initialMaterials}
          supplierQuotations={supplierQuotations}
          quotationHeader={quotationHeader}
          countsData={countsData}
          onUpdate={handleDataUpdate} // Pass callback to handle data update
        />
      ),
    },
    {
      key: "3",
      label: "BAFO Analysis",
      children: <BAFOAnalysis rfq={rfq} activeTab={activeTab} />,
    },
  ];

  return (
    <>
      <Content>
        {/* <Card className="card-bordered"> */}
        {/* <div className="card-inner"> */}
        <div
          className="rfqoverview"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">Quotation Details</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <Descriptions column={3} style={{ flex: 1, marginLeft: " 10px" }}>
            <Descriptions.Item label="RFQ ID">{rfq.rfqId}</Descriptions.Item>
            <Descriptions.Item label="Site Name">
              {rfq.siteName}
            </Descriptions.Item>
            <Descriptions.Item label="Created Date">
              {creationDate}
            </Descriptions.Item>
          </Descriptions>
          {activeTab === "2" && (
            <>
              {disableExport ? (
                <Button type="default" style={{ marginLeft: "auto", marginRight: "10px" }}>
                  <Spinner size="sm" color="light" />
                </Button>
              ) : (
                <Button
                  type="default"
                  onClick={() => {
                    setDisableExport(true);
                    exportToExcel();
                  }}
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                >
                  <em
                    className="icon ni ni-download-cloud"
                    style={{ marginRight: "5px" }}
                  ></em>
                  Export
                </Button>
              )}
 
              <Button
                type="default"
                onClick={() => navigate(-1)}
                style={{ marginLeft: "auto" }}
              >
                <em
                  className="icon ni ni-arrow-left"
                  style={{ marginRight: "5px" }}
                ></em>
                Back
              </Button>
            </>
          )}
        </div>
        {isLoading == true ? (
          <>
          <Flex align="center" gap="middle" className="pageLoader">
            <Spin size="large" className="m-auto" />
          </Flex>
          </>
        ):(
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange} // Handle tab change
          items={items}
          tabBarStyle={{
            color: "#2263b3",
            fontSize: "20px",
          }}
          tabBarGutter={32}
          renderTabBar={(props, DefaultTabBar) => (
            <DefaultTabBar {...props} style={{ marginBottom: "0" }} />
          )}
        />
        )}
        {/* </div> */}
        {/* </Card> */}
      </Content>
    </>
  );
};

export default RFQFullView;

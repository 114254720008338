import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { Empty } from 'antd';

interface StatusData {
    name: string;
    value: number;
}

const PRSummaryWidget: React.FC<any> = ({prSummaryData}) => {
    const data: StatusData[] =prSummaryData?.filter((item: any) => item.value !== "0");
    // Check if all values are 0 or if prSummaryData is undefined
    const allValuesZero = !prSummaryData || prSummaryData.every((item: any) => item.value === "0");


    useEffect(() => {

        if (allValuesZero) return; // Skip initializing the chart if all values are 0

        const chartDom = document.getElementById('pr-summary-widget') as HTMLElement;
        const myChart = echarts.init(chartDom);

        const option = {
            tooltip: {
                trigger: 'item',
            },
            series: [
                {
                    name: 'PR Status',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    padAngle: 2,

                    label: {
                        show: true,
                        formatter: '{b|{b}}\n{c|{c} ({d}%)}',
                        rich: {
                            b: {
                                fontSize: 14,
                                color: '#333',
                                lineHeight: 24,
                            },
                            c: {
                                fontSize: 12,
                                color: '#999',
                                lineHeight: 20,
                            },
                        },
                    },
                    labelLine: {
                        show: true,
                        length: 15,
                        length2: 10,
                        smooth: true,
                    },
                    itemStyle: {
                        borderColor: '#E0E4FF',  // Border color
                        borderWidth: 1,           // Border width
                        shadowBlur: 10,           // Shadow blur
                        shadowOffsetX: 0,         // Shadow X offset
                        shadowOffsetY: 5,         // Shadow Y offset
                        shadowColor: 'rgba(255, 255, 255, 0.2)', // Shadow color
                        borderRadius: 5
                    },
                    data: data?.map((item) => ({ value: item.value, name: item.name })),
                },
            ],
            color : ['#173F5F', '#20639B', '#3CAEA3', '#F6D55C', '#ED553B', '#e81e63', '#03a9f4', '#cddc39', '#9c27b0', '#ffeb3b'],
            // color: [
            //     "#437ACF", // Base Blue (Darker, slightly less bright)
            //     "#4A83D8", // Blue Shade 1
            //     "#518BE1", // Blue Shade 2
            //     "#5983E8", // Blue Shade 3
            //     "#607AEC", // Blue Shade 4
            //     "#6870EF", // Blue Shade 5
            //     "#6F66F2", // Blue Shade 6
            //     "#7761F4", // Blue Shade 7
            //     "#7F5BF7", // Blue Shade 8
            //     "#8855FA", // Blue Shade 9
            //     "#914FFD", // Blue Shade 10
            //     "#9B48FF", // Blue Shade 11
            //     "#A547FF", // Blue Shade 12
            //     "#AF4AFF", // Blue Shade 13
            //     "#B94DFF"  // Lightest Blue (More pronounced)
            // ]
            // color: [
            //     "#2B3A7E", // Dark Blue
            //     "#3449A1", // Slightly lighter
            //     "#3E58C4", // Gradually lighter, transitioning to blue
            //     "#4867E7", // More towards the original blue
            //     "#549CFB", // Original Blue
            //     "#5FA7FC", // Slightly lighter
            //     "#6AB2FD", // Lighter, moving towards sky blue
            //     "#75BDFF", // More desaturated and lighter
            //     "#6676FF", // Original Mid Blue/Violet
            //     "#8172FF", // Slightly lighter with more violet
            //     "#9A8CFF", // Moving towards lighter violet
            //     "#B3A6FF", // Very light violet, closer to lavender
            //     "#816BFF", // Original Violet
            //     "#A18EFF", // Lighter violet with more pastel
            //     "#C1B1FF", // Lightest, pastel violet
            // ]

        };
        myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, [data]);

    return (
        <>
            <div className="card-title-group mb-1">
                <div className="card-title">
                    <h6 className="title">Purchase Request Summary</h6>
                    <p>
                        Tracking the Journey from Request to Delivery
                    </p>
                </div>
            </div>
             {/* Conditionally render the chart or the empty state */}
             {allValuesZero ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <Empty description="No Data Available " />
                </div>
            ) : (
                <div id="pr-summary-widget" style={{ width: '100%', height: '300px' }} />
            )}
        </>
    )
};

export default PRSummaryWidget;

import React, { useState, useEffect, FC } from "react";
import { Button } from "reactstrap";
import { TableColumnsType } from "antd";
import { Icon } from "../../components/Component";
import { useNavigate } from "react-router";
import BOQAside from "./boqAside";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const BOQList = () => {
  const [sm, setSm] = useState(false);
  const navigate = useNavigate();
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [siteOptions, setSiteOptions] = useState<any>([]);
  const { data: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility) {
        const options = currentUser.siteAccessibility.map((item: any) => ({
          label: item.siteName,
          value: item.siteID,
        }));
        setSiteOptions(options);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    viewChange();
    // fetchAllInvoices();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "Target Number",
      dataIndex: "targetNumber",
      key: "targetNumber",
      fixed: "left",
      width: 200,
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Block / Tower",
      dataIndex: "blockName",
      key: "blockName",
      width: 200,
    },
    {
      title: "Contractor",
      dataIndex: "contractorName",
      key: "contractorName",
      width: 200,
    },
    {
      title: "Limit GI",
      dataIndex: "limitPostTargetConsumption",
      key: "limitPostTargetConsumption",
      width: 200,
      render: (text: any) => {
        return <>{text == 0 ? <span>No</span> : <span>Yes</span>}</>;
      },
    },
    {
      title: "Consumption Alerts",
      dataIndex: "setAlerts",
      key: "setAlerts",
      width: 200,
      render: (text: any) => {
        return (
          <>
            {text?.length ? <span>{text.toString()}</span> : <span>N/A</span>}
          </>
        );
      },
    },
    {
      title: "View",
      key: "actions",
      fixed: "right",
      width: 70,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(`/consumption_target_view/${record.targetId}`);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ]);

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="consumption-target-list"
      title="BOQ List"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <BOQAside updateSm={setSm} sm={sm} />
        </div>
      }
      selectFilters={[
        {
          title: "Site",
          placeholder: "Select Site",
          options: siteOptions,
        },
      ]}
      extra={{
        // tableScrollX: 1100,
        dateRange: false,
        excelExport: false,
        dataFilterTootip: "Filtering based on quotation creation date",
      }}
    />
  );
};

export default BOQList;

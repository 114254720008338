import { Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { StepComponentProps } from "react-step-builder";
interface VersionControl_GeneralDetailsProps extends StepComponentProps {
  gendralDetailsColumns: any;
  gendralDetailsData: any;
}

const VersionControl_GeneralDetails: FC<any> = ({
  gendralDetailsColumns,
  gendralDetailsData,
}) => {
  const [screenSize, setScreenSize] = useState(0);
  const [sm, setSm] = useState(false);
  useEffect(() => {
    viewChange();
  }, []);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth >= 990) {
      setSm(false);
    }
  };

  return (
    <>
      <div
        style={{
          width: screenSize - 100 + "px",
          margin: "0px auto",
        }}
      >
        <Table
          className="customTable"
          columns={gendralDetailsColumns}
          dataSource={gendralDetailsData}
          scroll={{ x: 1500 }}
          bordered
          size="middle"
          pagination={false}
        />
      </div>
    </>
  );
};

export default VersionControl_GeneralDetails;

import React from "react";
import { useNavigate } from "react-router";
import ReportTemplate2 from "../reportTemplate2";
import dayjs from "dayjs";
import { Button } from "reactstrap";
import { Icon } from "../../../components/Component";

function MaterialsToSiteReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Material Issue Number",
      key: "issueNumber",
      dataIndex: "issueNumber",
      width: 200,
    },
    {
      title: "Material HandOver Number",
      key: "handoverNumber",
      dataIndex: "handoverNumber",
      width: 200,
    },

    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Block/Tower",
      dataIndex: "blockName",
      key: "blockName",
      width: 200,
    },
    {
      title: "Created By",
      key: "createdUserName",
      dataIndex: "createdUserName",
      width: 100,
    },
    {
      title: "Creation Date",
      key: "createdAt",
      dataIndex: "createdAt",
      width: 100,
      render: (text: string) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "HandOver Status",
      key: "handoverStatus",
      dataIndex: "handoverStatus",
      width: 100,
    },
    {
      title: "Action",
      key: "operation",
      // fixed: "right",
      width: 150,
      render: (text: any, record: any, index: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              navigate(
                `/view-material-issue-details/${record.materialIssueId}`,
                {
                  state: {
                    previousPath: `/inventory-materials-to-site-reports-list`,
                  },
                }
              );
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Materials Issued To Site"
      pageDescription="Record of materials dispatched to construction sites"
      moduleKey="materialsIssuedToSite"
      columns={columns}
      filtersOption={{
        site: true,
        // contractor: true,
        // dateRange: true,
      }}
      previousPath="/view-inventory-reports"
    />
  );
}

export default MaterialsToSiteReport;

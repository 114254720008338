import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';
import moment from 'moment';
import quotationApis from '../../../../api/master/quotations';
import dayjs from 'dayjs';


interface TimelineItem {
    status: boolean;
    label: string;
    date: string | null;
}


interface SnapshotProps {
    rfq: any;
  }

// const sampleData: TimelineItem[] = [
//     {
//         status: true,
//         label: "Released",
//         date: "01-Aug-2024"
//     },
//     {
//         status: true,
//         label: "Shortlisted",
//         date: "02-Aug-2024"
//     },
//     {
//         status: true,
//         label: "Negotiated",
//         date: "03-Aug-2024"
//     },
//     {
//         status: false,
//         label: "Approved",
//         date: null
//     },
//     {
//         status: false,
//         label: "Finalized",
//         date: null
//     }
// ];

const QuotationTimeLine: React.FC<SnapshotProps> = ({rfq}) => {
    const [sampleData, setSampleData] = useState<TimelineItem[]>([]);

    useEffect(() => {
        getTimeLineData()
    }, [])

    const getTimeLineData = async () => {
        try{
        const { response, status } = await quotationApis.quotationTimeLine(rfq);
        if(status){
            setSampleData(response.data)
        }
    }catch{

    }
    }
    return (
        <Steps
            current={-1}
            direction="horizontal"
            style={{ overflowX: 'auto', padding: '20px 0' }}
            className="custom-steps"
        >
            {sampleData.map((item: any, index: any) => (
                <Steps.Step
                    key={index}
                    title={
                        <div style={{ color: item.status ? '#004293' : 'grey' }}>
                            {item.label}
                        </div>
                    }
                    description={
                        <div style={{ color: '#333' }}>
                            {item.date ? dayjs(item.date).format('DD-MMM-YYYY HH:mm:ss') : ''}
                        </div>
                    }
                    icon={
                        <div style={{
                            width: '9px',
                            height: '9px',
                            borderRadius: '50%',
                            backgroundColor: item.status ? '#004293' : 'grey',
                            // border: '1px solid #000'
                        }}></div>
                    }
                />
            ))}
        </Steps>
    );
};

export default QuotationTimeLine;

import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import { Content } from "antd/es/layout/layout";
import { Badge, Button, Card } from "reactstrap";
import { Empty, Flex, Popover, Spin, Table, Timeline } from "antd";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { useLocation, useNavigate, useParams } from "react-router";
import VersionControlDefaultTemplate from "../../version-control/versionControlDefaultTemplate";
import quotationApis from "../../../api/master/quotations";
import Swal from "sweetalert2";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { render } from "@testing-library/react";

const RFQVersionControl = () => {
  const location = useLocation();
  const rfqId = location.state?.rfqId; // Get the state data

  const [apiResponse, setApiResponse] = React.useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (rfqId) {
      fetchVersionControlData(rfqId);
    }
  }, []);

  const fetchVersionControlData = async (rfqId: any) => {
    setLoading(true);
    try {
      const res = await quotationApis.fetchOrderVersions(rfqId);
      const { response, status, message } = res;
      if (status) {
        setApiResponse(response);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  const ApprovalTimelineContent = (orderApprovalsData: any) => {
    const approvalsData = orderApprovalsData;

    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "Approved";
              const isRejected = approval.status == "Rejected";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Badge color="outline-success">Yes</Badge>
        </span>
      </Popover>
    );
  };

  const displayFields: any = [
    { label: "Quotation For", key: "quotationFor" },
    { label: "Preferred Brands / Make", key: "preferredBrands" },
    { label: "Description", key: "quotationDescription" },
    { label: "Cost Center", key: "costCenterName" },
    { label: "Last Submission Date", key: "lastSubmissionDate" },
    { label: "Time Limit", key: "deadlineType" },
    { label: "Site Name", key: "siteName" },
    { label: "Contractor", key: "contractorName" },
    {
      label: "Approvals",
      key: "approvals",
      render: (text: any, record: any) => {
        if (record?.approvalRequired === "N") {
          return "No";
        }
        return ApprovalTimelineContent(record?.approvals);
      },
    },
    { label: "Additional Charges", key: "additionalCharges" },
    { label: "Auto Release", key: "autoRelease" },
    {
      label: "Documents Required From Supplier",
      key: "supplierDocsRequired",
      render: (text: any, record: any) => {
        return text === "Y" ? (
          <Popover
            content={
              record?.requiredDocs ? (
                <Table
                  dataSource={record?.requiredDocs?.map(
                    (doc: any, index: number) => ({
                      key: index + 1,
                      sNo: index + 1,
                      documentName: doc.documentName,
                      mandatory: doc.mandatory ? "Yes" : "No",
                    })
                  )}
                  columns={[
                    {
                      title: "S.No",
                      dataIndex: "sNo",
                      key: "sNo",
                    },
                    {
                      title: "Document Name",
                      dataIndex: "documentName",
                      key: "documentName",
                    },
                    {
                      title: "Mandatory to Upload",
                      dataIndex: "mandatory",
                      key: "mandatory",
                    },
                  ]}
                  pagination={false}
                  size="small"
                  style={{ minWidth: "200px" }}
                />
              ) : null
            }
            title={<span style={{ color: "#2263b3" }}>Required Documents</span>}
          >
            <span
              style={{
                color: "#2263b3",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Yes
            </span>
          </Popover>
        ) : (
          "No"
        );
      },
    },
    { label: "Delivery Address Type", key: "deliveryLevelType" },
    {
      label: "Billing Address",
      key: "billingAddressId",
      render: (text: any, record: any) => {
        return (
          <Popover
            content={
              <div className="billingAddress_align">
                <strong>{record.billingAddress?.deliveryLocationName}</strong>
                <br></br>
                <p>
                  {record.billingAddress?.addressLine1},{" "}
                  {record.billingAddress?.addressLine2}
                </p>
                <p>
                  {record.billingAddress?.city},{" "}
                  {record.billingAddress?.district?.districtName},{" "}
                  {record.billingAddress?.state?.stateName},{" "}
                  {record.billingAddress?.countryInfo?.countryName} -{" "}
                  {record.billingAddress?.pincode}
                </p>
                <p>
                  <strong>GSTIN</strong> : {record?.organizationDetails?.gstin}
                </p>
                <p>
                  <strong>CIN</strong> : {record?.organizationDetails?.cin}
                </p>
                <p>
                  <strong>PAN</strong> : {record?.organizationDetails?.pan}
                </p>
                <p>
                  <strong>Phone</strong> :{" "}
                  {record?.organizationDetails?.phoneNumber}
                </p>
              </div>
            }
            title={<span style={{ color: "#2263b3" }}>Billing Address</span>}
          >
            <span
              style={{
                color: "#2263b3",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {record.billingAddress?.deliveryLocationName}
            </span>
          </Popover>
        );
      },
    },
    {
      label: "Delivery Address",
      key: "deliveryAddressId",
      render: (text: any, record: any) => {
        return (
          <Popover
            content={
              <div className="billingAddress_align">
                <strong>{record.deliveryAddress?.deliveryLocationName}</strong>
                <br></br>
                <p>
                  {record.deliveryAddress?.addressLine1},{" "}
                  {record.deliveryAddress?.addressLine2}
                </p>
                <p>
                  {record.deliveryAddress?.city},{" "}
                  {record.deliveryAddress?.district?.districtName},{" "}
                  {record.deliveryAddress?.state?.stateName},{" "}
                  {record.deliveryAddress?.countryInfo?.countryName} -{" "}
                  {record.deliveryAddress?.pincode}
                </p>
                <p>
                  <strong>Contact Person</strong> :{" "}
                  {record.deliveryAddress?.phoneNumber}
                </p>
                <p>
                  <strong>Contact Person</strong> :{" "}
                  {record.deliveryAddress?.phoneNumber}
                </p>
              </div>
            }
            title={<span style={{ color: "#2263b3" }}>Delivery Address</span>}
          >
            <span
              style={{
                color: "#2263b3",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {record.deliveryAddress?.deliveryLocationName}
            </span>
          </Popover>
        );
      },
    },
    { label: "Multiple Delivery Dates", key: "deliveryDateType" },
    {
      label: "Need By Date",
      key: "deliveryDate",
      render: (text: any, record: any) => {
        return record.deliveryDate ? text : "N/A";
      },
    },
    { label: "Payment Terms", key: "paymentTerms" },
  ];

  const materialColumns = [
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      fixed: "left",
      width: 150,
      render: (text: any) => `Version ${text}`,
    },
    { title: "Category", dataIndex: "categoryName", key: "categoryName" },
    { title: "Material Name", dataIndex: "materialName", key: "materialName" },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      render: (text: any, record: any) => {
        const isModified = record?.isModified?.specification;
        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {text}
          </strong>
        ) : (
          text
        );
      },
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, record: any) => {
        const isModified = record?.isModified?.quantity;
        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {text}
          </strong>
        ) : (
          text
        );
      },
    },
    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
      render: (text: any, record: any) => {
        const isModified = record?.isModified?.quantity;
        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {text}
          </strong>
        ) : (
          text
        );
      },
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (text: any, record: any) => {
        const isModified = record?.isModified?.quantity;
        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {text}
          </strong>
        ) : (
          text
        );
      },
    },
  ];

  return (
    <>
      {loading == true ? (
        <>
          <Flex align="center" gap="middle" className="pageLoader">
            <Spin size="large" className="m-auto" />
          </Flex>
        </>
      ) : apiResponse ? (
        <VersionControlDefaultTemplate
          displayFields={displayFields}
          title="Quotation Version History"
          backPath="/view-rfqs"
          apiResponse={apiResponse}
          materialColumns={materialColumns}
          extraMaterialInfo={false}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Empty description="No Data Found " />
        </div>
      )}
    </>
  );
};

export default RFQVersionControl;

import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import { formatCurrency} from "../../../utils/Utils";

function PurchaseByMaterialReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      fixed: "left",
    },
    {
      title: "Average Price",
      dataIndex: "avgPrice",
      key: "avgPrice",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(
                `/purchase-by-material-reports-detail-report/${record?.materialId}`,
                {
                  state: {
                    name: record?.materialName,
                    uomId: record?.uomId,
                  },
                }

              );
            }}
          >
            {formatCurrency(text?.toString(), record?.currencyCode)}
          </span>
        </div>
      ),
    },
    {
      title: "Quantity Purchased",
      dataIndex: "quantityPurchased",
      key: "quantityPurchased",
      render: (text: any, record: any) => (
        <span>
          {text} {record?.quantityUom}
        </span>
      ),
    },
    {
      title: "Value Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Value With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Purchase By Material"
      pageDescription="	Detailed summary of purchases categorized by materials."
      moduleKey="purchaseByMaterialReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-purchase-reports"
      extra={{
        tableScrollX: 1000,
      }}
    />
  );
}

export default PurchaseByMaterialReport;

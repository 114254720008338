import React, { useState, useEffect, useMemo, FC, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Badge, Card, Spinner } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import supplierApis from "../../../api/master/supplier";
import FormatDate from "../DateFormater";
import usersApis from "../../../api/master/users";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import materialsApis from "../../../api/master/materials";
import { add, set } from "date-fns";

interface SelectOptions {
  label: string;
  value: string;
}
const ViewSuppliersDetails: FC = () => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { siteId } = useParams();
  const [data, setData] = useState<any>();
  const [modalGI, setGIModal] = useState(false);
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);

  const [selectedValues, setSelectedValues] = useState<any[]>([]); // Store selected values

  const methods = useForm();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = methods;

  const {
    fields: additionalContactsFields,
    append: appendAdditionalContacts,
    remove: removeAdditionalContacts,
    update: updateAdditionalContacts,
  } = useFieldArray({
    control,
    name: "additionalContacts",
  });

  const [verifyingGstin, setVerifyingGstin] = useState(false);

  const FullFormDetails = watch();
  const gstinVerified = watch("gstinVerified");
  const gstin = watch("gstin");
  const cin = watch("cin");
  const businessType = watch("bussinessType");

  useEffect(() => {
    if (businessType === "unregistered") {
      setValue("gstin", "");
    }
  }, [businessType]);

  useEffect(() => {
    if (!gstin) return;
    if (
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/.test(
        gstin
      ) &&
      gstinVerified != "true"
    ) {
      setValue("gstinVerified", "false");
      setVerifyingGstin(true);
      validateSandbox({
        gstin: gstin,
        orgName: FullFormDetails.supplierName,
      });
    } else {
      setVerifyingGstin(false);
    }
  }, [gstin]);

  const validateSandbox = async (data: any) => {
    try {
      const response = await supplierApis.verifyLegalDetails({
        gstin: data?.gstin?.trim(),
        orgName: data.orgName?.trim(),
      });

      const sandboxResponse = response?.verificationResponse;

      const gstinVerification = sandboxResponse.find(
        (obj: any) => obj.gstin && !obj.verified
      );

      if (gstinVerification) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "GSTIN verification failed.",
        });
        setVerifyingGstin(false);
        return { errors: ["GSTIN verification failed."] };
      }

      const sandBoxObj = sandboxResponse.find(
        (obj: any) => obj.gstin && obj.verified
      );

      const getDistricts = async (stateID: any, districtName: string) => {
        try {
          const response = await usersApis.getDistrictsByState(stateID);
          const { data, status } = response;
          if (status) {
            if (data.length > 0) {
              const district = data.find((item: any) => {
                return (
                  item.districtName.toLowerCase().replace(/\s+/g, "") ===
                  districtName
                );
              });
              if (district) {
                setValue("district", district.districtId.toString());
              }
            }
          }
        } catch (error: any) {
          console.log(error.message);
        }
      };

      const fetchStatesByCountry = async (
        countryId: string,
        stateName: string,
        districtName: string
      ) => {
        try {
          const data = await usersApis.getStatesByCountry(countryId);
          const stateOptions = data?.data?.map((state: any) => ({
            value: state.stateId.toString(),
            label: state.stateName,
          }));

          if (stateName) {
            const state = stateOptions.find(
              (state: SelectOptions) => state.label === stateName
            );
            if (!state) {
              return;
            }
            setValue("state", state.value);
            await getDistricts(state?.value, districtName);
          }
        } catch (error) {}
      };

      const { companyAddress, dateOfRegistration } = sandBoxObj;

      if (!gstinVerification) {
        if (companyAddress) {
          setValue("addressLine1", companyAddress?.bno);
          setValue("addressLine2", companyAddress?.landMark);
          setValue("country", "1");
          await fetchStatesByCountry(
            "1",
            companyAddress?.stcd,
            companyAddress?.dst.toLowerCase().replace(/\s+/g, "")
          );

          setValue(
            "area",
            companyAddress?.locality + ", " + companyAddress?.landMark
          );

          setValue("city", companyAddress?.loc);
          setValue("pincode", companyAddress?.pncd);
        }
        setValue("pan", data.gstin.substring(2, 12));
        setValue("gstinVerified", "true");
      }
      setValue("incorporationDate", dayjs(dateOfRegistration, "DD/MM/YYYY"));
      setVerifyingGstin(false);
      return { errors: [], companyAddress };
    } catch (error: any) {
      setVerifyingGstin(false);
      console.log(error.message);
      throw error;
    }
  };

  const [formData, setFormData] = useState<any>({
    referenceID: "",
    supplierName: "",
    supplierCategory: "",
    incorporationDate: "",
    pan: "",
    gstin: "",
    lastFYTurnover: "",
    contactPerson: "",
    phoneNumber: "",
    email: "",
    country: "",
    state: "",
    district: "",
    city: "",
    area: "",
    pincode: "",
    applicationAccess: "",
    accountNumber: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    deliveryLeadDays: "",
    paymentTerms: "",
    tradeName: "",
    brandName: "",
    accountHolderName: "",
    cin: "",
    categories: [],
    materials: [],
  });
  const [docs, setDocs] = useState<any>([]);
  const cancelledChequeWatch = watch("cancelledCheque");
  const submitForm = (submitData: any) => {
    delete submitData.gstinVerified;
    const formData: any = new FormData();
    formData.append("supplierData", JSON.stringify(submitData));
    formData.append("files", JSON.stringify([]));
    formData.append(
      "filesData",
      JSON.stringify({
        newfiledata: [],
        deletedFileData: [],
        updatedFileData: [],
      })
    );
    submitUpdates(formData);
    setGIModal(false);
  };

  const [categories, setCategories] = useState<SelectOptions[]>([]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState<
    SelectOptions[]
  >([]);
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  const fetchIndustryType = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchMaterialTyepesByCategory();
      if (status) {
        console.log(data);
        setIndustryTypeOptions(
          data.map((obj: any) => ({
            label: obj.name,
            value: obj.material_id.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const getCountries = async () => {
    try {
      const _countries = await usersApis.getCountrys();
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountries(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
      }
    } catch (error) {}
  };
  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    viewChange();

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = siteId;
    if (id !== undefined || null || "") {
      fetchSupplierData(id);
      fetchIndustryType();
      getCountries();
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [siteId]);

  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
  };
  const docUploadSubmit = (data: any) => {
    const formData: any = new FormData();
    console.log("data?.cancelledCheque", data?.cancelledCheque);
    if (data?.cancelledCheque !== null) {
      console.log("IFFF");
      const filesData = [];
      const deletedFileData: any = [];
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "supplier",
        purpose: "cancled bank cheque",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      formData.append(`files`, data?.cancelledCheque[0]);

      docs.forEach((doc: any) => {
        deletedFileData.push({
          docId: doc?.docId,
        });
      });

      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: filesData,
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
    } else {
      console.log("ELSE");
      formData.append("files", JSON.stringify([]));
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [],
          deletedFileData: [],
          updatedFileData: [],
        })
      );
    }
    delete data?.cancelledCheque;
    formData.append("supplierData", JSON.stringify(data));
    setGIModal(false);
    submitUpdates(formData);
    setValue("cancelledCheque", null);
  };

  const submitUpdates = async (submitData: any) => {
    const { status, message } = await supplierApis.updateSupplierById(
      siteId,
      submitData
    );
    if (status) {
      Swal.fire("Updated!", "Supplier has been updated", "success");
      const id = siteId;
      console.log("id", id);
      if (id !== undefined || null || "") {
        fetchSupplierData(id);
      }
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const fetchSupplierData = async (id: any) => {
    try {
      const { data, status, message } = await supplierApis.fetchSupplierById(
        id
      );
      if (status) {
        const newData = data;
        setDocs(data.docs);
        setData(newData);
        let formatedAdditionalContacts = newData.additionalContacts.map(
          (items: any) => ({
            name: items.name,
            designation: items.designation,
            phoneNumber: items.phoneNumber,
            email: items.email,
          })
        );
        if (newData.additionalContacts.length === 0)
          formatedAdditionalContacts = [
            { name: "", designation: "", phoneNumber: "", email: "" },
          ];
        setFormData({
          referenceID: newData.referenceID,
          supplierName: newData.supplierName,
          categories: newData.categories,
          materials: newData.materials,
          incorporationDate: newData.incorporationDate,
          pan: newData.pan,
          gstin: newData.gstin,
          lastFYTurnover: newData.lastFYTurnover,
          contactPerson: newData.contactPerson,
          phoneNumber: newData.phoneNumber,
          email: newData.email,
          country: newData.country,
          state: newData.state,
          district: newData.district,
          city: newData.city,
          area: newData.area,
          pincode: newData.pincode,
          applicationAccess: newData.applicationAccess,
          accountNumber: newData.accountNumber,
          bankName: newData.bankName,
          branch: newData.branch,
          ifscCode: newData.ifscCode,
          deliveryLeadDays: newData.deliveryLeadDays,
          paymentTerms: newData.paymentTerms,
          tradeName: newData.tradeName,
          brandName: newData.brandName,
          accountHolderName: newData.accountHolderName,
          cin: newData.cin,
          creditDays: newData.creditDays,
          businessType: newData.businessType,
          additionalContacts: formatedAdditionalContacts,
        });
        setValue("referenceID", newData.referenceID);
        setValue("supplierName", newData.supplierName);
        setValue("incorporationDate", moment(newData.incorporationDate));
        setValue("pan", newData.pan);
        setValue("gstin", newData.gstin);
        setValue("lastFYTurnover", newData.lastFYTurnover);
        setValue("contactPerson", newData.contactPerson);
        setValue("phoneNumber", newData.phoneNumber);
        setValue("email", newData.email);
        setValue("country", newData.country.countryId);
        setValue("state", newData.state.stateId);
        setValue("district", newData.district.districtId);
        setValue("city", newData.city);
        setValue("area", newData.area);
        setValue("pincode", newData.pincode);
        setValue("applicationAccess", newData.applicationAccess);
        setValue("accountNumber", newData.accountNumber);
        setValue("bankName", newData.bankName);
        setValue("branch", newData.branch);
        setValue("ifscCode", newData.ifscCode);
        setValue("addressLine1", newData.addressLine1);
        setValue("addressLine2", newData.addressLine2);
        setValue("deliveryLeadDays", newData.deliveryLeadDays);
        setValue("tradeName", newData.tradeName);
        setValue("brandName", newData.brandName);
        setValue("paymentTerms", newData.paymentTerms);
        setValue("accountHolderName", newData.accountHolderName);
        setValue("creditDays", newData.creditDays);
        setValue("bussinessType", newData.bussinessType);
        setValue("cin", newData.cin);
        setValue(
          "categories",
          newData.categories.map((item: any) => item.categoryID?.toString())
        );
        setValue(
          "materials",
          newData.materials.map((item: any) => item.materialID?.toString())
        );
        setValue("additionalContacts", formatedAdditionalContacts);
        fetchIndustryType();
        findCategoryByMaterialTypeId(
          newData.materials.map((item: any) => item.materialID)
        );
        fetchStatesByCountry(newData.country.countryId, newData.state.stateId);
        getDistricts(newData.state.stateId);
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  const findCategoryByMaterialTypeId = async (materialTypeId: any) => {
    console.log("id", materialTypeId);
    const idsString = materialTypeId.join(",");
    console.log("IDs as string:", idsString);
    if (idsString === "") {
      setValue("categories", []);
      setCategories([]); // Also clear category options
      return;
    }
    try {
      const {
        data: _data,
        status,
        message,
      } = await materialsApis.findCategoryByMaterialType(idsString);
      if (status) {
        console.log(_data);
        let options: any = _data?.map((obj: any) => ({
          label: obj.category,
          value: obj.id.toString(),
        }));
        setCategories(options);
        const selectedSetCategories = getValues("categories") || [];
        const validCategories = selectedSetCategories.filter(
          (category: string) =>
            options.some((option: any) => option.value === category)
        );
        setValue("categories", validCategories);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?._data?.message || err.message, "error");
    }
  };

  return (
    <React.Fragment>
      <Head title="Supplier Details - Regular"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Supplier Details -{" "}
                    <strong className="text-primary small">
                      {data["supplierName"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Link to={`${process.env.PUBLIC_URL}/suppliers`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  lg="5"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">General Information</BlockTitle>
                        <p>Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("GeneralInformation")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supplier ID</div>
                          <div className="data-value">{data.referenceID}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supplier Name</div>
                          <div className="data-value">{data.supplierName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Material Type</div>
                          <div className="data-value">
                            {formData.materials
                              .map((material: any) => material.materialName)
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supply Categories</div>
                          <div className="data-value">
                            {formData.categories
                              .map((categories: any) => categories.categoryName)
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Trade Name</div>
                          <div className="data-value">{data.tradeName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Brand Name</div>
                          <div className="data-value">{data.brandName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Delivery Lead Days</div>
                          <div className="data-value">
                            {data.deliveryLeadDays}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Payment Terms</div>
                          <div className="data-value">{data.paymentTerms}</div>
                        </div>
                      </li>
                      {data.paymentTerms == "Credit" && (
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label">Credit Days</div>
                            <div className="data-value">{data.creditDays}</div>
                          </div>
                        </li>
                      )}
                      {/* <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supply States</div>
                          <div className="data-value">
                            {formData.supplyStates
                              .map(
                                (supplyStates: any) => supplyStates.stateName
                              )
                              .join(", ")}
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Legal Information</BlockTitle>
                        <p>BusinessType, PAN, GSTIN etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("LegalInformation")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label"> BusinessType</div>
                          <div className="data-value">
                            {data.bussinessType || "N/A"}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">CIN</div>
                          <div className="data-value">{data.cin || "N/A"}</div>
                        </div>
                      </li>
                      {data.bussinessType == "registered" && (
                        <>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                Incorporation Date
                              </div>
                              <div className="data-value">
                                {FormatDate(data.incorporationDate || "N/A")}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">PAN</div>
                              <div className="data-value">
                                {data.pan || "N/A"}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">GSTIN</div>
                              <div className="data-value">
                                {data.gstin || "N/A"}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                Last FY Turn over
                              </div>
                              <div className="data-value">
                                {data.lastFYTurnover || "N/A"}
                              </div>
                            </div>
                          </li>
                        </>
                      )}
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Bank Information</BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("BankInformation")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Company Account Name</div>
                          <div className="data-value">
                            {data.accountHolderName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Account Number</div>
                          <div className="data-value">{data.accountNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Bank Name</div>
                          <div className="data-value">{data.bankName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Branch</div>
                          <div className="data-value">{data.branch}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">IFSC Code</div>
                          <div className="data-value">{data.ifscCode}</div>
                        </div>
                      </li>
                      {docs?.map((document: any) => {
                        // if (document.moduleFileType !== "cancled bank cheque")
                        //   return <></>;
                        return (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                {document.moduleFileType}
                              </div>
                              <span className="data-value">
                                <a
                                  href={document.filePath}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "default",
                                  }}
                                >
                                  {document.fileName}
                                </a>
                              </span>
                            </div>
                          </li>
                        );
                      })}
                      {/* <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Cancelled Cheque</div>
                          <div className="data-value">
                            <a
                              href={docs?.filePath}
                              target="_blank"
                              style={{
                                pointerEvents: "auto",
                                cursor: "default",
                              }}
                            >
                              {docs?.fileName}
                            </a>
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Contact Information</BlockTitle>
                        <p>Basic Contact Information Details etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("ContactInformation")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contact Person</div>
                          <div className="data-value">{data.contactPerson}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Phone Number</div>
                          <div className="data-value">{data.phoneNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email</div>
                          <div className="data-value">{data.email}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Address</div>
                          <div className="data-value">
                            {[data?.addressLine1, data?.addressLine2]
                              ?.filter(Boolean)
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Country</div>
                          <div className="data-value">
                            {
                              countries.find(
                                (c) => c.value == data.country.countryId
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">State</div>
                          <div className="data-value">
                            {
                              states.find((s) => s.value == data.state.stateId)
                                ?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">district</div>
                          <div className="data-value">
                            {
                              districtOptions.find(
                                (d) => d.value == data.district.districtId
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">City</div>
                          <div className="data-value">{data.city}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Area</div>
                          <div className="data-value">{data.area}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Zip/Pincode</div>
                          <div className="data-value">{data.pincode}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Application Access</div>
                          <div className="data-value">
                            {data.applicationAccess}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Additional Contacts Information
                        </BlockTitle>
                        <p>Additional Contacts Information details</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() =>
                              openEditModal("AdditionalContactInformation")
                            }
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <div className="table-responsive mt-2 mb-2">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Desigantion</th>
                            <th>Phone Number</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.additionalContacts?.length > 0 ? (
                            data?.additionalContacts?.map((itm: any) => {
                              return (
                                <tr key={itm.id}>
                                  <td>{itm.name}</td>
                                  <td>{itm.designation}</td>
                                  <td>{itm.phoneNumber}</td>
                                  <td>{itm.email}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <tr>
                                <td colSpan={5} className="text-center">
                                  <span className="text-silent mt-5">
                                    No data found
                                  </span>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>

              {modalEditBlockName === "GeneralInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit General Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="referenceID"
                                  label="Supplier ID"
                                  required={true}
                                  type="text"
                                  pattern={/^[a-zA-Z0-9./_-]+$/}
                                  minLength={2}
                                  maxLength={30}
                                  placeholder="Enter Supplier ID"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="supplierName"
                                  label="Supplier Name"
                                  required={true}
                                  disabled={true}
                                  type="text"
                                  // maxLength={50}
                                  placeholder="Enter Supplier Name"
                                  pattern={/^[A-Za-z\s!@#$%^&*(),.?":{}|<>/&.-]+$/}
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(/[^A-Za-z\s.&/-]/g, ""); // Removes invalid characters
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormMultiSelect
                                  name="materials"
                                  label="Material Type"
                                  required={true}
                                  options={industryTypeOptions}
                                  placeholder="Select Material Type"
                                  onChange={(values: any[]) => {
                                    console.log("Values changed:", values); // Debug log
                                    setSelectedValues(values); // Capture selected values on change
                                    findCategoryByMaterialTypeId(values);
                                  }}
                                  // onBlur={(event, values) => {
                                  //   console.log(
                                  //     "Selected values on blur:",
                                  //     values
                                  //   ); // Pass values from change event
                                  //   setValue("categories", []); // Reset categories
                                  //   findCategoryByMaterialTypeId(values); // Call your function on blur with the selected values directly
                                  // }}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <FormMultiSelect
                                name="categories"
                                label="Supply Categories"
                                required={true}
                                options={categories}
                                placeholder="Select Supply Categories"
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="tradeName"
                                label="Trade Name"
                                required={true}
                                type="text"
                                defaultValue={formData.tradeName}
                                placeholder="Enter Trade Name"
                                pattern={/^[A-Za-z\s]+$/}
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^A-Za-z\s]/g,
                                    ""
                                  ); // Removes invalid characters
                                }}
                              />
                            </Col>
                            <Col lg="6">
                              <FormInput
                                name="brandName"
                                label="Brand Name"
                                required={true}
                                type="text"
                                defaultValue={formData.brandName}
                                placeholder="Enter Brand Name"
                              />
                            </Col>
                            {/* <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  type="number"
                                  label="Min.Supply"
                                  name="minSupply"
                                  defaultValue={formData.minSupply}
                                  placeholder="Enter Min.Supply"
                                />
                              </div>
                            </Col> */}
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  type="number"
                                  name="deliveryLeadDays"
                                  required={true}
                                  label="Delivery Lead Days"
                                  defaultValue={formData.deliveryLeadDays}
                                  placeholder="Enter Delivery Lead Days"
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormSelect
                                  name="paymentTerms"
                                  label="Payment Terms"
                                  required={true}
                                  options={[
                                    { label: "Advance", value: "Advance" },
                                    { label: "Credit", value: "Credit" },
                                  ]}
                                  placeholder="Enter Payment Terms"
                                />
                              </div>
                            </Col>
                            {watch("paymentTerms") == "Credit" && (
                              <Col lg="6">
                                <FormInput
                                  name="creditDays"
                                  label="Credit Days"
                                  required={true}
                                  defaultValue={formData.creditDays}
                                  type="number"
                                  placeholder="Enter Credit Days"
                                />
                              </Col>
                            )}
                            {/* <Col md="6">
                              <FormMultiSelect
                                name="supplyStates"
                                label="Supply State"
                                options={states}
                                placeholder="Select Supply State"
                              />
                            </Col> */}
                          </Row>
                          <Row className="gy-4 mt-2">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "LegalInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Legal Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col md="6">
                              <div className="form-group">
                                <FormSelect
                                  name="bussinessType"
                                  label="Business Type"
                                  options={[
                                    {
                                      label: "Registered",
                                      value: "registered",
                                    },
                                    {
                                      label: "Unregistered",
                                      value: "unregistered",
                                    },
                                  ]}
                                  disabled={data.bussinessType === "registered"}
                                  // defaultValue={data.bussinessType}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="cin"
                                  label="CIN"
                                  type="text"
                                  disabled={data.cin}
                                  // defaultValue={data.cin}
                                  placeholder="Enter cin"
                                />
                              </div>
                            </Col>

                            {businessType === "registered" && (
                              <>
                                <Col md="6">
                                  <div className="form-group">
                                    <FormInput
                                      type="text"
                                      label="GSTIN"
                                      name="gstin"
                                      required={true}
                                      disabled={data.gstin}
                                      // defaultValue={data.gstin}
                                      placeholder="Enter GSTIN"
                                      pattern={
                                        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                                      }
                                    />
                                  </div>
                                </Col>
                                {verifyingGstin && (
                                  <Col
                                    lg="4"
                                    className="d-flex align-items-end"
                                  >
                                    <Spinner
                                      size="sm"
                                      color="primary"
                                      className="m-2"
                                    />
                                  </Col>
                                )}
                                <span style={{ display: "none" }}>
                                  <FormInput name="gstinVerified" label="" />
                                </span>
                              </>
                            )}

                            {businessType === "registered" &&
                              !verifyingGstin && (
                                <>
                                  <Col lg="4">
                                    <FormInput
                                      name="pan"
                                      label="PAN"
                                      required={true}
                                      disabled={
                                        data.bussinessType === "registered"
                                      }
                                      defaultValue={data.pan}
                                      type="text"
                                      placeholder="Enter PAN"
                                      pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
                                    />
                                  </Col>
                                  <Col md="6">
                                    <div className="form-group">
                                      {/* <label
                                  className="form-label"
                                  htmlFor="incorporationDate"
                                >
                                  Incorporation Date
                                </label> */}
                                      <FormDatePicker
                                        name="incorporationDate"
                                        label="Incorporation Date"
                                        disabled={businessType === "registered"}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="form-group">
                                      <FormInput
                                        type="number"
                                        label="Last FY Turn over"
                                        name="lastFYTurnover"
                                        disabled={businessType === "registered"}
                                        defaultValue={data.lastFYTurnover}
                                        placeholder="Enter Last FY Turn over"
                                      />
                                    </div>
                                  </Col>
                                </>
                              )}
                          </Row>
                          <Row className="gy-4 mt-1">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ContactInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Contact Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitForm)}
                        >
                          <Row className="gy-4">
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  name="contactPerson"
                                  label="Contact Person"
                                  type="text"
                                  required={true}
                                  defaultValue={formData.contactPerson}
                                  placeholder="Enter Contact Person"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  pattern={/^\d+$/} // Allows only digits
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ); // Removes non-numeric characters
                                  }}
                                  label="Phone Number"
                                  name="phoneNumber"
                                  required={true}
                                  defaultValue={formData.phoneNumber}
                                  placeholder="Enter Phone Number"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  label="Email"
                                  disabled={true}
                                  required={true}
                                  name="email"
                                  defaultValue={formData.email}
                                  placeholder="Enter Email"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormSelect
                                  label="Country"
                                  name="country"
                                  options={countries}
                                  // disabled
                                  required={true}
                                  // defaultValue={countries.find(
                                  //   (option) =>
                                  //     option.value == formData.country.countryId
                                  // )}
                                  onChange={(value: any) => {
                                    // setFormData({
                                    //   ...formData,
                                    //   country: value,
                                    // });
                                    fetchStatesByCountry(value);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormSelect
                                  name="state"
                                  label="State"
                                  // disabled
                                  options={states}
                                  required={true}
                                />
                              </div>
                            </Col>
                            <Col lg="4">
                              <div className="form-group">
                                <FormSelect
                                  name="district"
                                  label="District"
                                  options={districtOptions}
                                  required={true}
                                  // disabled={true}
                                  placeholder="Select District"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  label="City"
                                  // disabled
                                  required={true}
                                  name="city"
                                  // defaultValue={formData.city}
                                  placeholder="Enter City"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  label="Area"
                                  // disabled
                                  required={true}
                                  name="area"
                                  // defaultValue={formData.area}
                                  placeholder="Enter Area"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  label="Zip/Pincode"
                                  type="text"
                                  pattern={/^\d+$/} // Allows only digits
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ); // Removes non-numeric characters
                                  }}
                                  name="pincode"
                                  required={true}
                                  // disabled
                                  // defaultValue={formData.pincode}
                                  placeholder="Enter Zip/Pincode"
                                />
                              </div>
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="addressLine1"
                                label="Address Line 1"
                                // disabled
                                required={true}
                                placeholder="Enter Site Address"
                                type="text"
                              />
                            </Col>
                            <Col lg="4">
                              <FormInput
                                name="addressLine2"
                                label="Address Line 2"
                                // required={true}
                                // disabled
                                placeholder="Enter Address Line 2"
                                type="text"
                              />
                            </Col>
                            <Col lg="4">
                              <FormSelect
                                name="applicationAccess"
                                // disabled={action === "view"}
                                label="Application Access"
                                required={true}
                                options={[
                                  { label: "Yes", value: "Yes" },
                                  { label: "No", value: "No" },
                                ]}
                                placeholder="Enter Application Access"
                              />
                            </Col>
                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "BankInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Bank Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(docUploadSubmit)}
                        >
                          <Row className="gy-4">
                            <Col lg="4">
                              <FormInput
                                name="accountHolderName"
                                type="text"
                                label="Company Account Name"
                                required={true}
                                placeholder="Enter Company Account Name"
                              />
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="number"
                                  required={true}
                                  name="accountNumber"
                                  label="Account Number"
                                  defaultValue={formData.accountNumber}
                                  placeholder="Enter Account Number"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="bankName"
                                  required={true}
                                  label="Bank Name"
                                  defaultValue={formData.bankName}
                                  placeholder="Enter Bank Name"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="branch"
                                  label="Branch"
                                  required={true}
                                  defaultValue={formData.branch}
                                  placeholder="Enter Branch"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="text"
                                  name="ifscCode"
                                  label="IFSC Code"
                                  pattern={/^[A-Z]{4}0[A-Z0-9]{6}$/}
                                  maxLength={11}
                                  required={true}
                                  defaultValue={formData.ifscCode}
                                  placeholder="Enter IFSC Code"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="form-group">
                                <FormInput
                                  type="file"
                                  required={docs?.[0].filePath ? false : true}
                                  name="cancelledCheque"
                                  label="Upload Cancelled Cheque"
                                />
                                {cancelledChequeWatch && (
                                  <div>{cancelledChequeWatch[0]?.name}</div>
                                )}
                                {!cancelledChequeWatch && (
                                  <div>{docs?.[0].fileName}</div>
                                )}
                              </div>
                            </Col>

                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button color="primary" size="lg">
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      reset(formData);
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}
              {modalEditBlockName === "AdditionalContactInformation" && (
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitForm)}>
                      <div className="">
                        <div className="nk-modal-head">
                          <h5 className="title">
                            Edit Additional Contact Information{" "}
                          </h5>
                        </div>
                        <div className="nk-tnx-details mt-sm-3">
                          <Row className="gy-4">
                            <Col md="12">
                              {additionalContactsFields?.map((field, index) => {
                                return (
                                  <div key={field.id} className="row mb-3">
                                    <div className="col-md-2">
                                      <FormInput
                                        name={`additionalContacts.${index}.name`}
                                        label="Name"
                                        type="text"
                                        placeholder="Enter Name"
                                        required={true}
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <FormInput
                                        name={`additionalContacts.${index}.designation`}
                                        label="Designation"
                                        type="text"
                                        required={true}
                                        placeholder="Select Designation"
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <FormInput
                                        name={`additionalContacts.${index}.phoneNumber`}
                                        label="Phone Number"
                                        required={true}
                                        type="text"
                                        pattern={/^\d+$/} // Allows only digits
                                        onInput={(e: any) => {
                                          e.target.value =
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            ); // Removes non-numeric characters
                                        }}
                                        placeholder="Enter Phone Number"
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <FormInput
                                        name={`additionalContacts.${index}.email`}
                                        label="Email"
                                        required={true}
                                        // disabled={action === "view"}
                                        type="email"
                                        maxLength={50}
                                        placeholder="Enter Email address"
                                        pattern={
                                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/
                                        }
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <div style={{ marginTop: 30 }}>
                                        <button
                                          type="button"
                                          className="btn btn-success ms-2"
                                          onClick={() =>
                                            appendAdditionalContacts({
                                              name: "",
                                              designation: "",
                                              phoneNumber: "",
                                              email: "",
                                            })
                                          }
                                        >
                                          +
                                        </button>
                                        &nbsp;
                                        {index > 0 && (
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() =>
                                              removeAdditionalContacts(index)
                                            }
                                          >
                                            -
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="md"
                                    type="submit"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ViewSuppliersDetails;

import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function PurchaseBySupplier() {
  const navigate = useNavigate();

  const columns: any = [
    {
      key: "supplierName",
      title: "Supplier Name",
      width: 300,
      dataIndex: "supplierName",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(
                `/purchase-by-supplier-detail-report/${record?.supplierId}`,
                {
                  state: {
                    name: record?.supplierName,
                  },
                }
              );
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      key: "totalQuotationsRequested",
      title: "Quotations Requested",
      dataIndex: "totalQuotationsRequested",
    },
    {
      key: "totQuoteResponses",
      title: "Quote Responses",
      dataIndex: "totQuoteResponses",
      render: (text: any, record: any) => (
        <span>
          {text} {"("}
          {record?.quotationResponsePercentage || 0}%{")"}
        </span>
      ),
    },
    {
      key: "avgResponseTime",
      title: "Average Response Time",
      dataIndex: "avgResponseTime",
    },
    {
      key: "totalAwards",
      title: "Awards",
      dataIndex: "totalAwards",
      render: (text: any, record: any) => (
        <span>
          {text} {"("}
          {record?.responseToAwardPercentage || 0}%{")"}
        </span>
      ),
    },
    // {
    //   key: "responseToAwardPercentage",
    //   title: "Response To Award Percentage",
    //   dataIndex: "responseToAwardPercentage",
    //   render:(text: any, record: any) =><span>{text}%</span>,
    // },
    {
      key: "amountWithOutTax",
      title: "Awarded Value Without Tax",
      dataIndex: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      key: "amountWithTax",
      title: "Awarded Value With Tax",
      dataIndex: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Awarded To Supplier"
      pageDescription="Finalized orders awarded based on submitted quotations"
      moduleKey="purchaseBySupplier"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-purchase-reports"
    />
  );
}

export default PurchaseBySupplier;

import { Descriptions, Modal as AntModal, Empty } from "antd";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import {
  PaperClipOutlined,
  DownloadOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
const VersionControl_Others: FC<any> = ({ docs }) => {
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [instructions, setInstructions] = useState("");
  const [attachments, setAttachments] = useState<any>([]);
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    formatDocs();
  }, [docs]);

  const formatDocs = async () => {
    if (docs) {
      const _termsAndConditions = docs?.find(
        (item: any) => item.moduleFileType == "termsAndConditions"
      );
      if (_termsAndConditions) {
        const data: any = await fetchHtmlContentFromUrl(
          _termsAndConditions?.filePath
        );
        setTermsAndConditions(data);
      }
      const _instructions = docs?.find(
        (item: any) => item.moduleFileType == "instructions"
      );
      if (_instructions) {
        const data: any = await fetchHtmlContentFromUrl(
          _instructions?.filePath
        );
        setInstructions(data);
      }
      const _attachments = docs?.filter(
        (item: any) => item.moduleFileType == "attachments" && item.docHash
      );
      if (_attachments) {
        setAttachments(
          _attachments.map((attachment: any) => ({
            docId: attachment?.docId,
            name: attachment?.fileName,
            link: attachment?.filePath,
            moduleFileType: attachment?.moduleFileType,
            module: attachment?.module,
            label: "attachments",
            isActive: true,
          }))
        );
      }
    }
  };

  return (
    <>
      <div>
        <Descriptions title="" className="custom-description-title">
          <Descriptions.Item label="Instructions">
            <span
              style={{
                color: "#2263b3",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setInstructionsVisible(true)}
            >
              View
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Terms and Conditions">
            <span
              style={{
                color: "#2263b3",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setTermsVisible(true)}
            >
              View
            </span>
          </Descriptions.Item>

          <Descriptions.Item label="">
            <div>
              <strong className="">
                Attachments &nbsp;
                <PaperClipOutlined />
              </strong>
              <div>
                {attachments?.map((attachment: any, index: number) => (
                  <div
                    key={index}
                    className="w-100 d-flex justify-content-between"
                  >
                    <div>
                      <strong>{index + 1}.</strong>
                      &nbsp;
                      {attachment.name}
                      &nbsp; &nbsp; &nbsp; &nbsp;
                    </div>
                    <div>
                      <a
                        href={attachment.link}
                        download={attachment.link}
                        target="_blank" // Opens in a new tab
                        rel="noopener noreferrer" // Adds security
                        style={{
                          color: "black",
                          fontSize: "18px",
                        }}
                      >
                        <DownloadOutlined />
                      </a>
                      &nbsp; &nbsp;
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Descriptions.Item>
        </Descriptions>
      </div>
      <AntModal
        title={undefined}
        visible={instructionsVisible}
        footer={null}
        onCancel={() => {
          setInstructionsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Instructions </h5>
        </div>
        {instructions ? (
          <pre className="m-3">{instructions || ""}</pre>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Instructions Available for this Purchase Order" />
          </div>
        )}
      </AntModal>
      <AntModal
        title={undefined}
        visible={termsVisible}
        footer={null}
        onCancel={() => {
          setTermsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Terms and Conditions</h5>
        </div>
        {termsAndConditions ? (
          <pre className="m-3">{termsAndConditions || ""}</pre>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Terms and Conditions Available for this Purchase Order" />
          </div>
        )}
      </AntModal>
    </>
  );
};

export default VersionControl_Others;

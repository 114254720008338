import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Icon,
  TooltipComponent,
  UserAvatar,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { findUpper } from "../../utils/Utils";
import miscService from "../../api/misc/misc";
import dayjs from "dayjs";
import { Flex, Spin } from "antd";
import { useNavigate } from "react-router";
import { path } from "d3";

const modulePaths: any = {
  "Purchase Request Pending Approval": {
    path: "/view_pr",
    paramKey: "prId",
  },
  "Quotation Ready for Approval": {
    path: "/view",
    paramKey: "quotationId",
  },
  //   //supplier
  //   "New Quotation Request Received": {
  //     path: "/view-rfqs-details",
  //     paramKey: "quotationId",
  //   },
  "Supplier Response Received": {
    path: "/rfq-full-view",
    paramKey: "quotationId",
  },
  "Quotation Comparison Approval Required": {
    path: "/qc-approvals",
    paramKey: "",
    sideDrawerKey: "qcId",
  },
  "Purchase Order Pending Approval": {
    path: "/approvals-purchase-order",
    paramKey: "orderNumber",
  },
  //supplierSide
  "New Purchase Order Received": {
    path: "/view-purchase-order",
    paramKey: "orderNumber",
  },
  "Invoice Pending Internal Approval": {
    path: "/view-invoice",
    paramKey: "invoiceNumber",
  },
  "New Invoice Received": {
    path: "/view-invoice",
    paramKey: "invoiceNumber",
  },
  "Goods Receipt Note (GRN) Confirmed": {
    path: "/view-dispatch-details",
    paramKey: "grnNumber",
  },
  "Payment Pending Approval": {
    path: "/pending-payment-approvals",
    paramKey: "",
    sideDrawerKey: "paymentNumber",
  },
  "Payment Sent": {
    path: "/payment-pending-approvals",
    paramKey: "paymentNumber",
  },
  "Payment Confirmation Received": {
    path: "/view-payments",
    paramKey: "",
    sideDrawerKey: "paymentNumber",
  },
  "Material Issue Approval": {
    path: "/view-material-issue-details",
    paramKey: "materialIssueId",
  },
  "Budget Creation Approval": {
    path: "/view_budget_details",
    paramKey: "headerId",
  },
  "Budget Approval Confirmation": {
    path: "/view_budget_details",
    paramKey: "headerId",
  },
  "Budget Rejection Notification": {
    path: "/view_budget_details",
    paramKey: "headerId",
  },
  "Budget Update Approval": {
    path: "/view_budget_details",
    paramKey: "headerId",
  },
  "Budget Update Approval Confirmation": {
    path: "/view_budget_details",
    paramKey: "headerId",
  },
  //   "Material Handover Notification": {
  //     path: "/module-b/details",
  //     paramKey: "purchaseRequestId",
  //   },
  //   "Material Utilization Update": {
  //     path: "/module-b/details",
  //     paramKey: "purchaseRequestId",
  //   },
  //   "Material Damage Notification": {
  //     path: "/module-b/details",
  //     paramKey: "purchaseRequestId",
  //   },
  //   "Material Return Notification": {
  //     path: "/module-b/details",
  //     paramKey: "purchaseRequestId",
  //   },
};

const InboxMessages: React.FC<any> = ({ mailId, toggleMessageView }) => {
  const [mailData, setMailData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const deleteInbox = (id: any) => {};
  // const scrollToBottom = () => {
  //   if (messagesEndRef.current) {
  //     const scrollHeight = messagesEndRef.current.scrollHeight;
  //     const height = messagesEndRef.current.clientHeight;
  //     const maxScrollTop = scrollHeight - height;
  //     messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  //   }
  // };

  useEffect(() => {
    console.log("mailId", mailId);
    if (mailId) {
      fetchNotificationById();
    }
  }, []);

  const fetchNotificationById = async () => {
    setLoading(true);
    try {
      const { response, status } = await miscService.viewNotificationById(
        mailId
      );
      if (status) {
        setMailData(response);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Flex align="center" gap="middle" className="pageLoader">
            <Spin size="large" className="m-auto" />
          </Flex>
        </>
      ) : (
        <>
          <div className="nk-ibx-head">
            <div className="nk-ibx-head-actions">
              <ul className="nk-ibx-head-tools g-1">
                <li className="ms-n2" onClick={() => toggleMessageView()}>
                  <a
                    href="#item"
                    // onClick={}
                    className="btn btn-icon btn-trigger nk-ibx-hide"
                  >
                    <Icon name="arrow-left"></Icon>
                  </a>
                </li>
                <li onClick={() => deleteInbox(mailData?.notificationId)}>
                  <TooltipComponent
                    tag="a"
                    iconClass=""
                    containerClassName="btn btn-icon btn-trigger"
                    icon="trash"
                    id="ibx-msg-trash"
                    direction="top"
                    text="Delete"
                  />
                </li>
              </ul>
            </div>
          </div>
          <SimpleBar
            className="nk-ibx-reply nk-reply"
            scrollableNodeProps={{ ref: messagesEndRef }}
          >
            <div className="nk-ibx-reply-head">
              <div>
                <h4 className="title">
                  {mailData?.messageTitle ? mailData?.messageTitle : ""}
                </h4>
                <ul className="nk-ibx-tags g-1">
                  <li className="btn-group is-tags" key={mailData?.moduleId}>
                    <Button color="primary" size="sm" className="btn-dim">
                      {mailData?.moduleName}
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nk-ibx-reply-group">
              <div className="nk-ibx-reply-item nk-reply-item">
                <div className={`nk-reply-header nk-ibx-reply-header`}>
                  <div className="nk-reply-desc">
                    <UserAvatar
                      size="sm"
                      type="square"
                      className="nk-reply-avatar"
                      text=""
                      theme=""
                      icon="user"
                      image=""
                      imageAlt="user"
                    />
                    <div className="nk-reply-info">
                      <div className="nk-reply-author lead-text">
                        {mailData?.senderName}{" "}
                      </div>
                    </div>
                  </div>
                  <ul className="nk-reply-tools g-1">
                    <li className="date-msg">
                      <span className="date">
                        {dayjs(mailData?.createdAt).format("DD-MM-YYYY HH:mm")}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className={`nk-reply-body nk-ibx-reply-body is-shown`}>
                  <div
                    className="nk-reply-entry entry"
                    style={{ fontSize: "0.875rem" }}
                  >
                    <p>Hello,</p>
                    <br></br>
                    <p>{mailData?.message}</p>
                    <br></br>
                    <p>Thank you.</p>
                  </div>
                </div>
                <br></br>
                <div className="alert alert-info" role="alert">
                  <Icon name="info"></Icon>
                  <span>
                    {" "}
                    <a
                      href="#"
                      className="alert-link"
                      onClick={(e) => {
                        e.preventDefault();
                        const moduleConfig = modulePaths[mailData?.moduleType];
                        const path = moduleConfig?.path;
                        const paramKey = moduleConfig?.paramKey;
                        const sideDrawerKey = moduleConfig?.sideDrawerKey;

                        const paramValue = paramKey
                          ? `/${mailData?.params?.[paramKey]}`
                          : ``;

                        navigate(`${path}${paramValue}`, {
                          state: {
                            showSideDrawer: true,
                            id: mailData?.params?.[sideDrawerKey],
                          },
                        });
                      }}
                    >
                      {modulePaths[mailData?.moduleName]}
                      {`Click here to see the details for ${
                        mailData?.moduleName || "General"
                      }`}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </SimpleBar>
        </>
      )}
    </>
  );
};

export default InboxMessages;

import makeApiCall from "../master/axiosIndex";
const boqApis = {
  fetchConsumptionTarget: (params: any): Promise<any> =>
    makeApiCall<any>({
      url: "/inventory/consumptionTarget/list",
      method: "get",
      params,
    }),
  newConsumptionTarget: (data: any): Promise<any> =>
    makeApiCall({
      url: "/inventory/consumptionTarget/new",
      method: "post",
      data,
    }),

  checkConsumptionTargetValidity: (data: any): Promise<any> =>
    makeApiCall({
      url: "/inventory/consumptionTarget/checkValidHeader",
      method: "post",
      data,
    }),

  fetchViewDeatils: (targetId: any): Promise<any> =>
    makeApiCall<any>({
      url: "/inventory/consumptionTarget/view",
      method: "get",
      params: { targetId },
    }),

  newVersionboq: (data: any) =>
    makeApiCall({
      url: "/gateWay/inventory/consumptionTarget/update",
      method: "put",
      data,
    }),

  fetchConsumptionTargetMaterialDeviationsList: ({
    sortOrder,
    pageSize,
    direction,
    pageDiff,
    paginationId,
    deviation,
    siteIds,
    blockIds,
  }: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/inventory/consumptionTargetMaterialDeviationsList",
      params: {
        sortOrder,
        pageSize,
        direction,
        pageDiff,
        paginationId,
        deviation,
        siteIds,
        blockIds,
      },
    }),
};

export default boqApis;

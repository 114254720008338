import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../components/Component";
import { Steps, Step } from "react-step-builder";
import { Link } from "react-router-dom";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { Flex, Spin, Table } from "antd";
import VersionControl_GeneralDetails from "./VersionControl_GeneralDetails";
import VersionControl_Materails from "./VersionControl_Materails";
import VersionControl_Others from "./VersionControl_Others";
import { use } from "echarts";

const VersionControlDefaultTemplate: FC<any> = ({
  displayFields,
  title,
  backPath,
  apiResponse,
  materialColumns,
  extraMaterialInfo,
  versionHistoryPR,
}) => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const formMethods = useForm({
    mode: "onChange",
  });
  const { watch, register, reset, setValue } = formMethods;
  const [loading, setLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(1); // Track the current step

  const goToStep = (step: number) => {
    setCurrentStep(step);
  };

  const [versions, setVersions] = useState([]);
  const [gendralDetailsData, setGendralDetailsData] = useState<any>();

  useEffect(() => {
    if (displayFields) {
      setGendralDetailsData(transformDataForTable(apiResponse, displayFields));
    }
  }, [displayFields]);
  useEffect(() => {
    console.log(
      "111",
      apiResponse?.others?.docs?.filter(
        (item: any) => item.moduleFileType === "signature"
      )
    );
    setVersions(apiResponse?.headers?.map((v: any) => `version_${v.version}`));
  }, [apiResponse]);

  const gendralDetailsColumns: any = [
    {
      title: "Field Name",
      dataIndex: "field",
      key: "field",
      fixed: "left",
      width: 250,
      render: (text: any) => <strong>{text}</strong>,
    },
    ...versions.map((version: any, index: any) => ({
      title: `Version ${index + 1}`,
      dataIndex: version,
      key: version,
      render: (text: any) => {
        if (text === null) return "N/A ";
        return text;
      },
    })),
  ];

  const transformDataForTable = (apiData: any, fields: any) => {
    return fields.map(({ label, key, render }: any) => {
      let row: any = { field: label }; // Use label for display
      apiData?.headers?.forEach((versionEntry: any) => {
        let currentValue = versionEntry[key] || "N/A";

        if (render) {
          currentValue = render(currentValue, versionEntry);
        }

        // Check if the field is marked as modified in isModified
        const isModified =
          versionEntry.isModified && versionEntry.isModified[key];

        row[`version_${versionEntry.version}`] = isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {currentValue}
          </strong>
        ) : (
          currentValue
        );
      });

      return row;
    });
  };

  // const gendralDetailsData = transformDataForTable(apiResponse, displayFields);

  // const latestMaterialData = apiResponse.lines.map((item: any, index: any) => {
  //   return {
  //     key: index,
  //     version: `Version ${item.version}`,
  //     category: item.categoryName,
  //     materialName: item.materialName,
  //     deliveryDate:
  //       item.versions.length > 0 &&
  //       item.versions[0].isModified?.deliveryDate ? (
  //         <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //           {item.deliveryDate || "N/A"}
  //         </strong>
  //       ) : (
  //         item.deliveryDate || "N/A"
  //       ),
  //     specification:
  //       item.versions.length > 0 &&
  //       item.versions[0].isModified?.specification ? (
  //         <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //           {item.specification || "N/A"}
  //         </strong>
  //       ) : (
  //         item.specification || "N/A"
  //       ),
  //     qty:
  //       item.versions.length > 0 && item.versions[0].isModified?.quantity ? (
  //         <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //           {item.quantity}
  //         </strong>
  //       ) : (
  //         item.quantity
  //       ),
  //     uom:
  //       item.versions.length > 0 && item.versions[0].isModified.uomName ? (
  //         <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //           {item.uomName}
  //         </strong>
  //       ) : (
  //         item.uomName
  //       ),
  //     versions: item.versions,
  //   };
  // });

  // const expandedRowRender = (record: any) => {
  //   console.log("versions", record);
  //   const versionData = record?.versions?.map((version: any) => {
  //     return {
  //       version: `Version ${version.version}`,
  //       category: record.category,
  //       materialName: record.materialName,
  //       deliveryDate: version.isModified.deliveryDate ? (
  //         <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //           {version.deliveryDate || "N/A"}
  //         </strong>
  //       ) : (
  //         version.deliveryDate || "N/A"
  //       ),
  //       specification: version.isModified.specification ? (
  //         <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //           {version.specification || "N/A"}
  //         </strong>
  //       ) : (
  //         version.specification || "N/A"
  //       ),
  //       qty: version.isModified.quantity ? (
  //         <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //           {version.quantity}
  //         </strong>
  //       ) : (
  //         version.quantity
  //       ),
  //       uom: version.isModified.uomName ? (
  //         <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //           {version.uomName}
  //         </strong>
  //       ) : (
  //         version.uomName
  //       ),
  //     };
  //   });
  //   console.log("versionData", versionData);
  //   return (
  //     <Table
  //       style={{ margin: "2%" }}
  //       columns={[
  //         { title: "Version", dataIndex: "version", key: "version" },
  //         { title: "Category", dataIndex: "category", key: "category" },
  //         {
  //           title: "Material Name",
  //           dataIndex: "materialName",
  //           key: "materialName",
  //         },
  //         {
  //           title: "Specification",
  //           dataIndex: "specification",
  //           key: "specification",
  //         },
  //         { title: "QTY", dataIndex: "qty", key: "qty" },
  //         { title: "UOM", dataIndex: "uom", key: "uom" },
  //         {
  //           title: "Delivery Date",
  //           dataIndex: "deliveryDate",
  //           key: "deliveryDate",
  //         },
  //       ]}
  //       dataSource={versionData}
  //       pagination={false}
  //       size="small"
  //       bordered
  //     />
  //   );
  // };

  // Generate dynamic data for materials table based on received columns
  // const latestMaterialData = apiResponse.lines.map((item: any, index: any) => {
  //   let rowData: any = { key: index, version: `Version ${item.version}` };

  //   materialColumns?.forEach(({ dataIndex }: any) => {
  //     const isModified =
  //       item.versions.length > 0 && item.versions[0].isModified?.[dataIndex];

  //     rowData[dataIndex] = isModified ? (
  //       <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //         {item[dataIndex] || "N/A"}
  //       </strong>
  //     ) : (
  //       item[dataIndex] || "N/A"
  //     );
  //   });

  //   rowData.versions = item.versions;
  //   return rowData;
  // });

  const latestMaterialData = apiResponse?.lines?.map(
    (item: any, index: any) => ({
      key: index,
      version: `Version ${item.version}`,
      ...item, // Spread item to keep all properties
    })
  );

  // const versionData = record?.versions?.map((version: any) => {
  //   let rowData: any = { version: `Version ${version.version}` };

  //   materialColumns.forEach(({ dataIndex }: any) => {
  //     const isModified = version.isModified?.[dataIndex];

  //     rowData[dataIndex] = isModified ? (
  //       <strong style={{ background: "#e4cfcf", padding: "2px" }}>
  //         {version[dataIndex] || "N/A"}
  //       </strong>
  //     ) : (
  //       version[dataIndex] || "N/A"
  //     );
  //   });

  //   return rowData;
  // });

  // Expanded Row Renderer (For Versions)
  const expandedRowRender = (record: any) => {
    const versionData = record?.versions?.map((version: any) => ({
      version: `Version ${version.version}`,
      ...version, // Spread version data directly
    }));

    return (
      <Table
        style={{ margin: "2%" }}
        columns={materialColumns} // <-- Use dynamic material columns
        dataSource={versionData}
        pagination={false}
        size="small"
        bordered
      />
    );
  };

  return (
    <>
      <Head title="User List - Profile" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">{title}</BlockTitle>
                  </BlockHeadContent>
                  <Link to={`${backPath}`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block className="mt-1">
                {loading == true ? (
                  <>
                    <Flex align="center" gap="middle" className="pageLoader">
                      <Spin size="large" className="m-auto" />
                    </Flex>
                  </>
                ) : (
                  <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                    <FormProvider {...formMethods}>
                      <div id="poForms" className="steps clearfix">
                        <ul>
                          <li
                            className={
                              currentStep >= 1 ? "first done" : "first"
                            }
                            onClick={() => goToStep(1)}
                          >
                            <a
                              href="#wizard-01-h-0"
                              onClick={(ev) => ev.preventDefault()}
                            >
                              <h5>General Details</h5>
                            </a>
                          </li>
                          <li
                            className={currentStep >= 2 ? "done" : ""}
                            onClick={() => goToStep(2)}
                          >
                            <a
                              href="#wizard-01-h-2"
                              onClick={(ev) => ev.preventDefault()}
                            >
                              <h5>Material/Service Details</h5>
                            </a>
                          </li>
                          {!versionHistoryPR && (
                            <li
                              className={currentStep >= 3 ? "done" : ""}
                              onClick={() => goToStep(3)}
                            >
                              <a
                                href="#wizard-01-h-3"
                                onClick={(ev) => ev.preventDefault()}
                              >
                                <h5>Others</h5>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      {currentStep === 1 && (
                        <VersionControl_GeneralDetails
                          gendralDetailsColumns={gendralDetailsColumns}
                          gendralDetailsData={gendralDetailsData}
                        />
                      )}
                      {currentStep === 2 && (
                        <VersionControl_Materails
                          columns={materialColumns}
                          latestMaterialData={latestMaterialData}
                          expandedRowRender={expandedRowRender}
                          orderAmountDetails={
                            apiResponse?.others.orderAmountDetails || {}
                          }
                          _signature={
                            apiResponse?.others?.docs?.filter(
                              (item: any) => item.moduleFileType === "signature"
                            ) || []
                          }
                          extraMaterialInfo={extraMaterialInfo}
                        />
                      )}
                      {currentStep === 3 && (
                        <VersionControl_Others
                          docs={
                            apiResponse?.others?.docs?.filter(
                              (item: any) => item.moduleFileType !== "signature"
                            ) || []
                          }
                        />
                      )}
                    </FormProvider>
                  </div>
                )}
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default VersionControlDefaultTemplate;

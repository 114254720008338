import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import { formatCurrency } from "../../../utils/Utils";

function BudgetTrackReport() {
  const columns: any = [
    {
      title: "Budget ID",
      key: "budgetId",
      dataIndex: "budgetId",
      width: 150,
    },
    {
      title: "Budget Name",
      key: "budgetName",
      dataIndex: "budgetName",
      width: 250,
    },
    {
      title: "Budget Line ID",
      key: "budgetLineId",
      dataIndex: "budgetLineId",
      width: 150,
    },
    {
      title: "Budget Line Name",
      key: "budgetLineName",
      dataIndex: "budgetLineName",
      width: 250,
    },
    {
      title: "Budget Value",
      key: "budgetValue",
      dataIndex: "budgetValue",
      width: 150,
      render: (text: any, record: any) => (
        <p className="justify-end">{formatCurrency(Number(text), "INR")}</p>
      ),
    },
    {
      title: "Site Name",
      key: "siteName",
      dataIndex: "siteName",
      width: 150,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 150,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 150,
    },
    {
      title: "Consumption Value",
      key: "consumptionValue",
      dataIndex: "consumptionValue",
      width: 150,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {text !== null ? formatCurrency(text?.toString(), "INR") : "N/A"}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 150,
      render: (text: string) => (
        <span style={{ color: text === "Under Utilized" ? "green" : "red" }}>
          {text}
        </span>
      ),
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Budget Tracking"
      pageDescription="Monitoring expenses against allocated budgets for projects or sites."
      moduleKey={window.location.pathname}
      columns={columns}
      extra={{
        tableScrollX: 1700,
      }}
      filtersOption={{
        site: true,
        category: true,
        material: true,
        dateRange: true,
      }}
      previousPath="/view-budget-reports"
    />
  );
}

export default BudgetTrackReport;

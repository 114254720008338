import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Spinner, Alert } from "reactstrap";
import Cookies from "js-cookie";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import LogoDark2x from "../../images/logo-dark2x.png";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";

import { LoginCredentials } from "../../api/types/auth/authTypes";
// import { login } from "../../api/auth/authService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchCurrentUser, logout } from "../../store/slices/misc/currentUser";
import authService from "../../api/auth/authService";
import axios from "axios";
import { ReactTyped } from "react-typed";

const Login: React.FC<any> = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [passState, setPassState] = useState<boolean>(false);
  const [errorVal, setError] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCredentials>();

  const onFormSubmit = async (formData: LoginCredentials) => {
    setLoading(true);
    try {
      dispatch(logout());
      const data = await authService.login({
        ...formData,
        applicationType: "Buyer",
      });
      if (data.status && data.token && data.token.access_token) {
        Cookies.set("access-token", JSON.stringify(data.token), {
          expires: 7,
          secure: true,
          sameSite: "Strict",
        });
        Cookies.set("refresh-token", data.token.refresh_token, {
          expires: 7,
          secure: true,
          sameSite: "Strict",
        });
        const ipAddress = await getIpAddress();
        dispatch(fetchCurrentUser());
        authService.createLoginActivity({
          deviceInfo: getBrowserInfo(),
          ipAddress,
          loginDateTime: new Date().toISOString(),
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);

        setLoading(false);
        setIsLoggedIn(true);
      } else {
        setError(data.message);
        setLoading(false);
      }
    } catch (err: any) {
      console.log("Login failed:", err.message);
      setError(err.message);
      setLoading(false);
    }
  };

  const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    console.log("userAgent", userAgent);
    // alert(JSON.stringify(userAgent));
    const browserMapper = {
      Edg: "Microsoft Edge",
      OPR: "Opera",
      Vivaldi: "Vivaldi",
      SamsungBrowser: "Samsung Internet",
      QQBrowser: "QQ Browser",
      UCBrowser: "UC Browser",
      YaBrowser: "Yandex Browser",
      Silk: "Amazon Silk",
      Maxthon: "Maxthon",
      Brave: "Brave",
      Chrome: "Chrome",
      Safari: "Safari",
      Firefox: "Firefox",
      MSIE: "Internet Explorer",
      "Trident/": "Internet Explorer",
      IEMobile: "Internet Explorer",
      Opera: "Opera",
      Netscape: "Netscape",
      SeaMonkey: "SeaMonkey",
      Konqueror: "Konqueror",
      "Firefox Focus": "Firefox Focus",
      Iceweasel: "Firefox",
    };

    // Default to unknown
    let browserName = "Unknown";

    // Check each key in the mapper object
    for (const key in browserMapper) {
      if (userAgent.indexOf(key) > -1) {
        browserName = browserMapper[key as keyof typeof browserMapper];
        break;
      }
    }
    // alert(browserName);
    return browserName;
  };

  const getIpAddress = async () => {
    try {
      const response: any = await axios.get(
        "https://api.ipify.org?format=json"
      );
      const data = response.data;
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address: ", error);
    }
  };

  useEffect(() => {
    // const accessToken = Cookies.get('accessToken');
    // if (accessToken) {
    //   window.location.href = `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/dashboard"}`;
    // }
  }, []);

  return (
    <>
      <Head title="Login" />
      <div className="login-container pt-2">
        <>
          <div className="brand-logo pb-1 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="" src={LogoDark2x} alt="logo" />
            </Link>
          </div>
          <div className="d-flex justify-content-center h-100">
            <div className="header-area title">
              Revolutionizing Construction Through
              <span style={{ color: "rgb(37, 99, 235)" }}> AI</span> :
              Streamlining
              <span style={{ color: "rgb(37, 99, 235)" }}>
                <ReactTyped
                  strings={[`&nbsp; Procurement &nbsp;`]}
                  typeSpeed={20}
                  showCursor={false}
                />
              </span>
              Processes
            </div>
            <Block className="nk-block-middle nk-auth-body wide-xs w-50 pb-0">
              <PreviewCard
                className="login-previewCard card-bordered"
                bodyClass="card-inner-lg"
              >
                <BlockHead>
                  <BlockContent>
                    <BlockTitle tag="h4">Sign-In</BlockTitle>
                    <BlockDes>
                      <p>
                        <ReactTyped
                          strings={[
                            `Access Procurex using your email and password.`,
                          ]}
                          typeSpeed={10}
                          showCursor={false}
                        />
                      </p>
                    </BlockDes>
                  </BlockContent>
                </BlockHead>
                {errorVal && (
                  <div className="mb-3">
                    <Alert color="danger" className="alert-icon">
                      <Icon name="alert-circle" />
                      Unable to login with credentials
                    </Alert>
                  </div>
                )}
                <Form
                  className="is-alter"
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Email Address
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="default-01"
                        {...register("email", {
                          required: "This field is required",
                        })}
                        placeholder="Enter your email address"
                        className="form-control-lg form-control"
                      />
                      {errors.email && (
                        <span className="invalid">{errors.email.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                    </div>
                    <div className="form-control-wrap">
                      <a
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState(!passState);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      >
                        <Icon
                          name="eye"
                          className="passcode-icon icon-show"
                        ></Icon>
                        <Icon
                          name="eye-off"
                          className="passcode-icon icon-hide"
                        ></Icon>
                      </a>
                      <input
                        type={passState ? "text" : "password"}
                        id="password"
                        {...register("password", {
                          required: "This field is required",
                        })}
                        placeholder="Enter your passcode"
                        className={`form-control-lg form-control ${
                          passState ? "is-hidden" : "is-shown"
                        }`}
                      />
                      {errors.password && (
                        <span className="invalid">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <Button
                      size="lg"
                      className="btn-block"
                      type="submit"
                      color="primary"
                    >
                      {loading ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Sign in"
                      )}
                    </Button>
                  </div>
                </Form>
                <div className="form-note-s2 text-center pt-4">
                  Forgot Password?{" "}
                  <Link
                    className="link link-primary"
                    to={`${process.env.PUBLIC_URL}/auth-reset`}
                  >
                    Click here to reset
                  </Link>
                </div>
              </PreviewCard>
            </Block>
          </div>
        </>
        <div className="login-bgi"></div>
      </div>
      <AuthFooter />
    </>
  );
};

export default Login;

import React, { useEffect, FC } from "react";
import { Modal, ModalBody, Form } from "reactstrap";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Col,
  RSelect,
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Steps, Step } from "react-step-builder";
import { useForm, FormProvider } from "react-hook-form";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import BankAccountDetails from "./BankAccountDetails";
import ContactInformation from "./ContactInformation";
import GeneralInformation from "./GeneralInformation";
import LegalInformation from "./LegalInformation";
import AdditionalContactInformation from "./AdditionalContactInformation";

const config = {
  before: Header as React.ComponentType<{}> as () => React.ReactElement,
};

const AddSupplier: React.FC = (props: any) => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      additionalContacts: [
        {
          name: "",
          designation: "",
          phoneNumber: "",
          email: "",
        },
      ],
    },
  });
  useEffect(() => {}, []);

  const backList = () => {
    navigate(`/suppliers`);
  };

  return (
    <React.Fragment>
      <Head title="Regular Page v-1" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Create Suppliers</BlockTitle>
              <BlockDes>
                Onboard Suppliers platform, collecting essential information to
                create Suppliers profiles.
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                className="btn-dim"
                onClick={() => backList()}
              >
                <Icon name="menu-alt-r"></Icon>
                <span>List of Suppliers</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <FormProvider {...formMethods}>
                <Steps config={config}>
                  <Step component={GeneralInformation} />
                  <Step component={LegalInformation} />
                  <Step component={ContactInformation} />
                  <Step component={AdditionalContactInformation} />
                  <Step component={BankAccountDetails} />
                </Steps>
              </FormProvider>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default AddSupplier;

import React, { useEffect, useState } from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";
import TransactionFlowDefaultTemplate from "../../others/transactionFlowDefaultTemplate";
import { useDispatch, useSelector } from "react-redux";
import { setUploadHighlight } from "../../../store/slices/misc/highlightSlice";
import { ReactTyped } from "react-typed";
import { Drawer, Flex, Popover, Spin, Table, Tooltip } from "antd";
import { Badge, Button, Spinner } from "reactstrap";
import { Icon, TooltipComponent } from "../../../components/Component";
import miscService from "../../../api/misc/misc";
import Swal from "sweetalert2";

const drawerColConfig: any = {
  supplierCreate: {
    title: "Supplier Creation",
    columns: [
      {
        title: "Validation Status",
        dataIndex: "excelValidations",
        key: "validationStatus",
        width: 120,
        fixed: "left",
        render: (validations: string[], record: any) =>
          validations?.length > 0 ? (
            <Popover
              placement="right"
              content={
                <div>
                  <p>{validations?.join(", ")}</p>
                </div>
              }
              title={<span style={{ color: "red" }}>Failed</span>}
            >
              <Badge
                color="outline-danger"
                className="ms-3"
                placement="right"
                style={{ cursor: "pointer" }}
              >
                Failed
              </Badge>
            </Popover>
          ) : (
            <Popover
              content={
                <div>
                  <p>
                    {record?.supplierID} ({record?.supplierName}) Is Valid
                    Record
                  </p>
                </div>
              }
              placement="right"
              title={<span style={{ color: "green" }}>Success</span>}
            >
              <Badge
                color="outline-success"
                className="ms-3"
                style={{ cursor: "pointer" }}
              >
                Success
              </Badge>
            </Popover>
          ),
      },
      {
        title: "Supplier ID",
        dataIndex: "supplierID",
        key: "supplierID",
        width: 150,
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        width: 150,
      },
      {
        title: "Supplier Name",
        dataIndex: "supplierName",
        key: "supplierName",
        width: 200,
      },
      {
        title: "Account Number",
        dataIndex: "accountNumber",
        key: "accountNumber",
        width: 200,
      },
      {
        title: "CIN",
        dataIndex: "cin",
        key: "cin",
        width: 200,
      },
      {
        title: "PAN",
        dataIndex: "pan",
        key: "pan",
        width: 150,
      },
      {
        title: "Area",
        dataIndex: "area",
        key: "area",
        width: 150,
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
        width: 150,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
      },
      {
        title: "GSTIN",
        dataIndex: "gstin",
        key: "gstin",
        width: 200,
      },
      {
        title: "Branch",
        dataIndex: "branch",
        key: "branch",
        width: 150,
      },
      {
        title: "Pincode",
        dataIndex: "pincode",
        key: "pincode",
        width: 100,
      },
      {
        title: "Bank Name",
        dataIndex: "bankName",
        key: "bankName",
        width: 150,
      },
      {
        title: "IFSC Code",
        dataIndex: "ifscCode",
        key: "ifscCode",
        width: 150,
      },
      {
        title: "Brand Name",
        dataIndex: "brandName",
        key: "brandName",
        width: 150,
      },
      {
        title: "Trade Name",
        dataIndex: "tradeName",
        key: "tradeName",
        width: 150,
      },
      {
        title: "Categories",
        dataIndex: "categories",
        key: "categories",
        width: 150,
      },
      {
        title: "Credit Days",
        dataIndex: "creditDays",
        key: "creditDays",
        width: 120,
      },

      {
        title: "Address Line 1",
        dataIndex: "addressLine1",
        key: "addressLine1",
        width: 200,
      },
      {
        title: "Address Line 2",
        dataIndex: "addressLine2",
        key: "addressLine2",
        width: 200,
      },
      {
        title: "Payment Terms",
        dataIndex: "paymentTerms",
        key: "paymentTerms",
        width: 150,
      },

      {
        title: "Business Type",
        dataIndex: "bussinessType",
        key: "bussinessType",
        width: 150,
      },
      {
        title: "Contact Person",
        dataIndex: "contactPerson",
        key: "contactPerson",
        width: 200,
      },
      {
        title: "Delivery Lead Days",
        dataIndex: "deliveryLeadDays",
        key: "deliveryLeadDays",
        width: 150,
      },
      {
        title: "Account Holder Name",
        dataIndex: "accountHolderName",
        key: "accountHolderName",
        width: 200,
      },
      {
        title: "Application Access",
        dataIndex: "applicationAccess",
        key: "applicationAccess",
        width: 150,
      },
      {
        title: "Incorporation Date",
        dataIndex: "incorporationDate",
        key: "incorporationDate",
        width: 180,
        render: (text: any) => <span>{dayjs(text).format("DD-MM-YYYY")}</span>,
      },
    ],
    tableWidth: 5000,
  },
  materialCreate: {
    title: "Material Creation",
    columns: [
      {
        title: "Validation Status",
        dataIndex: "excelValidations",
        key: "validationStatus",
        width: 120,
        fixed: "left",
        render: (validations: string[], record: any) =>
          validations?.length > 0 ? (
            <Popover
              placement="right"
              content={
                <div>
                  <p>{validations?.join(", ")}</p>
                </div>
              }
              title={<span style={{ color: "red" }}>Failed</span>}
            >
              <Badge
                color="outline-danger"
                className="ms-3"
                placement="right"
                style={{ cursor: "pointer" }}
              >
                Failed
              </Badge>
            </Popover>
          ) : (
            <Popover
              content={
                <div>
                  <p>
                    {record?.materialCode} ({record?.materialName}) Is Valid
                    Record
                  </p>
                </div>
              }
              placement="right"
              title={<span style={{ color: "green" }}>Success</span>}
            >
              <Badge
                color="outline-success"
                className="ms-3"
                style={{ cursor: "pointer" }}
              >
                Success
              </Badge>
            </Popover>
          ),
      },
      // {
      //   title: "Material Code",
      //   dataIndex: "materialCode",
      //   key: "materialCode",
      //   width: 150,
      // },
      {
        title: "Material Name",
        dataIndex: "materialName",
        key: "materialName",
        width: 200,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: 200,
      },
      {
        title: "Product Life",
        dataIndex: "productLife",
        key: "productLife",
        width: 150,
      },
      {
        title: "Specification",
        dataIndex: "specification",
        key: "specification",
        width: 150,
      },
      {
        title: "UOM Conversion Ratio",
        dataIndex: "uomConversionRatio",
        key: "uomConversionRatio",
        width: 200,
      },
      {
        title: "Primary Unit of Measure",
        dataIndex: "primaryUnitOfMeasure",
        key: "primaryUnitOfMeasure",
        width: 200,
      },
      {
        title: "Secondary Unit of Measure",
        dataIndex: "secondaryUnitOfMeasure",
        key: "secondaryUnitOfMeasure",
        width: 200,
      },
    ],
    tableWidth: 1000,
  },
  stockCreate: {
    title: "Stock Creation",
    columns: [
      {
        title: "Validation Status",
        dataIndex: "excelValidations",
        key: "validationStatus",
        width: 120,
        fixed: "left",
        render: (validations: string[], record: any) =>
          validations?.length > 0 ? (
            <Popover
              placement="right"
              content={
                <div>
                  <p>{validations?.join(", ")}</p>
                </div>
              }
              title={<span style={{ color: "red" }}>Failed</span>}
            >
              <Badge
                color="outline-danger"
                className="ms-3"
                placement="right"
                style={{ cursor: "pointer" }}
              >
                Failed
              </Badge>
            </Popover>
          ) : (
            <Popover
              content={
                <div>
                  <p>
                    This Is Valid Record
                  </p>
                </div>
              }
              placement="right"
              title={<span style={{ color: "green" }}>Success</span>}
            >
              <Badge
                color="outline-success"
                className="ms-3"
                style={{ cursor: "pointer" }}
              >
                Success
              </Badge>
            </Popover>
          ),
      },
      {
        title: "Site ID",
        dataIndex: "siteID",
        key: "siteID",
        width: 100,
      },
      {
        title: "Material ID",
        dataIndex: "materialID",
        key: "materialID",
        width: 100,
      },
      {
        title: "Reorder Point",
        dataIndex: "reorderPoint",
        key: "reorderPoint",
        width: 100,
      },
      {
        title: "Stock Quantity",
        dataIndex: "stockQuantity",
        key: "stockQuantity",
        width: 100,
      },
      {
        title: "Maximum Stock Level",
        dataIndex: "maximumStockLevel",
        key: "maximumStockLevel",
        width: 100,
      },
      {
        title: "Minimum Stock Level",
        dataIndex: "minimumStockLevel",
        key: "minimumStockLevel",
        width: 100,
      },
    ],
    tableWidth: 500,
  },
};

const BulkUploadList = () => {
  const [screenSize, setScreenSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(null);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [activeReportId, setActiveReportId] = useState(null);
  const [pagination, setPagination] = useState<{
    pageSize: number;
    currentPage: number;
    totalPageCount: number;
    totalRowCount: number;
  }>({
    pageSize: 15,
    currentPage: 1,
    totalPageCount: 0,
    totalRowCount: 0,
  });
  const [drawerData, setDrawerData] = useState<{
    columns: any[];
    dataSource: any[];
    tableWidth: number;
  }>({
    columns: [],
    dataSource: [],
    tableWidth: 1000,
  });
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const uploadHighlighted = useSelector(
    (state: any) => state.highlight.uploadHighlighted
  );

  const handleCreation = async (reportId: any, requestType: any) => {
    try {
      const formData: any = new FormData();
      formData.append(
        "filesData",
        JSON.stringify({
          module: "masters",
          requestType,
          action: "Submit",
          reportId,
          processedExcelData: {},
        })
      );
      formData.append("files", JSON.stringify([]));
      const res = await miscService.bulkUpload(formData);
      const { status, error, message } = res || {};
      if (status) {
        setRefreshToggle((pre) => !pre);
        Swal.fire("Created", message, "success");
      }
    } catch (error) {
    } finally {
      setSubmitting(null);
    }
  };

  const fetchFileContent = async (
    pagination: {
      pageSize: number;
      currentPage: number;
      totalPageCount: number;
      totalRowCount: number;
    },
    reportId: any
  ) => {
    try {
      setLoading(true);
      const res = await miscService.fetchConvertedJsonListWithPagination({
        reportId,
        pageSize: pagination?.pageSize,
        paginationId: pagination?.currentPage,
      });
      const { status, data } = res || {};
      if (status) {
        const { count, list, noOfPages } = data;
        setPagination(() => {
          return {
            ...pagination,
            totalPageCount: noOfPages,
            totalRowCount: count,
          };
        });
        setDrawerData((pre) => {
          return {
            ...pre,
            dataSource: list,
          };
        });
        console.log("list", list);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  useEffect(() => {
    if (uploadHighlighted) {
      dispatch(setUploadHighlight(false));
    }
  }, [uploadHighlighted, dispatch]);

  const columns: any = [
    {
      title: "Report #",
      dataIndex: "reportId",
      key: "reportId",
      width: 200,
    },
    {
      title: "Report Name",
      dataIndex: "reportType",
      key: "reportType",
      width: 250,
      render: (text: any) => <span>{drawerColConfig[text]?.title}</span>,
    },
    {
      title: "Date & Time",
      dataIndex: "requestedDate",
      key: "requestedDate",
      width: 200,
      render: (text: any) => (
        <span>{dayjs(text)?.format("DD-MM-YYYY hh:mm:ss")}</span>
      ),
    },
    {
      title: "Status",
      key: "status",
      width: 200,
      render: (text: any, record: any) => {
        if (record?.uploadStatus == "Submit" && record?.status == "Processed")
          return (
            <div className="justify-start align-center">
              <h6
                className={`tb-status ms-1`}
                style={{
                  color: `green`,
                  fontSize: "14px",
                }}
              >
                <CheckCircleOutlined /> Created Successfully
              </h6>
            </div>
          );
        const status = record?.status;
        switch (status) {
          case "Processing":
            return (
              <div className="justify-start align-center">
                <h6
                  className={`tb-status ms-1`}
                  style={{
                    color: `orange`,
                    fontSize: "14px",
                  }}
                >
                  <SyncOutlined /> Processing{" "}
                  <ReactTyped
                    strings={["...."]}
                    typeSpeed={50}
                    backSpeed={10}
                    loop
                    showCursor={false}
                  />
                </h6>
              </div>
            );
          case "Failed":
            return (
              <div className="justify-start align-center">
                <h6
                  className={`tb-status ms-1`}
                  style={{
                    color: `red`,
                    fontSize: "14px",
                  }}
                >
                  <CloseCircleOutlined /> Failed
                </h6>
              </div>
            );
          case "Error":
            return (
              <div className="justify-start align-center">
                <h6
                  className={`tb-status ms-1`}
                  style={{
                    color: `red`,
                    fontSize: "14px",
                  }}
                >
                  <CloseCircleOutlined /> Error In File
                </h6>
              </div>
            );
          case "Processed":
            return (
              <div className="justify-start align-center">
                <h6
                  className={`tb-status ms-1`}
                  style={{
                    color: `green`,
                    fontSize: "14px",
                  }}
                >
                  <span id={`ProcessedStatus-${record?.reportId}`}>
                    <CheckCircleOutlined /> Processed Successfully
                  </span>
                  <TooltipComponent
                    iconClass="info-circle text-info"
                    icon={"info"}
                    id={`ProcessedStatus-${record?.reportId}`}
                    direction="left"
                    text={"Click On Create Button To Complete Creation Process"}
                    containerClassName={"mt-1"}
                  />
                </h6>
              </div>
            );
          default:
            return (
              <div className="justify-start align-center">
                <h6
                  className={`tb-status ms-1`}
                  style={{
                    color: `black`,
                    fontSize: "14px",
                  }}
                >
                  {record?.status}
                </h6>
              </div>
            );
        }
      },
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (text: any, record: any) => {
        if (record?.uploadStatus == "Submit" && record?.status == "Processed")
          return (
            <>
              <span
                className="inlineTableCellLink ms-3"
                id={`SuccessStatus-${record?.reportId}`}
                onClick={() => {
                  setDrawerData((pre: any) => {
                    const _config = drawerColConfig[record?.reportType] || {};
                    return {
                      ...pre,
                      columns: _config?.columns,
                      tableWidth: _config?.tableWidth,
                    };
                  });
                  setActiveReportId(record?.reportId);
                  setDrawerVisible(true);
                  fetchFileContent(pagination, record?.reportId);
                }}
              >
                View
              </span>
              <TooltipComponent
                iconClass="info-circle text-info"
                icon={""}
                id={`SuccessStatus-${record?.reportId}`}
                direction="right"
                text={"Click To See Uploaded File"}
                containerClassName={"mt-1"}
              />
            </>
          );
        const status = record?.status;
        switch (status) {
          case "Failed":
            return (
              <Popover
                content={
                  <div>
                    <p>{record?.error}</p>
                  </div>
                }
                placement="right"
                title={<span style={{ color: "red" }}>Failed</span>}
              >
                <Badge
                  color="outline-danger"
                  className="ms-3"
                  style={{ cursor: "pointer" }}
                >
                  Reason
                </Badge>
              </Popover>
            );
          case "Error":
            return (
              <>
                <span
                  className="inlineTableCellLink ms-3"
                  id={`ErrorStatus-${record?.reportId}`}
                  onClick={() => {
                    setDrawerData((pre: any) => {
                      const _config = drawerColConfig[record?.reportType] || {};
                      return {
                        ...pre,
                        columns: _config?.columns,
                        tableWidth: _config?.tableWidth,
                      };
                    });
                    setActiveReportId(record?.reportId);
                    setDrawerVisible(true);
                    fetchFileContent(pagination, record?.reportId);
                  }}
                >
                  View
                </span>
                <TooltipComponent
                  iconClass="info-circle text-info"
                  icon={""}
                  id={`ErrorStatus-${record?.reportId}`}
                  direction="left"
                  text={"Click To See Error"}
                  containerClassName={"mt-1"}
                />
              </>
            );
          case "Processed":
            return (
              <>
                <span
                  className="inlineTableCellLink ms-3"
                  id={`ProcessedViewStatus-${record?.reportId}`}
                  onClick={() => {
                    setDrawerData((pre: any) => {
                      const _config = drawerColConfig[record?.reportType] || {};
                      return {
                        ...pre,
                        columns: _config?.columns,
                        tableWidth: _config?.tableWidth,
                      };
                    });
                    setActiveReportId(record?.reportId);
                    setDrawerVisible(true);
                    fetchFileContent(pagination, record?.reportId);
                  }}
                >
                  View
                </span>
                <TooltipComponent
                  iconClass="info-circle text-info"
                  icon={""}
                  id={`ProcessedViewStatus-${record?.reportId}`}
                  direction="left"
                  text={"Click To See Uploaded File"}
                  containerClassName={"mt-1"}
                />
                <span id={`createBtn-${record?.reportId}`} className="ms-3">
                  {submitting == record?.reportId ? (
                    <Button size="sm" color="primary">
                      <Spinner size="sm" color="light" />
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color="primary"
                      disabled={!!submitting}
                      onClick={() => {
                        setSubmitting(record?.reportId);
                        handleCreation(record?.reportId, record?.reportType);
                      }}
                    >
                      <span>Create</span>
                      <TooltipComponent
                        iconClass="info-circle text-info"
                        icon={"info"}
                        id={`createBtn-${record?.reportId}`}
                        direction="right"
                        text={"Click To Complete Creation Process"}
                        containerClassName={"mt-1"}
                      />
                    </Button>
                  )}
                </span>
              </>
            );
          default:
            return null;
        }
      },
    },
  ];

  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey={window.location.pathname}
        title="Bulk Upload Requested List"
        refresh={refreshToggle}
        columns={columns}
        extra={{
          tableScrollX: 1300,
        }}
      />
      <Drawer
        title="File Data Preview"
        placement="right"
        width={"90%"}
        open={drawerVisible}
        onClose={() => {
          setDrawerData(() => {
            return {
              columns: [],
              dataSource: [],
              tableWidth: 1000,
            };
          });
          setPagination(() => {
            return {
              currentPage: 1,
              totalPageCount: 0,
              totalRowCount: 0,
              pageSize: 15,
            };
          });
          setActiveReportId(null);
          setDrawerVisible(false);
        }}
      >
        {loading ? (
          <>
            <Flex align="center" gap="middle" className="pageLoader">
              <Spin size="large" className="m-auto" />
            </Flex>
          </>
        ) : (
          <div className="table-responsive poTable">
            <div
              style={{
                width: screenSize - 230 + "px",
                margin: "0px auto 15px auto",
              }}
            >
              <Table
                className="customTable"
                columns={drawerData?.columns}
                dataSource={drawerData?.dataSource}
                scroll={{ x: drawerData?.tableWidth }}
                bordered
                onChange={(newPagination) => {
                  setPagination((pre: any) => {
                    return {
                      ...pre,
                      currentPage: newPagination.current,
                      pageSize: newPagination.pageSize,
                    };
                  });
                  fetchFileContent(
                    {
                      ...pagination,
                      currentPage: newPagination?.current || 1,
                      pageSize: newPagination?.pageSize || 15,
                    },
                    activeReportId
                  );
                }}
                pagination={{
                  current: pagination.currentPage,
                  pageSize: pagination.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ["15", "20", "30", "40", "50"],
                  total: pagination.totalRowCount,
                }}
              />
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default BulkUploadList;

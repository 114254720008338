import { Drawer } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import { useEffect } from "react";
import FormSelect from "../form-components/SelectComponent";

interface SettingsFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  prId: any;
  initialValues: any;
  settingFormData?: any; // New prop for current document data
}

const SettingsForm: React.FC<SettingsFormProps> = ({
  open,
  onClose,
  initialValues,
  onSubmit,
}) => {
  const methods = useForm<any>({});

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    if (initialValues) {
      setValue("inwardRequired", initialValues.inwardRequired);
      setValue("autoPoDoc", initialValues.autoPoDoc);
      setValue("needByDateType", initialValues.needByDateType);
      setValue("deliveryAddressType", initialValues.deliveryAddressType);
    }
  }, [open]);

  const submitSettingsData = (values: any) => {
    onSubmit(values);
    onClose();
  };

  return (
    <Drawer title="PO Settings" placement="right" onClose={onClose} open={open}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitSettingsData)}>
          <Row>
            <Col lg="12">
              <FormSelect
                name="inwardRequired"
                label="GRN Required ?"
                options={[
                  { label: "Yes", value: "true" },
                  { label: "No", value: "false" },
                ]}
                required={true}
                placeholder="-Select-"
                // tooltipText=" If GRN (Inward Process) is required for the line items then you can select 'Yes' otherwise select 'No'."
              />
            </Col>
            <Col lg="12" className="mt-2">
              <FormSelect
                name="autoPoDoc"
                label="Auto PO Document ?"
                options={[
                  { label: "Yes", value: "true" },
                  { label: "No", value: "false" },
                ]}
                required={true}
                placeholder="-Select-"
                // tooltipText='If you select "Yes" then, system-generated PO document will be sent to the supplier. But, if you select "No" then, you have to manually attach your  PO document.'
              />
            </Col>
            <Col lg="12" className="mt-2">
              <FormSelect
                name="needByDateType"
                label="Multiple Delivery Dates ?"
                options={[
                  { label: "Yes", value: "Line Level" },
                  { label: "No", value: "Order Level" },
                ]}
                placeholder="-Select-"
                // tooltipText="delivery date will be based on the line level. If you select 'No' then delivery date will be based on the Order Level."
                required={true}
              />
            </Col>

            <Col lg="12" className="mt-2">
              <FormSelect
                name="deliveryAddressType"
                label={`Delivery Address Type`}
                options={[
                  { label: "Order Level", value: "Order Level" },
                  { label: "Line Level", value: "Line Level" },
                ]}
                required={true}
                placeholder="-Select-"
                // tooltipText="Select the delivery address type for the purchase order."
              />
            </Col>
          </Row>
          <Button type="submit" color="primary" className="mt-3">
            Save
          </Button>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default SettingsForm;

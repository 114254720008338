import React from 'react'
import ViewOrderDetails from './ViewOrderDetails';
import { useParams } from 'react-router';

function PurchaseOrderViewWrapper() {
  let { orderNumber }: any = useParams();
  return (
    <ViewOrderDetails orderNumber={orderNumber} previousPath="/view-purchase-order" extra={{
      hideAside: true,
      reduceTableWidth: 130
    }} />
  )
}

export default PurchaseOrderViewWrapper
import React from "react";
import { useLocation } from "react-router";

import ReportTemplate2 from "../reportTemplate2";

function InventoryAgingReport() {
  const location = useLocation();

  const columns: any = [
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      fixed: "left",
      width: 200,
    },

    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
      width: 200,
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Inventory Aging Report"
      pageDescription="	Analysis of inventory categorized by duration in stock."
      moduleKey="InventoryAgingReport"
      columns={columns}
      filtersOption={{
        site: true,
        date: true,
        aging: true,
        material: true,
      }}
      previousPath={
        location.state?.originPath == "InventoryAnalytics"
          ? "/dashboard-inventory"
          : "/view-inventory-reports"
      }
      extra={{
        tableScrollX: 1700,
      }}
    />
  );
}

export default InventoryAgingReport;

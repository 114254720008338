import React, { useState, useEffect } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Label, Input, Row, Col } from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { useFormContext } from "react-hook-form";
const LegalInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    props.next();
  };

  const businessType = watch("bussinessType");
  const FullFormDetails = watch();
  const gstin = watch("gstin");
  const cin = watch("cin");

  useEffect(() => {
    if (businessType === "unregistered") {
      setValue("gstin", "");
    }
  }, [businessType]);

  return (
    <>
      <form
        className="content clearfix"
        onSubmit={handleSubmit((data) => {
          props.next();
        })}
      >
        <Row className="gy-4">
          <Col lg="4">
            <FormSelect
              name="bussinessType"
              label="Business Type"
              required={true}
              options={[
                { label: "Registered", value: "registered" },
                { label: "UnRegistered", value: "unregistered" },
              ]}
              placeholder="Select Business Type"
            />
          </Col>

          <Col lg="4">
            <FormInput
              name="cin"
              label="CIN"
              type="text"
              placeholder="Enter CIN"
              pattern={/^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/}
            />
          </Col>
          {businessType === "registered" && (
            <>
              <Col lg="4">
                <FormInput
                  name="gstin"
                  label="GSTIN"
                  required={true}
                  type="text"
                  placeholder="Enter GSTIN"
                  pattern={
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                  }
                />
              </Col>
              <Col lg="4">
                <FormDatePicker
                  name="incorporationDate"
                  label="Incorporation Date"
                  required={true}
                  // minDate={new Date()} // Today's date as the minimum date
                  maxDate={new Date()} // Maximum date set to one year from today
                />
              </Col>
              <Col lg="4">
                <FormInput
                  name="pan"
                  label="PAN"
                  required={true}
                  type="text"
                  placeholder="Enter PAN"
                  pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
                />
              </Col>

              <Col lg="4">
                <FormInput
                  name="lastFYTurnover"
                  label="Last FY Turn Over (INR)"
                  type="number"
                  placeholder="Enter Last FY Turn over"
                />
              </Col>
            </>
          )}
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default LegalInformation;

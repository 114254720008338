import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import { formatCurrency2 } from "../../../utils/Utils";

function ABCClassification() {
  const columns: any = [
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 100,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 100,
    },
    {
      title: "UOM",
      key: "uomName",
      dataIndex: "uomName",
      width: 100,
    },
    {
      title: "Cumulative Value",
      key: "cumulativeUsageValue",
      dataIndex: "cumulativeUsageValue",
      width: 100,
      render: (text: any, record: any) =>
        formatCurrency2(text, record.currencyCode),
    },
    {
      title: "Cumulative Share",
      key: "cumulativeUsagePercentage",
      dataIndex: "cumulativeUsagePercentage",
      width: 100,
      render: (text: any) => `${text}%`,
    },
    {
      title: "Current Class",
      key: "classification",
      dataIndex: "classification",
      width: 150,
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Inventory ABC Classification Report"
      pageDescription="Categorization of inventory based on value and usage priority."
      moduleKey="ABCClassification"
      columns={columns}
      filtersOption={{
        // contractor: true,
        // aging: false,
        // material: false,
        site: true,
        classPercentage: true,
        dateRange: true,
      }}
      previousPath="/view-inventory-reports"
    />
  );
}

export default ABCClassification;

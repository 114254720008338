import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Row, Col, Button, Spinner, Badge } from "reactstrap";
import { Block, Icon } from "../../../components/Component";
import { Popover, Table, TableColumnsType } from "antd";
import MaterialModal from "./MaterialModal";
import supplierApis from "../../../api/master/supplier";
import BulkMaterialUpload from "./BulkMaterialUpload";
const PurchaseRequest_Materails: React.FC = (props: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useFormContext();
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);

  const [activeMaterial, setActiveMaterial] = useState<any>({
    action: null,
    index: null,
    item: null,
  });
  const [screenSize, setScreenSize] = useState(0);

  const { fields, append, remove, update }: any = useFieldArray({
    control,
    name: "items",
  });

  const addNewRow = () => {
    setActiveMaterial({
      action: "add",
      index: null,
      item: null,
    });
  };

  const columns: TableColumnsType = [
    {
      title: "#",
      key: "index",
      width: 50,
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "Material Category",
      dataIndex: "categoryLabel",
      key: "categoryLabel",
      width: 200,
      fixed: "left",
    },
    {
      title: "Material Name",
      dataIndex: "materialLabel",
      key: "materialLabel",
      width: 200,
      fixed: "left",
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 200,
    },
    {
      title: "Req Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record) => `${text} ${record?.uomLabel}`,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "errorFlag",
      key: "errorFlag",
      width: 100,
      render: (text, record) => {
        if (record?.errorFlag == true) {
          return (
            <Popover
              placement="left"
              content={
                <div>
                  <p>{record?.errorMessage?.join(", ")}</p>
                </div>
              }
              title={<span style={{ color: "red" }}>Failed</span>}
            >
              <Badge
                color="outline-danger"
                className="ms-3"
                placement="right"
                style={{ cursor: "pointer" }}
              >
                <span>Error</span>
                <Icon name="info" />
              </Badge>
            </Popover>
          );
        } else
          return (
            <Badge
              color="outline-success"
              className="ms-3"
              placement="right"
              style={{ cursor: "pointer" }}
            >
              <Icon name="check" />
              <span>Success</span>
            </Badge>
          );
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record, index) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setActiveMaterial({
                action: "edit",
                index: index,
                item: record,
              });
            }}
          >
            <Icon name="edit" />
          </Button>
          <Button
            size="sm"
            color=""
            onClick={() => {
              Swal.fire({
                title: `Are you sure, want to delete the material?`,
                text: `${record?.materialLabel||"This Material"} will be deleted.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  remove(index);
                }
              });
            }}
          >
            <Icon name="trash" />
          </Button>
        </>
      ),
    },
  ];

  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setMaterialCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategorys();
  }, []);

  return (
    <>
      <Block>
        <form className="form-validate" onSubmit={handleSubmit(() => {})}>
          <div className="d-flex justify-content-end">
            <BulkMaterialUpload
              moduleKey={"add-new-purchase-request"}
              append={append}
              extra={{ fields }}
            />
          </div>
          <div className="table-responsive">
            <div style={{ width: screenSize - 120 + "px", margin: "auto" }}>
              <Table
                className="customTable"
                columns={columns}
                dataSource={fields}
                scroll={{ x: "max-content" }}
                locale={{
                  emptyText: (
                    <span
                      style={{
                        height: "300px",
                        color: "#333",
                        fontSize: "16px",
                      }}
                    >
                      Please click on "Add New Item" or "Import" to add purchase
                      Request lines.
                    </span>
                  ),
                }}
                bordered
                size="small"
                pagination={false}
              />
            </div>

            <Row
              style={{
                margin: "20px",
              }}
            >
              <Col className="justify-start">
                <a onClick={addNewRow} className="addNewItem">
                  <Icon name="plus-round" />
                  <span>Add New Item</span>
                </a>
              </Col>
            </Row>
          </div>
          <div className="actions clearfix">
            <ul>
              <li>
                <Button
                  color="primary"
                  type="submit"
                  disabled={
                    fields?.length == 0 ||
                    fields?.find((line: any) => line?.errorFlag)?.errorFlag
                  }
                  onClick={() => {
                    props.next();
                  }}
                >
                  Next
                </Button>
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={props.prev}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>

      {activeMaterial.action && (
        <MaterialModal
          modal={!!activeMaterial.action}
          materialCategoryOptions={materialCategoryOptions}
          closeModal={() => {
            setActiveMaterial({
              action: null,
              index: null,
              item: null,
            });
          }}
          append={(data: any) => {
            const _data = fields.find((item: any) => {
              if (item.materialName == data.materialName) return true;
            });

            if (_data) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Material Already Added",
              });
              return;
            }
            append(data);
          }}
          update={(index: any, data: any) => {
            const ItemsList = [...fields];
            const _data = ItemsList?.filter(
              (item: any, idx) => idx != index
            )?.find((item: any) => {
              if (item.materialName == data.materialName) return true;
            });

            if (_data) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Material Already Added",
              });
              return;
            }
            update(index, data);
          }}
          formData={activeMaterial}
          materialsList={fields?.map((row: any) => row?.materialName)}
        />
      )}
    </>
  );
};

export default PurchaseRequest_Materails;

import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { Tooltip } from "antd";

function InventorySummaryReport() {
  const navigate = useNavigate();
  const columns: any = [
    // {
    //   title: "Material ID",
    //   key: "materialId",
    //   dataIndex: "materialId",
    //   width: 100,
    // },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 250,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 250,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 200,
    },
    {
      title: "UOM",
      key: "primaryUomName",
      dataIndex: "primaryUomName",
      width: 200,
    },
    {
      title: "Operations Start Date",
      key: "operationStartDate",
      dataIndex: "operationStartDate",
      width: 200,
      render: (text: any, record: any) => (
        <div>
          <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
        </div>
      ),
    },
    {
      title: "Opening Qty",
      key: "openingStock",
      dataIndex: "openingStock",
      width: 200,
    },
    {
      title: "Qty Ordered",
      key: "quantityOrdered",
      dataIndex: "quantityOrdered",
      width: 150,
    },
    {
      title: "Qty Received",
      key: "quantityInward",
      dataIndex: "quantityInward",
      width: 200,
    },
    {
      title: "Qty Handovered",
      key: "quantityHandovered",
      dataIndex: "quantityHandovered",
      width: 200,
    },
    {
      title: "Qty Returned",
      key: "quantityReturned",
      dataIndex: "quantityReturned",
      width: 200,
    },
    {
      // title: "Qty On Hand",
      title: (
        <Tooltip title=" Opening Qty	+ Qty Received - Qty Handovered To Site	+ Qty Returned From Site	">
          Qty On Hand
        </Tooltip>
      ),
      key: "quantityOnHand",
      dataIndex: "quantityOnHand",
      width: 150,
    },
    {
      title: "Qty Committed",
      key: "quantityCommitted",
      dataIndex: "quantityCommitted",
      width: 150,
      render(text: any, record: any) {
        return (
          <span
            className={text > 0 ? "inlineTableCellLink" : ""}
            onClick={() => {
              navigate(
                `/inventory-committed-stock-report-list/${record.inventoryId}/${record.historyId}`,
                {
                  state: {
                    material: record.materialName,
                    category: record.categoryName,
                    site: record.siteName,
                  },
                }
              );
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      // title: "Available for Utilization",
      title: (
        <Tooltip title=" Qty On Hand - Qty Committed ">
          Available for Utilization
        </Tooltip>
      ),
      key: "quantityAvailableToIssue",
      dataIndex: "quantityAvailableToIssue",
      width: 250,
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Inventory Summary Report"
      pageDescription="	Overview of stock levels across all materials and locations"
      moduleKey="InventorySummaryReport"
      columns={columns}
      extra={{
        tableScrollX: 1900,
      }}
      filtersOption={{
        site: true,
        stockAvailability: true,
        date: true,
        dateRange: false,
      }}
      previousPath="/view-inventory-reports"
    />
  );
}

export default InventorySummaryReport;

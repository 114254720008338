import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import { Block } from "../../components/Component";
import QuotationsAside from "./QuotationsAside";
import { Steps, Step } from "react-step-builder";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation } from "react-router-dom";
import RFQHeader from "./newQuotations/RFQHeader";
import RFQGeneralDetails from "./newQuotations/RFQGeneralDetails";
import RFQMaterials from "./newQuotations/RFQMaterials";
import RFQOthers from "./newQuotations/RFQOthers";
import RFQPreview from "./newQuotations/RFQPreview";
import dayjs from "dayjs";
import quotationApis from "../../api/master/quotations";
import { Flex, Spin } from "antd";
import axios from "axios";
const config = {
  before: RFQHeader as React.ComponentType<{}> as () => React.ReactElement,
};

const RFQCreation: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const formMethods: any = useForm({
    mode: "onChange",
    // TODO : remove default Values when form is ready
    // defaultValues: {
    //   quotationFor: "Material",
    //   preferredBrands: "some brand ",
    //   quotationDescription: "some desc of this quotation ",
    //   needByDate: dayjs("2025-09-23T18:30:00.000Z"),
    //   lastSubmissionDate: dayjs("2025-08-26T18:30:00.000Z"),
    //   costCenter: "33",
    //   siteId: "149",
    //   contractorId: "40",
    //   approvalRequired: "N",
    //   approvalsData: '{"open":false,"data":[]}',
    //   supplierDocsRequired: "N",
    //   billingAddressId: "22",
    //   paymentTerms: "Cash and Carry",
    //   autoRelease: "Y",
    //   needByDateType: "ORDER_LEVEL",
    //   deliveryAddressType: "ORDER_LEVEL",
    //   additionalCharges: "Flexible",
    //   deadlineType: "SOFT",
    //   deliveryAddressId: "24",
    //   items: [],
    // },
  });
  const { setValue } = formMethods;
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const convertionData = location.state?.data;
  const draftId = location.state?.draftId;

  useEffect(() => {
    if (draftId) {
      fetchDraftData(draftId);
    }
  }, [draftId]);

  const fetchDraftData = async (id: any) => {
    try {
      setLoading(true);
      const res = await quotationApis.getById(id);
      const { response, status, message } = res;
      if (status) {
        setDraftStateData(response.data);
      }
    } catch (error) {
      console.log("Error While Loading Draft Data");
    } finally {
      setLoading(false);
    }
  };

  const setDraftStateData = async (draftData: any) => {
    try {
      const {
        quotationId,
        orderNumber,
        quotationNumber,
        supplierQuoteId,
        rfqId,
        referenceId,
        mode,
        version,
        orderDate,
        needByDateType,
        needByDate,
        changeRequestNeedByDate,
        orgId,
        quotationFor,
        preferredBrands,
        quotationDescription,
        lastSubmissionDate,
        siteId,
        supplierId,
        costCenter,
        contractorId,
        description,
        ledgerId,
        departmentId,
        budgetId,
        paymentTerms,
        advancePercentage,
        creditDays,
        paymentTermsDescription,
        inwardRequired,
        autoRelease,
        autoPoDoc,
        billingAddressId,
        deliveryAddressId,
        gstin,
        currencyId,
        basicAmount,
        discount,
        netAmount,
        sgst,
        cgst,
        igst,
        additionalCharges,
        grossTotal,
        createdDate,
        createdBy,
        deletedAt,
        deliveryAddressType,
        approvalRequired,
        invoiceStatus,
        deliveryStatus,
        status,
        dms,
        supplierDocsRequired,
        deliveryAddress,
        deliveryDateType,
        deliveryDate,
        expectedCreditDays,
      } = draftData.quotationHeader;
      const quotationApprovals = draftData.quotationApprovals;
      const requiredDocs = draftData.materialsData;
      const lineItems = draftData.quotationLines;
      const originalDocId: any = {};
      // setDraftState({ qid: , draft: true });
      setValue("quotationId", quotationId);
      setValue("type", "draft");
      setValue("rfqId", rfqId);
      setValue("orderNumber", orderNumber);
      if (quotationApprovals?.length > 0) {
        setValue("approvalRequired", "Y");
        setValue(
          "approvalsData",
          JSON.stringify({
            open: false,
            data: quotationApprovals,
          })
        );
      } else setValue("approvalRequired", "N");
      if (requiredDocs?.length > 0) {
        setValue("supplierDocsRequired", "Y");
        setValue(
          "approvalsData",
          JSON.stringify({
            open: false,
            data: quotationApprovals,
          })
        );
      } else setValue("supplierDocsRequired", "N");
      if (quotationFor) setValue("quotationFor", quotationFor);
      if (preferredBrands) setValue("preferredBrands", preferredBrands);
      if (quotationDescription)
        setValue("quotationDescription", quotationDescription);
      if (costCenter) setValue("costCenter", costCenter);
      if (siteId) setValue("siteId", siteId);
      if (contractorId) setValue("contractorId", contractorId);
      if (lastSubmissionDate)
        setValue("lastSubmissionDate", dayjs(lastSubmissionDate));
      if (billingAddressId) setValue("billingAddressId", billingAddressId);
      if (deliveryAddressType)
        setValue("deliveryAddressType", deliveryAddressType);
      if (deliveryAddressId) setValue("deliveryAddressId", deliveryAddressId);
      if (deliveryDate) setValue("needByDate", dayjs(deliveryDate));
      if (needByDateType) setValue("needByDateType", deliveryDateType);
      if (paymentTerms) setValue("paymentTerms", paymentTerms);
      if (advancePercentage) setValue("advancePercentage", advancePercentage);
      if (creditDays) setValue("creditDays", creditDays);
      if (expectedCreditDays) setValue("creditDays", expectedCreditDays);
      const _termsAndConditions = dms?.find(
        (item: any) => item.moduleFileType == "termsAndConditions"
      );
      if (_termsAndConditions) {
        const data: any = await fetchHtmlContentFromUrl(
          _termsAndConditions?.filePath
        );
        originalDocId.termsAndConditionsId = _termsAndConditions.docId;
        setValue("termsAndConditions", data);
      }
      const _instructions = dms?.find(
        (item: any) => item.moduleFileType == "instructions"
      );
      if (_instructions) {
        const data: any = await fetchHtmlContentFromUrl(
          _instructions?.filePath
        );
        originalDocId.instructionsId = _instructions.docId;
        setValue("instructions", data);
      }
      const _attachments = dms?.filter(
        (item: any) => item.moduleFileType == "attachments" && item.docHash
      );
      if (_attachments) {
        originalDocId.attachmentsId = _attachments.map((i: any) => i.docId);
        setValue(
          "attachments",
          _attachments.map((attachment: any) => {
            return Object.assign({}, attachment, {
              name: attachment.fileName, // You can map the name from fileName or modify as needed
              type: "application/pdf",
            });
          })
        );
      }
      if (lineItems) {
        const originalLines: any = [];
        setValue(
          "items",
          lineItems?.map((item: any) => {
            const obj: any = {
              key: item.prLineId,
              materialLabel: item.materialName,
              materialName: item.materialId,
              materialCategory: item.categoryId,
              categoryLabel: item.categoryName,
              uomId: item.uomId,
              uomLabel: item.uomName,
              qty: item.quantity,
              specification: item.specification,
              quotationLineId: item.quotationLineId,
              // uuid: item.uuid,
              // materialId: item.materialId,
              // dms: dms?.filter((i: any) => i.lineNo == item.lineNo),
            };
            if (needByDateType == "Line Level") {
              obj.needByDate = item.needByDate;
            }
            if (deliveryAddressType == "Line Level") {
              obj.deliveryAddressId = item.deliveryAddressId;
            }
            originalLines.push(obj);
            let _item: any = {
              ...item,
              key: item?.lineNo,
              materialCategory: item.categoryId,
              categoryLabel: item.categoryName,
              materialLabel: item.materialName,
              materialName: item.materialId,
              quantity: Number(item?.quantity),
              uomLabel: item.uomName,
              quotationLineId: item.quotationLineId,
              dms: dms?.filter((i: any) => i.lineNo == item.lineNo),
            };
            if (item.needByDate) _item.needByDate = dayjs(item.needByDate);
            if (item.deliveryAddressId)
              _item.deliveryAddressId = item.deliveryAddressId;
            return _item;
          })
        );
        setValue("originalLines", originalLines);
      }
      setValue("originalDocId", JSON.stringify(originalDocId));
      return;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    try {
      const _data = convertionData;
      if (!_data) {
        return;
      }
      let {
        deliveryAddressId,
        prId,
        deliveryLevelType,
        deliveryDateType,
        siteId,
        deliveryDate,
        contractorId,
      }: any = _data?.header;

      const lines = _data?.lines;

      // if (deliveryDateType === "ORDER_LEVEL") {
      //   deliveryDateType = "Order Level"
      // }

      setValue("prId", prId);
      setValue("siteId", siteId.toString());
      setValue("deliveryAddressId", deliveryAddressId);
      setValue("deliveryLevelType", deliveryLevelType);
      setValue("needByDateType", deliveryDateType);
      setValue("needByDate", dayjs(deliveryDate));
      setValue("contractorId", contractorId?.toString());

      if (lines)
        setValue(
          "items",
          lines?.map((item: any) => {
            let _item: any = {
              ...item,
              key: item.prLineId,
              materialLabel: item.materialName,
              materialName: item.materialId,
              materialCategory: item.categoryId,
              categoryLabel: item.categoryName,
              uomId: item.uom,
              uomLabel: item.uomName,
              qty: item.quantity,
              specification: item.specification,
              prLineId: item.prLineId,
            };
            if (item.needByDate) _item.needByDate = dayjs(item.needByDate);
            return _item;
          })
        );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  return (
    <>
      <Head title="RFQ - Create New" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <QuotationsAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <Block>
                {loading == true ? (
                  <>
                    <Flex align="center" gap="middle" className="pageLoader">
                      <Spin size="large" className="m-auto" />
                    </Flex>
                  </>
                ) : (
                  <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                    <FormProvider {...formMethods}>
                      <Steps config={config}>
                        <Step component={RFQGeneralDetails} />
                        <Step component={RFQMaterials} />
                        <Step component={RFQOthers} />
                        <Step component={RFQPreview} />
                      </Steps>
                    </FormProvider>
                  </div>
                )}
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default RFQCreation;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import purchaseRequestApis from "../../api/master/purchaseRequest";
import Swal from "sweetalert2";
import { Badge, Button, Card } from "reactstrap";
import { Empty, Flex, Popover, Spin, Table, Timeline } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import VersionControlDefaultTemplate from "../version-control/versionControlDefaultTemplate";

const PRVersionControl = () => {
  const location = useLocation();
  const prSequence = location.state?.prSequence;
  const prId = location.state?.prId;
  const [apiResponse, setApiResponse] = React.useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (prSequence) {
      fetchVersionControlData(prSequence);
    }
  }, []);
  const fetchVersionControlData = async (prSequence: any) => {
    setLoading(true);
    try {
      const res = await purchaseRequestApis.fetchPRVersions(prSequence);
      const { response, status, message } = res;
      if (status) {
        setApiResponse(response);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  const ApprovalTimelineContent = (orderApprovalsData: any) => {
    const approvalsData = orderApprovalsData;

    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "Approved";
              const isRejected = approval.status == "Rejected";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Badge color="outline-success">Yes</Badge>
        </span>
      </Popover>
    );
  };

  const displayFields: any = [
    { label: "Reference Indent Number", key: "referenceIndentNumber" },
    { label: "Indent Date", key: "indentDate" },
    { label: "Level Type", key: "levelType" },
    { label: "Site", key: "siteName" },
    { label: "Block", key: "blockName" },
    { label: "Contractor", key: "contractorName" },
    { label: "Requested By", key: "requestByName" },
    { label: "Need By Date", key: "needByDate" },
    {
      label: "Approvals",
      key: "prApprovals",
      render: (text: any, record: any) => {
        if (record?.approvalRequired === "N") {
          return "No";
        }
        return ApprovalTimelineContent(record?.prApprovals);
      },
    },
    {
      label: "Delivery Address",
      key: "deliveryAddressId",
      render: (text: any, record: any) => {
        return (
          <Popover
            content={
              <div className="billingAddress_align">
                <strong>{record.deliveryAddress?.deliveryLocationName}</strong>
                <br></br>
                <p>
                  {record.deliveryAddress?.addressLine1},{" "}
                  {record.deliveryAddress?.addressLine2}
                </p>
                <p>
                  {record.deliveryAddress?.city},{" "}
                  {record.deliveryAddress?.district?.districtName},{" "}
                  {record.deliveryAddress?.state?.stateName},{" "}
                  {record.deliveryAddress?.countryInfo?.countryName} -{" "}
                  {record.deliveryAddress?.pincode}
                </p>
                <p>
                  <strong>Contact Person</strong> :{" "}
                  {record.deliveryAddress?.phoneNumber}
                </p>
                <p>
                  <strong>Contact Person</strong> :{" "}
                  {record.deliveryAddress?.phoneNumber}
                </p>
              </div>
            }
            title={<span style={{ color: "#2263b3" }}>Delivery Address</span>}
          >
            <span
              style={{
                color: "#2263b3",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {record.deliveryAddress?.deliveryLocationName}
            </span>
          </Popover>
        );
      },
    },
  ];

  const materialColumns = [
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      fixed: "left",
      width: 150,
      render: (text: any) => `Version ${text}`,
    },
    { title: "Category", dataIndex: "categoryName", key: "categoryName" },
    { title: "Material Name", dataIndex: "materialName", key: "materialName" },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      render: (text: any, record: any) => {
        const isModified = record?.isModified?.specification;
        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {text}
          </strong>
        ) : (
          text
        );
      },
    },
    {
      title: "Req QTY",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, record: any) => {
        const isModified = record?.isModified?.quantity;
        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {text}
          </strong>
        ) : (
          text
        );
      },
    },
    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
      render: (text: any, record: any) => {
        const isModified = record?.isModified?.quantity;
        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {text}
          </strong>
        ) : (
          text
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

  return (
    <>
      {loading == true ? (
        <>
          <Flex align="center" gap="middle" className="pageLoader">
            <Spin size="large" className="m-auto" />
          </Flex>
        </>
      ) : apiResponse ? (
        <VersionControlDefaultTemplate
          displayFields={displayFields}
          title="Purchase Request Version History"
          backPath={`/view_pr/${prId}`}
          apiResponse={apiResponse}
          materialColumns={materialColumns}
          extraMaterialInfo={false}
          versionHistoryPR={true}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Empty description="No Data Found " />
        </div>
      )}
    </>
  );
};

export default PRVersionControl;

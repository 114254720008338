import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import { useLocation, useNavigate } from "react-router";
import { formatCurrency2 } from "../../../utils/Utils";

function InventoryValueReport() {
  const navigate = useNavigate();
  const location = useLocation();
  const columns: any = [
    // {
    //   title: "Material ID",
    //   key: "materialId",
    //   dataIndex: "materialId",
    //   width: 100,
    // },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 150,
    },
    {
      title: "Category",
      key: "categoryName",
      dataIndex: "categoryName",
      width: 200,
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 100,
    },
    {
      title: "UOM",
      key: "primaryUomName",
      dataIndex: "primaryUomName",
      width: 100,
    },
    {
      title: "Qty On Hand",
      key: "quantityOnHand",
      dataIndex: "quantityOnHand",
      width: 100,
    },
    {
      title: "Avg Unit Price",
      key: "movingAvg",
      dataIndex: "movingAvg",
      width: 100,
      render(text: any, record: any) {
        return (
          <span
            className={text > 0 ? "inlineTableCellLink" : ""}
            onClick={() => {
              navigate(
                `/inventory-value-report-preview/${record.inventoryId}/${record.historyId}`
              );
            }}
          >
            {formatCurrency2(text, "INR")}
          </span>
        );
      },
    },
    {
      title: "Avg Asset Value",
      key: "averageAssetValue",
      dataIndex: "averageAssetValue",
      width: 150,
      render: (text: any) => <span>{formatCurrency2(text, "INR")}</span>,
    },
  ];
  return (
    <ReportTemplate2
      pageTitle="Inventory Value Report"
      pageDescription="	Total monetary value of inventory across all materials and sites."
      moduleKey="InventoryValueReport"
      columns={columns}
      filtersOption={{
        site: true,
        date: true,
      }}
      previousPath={
        location.state?.originPath == "InventoryAnalytics"
          ? "/dashboard-inventory"
          : "/view-inventory-reports"
      }
    />
  );
}

export default InventoryValueReport;

import { message } from 'antd';
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import purchaseOrderCreation from "../../../api/purchase-orders/pruchaseOrderCreation";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../../api/master/PurchaseOrder";

export const base64ToFile = (base64String: string, fileName: string) => {
  const byteString = atob(base64String.split(",")[1]);
  const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: mimeString });
  return new File([blob], fileName, { type: mimeString });
};

export const saveDraft = async (
  data: any,
  newDraft: any,
  navigate: any,
  setDisableSubmit: any,
  mode?: string
) => {
  setDisableSubmit(true);
  console.clear();
  console.log("data", data);
  if (!newDraft) {
    await newOrder(data, navigate, setDisableSubmit, "DR");
    return;
  }
  try {

    const {
      siteId,
      needByDateType,
      supplierId,
      costCenterId,
      description,
      ledgerId,
      departmentId,
      budgetId,
      inwardRequired,
      lineItemAddition,
      autoPoDoc,
      billingAddressId,
      deliveryAddressType,
      paymentTerms,
      advancePercentage,
      creditDays,
      needByDate,
      deliveryAddressId,
      signatureObj,
      approvalsData,
      instructions,
      termsAndConditions,
      attachments,
      contractorId,
      originalLines,
      items,
      originalDocId,
      orderNumber,
    } = data;
    
    let approvalRequired:any=Boolean(data.approvalRequired);
    const _formData = new FormData();

    const existingLineItems: any = originalLines;
    const updatedLineItems: any = [];
    let deletedLineItems: any = [];
    const newLineItems: any = [];

    const newfiledata: any = [];
    const deletedFileData: any = [];
    const updatedFileData: any = [];

    const signature = JSON.parse(signatureObj);
    const approvals = JSON.parse(approvalsData);
    const originalDocIds = JSON.parse(originalDocId);

    if (signature?.image?.startsWith("data:image/png;base64,")) {
      if (originalDocIds?.signatureDocId) {
        deletedFileData.push({ docId: originalDocIds?.signatureDocId });
      }
      const image = base64ToFile(signature?.image, "signature.png");
      _formData.append("files", image);
      newfiledata.push({
        indexOfFileLocated: newfiledata.length,
        module: "po",
        purpose: "signature",
        level: "Header Level",
        uniqueId: nanoid(4),
        action: "Create",
      });
    } else if (!signature?.image) {
      if (originalDocIds?.signatureDocId) {
        deletedFileData.push({ docId: originalDocIds?.signatureDocId });
      }
    }
    if (attachments) {
      const _existingAttachments: any = [];
      attachments.forEach((attachment: any) => {
        // console.log("attachment", attachment);
        if (attachment?.docId) {
          _existingAttachments.push(attachment?.docId);
          return;
        }
        const file = attachment?.originFileObj;
        _formData.append(`files`, file);
        newfiledata.push({
          indexOfFileLocated: newfiledata.length,
          module: "po",
          purpose: "attachments",
          level: "Line Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
      });
      const { attachmentsId } = originalDocIds;
      attachmentsId?.forEach((docId: any) => {
        if (!_existingAttachments.includes(docId)) {
          // console.log("docId attachment is deleted - ", docId);
          if (docId) deletedFileData.push({ docId });
        }
      });
    }
    if (instructions) {
      if (originalDocIds?.instructionsId) {
        deletedFileData.push({ docId: originalDocIds?.instructionsId });
      }
      const instructionsFile = new Blob([instructions], {
        type: "text/html",
      });
      _formData.append(
        `files`,
        new File([instructionsFile], "instructions.html", {
          type: "text/html",
        })
      );
      newfiledata.push({
        indexOfFileLocated: newfiledata.length,
        module: "po",
        purpose: "instructions",
        level: "Header Level",
        uniqueId: nanoid(4),
        action: "Create",
      });
    }

    if (termsAndConditions) {
      if (originalDocIds?.termsAndConditionsId) {
        deletedFileData.push({ docId: originalDocIds?.termsAndConditionsId });
      }
      const termsAndConditionsFile = new Blob([termsAndConditions], {
        type: "text/html",
      });
      _formData.append(
        `files`,
        new File([termsAndConditionsFile], "termsandconditions.html", {
          type: "text/html",
        })
      );
      newfiledata.push({
        indexOfFileLocated: newfiledata.length,
        module: "po",
        purpose: "termsAndConditions",
        level: "Header Level",
        uniqueId: nanoid(4),
        action: "Create",
      });
    }

    if (approvals?.data?.length > 0 && approvalRequired) {
      _formData.append(
        "orderApprovalHierarchy",
        JSON.stringify(approvals?.data)
      );
    } else {
      _formData.append("orderApprovalHierarchy", JSON.stringify([]));
    }
    let _newData: any = {
      signatureLabel: signature?.label,
      needByDateType,
      supplierId: Number(supplierId),
      costCenterId: costCenterId ? Number(costCenterId) : null,
      description,
      ledgerId: Number(ledgerId),
      departmentId: Number(departmentId),
      budgetId: Number(budgetId),
      contractorId: Number(contractorId),
      inwardRequired: inwardRequired==true,
      autoRelease: !approvalRequired,
      lineItemAddition,
      autoPoDoc: autoPoDoc==true,
      billingAddressId: Number(billingAddressId),
      deliveryAddressType,
      paymentTerms,
      advancePercentage: Number(advancePercentage),
      creditDays: Number(creditDays),
    };
    for (let i = 0; i < items?.length; i++) {
      let item = items[i];
      let combinedLines = [...updatedLineItems];
      for (let j = 0; j < combinedLines.length; j++) {
        let _oldData = combinedLines[j];
        if (
          item.materialName == _oldData.materialId &&
          checkDeliveryAddress(
            deliveryAddressType,
            item.deliveryAddressId,
            _oldData.deliveryAddressId
          ) &&
          checkNeedByDate(needByDateType, item.needByDate, _oldData.needByDate)
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text:
              item?.materialLabel +
              " at " +
              (i + 1) +
              ", already has similar data in table",
          });
          throw "material already exist";
        }
      }

      let OData = originalLines?.find(
        (line: any) => line.lineNo == item.lineNo
      );
      if (item?.lineNo) {
        let obj: any = {
          quantity: item.quantity,
          unitPrice: Number(item.unitPrice),
          hsnCode: item.hsnCode,
          categoryId: Number(item.materialCategory),
          description: item.description,
          uomId: item.uomId,
          discountPercentage: 0,
          sgstPercentage: Number(item.sgstPercentage),
          cgstPercentage: Number(item.cgstPercentage),
          igstPercentage: Number(item.igstPercentage),
          additionalCharges: 0,
          materialId: Number(item.materialName),
          lineNo: item.lineNo,
          uuid: item.uuid,
        };
        if (needByDateType == "Line Level") {
          if (item.needByDate) {
            obj.needByDate = dayjs(item.needByDate).format("YYYY-MM-DD");
          } else {
            Swal.fire(
              "Error!",
              "Need by date is required for line -" + (i + 1),
              "error"
            );
            throw "Need by date is required for line -" + (i + 1);
          }
        } else {
          obj.needByDate = null;
        }
        if (deliveryAddressType == "Line Level") {
          if (item.deliveryAddressId)
            obj.deliveryAddressId = Number(item.deliveryAddressId);
          else {
            Swal.fire(
              "Error!",
              "Delivery address is required for line -" + (i + 1),
              "error"
            );
            throw "Delivery address is required for line -" + (i + 1);
          }
        } else {
          obj.deliveryAddressId = null;
        }
        const file = OData?.dms?.[0];
        if (item?.file && item?.file[0]) {
          if (file?.docId) {
            deletedFileData.push({
              docId: file?.docId,
            });
          }
          newfiledata.push({
            indexOfFileLocated: newfiledata.length,
            module: "po",
            purpose: "special instructions",
            level: "Line Level",
            uniqueId: OData.uuid,
            action: "Create",
          });
          _formData.append("files", item.file[0]);
        } else if (!item?.file && file?.docId && !item?.dms[0]?.docId) {
          deletedFileData.push({
            docId: file?.docId,
          });
        }
        updatedLineItems.push(obj);
      } else {
        let obj: any = {
          uuid: item?.key?.toString(),
          materialId: Number(item.materialName),
          hsnCode: item?.hsnCode ? Number(item.hsnCode) : null,
          categoryId: Number(item.materialCategory),
          description: item.description,
          deliveryAddressId: Number(item.deliveryAddressId),
          quantity: Number(item.quantity),
          uomId: Number(item.uomId),
          unitPrice: Number(item.unitPrice),
          discountPercentage: 0,
          sgstPercentage: Number(item.sgstPercentage),
          cgstPercentage: Number(item.cgstPercentage),
          igstPercentage: Number(item.igstPercentage),
          additionalCharges: 0,
        };
        if (needByDateType == "Line Level") {
          if (item.needByDate)
            obj.needByDate = dayjs(item.needByDate).format("YYYY-MM-DD");
          else {
            Swal.fire(
              "Failed!",
              "Need By Date is required at Line - " + (i + 1),
              "error"
            );
            throw "Need By Date is required at Line - " + (i + 1);
          }
        } else {
          obj.needByDate = null;
        }
        if (deliveryAddressType == "Line Level") {
          if (item.deliveryAddressId)
            obj.deliveryAddressId = Number(item.deliveryAddressId);
          else {
            Swal.fire(
              "Failed!",
              "Delivery Address is required at Line - " + (i + 1),
              "error"
            );
            throw "Delivery Address is required at Line - " + (i + 1);
          }
        } else {
          obj.deliveryAddressId = null;
        }
        if (item?.file && item?.file[0]) {
          _formData.append("files", item?.file[0]);
          newfiledata.push({
            indexOfFileLocated: newfiledata.length,
            module: "po",
            purpose: "special instructions",
            level: "Line Level",
            uniqueId: item?.key?.toString(),
            action: "Create",
          });
        }
        newLineItems.push(obj);
      }
    }
    originalLines.forEach((item: any) => {
      let _item = updatedLineItems.find((i: any) => i.lineNo == item.lineNo);
      if (!_item) {
        if (item?.dms?.[0]?.docId)
          deletedFileData.push({
            docId: item?.dms[0]?.docId,
          });
        deletedLineItems.push(item);
      }
    });

    if (deliveryAddressType != "Line Level") {
      _newData.deliveryAddressId = Number(deliveryAddressId);
    } else {
      _newData.deliveryAddressId = null;
    }
    if (data?.needByDateType == "Order Level") {
      _newData.needByDate = dayjs(needByDate).format("YYYY-MM-DD");
    } else {
      _newData.needByDate = null;
    }

    const submitData = {
      mode: mode || "DR",
      combinedLineItems: {
        existingLineItems: existingLineItems?.map((item: any) => {
          let obj = { ...item };
          delete item?.dms;
          return obj;
        }),
        updatedLineItems,
        newLineItems,
        deletedLineItems,
      },
      ..._newData,
    };
    const filesData = {
      newfiledata,
      deletedFileData,
      updatedFileData,
    };
    _formData.append("filesData", JSON.stringify(filesData));

    _formData.append("orderData", JSON.stringify(submitData));
    console.log("submitData", submitData);
    const res = await purchaseOrderApis.purchaseOrderUpdate(
      orderNumber,
      _formData
    );
    const { status, error,data:message } = res;
    if (status) {
      if(mode=="F")Swal.fire("Created!",message, "success");
      else Swal.fire("Updated!",message, "success");
      navigate("/view-purchase-order");
    } else {
      Swal.fire("Failed!", JSON.stringify(error), "error");
    }
    setDisableSubmit(false);
  } catch (error) {
    console.log("error while saving draft", error);
    setDisableSubmit(false);
  }
};

export const newOrder = async (
  previousFormData: any,
  navigate: any,
  setDisableSubmit: any,
  mode: any
) => {
  const formData: any = new FormData();
  let _filesData: any = [];
  let _quotationsFileData: any = [];
  let orderData: any = {
    ...previousFormData,
  };
  try {
    const lineItems = await Promise.all(
      previousFormData?.items.map(async (lineItem: any, index: number) => {
        let obj = { ...lineItem };
        const uuid = obj?.key?.toString();
        let finalObj: any = {
          uuid,
          materialId: Number(obj?.materialName),
          hsnCode: Number(obj?.hsnCode),
          categoryId: Number(obj?.materialCategory),
          description: obj?.description,
          quantity: Number(obj?.quantity),
          uomId: Number(obj?.uomId),
          unitPrice: Number(obj?.unitPrice),
          discountPercentage: 0,
          sgstPercentage: Number(obj?.sgstPercentage),
          cgstPercentage: Number(obj?.cgstPercentage),
          igstPercentage: Number(obj?.igstPercentage),
          additionalCharges: 0,
        };

        if (obj?.file && obj?.file[0]) {
          formData.append("files", obj?.file[0]);
          _filesData.push({
            indexOfFileLocated: _filesData.length,
            module: "po",
            purpose: "special instructions",
            level: "Line Level",
            uniqueId: uuid,
            action: "Create",
          });
        } else if (obj?.dms?.length > 0) {
          _quotationsFileData.push({
            docId: obj?.dms[0]?.docId,
            indexOfFileLocated: 99999,
            module: "po",
            purpose: "special instructions",
            level: "Line Level",
            uniqueId: uuid,
            action: "Create",
          });
        }
        if (previousFormData?.needByDateType === "Line Level") {
          finalObj.needByDate = dayjs(obj?.needByDate).format("YYYY-MM-DD");
        }
        if (previousFormData?.deliveryAddressType == "Line Level") {
          finalObj.deliveryAddressId = Number(obj?.deliveryAddressId);
        }
        if (obj?.quoteShortListId) {
          finalObj.quoteShortListId = Number(obj?.quoteShortListId);
        }
        if (obj?.supplierQuoteLineId) {
          finalObj.supplierQuoteLineId = Number(obj?.supplierQuoteLineId);
        }

        return finalObj;
      })
    );

    Object.keys(orderData).forEach((key) => {
      if (
        !isNaN(Number(orderData[key])) &&
        !["needByDate", "description"].includes(key)
      ) {
        orderData[key] = Number(orderData[key]);
      } else if (orderData[key] === "true" || orderData[key] === "false") {
        orderData[key] = orderData[key] === "true";
      } else {
        orderData[key] = orderData[key];
      }
    });
    const {
      siteId,
      needByDateType,
      supplierId,
      costCenterId,
      description,
      ledgerId,
      departmentId,
      budgetId,
      inwardRequired,
      autoRelease,
      lineItemAddition,
      autoPoDoc,
      billingAddressId,
      deliveryAddressType,
      paymentTerms,
      advancePercentage,
      creditDays,
      needByDate,
      deliveryAddressId,
      signatureObj,
      currencyObj,
      approvalsData,
      instructions,
      termsAndConditions,
      attachments,
      quotationId,
      supplierQuoteId,
      orderId,
      contractorId,
    } = orderData;
    let approvalRequired:any=Boolean(orderData.approvalRequired)
    const currency = JSON.parse(currencyObj);
    const signature = JSON.parse(signatureObj);
    const approvals = JSON.parse(approvalsData);
    if (approvals?.data?.length > 0 && approvalRequired) {
      formData.append(
        "orderApprovalHierarchy",
        JSON.stringify(approvals?.data)
      );
    } else {
      formData.append("orderApprovalHierarchy", JSON.stringify([]));
    }
    if (signature?.image) {
      const image = base64ToFile(signature?.image, "signature.png");
      // console.log("image", image);
      formData.append("files", image);
      _filesData.push({
        indexOfFileLocated: _filesData.length,
        module: "po",
        purpose: "signature",
        level: "Header Level",
        uniqueId: nanoid(4),
        action: "Create",
      });
    }

    if (attachments) {
      attachments.forEach((attachment: any, index: number) => {
        if (!attachment) {
          return;
        }
        const file = attachment?.originFileObj;
        formData.append(`files`, file);
        _filesData.push({
          indexOfFileLocated: _filesData.length,
          module: "po",
          purpose: "attachments",
          level: "Line Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
      });
    }
    if (instructions) {
      const instructionsFile = new Blob([instructions], {
        type: "text/html",
      });
      formData.append(
        `files`,
        new File([instructionsFile], "instructions.html", {
          type: "text/html",
        })
      );
      _filesData.push({
        indexOfFileLocated: _filesData.length,
        module: "po",
        purpose: "instructions",
        level: "Header Level",
        uniqueId: nanoid(4),
        action: "Create",
      });
    }

    if (termsAndConditions) {
      const termsAndConditionsFile = new Blob([termsAndConditions], {
        type: "text/html",
      });
      formData.append(
        `files`,
        new File([termsAndConditionsFile], "termsandconditions.html", {
          type: "text/html",
        })
      );
      _filesData.push({
        indexOfFileLocated: _filesData.length,
        module: "po",
        purpose: "termsAndConditions",
        level: "Header Level",
        uniqueId: nanoid(4),
        action: "Create",
      });
    }

    let _OrderData: any = {
      referenceId: nanoid(4),
      mode,
      currencyId: currency?.id,
      signatureLabel: signature?.label,
      siteId,
      needByDateType,
      supplierId,
      costCenterId: costCenterId ? Number(costCenterId) : null,
      description,
      ledgerId,
      departmentId,
      budgetId,
      contractorId,
      inwardRequired,
      autoRelease: !approvalRequired,
      lineItemAddition,
      autoPoDoc,
      billingAddressId,
      deliveryAddressType,
      paymentTerms,
      advancePercentage,
      creditDays,
      lineItems: lineItems,
    };
    if (orderId) {
      _OrderData.orderId = orderId?.toString();
    }
    if (quotationId) {
      _OrderData.quotationNumber = quotationId;
    }
    if (supplierQuoteId) {
      _OrderData.supplierQuoteId = supplierQuoteId;
    }
    if (deliveryAddressType != "Line Level") {
      _OrderData.deliveryAddressId = deliveryAddressId;
    }
    if (previousFormData?.needByDateType == "Order Level") {
      _OrderData.needByDate = dayjs(needByDate).format("YYYY-MM-DD");
    }
    formData.append("orderData", JSON.stringify(_OrderData));
    formData.append(
      "filesData",
      JSON.stringify([..._filesData, ..._quotationsFileData])
    );
    console.log("Order Data",_OrderData);
    postPO(formData, navigate, setDisableSubmit, mode);
  } catch (error) {
    console.log(error);
    setDisableSubmit(false);
  }
};

export const postPO = async (
  _data: any,
  navigate: any,
  setDisableSubmit: any,
  mode?: string
) => {
  try {
    const response: any =
      await purchaseOrderCreation.purchaseOrderCreationMethod(_data);
    const { status, message, data } = response;
    if (status) {
      Swal.fire("Created!", data?.orderId, "success");
      if (mode == "DR") {
        navigate(`/view-purchase-order`);
      } else {
        navigate(`/view-purchase-order/${data?.orderNumber}`);
      }
    } else {
      Swal.fire("Failed!", message, "error");
    }
  } catch (error) {
    Swal.fire("Failed!", "Something went wrong", "error");
  } finally {
    setDisableSubmit(false);
  }
};

export const checkDeliveryAddress = (type: string, value: any, target: any) => {
  if (type == "Order Level") return true;
  return value == target;
};
export const checkNeedByDate = (type: string, value: any, target: any) => {
  if (type == "Order Level") return true;
  return (
    dayjs(value).format("YYYY-MM-DD") == dayjs(target).format("YYYY-MM-DD")
  );
};

import React, { useState, useEffect, FC } from "react";
import Content from "../../../layout/content/Content";
import {
  Modal,
  ModalBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  DataTableHead,
  DataTableRow,
  DataTable,
  DataTableBody,
  DataTableItem,
  PaginationComponent,
  TooltipComponent,
} from "../../../components/Component";
import Swal from "sweetalert2";
import ApprovalHeirarchyMaster from "./ApprovalHeirarchyMaster";
import ApprovalHeirarchyApis from "../../../api/master/approvalHeirarchy";
import Export from "../../../components/export/Export";
import { useSelector } from "react-redux";

const ApprovalHeirarchy: FC = () => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, setSm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const [popUpContent, setPopUpContent] = useState<JSX.Element | null>(null);
  const [data, setData] = useState<any>();
  const [editOnly, setEditOnly] = useState(false);

  const [rowData, setRowData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const handleModelPopUp = (id?: any, type?: string, selectedModule?: any) => {
    console.log("selectedModule", selectedModule);
    const content = (
      <ApprovalHeirarchyMaster
        formAction={editOnly ? "edit" : type}
        module={selectedModule}
        id={id}
        data={rowData}
        toggleModelPopUp={() => {
          setSelectedModule(null);
          setIsOpen(false);
          fetchApprovalHeirarchy();
        }}
      />
    );
    setPopUpContent(content);
    setIsOpen(true);
  };
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  const handleDelete = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { status, message } =
            await ApprovalHeirarchyApis.deleteApprovalHeirarchyById(id);
          if (status) {
            Swal.fire("Deleted!", "Hierarchy has been deleted.", "success");
            fetchApprovalHeirarchy();
          } else {
            Swal.fire("Error", message, "error");
          }
        } catch (err: any) {
          Swal.fire(
            "Error",
            err.response?.data?.message || err.message,
            "error"
          );
        }
      }
    });
  };

  const fetchData = async (id?: any) => {
    try {
      const { data, status, message } =
        await ApprovalHeirarchyApis.fetchApprovalHierarchyByModuleId(
          id ? id : selectedModule
        );
      if (status) {
        console.log(data);
        setData(data);
        if (data) setEditOnly(true);
        else setEditOnly(false);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error: any) {
      console.log(error);

      Swal.fire("Error", error, "error");
    }
  };

  const fetchApprovalHeirarchy = async () => {
    try {
      const res = await ApprovalHeirarchyApis.fetchApprovalHeirarchy();
      const data = res.data;
      setRowData(data);
      setOriginalData(data); // Store the original data
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchApprovalHeirarchy();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  const onFilterChange = (e: any) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (onSearchText !== "") {
      const searchText = onSearchText.toLowerCase();

      const filteredObject = originalData.filter((item: any) => {
        return Object.values(item).some((value: any) => {
          // Convert value to string and then perform case-insensitive search
          return value && value.toString().toLowerCase().includes(searchText);
        });
      });
      setRowData(filteredObject); // Update state with filtered data
    } else {
      setRowData(originalData); // Reset to original data
    }
  }, [onSearchText, originalData]);

  const sortFunc = (params: any) => {
    let defaultData = rowData;
    if (params === "asc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        a.createdDate.localeCompare(b.createdDate)
      );
      setRowData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        b.createdDate.localeCompare(a.createdDate)
      );
      setRowData([...sortedData]);
    }
  };
  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = rowData?.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  return (
    <>
      <Head title="Approval Hierarchy" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Approval Hierarchy
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Approval Hierarchy</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => setSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Export data={rowData} />
                    </li>
                    {allowedActions?.add ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          color="primary"
                          onClick={() => {
                            handleModelPopUp(null, "");
                          }}
                        >
                          <Icon name="plus"></Icon>
                          <span>Add </span>
                        </Button>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable
            className="card-stretch"
            bodyClassName={undefined}
            title={undefined}
          >
            <div className="card-inner position-relative card-tools-toggle">
              <div className={`card-search search-wrap active`}>
                <div className="card-body">
                  <div
                    className="search-content"
                    style={{ whiteSpace: "nowrap", display: "flex" }}
                  >
                    <Button className="search-back btn-icon toggle-search active">
                      <Icon name="search"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="btn btn-trigger btn-icon dropdown-toggle"
                      >
                        <Icon name="filter"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end className="dropdown-menu-xs">
                        <ul className="link-check">
                          <li>
                            <span>Show</span>
                          </li>
                          <li className={itemPerPage === 10 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(10);
                              }}
                            >
                              10
                            </DropdownItem>
                          </li>
                          <li className={itemPerPage === 15 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(15);
                              }}
                            >
                              15
                            </DropdownItem>
                          </li>
                        </ul>
                        <ul className="link-check">
                          <li>
                            <span>Order</span>
                          </li>
                          <li className={sort === "dsc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("dsc");
                                sortFunc("dsc");
                              }}
                            >
                              DESC
                            </DropdownItem>
                          </li>
                          <li className={sort === "asc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("asc");
                                sortFunc("asc");
                              }}
                            >
                              ASC
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            </div>

            <DataTableBody compact className={undefined} bodyclass={undefined}>
              <DataTableHead>
                {/* <DataTableRow className={undefined} size={undefined}>
                  <span className="sub-text">#</span>
                </DataTableRow> */}
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Module Name</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Auto</span>
                </DataTableRow>
                <DataTableRow size="sm" className={undefined}>
                  <span className="sub-text">Approval Levels</span>
                </DataTableRow>
                <DataTableRow size="sm" className={undefined}>
                  <span className="sub-text">Hierarchy</span>
                </DataTableRow>

                <DataTableRow size="md" className={undefined}>
                  {/* <span className="sub-text">Details</span> */}
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems?.length > 0
                ? currentItems?.map((item: any, index) => {
                    return (
                      <DataTableItem
                        key={item.id}
                        className={undefined}
                        style={{ color: "black" }}
                      >
                        {/* <DataTableRow size="md" className={undefined}>
                        <span>{index + 1}</span>
                      </DataTableRow> */}
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.approvalModuleName}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.approvalType}</span>
                        </DataTableRow>
                        <DataTableRow size="sm" className={undefined}>
                          <span>{item.approvalCount}</span>
                        </DataTableRow>
                        <DataTableRow
                          size="sm"
                          className={undefined}
                          style={{
                            backgroundColor: "lightgray",
                            padding: "10px",
                          }}
                        >
                          {/* <span style={{ color: "blue", fontWeight: "bold" }}>
                            {item.level
                              .map((level: any) => level.levelName)
                              .join(" -> ")}
                          </span> */}
                          {item.level.length === 0 ? (
                            <span>NA</span>
                          ) : (
                            item.level.map((level: any, index: number) => (
                              <React.Fragment key={level.id}>
                                <span
                                  style={{ color: "blue", fontWeight: "bold" }}
                                >
                                  {level.levelRoleName || "NA"}
                                </span>
                                {index < item.level.length - 1 && (
                                  <Icon
                                    style={{
                                      color: "blue",
                                      fontWeight: "bold",
                                    }}
                                    name="arrow-long-right"
                                  ></Icon>
                                )}
                              </React.Fragment>
                            ))
                          )}
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <ul className="nk-tb-actions">
                            {allowedActions?.edit ? (
                              <li
                                className="nk-tb-action-hidden"
                                onClick={() => {
                                  handleModelPopUp(
                                    item.id,
                                    "edit",
                                    item.approvalModuleName
                                  );
                                }}
                              >
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"edit" + item.id}
                                  icon="edit-alt-fill"
                                  direction="top"
                                  text="Edit"
                                  iconClass={undefined}
                                />
                              </li>
                            ) : (
                              <></>
                            )}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    {allowedActions?.edit ? (
                                      <li
                                        onClick={() => {
                                          handleModelPopUp(
                                            item.id,
                                            "edit",
                                            item.approvalModuleName
                                          );
                                        }}
                                      >
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                    {allowedActions?.cancel ? (
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            handleDelete(item.id);
                                          }}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems?.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={rowData?.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        <Modal
          isOpen={isOpen}
          toggle={() => setIsOpen(false)}
          className="modal-dialog-centered"
          size="lg"
          backdrop="static"
        >
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setIsOpen(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>

          <ModalBody>{popUpContent}</ModalBody>
        </Modal>
      </Content>
    </>
  );
};

export default ApprovalHeirarchy;

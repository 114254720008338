import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Component";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const dataSourceMap: any = {
  "/view-purchase-reports": {
    title: "Purchase Reports",
    dataSource: [
      {
        key: "2",
        reportName: "Awarded To Supplier",
        reportType: "Finalized orders awarded based on submitted quotations",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-by-supplier-reports-list",
      },
      {
        key: "1",
        reportName: "Purchase Order",
        reportType:
          "Detailed document confirming order placement with suppliers.",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-order-reports-list",
      },
      {
        key: "6",
        reportName: "Invoice by Supplier",
        reportType: "Supplier-generated billing document for delivered goods",
        createdBy: "System Generated",
        onClickNavigation: "/invoice-by-supplier-reports-list",
      },
      {
        key: "3",
        reportName: "Purchase by Material",
        reportType: "Detailed summary of purchases categorized by materials.",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-by-material-reports-list",
      },
      {
        key: "4",
        reportName: "Purchase by Category",
        reportType: "Summary of purchases grouped by material categories",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-by-category-reports-list",
      },
      {
        key: "5",
        reportName: "Purchase by Site",
        reportType:
          "Overview of purchases categorized by construction site locations",
        createdBy: "System Generated",
        onClickNavigation: "/purchase-by-site-reports-list",
      },
    ],
  },
  "/view-accounts-payable-reports": {
    title: "Accounts Payable Reports",
    dataSource: [
      {
        key: "5",
        reportName: "Supplier Balances",
        reportType: "Outstanding payment amounts due to each supplier",
        createdBy: "System Generated",
        onClickNavigation: "/supplier-balances-report",
      },
      // {
      //   key: "1",
      //   reportName: "Supplier Payments",
      //   reportType: "Record of payments made to suppliers for invoices",
      //   createdBy: "System Generated",
      //   onClickNavigation: "/supplier-payments-reports-list",
      // },
      {
        key: "2",
        reportName: "Payments Aging By Supplier",
        reportType: "Overview of overdue payments categorized by aging periods",
        createdBy: "System Generated",
        onClickNavigation: "/payment-aging-summary-reports-list",
      },
      {
        key: "3",
        reportName: "Payments Aging By Invoice",
        reportType: "Detailed breakdown of unpaid invoices by aging periods",
        createdBy: "System Generated",
        onClickNavigation: "/payment-aging-reports-list",
      },
      {
        key: "4",
        reportName: "Payments To Supplier",
        reportType: "Comprehensive summary of all payments made to suppliers.",
        createdBy: "System Generated",
        onClickNavigation: "/payment-reports-list",
      },
    ],
  },
  "/view-inventory-reports": {
    title: "Inventory Reports",
    dataSource: [
      {
        key: "9",
        reportName: "GRN Details",
        reportType:
          "Comprehensive record of Goods Receipt Notes for materials received.",
        createdBy: "System Generated",
        onClickNavigation: "/delivery-note-details",
      },
      {
        key: "1",
        reportName: "Materials Issued To Site",
        reportType: "Record of materials dispatched to construction sites",
        createdBy: "System Generated",
        onClickNavigation: "/inventory-materials-to-site-reports-list",
      },
      {
        key: "8",
        reportName: "Material Utilization",
        reportType:
          "Record of materials used for activities at construction sites.",
        createdBy: "System Generated",
        onClickNavigation: "/inventory-material-utilization-report-list",
      },
      {
        key: "6",
        reportName: "Material Movement",
        reportType:
          "Detailed tracking of materials transferred between locations or sites",
        createdBy: "System Generated",
        onClickNavigation: "/inventory-track-report-list",
      },
      {
        key: "4",
        reportName: "Inventory Summary",
        reportType:
          "Overview of stock levels across all materials and locations",
        createdBy: "System Generated",
        onClickNavigation: "/inventory-summary-reports-list",
      },
      {
        key: "5",
        reportName: "Inventory Value",
        reportType:
          "Total monetary value of inventory across all materials and sites.",
        createdBy: "System Generated",
        onClickNavigation: "/inventory-value-report-list",
      },
      {
        key: "7",
        reportName: "Inventory Aging",
        reportType: "Analysis of inventory categorized by duration in stock.",
        createdBy: "System Generated",
        onClickNavigation: "/inventory-aging-report-list",
      },
      {
        key: "10",
        reportName: "ABC Classification",
        reportType:
          "Categorization of inventory based on value and usage priority.",
        createdBy: "System Generated",
        onClickNavigation: "/inventory-abc-classification-report-list",
      },
    ],
  },
  "/view-budget-reports": {
    title: "Budget Reports",
    dataSource: [
      {
        key: "1",
        reportName: "Budget Tracking",
        reportType:
          "Monitoring expenses against allocated budgets for projects or sites.",
        createdBy: "System Generated",
        onClickNavigation: "/material-tracking-report-list",
      },
      {
        key: "2",
        reportName: "Material Consumption Summary",
        reportType:
          "Consolidated report of materials consumed across all sites",
        createdBy: "System Generated",
        onClickNavigation: "/material-consumption-report-list",
      },
    ],
  },
  "/view-material-management-reports": {
    title: "Material Management Reports",
    dataSource: [
      {
        key: "1",
        reportName: "Material Request Summary",
        reportType: "Overview of all material requests raised across sites",
        createdBy: "System Generated",
        onClickNavigation: "/material-request-summary-list",
      },
      {
        key: "2",
        reportName: "Material Issue Summary",
        reportType:
          "Summary of materials issued from inventory to various sites",
        createdBy: "System Generated",
        onClickNavigation: "/material-issue-summary-list",
      },
      {
        key: "3",
        reportName: "Material Utilized Summary",
        reportType:
          "Detailed summary of materials used across projects and sites",
        createdBy: "System Generated",
        onClickNavigation: "/material-utilized-summary-list",
      },
      {
        key: "4",
        reportName: "Material Damage Summary",
        reportType: "Overview of damaged materials recorded across all sites",
        createdBy: "System Generated",
        onClickNavigation: "/material-damage-summary-list",
      },
      {
        key: "5",
        reportName: "Material Flow Summary",
        reportType:
          "Comprehensive overview of material requested, purchased, received, used & Damaged",
        createdBy: "System Generated",
        onClickNavigation: "/material-summary-list",
      },
    ],
  },
};

function ViewAllReportsList() {
  const navigate = useNavigate();
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth >= 990) {
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const isActive = (path: string) => {
    return window.location.pathname === path ? "active" : "";
  };
  const LinkTo = ({ path }: any) => {
    // if (!allowedRoutes.includes(path)) {
    //   return null;
    // }
    path = "/" + path;
    switch (path) {
      case "/view-purchase-reports":
        return (
          <li>
            <Link to={path} className={isActive(path)}>
              <span>Purchase Reports</span>
            </Link>
          </li>
        );
      case "/view-accounts-payable-reports":
        return (
          <li>
            <Link to={path} className={isActive(path)}>
              <span>Accounts Payable Reports</span>
            </Link>
          </li>
        );
      case "/view-inventory-reports":
        return (
          <li>
            <Link to={path} className={isActive(path)}>
              <span>Inventory Reports</span>
            </Link>
          </li>
        );
      case "/view-budget-reports":
        return (
          <li>
            <Link to={path} className={isActive(path)}>
              <span>Budget Reports</span>
            </Link>
          </li>
        );
      case "/view-material-management-reports":
        return (
          <li>
            <Link to={path} className={isActive(path)}>
              <span>Material Management Reports</span>
            </Link>
          </li>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Head title="View All Reports" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg 
                ${sm ? "content-active" : ""}
              `}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
                      Modules
                    </h6>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    {[
                      "view-purchase-reports",
                      "view-accounts-payable-reports",
                      "view-inventory-reports",
                      "view-budget-reports",
                      "view-material-management-reports",
                    ].map((item) => (
                      <li>
                        <LinkTo path={item} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* content start here */}
            <div className="card-inner card-inner-lg">
              {sm && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>
                        {dataSourceMap[window.location.pathname].title}
                      </span>
                    </BlockTitle>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <div className="content clearfix">
                <div
                  style={{
                    width: screenSize - 350 + "px",
                    margin: "0px auto",
                  }}
                >
                  <Table
                    className="customTable"
                    columns={[
                      {
                        title: "#",
                        key: "key",
                        width: 50,
                        render: (text, record, index) => index + 1,
                      },
                      {
                        title: "Report Name",
                        dataIndex: "reportName",
                        key: "reportName",
                        render: (text, record: any) => (
                          <span
                            className="inlineTableCellLink"
                            onClick={() => navigate(record?.onClickNavigation)}
                          >
                            {text}
                          </span>
                        ),
                      },
                      {
                        title: "Report Description",
                        dataIndex: "reportType",
                        key: "reportType",
                      },
                      // {
                      //   title: "Created By",
                      //   dataIndex: "createdBy",
                      //   key: "createdBy",
                      // },
                    ]}
                    dataSource={
                      dataSourceMap[window.location.pathname]?.dataSource
                      // ?.filter((route: any) =>
                      //   allowedRoutes.includes(route?.onClickNavigation?.slice(1))
                      // )
                    }
                    locale={{
                      emptyText: (
                        <span
                          style={{
                            height: "300px",
                            color: "#333",
                            fontSize: "16px",
                          }}
                        >
                          Kindly contact the Admin to request access permission
                          for the{" "}
                          {dataSourceMap[window.location.pathname].title}.
                        </span>
                      ),
                    }}
                    bordered
                    size="middle"
                    pagination={false}
                  />
                </div>
              </div>
            </div>

            {/* content end here */}
          </div>
        </Card>
      </Content>
    </>
  );
}

export default ViewAllReportsList;

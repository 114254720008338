import { Descriptions, Drawer, Table } from "antd";
import { use } from "echarts";
import { useEffect, useState } from "react";
import quotationApis from "../../../../api/master/quotations";
import { render } from "@testing-library/react";
import dayjs from "dayjs";
import { formatCurrency2 } from "../../../../utils/Utils";

const PurchaseHistoryDrawer: React.FC<any> = ({
  paramsData,
  visible,
  onClose,
}) => {
  const [PurchaseHistoryData, setPurchaseHistoryData] = useState<any>([]);
  const columns = [
    {
      title: "RFQ Id",
      dataIndex: "rfqId",
      key: "rfqId",
    },
    {
      title: "Quoted Price",
      dataIndex: "quotedPrice",
      key: "quotedPrice",
      render: (text: any, record: any) => {
        const price = text ? Number(text)?.toFixed(2) : "N/A";
        return <>{price !== "N/A" && formatCurrency2(price, "INR")}</>;
      },
    },
    {
      title: "Quoted Date",
      dataIndex: "quotedDate",
      key: "quotedDate",
      render: (text: any, record: any) => (
        <>{dayjs(text).format("DD-MMM-YYYY")}</>
      ),
    },
  ];

  useEffect(() => {
    if (paramsData && Object.keys(paramsData).length > 0) {
      getPurchaseHistoryData();
    }
  }, [paramsData]);

  const getPurchaseHistoryData = async () => {
    try {
      const { response, status } = await quotationApis.fetchPurchaseHistoryData(
        {
          quotationId: paramsData.quotationId,
          supplierId: paramsData.supplierId,
          materialId: paramsData.materialId,
          pageSize: 3,
        }
      );
      if (status == true) {
        setPurchaseHistoryData(response.lines);
      }
    } catch {}
  };

  return (
    <Drawer
      title="Purchase History"
      width={800}
      onClose={onClose}
      visible={visible}
    >
      <div className="mb-3">
        <Descriptions column={3} style={{ flex: 1, marginLeft: " 10px" }}>
          <Descriptions.Item label="Material">
            {paramsData?.materialName}
          </Descriptions.Item>
          <Descriptions.Item label="Supplier">
            {paramsData?.supplierName}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <Table columns={columns} dataSource={PurchaseHistoryData} />
    </Drawer>
  );
};

export default PurchaseHistoryDrawer;

import React, { useState, useEffect, FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  Badge,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Card,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Popover,
} from "reactstrap";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import {
  Form,
  TableColumnsType,
  Input,
  Table,
  Timeline,
  Collapse as AntCollapse,
  Modal as AntModal,
  Flex,
  Spin,
  Space,
  Divider,
} from "antd";
import { FormProvider, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import usersApis from "../../api/master/users";
import boqApis from "../../api/boq/boq";
import contractorsApis from "../../api/master/contractors";
import materialsApis from "../../api/master/materials";
import supplierApis from "../../api/master/supplier";
import sites from "../../api/master/sites";
import BOQAside from "./boqAside";
import misc from "../../api/misc/dropDowns";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import FormMultiSelect from "../form-components/MultiSelectComponent";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import BoqMaterialModal from "./editboqmaterialmodal";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";


const { Panel } = AntCollapse;

interface SelectOptions {
  label: string;
  value: string;
}

const ViewBoqDetails: FC<any> = () => {

  const { data: currentUser }: any = useSelector(
    (state: RootState) => state.currentUser
  );
  const methods = useForm({
      mode: "onChange",
    });
  const {
      handleSubmit,
      reset,
      watch,
      control,
      setValue,
      formState: { errors },
  } = methods;
  const [form] = Form.useForm();
  let { targetId}= useParams();
  const [sm, setSm] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [blockData, setBlockData] = useState<any>([]);
  const [status, setstatus] = useState<any>("");
  const [data, setData] = useState<any>([]);
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [contractorOptions, setContractorOptions] = useState<SelectOptions[]>(
      []
    );
  const { fields, append, remove, replace } = useFieldArray({
        control,
        name: "lines",
      });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);
  const [blockOptions, setBlockOptions] = useState<SelectOptions[]>([]);
  const [usersOptions, setUsersOptions] = useState<SelectOptions[]>([]);
  const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
  const [editLineRow, setEditLineRow] = useState<any>();
  const [ConsumptionTarget_GeneralDetailsEdit, setConsumptionTarget_GeneralDetailsEdit] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const siteIdWatch = watch("siteId");
  const navigate = useNavigate();

useEffect(() => {
  if (targetId) {
    console.log("calling fetch Boq details only after targetid is present");
    fetchBoqDetails() 
}
},[targetId]);

useEffect(() => {
    if (siteIdWatch) {
      fetchBlockList(siteIdWatch);
      fetchContractorList(siteIdWatch + "");
    }
  }, [siteIdWatch]);


useEffect(() => {
    console.log('siteIdWatch useEffect pr details',siteIdWatch);

    if (siteIdWatch) {
      fetchUsers(siteIdWatch);
    }
  }, [siteIdWatch]);



 useEffect(() => {

    fetchCategorys();
    fetchSites();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);


  const fetchBoqDetails = async ()=> {
    try {
      const res = await boqApis.fetchViewDeatils(targetId)
      const { response, status, message } = res;
      if (status) {
        let data: any = response;
        console.log('data:::::::',data)
        data?.lines?.forEach((item: any) => {
          item.headerStaus = data?.header?.status;
        });
        setMaterialData(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } 
  };

  const fetchUsers = async (siteId: any) => {
    try {
      const data = await misc.fetchSiteUsers(siteId);
      if (data) {
        let usersOptions = data?.data?.map((item: any) => ({
          label: item.userName,
          value: item.userId.toString(),
        }));
        setUsersOptions(usersOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
     console.log(data, status);
     
      if (status) {

        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setMaterialCategoryOptions(categoryOption);
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBlockList = async (siteId: any) => {
    try {
      const { data, status } = await sites.fetchBlockById(siteId);
      if (status) {
        let blockOptions = data.map((item: any) => ({
          label: item.blockName,
          value: item.blockID.toString(),
        }));
        setBlockOptions(blockOptions);
        setBlockData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContractorList = async (siteId: any) => {
    try {
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: siteId,
      });
      if (status) {
        let contractorOptions = data.map((item: any) => ({
          label: item?.companyName,
          value: item?.contractorID?.toString(),
        }));
        setContractorOptions(contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };



 const [columns] = useState<TableColumnsType<any>>([


  {
    title: "Material Category",
    dataIndex: "categoryName",
    key: "categoryLabel",
    width: 200,
    fixed: "left",
  },

  {
    title: "Material Name",
    dataIndex: "materialName",
    key: "materialLabel",
    width: 200,
    fixed: "left",
  },
  {
    title: "Req Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 120,
      render: (text, record) => {
    
        return `${record?.quantitySet}`; 
      },
  },
  {
     title: " Uom ",
     dataIndex: " Uom ",
     key: "uomName",
     width:120,
     render: (text, record) => {
      return `${record?.uomName}`
     }
  },
  
  
  {
  title: "Set Target/ Sft ",
  dataIndex: "Target",
  key:"targetPerSft ",
  width: 150,
  render: (text, record) => {
    return `${record?.purchasePerSft}`
  }
  },
  {
  title: "Action",
  key: "operation",
  fixed: "right",
  width: 150,
  render: (text, record: any, index) => {
    return (
      <>
      <Button
         size="sm"
            color=""
              onClick={() => {
                setEditLineRow(record);
             }}
            >
            <Icon name="edit" />
          </Button>
        </>
      );
     },
  },
]);
    const filteredColumns = columns.filter((col) => {
      const operationStatus = ["REJECTED", "IN_PROGRESS"];
      if (
        col.key === "operation" &&
        operationStatus.includes(materialData?.header?.status)
      ) {
        return false; 
      }
      return true; 
    });

  useEffect(() => {
      if (editLineRow) {
        setAddNewRowModel(true);
      }
    }, [editLineRow]);


    const SubmitChanges =async () => {
      try{
        const _formData = new FormData();
        console.log("materialData", materialData);

    let contractorId = materialData?.header?.contractorId
        ? Number(materialData?.header?.contractorId)
        : null;
  
    const submitData = {
      ...materialData?.header,
      status: "SUBMIT",
      
      contractorId: contractorId,
      siteId: Number(materialData?.header?.siteId),
      
    };

    _formData.append("header", JSON.stringify(submitData));
    _formData.append("lines", JSON.stringify(materialData?.lines));

    // console.log("submitData", submitData);

    const res = await boqApis.newVersionboq(targetId);
      const { status, message }: any = res;
      if (status) {
        fetchBoqDetails();
        Swal.fire("Updated!", message, "success");
        navigate("/consumption_target_view");
      } else {
        Swal.fire("Error!", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Head title="BOQ - Details" />

      {/* Modal to Update General Information */}
      <Modal
      isOpen={ConsumptionTarget_GeneralDetailsEdit}
      toggle={() => setConsumptionTarget_GeneralDetailsEdit(false)}
      className="modal-dialog-centered"
      size="xl"
      backdrop="static"
      >
        <a
        href="#cancel"
        onClick={(ev) => {
          ev.preventDefault();
          setConsumptionTarget_GeneralDetailsEdit(false);
        }}
        className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <ModalBody>
          <>
          <h5 className="title">Update General Information</h5>
          <div style={{ marginTop: "15px" }}></div>

          <FormProvider {...methods}>
            <form
            className="content claerfix"
            onSubmit={handleSubmit((data) => {
              setMaterialData((pre: any) => {
                const newheader = {
                  ...pre.header,
                  ...data,
                  siteName: sitesOptions.find(
                    (item) => item.value == data?.siteId
                  )?.label,
                  contractorName: contractorOptions.find(
                    (item) => item.value == data?.contractorId
                  )?.label,
                };

                console.log("updateData", newheader);

                const updatedMaterialData = {
                  ...pre,
                  header:newheader,
                };
                return{
                  ...pre,
                  ...data,
                  ...updatedMaterialData,
                };
              });

              setConsumptionTarget_GeneralDetailsEdit(false);
              setAddNewRowModel(false);
              setEditLineRow(null);
              setShowSubmitBtm(true);
            })}
              >
              <Row className="gy-4">
                <Col lg="4">
                  <FormInput
                    name="targetNumber"
                    label="Target Number"
                    required={true}
                    type="text"
                    placeholder="Enter Target Number"
                    tooltipText="Enter Target Number"
                  />
                </Col>
                <Col lg="4">
                  <FormSelect
                    name="siteId"
                    label="Site"
                    options={sitesOptions}
                    required={true}
                    placeholder="-Select-"
                    tooltipText="Select the site where the materials will be used."
                  />
                </Col>
                <Col lg="4">
                  <FormSelect
                    name="BlockId"
                    label="Block"
                    options={blockOptions}
                    required={true}
                    placeholder="-Select-"
                    tooltipText="Select the block where the materials will be used."
                  />
                </Col>
              </Row>
              <Row className="gy-4 mt-1">
                <Col lg="4">
                  <FormSelect
                    name="contractorId"
                    label="Contractor"
                    options={contractorOptions}
                    placeholder="-Select-"
                    tooltipText="Select the contractor for this purchase request."
                  />
                </Col>
                <Col lg="4">
                  <FormSelect
                    name="limitPostTargetConsumption"
                    label="Limit Material Issue"
                    options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                    placeholder="-Select-"
                    tooltipText="Material cannot be issued without approvals to site post BOQ Consumption."
                  />
                </Col>
                <Col lg="4">
                  <FormSelect
                    name="limitPostTargetOrder"
                    label="Limit PO?"
                    options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                    placeholder="-Select-"
                    tooltipText="Purchase orders cannot be released without approvals post exceeding BOQ purchases."
                  />
                </Col>
                <Col lg="4">
                  <FormMultiSelect
                    name="setAlerts"
                    label="Set Alerts"
                    options={Array.from({ length: 10 }, (_, i) => {
                      const value = (i + 1) * 5;
                      return { label: value.toString(), value: value.toString() };
                    })}
                    placeholder="-Select-"
                    tooltipText="Select Limit."
                  />
                </Col>
                
              </Row>

              <Row className="mt-4">
                <Col lg="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button className="btn btn-primary btn-md" type="submit" color="primary">
                        Save
                      </Button>
                    </li>
                    <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setConsumptionTarget_GeneralDetailsEdit(false);
                            reset();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                  </ul>
                </Col>
              </Row>
            </form>
          </FormProvider>
          </>
        </ModalBody>
      </Modal>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <BOQAside updateSm={setSm} sm={sm} />
            </div>
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">
                        <span>
                          Target Id - {materialData?.header?.targetId}  {" "}
                          {materialData?.header?.actualStatus} {" "}
                        </span>
                      </BlockTitle>
                    </BlockHeadContent>
                    <div className="d-flex">
                      {showSubmitBtm && (
                        <div className="actions clearfix">
                          <ul
                            className="m-auto d-flex align-items-center justify-content-center p-0"
                            style={{
                              flexDirection: "row-reverse",
                            }}
                          >
                            <li className="pt-0 pb-0">
                              {disableSubmit ? (
                                <Button color="primary" type="button">
                                  <Spinner size="sm" color="light" />
                                </Button>
                              ) 
                              : (
                                <Button
                                  color="primary"
                                  type="button"
                                  onClick={() => {
                                    setDisableSubmit(true);
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Do you want to submit the changes?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, submit it!",
                                      cancelButtonText: "No, cancel",
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {
                                        await SubmitChanges();
                                      } else {
                                        setDisableSubmit(false);
                                      }
                                    });
                                  }}
                                >
                                  Save Changes
                                </Button>
                              )}
                            </li>
                            <li className="pt-0 pb-0">
                              <Button
                                color="secondary"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "Do you want to revert the changes?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, revert it!",
                                    cancelButtonText: "No, cancel",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      fetchBoqDetails();
                                      Swal.fire(
                                        "Reverted!",
                                        "Your changes have been reverted.",
                                        "success"
                                      );
                                    } else {
                                      setDisableSubmit(false);
                                    }
                                  });
                                }}
                                type="button"
                                className="btn-dim btn btn-secondary"
                              >
                                Revert Changes
                              </Button>
                            </li>
                          </ul>
                        </div>
                      )}
                        
                      {!showSubmitBtm && (
                          <div className="paddingLeft10">
                              <Button
                                className="toggle"
                                color="primary"
                                onClick={async () => {
                                  navigate(-1);
                                  }}
                                  >
                                <Icon name="arrow-left" />
                                <span>Back</span>
                              </Button>
                            </div>
                        )}
                    </div>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""
                          }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>

                <div className="content clearfix">
                  <AntCollapse defaultActiveKey={["1", "2", "3"]}>
                    <Panel header="General Information" key="1">
                      {status !== "IN_PROGRESS" && status !== "REJECTED" && (
                        <span
                          className="cursor-pointer position-absolute"
                          style={{
                            right: 40,
                            top: 75,
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setValue("targetNumber", materialData?.header?.targetNumber);
                            setValue("siteId", materialData?.header?.siteId);
                            setValue("BlockId", materialData?.header?.blockId);
                            setValue("contractorId", materialData?.header?.contractorId);
                            setValue("limitPostTargetConsumption", materialData?.header?.limitPostTargetConsumption ? "Yes" : "No");
                            setValue("limitPostTargetOrder", materialData?.header?.limitPostTargetConsumption ? "Yes" : "No");
                            setValue("setAlerts", materialData?.header.setAlerts);
                            setConsumptionTarget_GeneralDetailsEdit(true);
                          }}
                        >
                          <Icon name="edit" />
                        </span>
                      )}
                      <Col className="justify-start w-100 flex-wrap mt-3 p-3">
                        <div className="w-30 border-end">
                          <div className="data-col align-center pt-1">
                            <div className="data-label w-45">Target Number</div>
                            <div className="data-value w-55">{materialData?.header?.targetNumber}</div>
                          </div>
                          <div className="data-col align-center pt-1">
                            <div className="data-label w-45">Site</div>
                            <div className="data-value w-55">
                              {sitesOptions.find((item) => item.value == materialData?.header?.siteId)?.label}
                            </div>
                          </div>
                          <div className="data-col align-center pt-1 ">
                            <div className="data-label w-45"> Block </div>
                            <div className="data-value w-55 ">
                              {materialData?.header?.blockName}
                            </div>
                          </div>
                          
                          <div className="data-col align-center pt-1">
                            <div className="data-label w-45">Contractor</div>
                            <div className="data-value w-55">
                              {contractorOptions.find(
                                (item) => item.value == materialData?.header?.contractorId
                              )?.label || materialData?.header?.contractorName || "NA"}
                            </div>
                          </div>
                        </div>
                        <div className="w-30 border-end paddingLeft10  ">
                          <div className="data-col align-center pt-1 ">
                            <div className="data-label w-55"> Limit Material Issue </div>
                            <div className="data-value w-55 ">
                            {materialData?.header?.limitPostTargetConsumption === 1 ? 'Yes' : 'No'}
                            </div>
                          </div>
                          <div className="data-col align-center pt-1 ">
                            <div className="data-label w-55"> Limit PO? </div>
                            <div className="data-value w-55 ">
                            {materialData?.header?.limitPostTargetConsumption === 1 ? 'Yes' : 'No'}
                            </div>
                          </div>
                        </div>
                        <div className="w-30 paddingLeft10">
                          <div className="data-col align-center pt-1 ">
                            <div className="data-label w-40"> Set Alerts </div>
                            <div className="data-value w-55 ">
                              {materialData?.header?.setAlerts}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Panel>
                    <Panel header="Material Information" key="2">
                      <div className="table-responsive poTable">
                        <div
                          style={{
                            width: screenSize - 400 + "px",
                            margin: "auto",
                          }}
                        >
                          <Table
                            className="customTable"
                            columns={filteredColumns}
                            dataSource={materialData?.lines}
                            scroll={{ x: 1000 }}
                            locale={{
                              emptyText: (
                                <span
                                  style={{
                                    height: "300px",
                                    color: "#333",
                                    fontSize: "16px",
                                  }}
                                >
                                  Please click on "Add New Item" to add purchase
                                  order lines.
                                </span>
                              ),
                            }}
                            bordered
                            size="middle"
                            pagination={false}
                          />
                        </div>
                        {materialData?.lines?.length > 0 && (
                          <Row
                            style={{
                              margin: "20px",
                            }}
                          >
                            {status !== "IN_PROGRESS" &&
                              status !== "REJECTED" && (
                                <Col
                                  className={`${status !== "Pending Approval"
                                      ? "justify-between"
                                      : "justify-end"
                                    }`}
                                >
                                  {/* {poStatus == "Pending Approval" && ( */}
                                  <a
                                    onClick={() => {
                                      setAddNewRowModel(true);
                                      setEditLineRow(null);

                                    }}
                                    href="#add"
                                    className="addNewItem"
                                  >
                                    <Icon name="plus-round" />
                                    <span>Add New Item</span>
                                  </a>
                                  {/* )} */}
                                </Col>
                              )}
                          </Row>
                        )}
                      </div>
                    </Panel>
                  </AntCollapse>
                  <Block>
                    {addNewRowModel && (
                      <BoqMaterialModal 
                        modal={addNewRowModel}
                        closeModal={() => {
                          setAddNewRowModel(false);
                          setEditLineRow(null);
                        }}
                        materialCategoryOptions={materialCategoryOptions}
                        status={materialData?.header?.status}
                        formData={{
                          action: editLineRow == null ? "add" : "edit",
                          index: editLineRow?.linesid,
                          item: editLineRow,
                        }}
                        append={(data: any) => {
                          console.log("Append", data, materialData);
                          let newLineItems = materialData?.lines || [];
                          console.log(materialData?.lines, "newLineItems");
                          const _data = newLineItems.find(
                            (item: any) => item.materialId == data.materialId
                          );
                          if (_data) {
                            Swal.fire({
                              icon: "error",
                              title: "Error",
                              text: "Material Alredy Added",
                            });
                            return;
                          }

                          setMaterialData((prev: any) => {
                            let newData = {
                              ...prev,
                              lines: [...prev.lines, data],
                            };
                            console.log(newData, "newData");
                            return newData;
                          });

                          setShowSubmitBtm(true);
                        }}
                        update={(id: any, data: any) => {
                          console.log(
                            "upadate",
                            data,
                            id,
                            materialData?.lines
                          );
                          let newLineItems = materialData?.lines || [];
                          const _data = newLineItems
                            .filter((item: any) => item.linesid != id)
                            .find(
                              (item: any) => item.materialId == data.materialId
                            );

                          if (_data) {
                            Swal.fire({
                              icon: "error",
                              title: "Error",
                              text: " Material Already Added",
                            });
                            return;
                          }
                          const updatedLines = newLineItems.map((item: any) => {
                            if (item.linesid == id) {
                              return data;
                            }
                            return item;
                          });

                          setMaterialData((prev: any) => ({
                            ...prev,
                            lines: updatedLines, 
                          }));

                          setData(updatedLines);
                          setShowSubmitBtm(true);

                        }}
                      />
                    )}
                  </Block>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Content>
    </>
  );
};

export default ViewBoqDetails;










import React, { useState, useEffect, useMemo, FC, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import contractorsApis from "../../../api/master/contractors";

import FormInput from "../../form-components/InputComponent";
import FormatDate from "../DateFormater";
import usersApis from "../../../api/master/users";
import FormSelect from "../../form-components/SelectComponent";
import { FormProvider, useForm } from "react-hook-form";
import FormDatePicker from "../../form-components/DatePicker";
import moment from "moment";
import { useSelector } from "react-redux";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
import sites from "../../../api/master/sites";

interface SelectOptions {
  label: string;
  value: string;
}
const ViewContractorDetails: FC = () => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { siteId } = useParams();
  const [data, setData] = useState<any>();
  const [modalGI, setGIModal] = useState<any>(false);
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");
  const [formData, setFormData]: any = useState({
    companyName: "",
    contactPerson: "",
    email: "",
    phoneNumber: "",
    mailingAddressLine1: "",
    businessDescription: "",
    yearsInBusiness: "",
    references: "",
    regulatoryCompliance: "",
    insuranceCoverage: "",
    docsUpload: "",
    projectRequirements: "",
    specifications: "",
    // docsUpload: "",
    billingRates: "",
    // docsUpload: "",
    contractTerms: "",
    insuranceRequirements: "",
    // docsUpload: "",
    qualityAssurancePlan: "",
    inspectionAndAcceptanceCriteria: "",
    docsUpload4: "",
    environmentalCompliance: "",
    wasteManagementProcedures: "",
    docsUpload2: "",
    emergencyContactPerson: "",
    emergencyContactNumber: "",
    emergencyProcedures: "",
    docsUpload3: "",
    disputeResolutionProcess: "",
    docsUpload5: "",
  });

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);

  const methods = useForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = methods;

  const businessType = watch("bussinessType");

  useEffect(() => {
    if (businessType === "unregistered") {
      setValue("gstin", "");
    }
  }, [businessType]);

  const [docs, setDocs] = useState([]);
  const [workingSites, setWorkingSites] = useState<SelectOptions[]>([]);
  const emergencyProceduresWatch = watch("emergencyProcedures");
  const regulatoryCompliancedocsUploadWatch = watch(
    "regulatoryCompliancedocsUpload"
  );
  const licensesAndPermitsdocsUploadWatch = watch(
    "licensesAndPermitsdocsUpload"
  );
  const insuranceCoveragedocsUploadWatch = watch("insuranceCoveragedocsUpload");
  const ScopeofWorkdocsUploadWatch = watch("ScopeofWorkdocsUpload");
  const BillingandPaymentTermsdocsUploadWatch = watch(
    "BillingandPaymentTermsdocsUpload"
  );
  const contractTermsdocsUploadWatch = watch("contractTermsdocsUpload");
  const environmentalCompliancedocsUploadWatch = watch(
    "environmentalCompliancedocsUpload"
  );
  const wasteManagementProceduresdocsUploadWatch = watch(
    "wasteManagementProceduresdocsUpload"
  );
  const qualityAssuranceProceduresdocsUploadWatch = watch(
    "qualityAssuranceProceduresdocsUpload"
  );
  const disputeResolutionProcessdocsUploadWatch = watch(
    "disputeResolutionProcessdocsUpload"
  );
  const getCountries = async () => {
    try {
      const _countries = await usersApis.getCountrys();
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountries(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        // setValue("state", state.value);
      }
    } catch (error) {}
  };
  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const fetchWorkingSites = async () => {
    try {
      const { data, status, message } = await sites.fetchSites();
      if (status) {
        setWorkingSites(
          data.map((item: any) => ({
            label: item.name,
            value: item.siteID,
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.message, "error");
    }
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = siteId;
    console.log("id", id);
    if (id !== undefined || null || "") {
      fetchContractorData(id);
      fetchWorkingSites();
      getCountries();
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [siteId]);

  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
  };

  const submitUpdates = async (submitData: any) => {
    const { emergencyProcedures } = submitData;
    const filesData = [];
    const files = [];
    const deletedFileData: any = [];
    const formData: any = new FormData();
    console.log(emergencyProcedures);
    if (emergencyProcedures && emergencyProcedures[0]) {
      console.log("emergencyProcedures", emergencyProcedures[0]);

      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "emergencyProcedures",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(emergencyProcedures[0]);
      const previousDoc = docs.filter(
        (document: any) => document.moduleFileType == "emergencyProcedures"
      );

      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
      setValue("emergencyProcedures", null);
    }
    console.log("deletedFileData", deletedFileData);

    delete submitData.emergencyProcedures;
    formData.append("contractorData", JSON.stringify(submitData));
    formData.append(
      "filesData",
      JSON.stringify({
        newfiledata: filesData,
        deletedFileData: deletedFileData,
        updatedFileData: [],
      })
    );
    files.forEach((file) => {
      formData.append(`files`, file);
      console.log("file", file);
    });
    const { status, message } = await contractorsApis.updateContractorById(
      siteId,
      formData
    );
    if (status) {
      Swal.fire("Updated!", "Contractor has been updated", "success");
      setGIModal(false);
      fetchContractorData(siteId);
      //   navigate(`/general-contractors`);
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const uploadDocsSubmit = async (data: any) => {
    const {
      regulatoryCompliancedocsUpload,
      licensesAndPermitsdocsUpload,
      insuranceCoveragedocsUpload,
      ScopeofWorkdocsUpload,
      BillingandPaymentTermsdocsUpload,
      contractTermsdocsUpload,
      environmentalCompliancedocsUpload,
      wasteManagementProceduresdocsUpload,
      qualityAssurancePlandocsUpload,
      disputeResolutionProcessdocsUpload,
    } = data;

    const filesData = [];
    const files = [];
    const deletedFileData: any = [];
    const formData: any = new FormData();
    if (regulatoryCompliancedocsUpload && regulatoryCompliancedocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "regulatoryCompliancedocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(regulatoryCompliancedocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) =>
          document.moduleFileType == "regulatoryCompliancedocsUpload"
      );

      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    if (licensesAndPermitsdocsUpload && licensesAndPermitsdocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "licensesAndPermitsdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(licensesAndPermitsdocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) =>
          document.moduleFileType == "licensesAndPermitsdocsUpload"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    if (insuranceCoveragedocsUpload && insuranceCoveragedocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "insuranceCoveragedocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(insuranceCoveragedocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) =>
          document.moduleFileType == "insuranceCoveragedocsUpload"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    if (ScopeofWorkdocsUpload && ScopeofWorkdocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "ScopeofWorkdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(ScopeofWorkdocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) => document.moduleFileType == "ScopeofWorkdocsUpload"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    if (
      BillingandPaymentTermsdocsUpload &&
      BillingandPaymentTermsdocsUpload[0]
    ) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "BillingandPaymentTermsdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(BillingandPaymentTermsdocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) =>
          document.moduleFileType == "BillingandPaymentTermsdocsUpload"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    if (contractTermsdocsUpload && contractTermsdocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "contractTermsdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(contractTermsdocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) => document.moduleFileType == "contractTermsdocsUpload"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    if (
      environmentalCompliancedocsUpload &&
      environmentalCompliancedocsUpload[0]
    ) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "environmentalCompliancedocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(environmentalCompliancedocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) =>
          document.moduleFileType == "environmentalCompliancedocsUpload"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    if (
      wasteManagementProceduresdocsUpload &&
      wasteManagementProceduresdocsUpload[0]
    ) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "wasteManagementProceduresdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(wasteManagementProceduresdocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) =>
          document.moduleFileType == "wasteManagementProceduresdocsUpload"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    if (qualityAssurancePlandocsUpload && qualityAssurancePlandocsUpload[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "qualityAssurancePlandocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(qualityAssurancePlandocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) =>
          document.moduleFileType == "qualityAssurancePlandocsUpload"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    if (
      disputeResolutionProcessdocsUpload &&
      disputeResolutionProcessdocsUpload[0]
    ) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "contractor",
        purpose: "disputeResolutionProcessdocsUpload",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(disputeResolutionProcessdocsUpload[0]);
      const previousDoc = docs.filter(
        (document: any) =>
          document.moduleFileType == "disputeResolutionProcessdocsUpload"
      );
      if (previousDoc) {
        previousDoc.forEach((doc: any) => {
          deletedFileData.push({
            docId: doc.docId,
          });
        });
      }
    }
    delete data.regulatoryCompliancedocsUpload;
    delete data.licensesAndPermitsdocsUpload;
    delete data.insuranceCoveragedocsUpload;
    delete data.ScopeofWorkdocsUpload;
    delete data.BillingandPaymentTermsdocsUpload;
    delete data.contractTermsdocsUpload;
    delete data.environmentalCompliancedocsUpload;
    delete data.wasteManagementProceduresdocsUpload;
    delete data.qualityAssurancePlandocsUpload;
    delete data.disputeResolutionProcessdocsUpload;
    methods.setValue("regulatoryCompliancedocsUpload", null);
    methods.setValue("licensesAndPermitsdocsUpload", null);
    methods.setValue("insuranceCoveragedocsUpload", null);
    methods.setValue("ScopeofWorkdocsUpload", null);
    methods.setValue("BillingandPaymentTermsdocsUpload", null);
    methods.setValue("contractTermsdocsUpload", null);
    methods.setValue("environmentalCompliancedocsUpload", null);
    methods.setValue("wasteManagementProceduresdocsUpload", null);
    methods.setValue("qualityAssurancePlandocsUpload", null);
    methods.setValue("disputeResolutionProcessdocsUpload", null);

    formData.append("contractorData", JSON.stringify(data));
    formData.append(
      "filesData",
      JSON.stringify({
        newfiledata: filesData,
        deletedFileData: deletedFileData,
        updatedFileData: [],
      })
    );
    files.forEach((file) => {
      formData.append(`files`, file);
    });
    const { status, message } = await contractorsApis.updateContractorById(
      siteId,
      formData
    );
    if (status) {
      Swal.fire("Updated!", "Contractor has been updated", "success");
      setGIModal(false);
      fetchContractorData(siteId);
      //   navigate(`/general-contractors`);
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const fetchContractorData = async (id: any) => {
    try {
      const { data, status, message } =
        await contractorsApis.fetchContractorById(id);
      console.log("status", data);
      if (status) {
        console.log("status", data);
        const newData = data;
        setDocs(data.docs);
        setData(newData); // Update data with fetched response
        fetchStatesByCountry(data.country, newData.state);
        getDistricts(data.state);
        setFormData({
          companyName: newData.companyName,
          contactPerson: newData.contactPerson,
          email: newData.email,
          phoneNumber: newData.phoneNumber,
          mailingAddressLine1: newData.mailingAddressLine1,
          businessDescription: newData.businessDescription,
          yearsInBusiness: newData.yearsInBusiness,
          references: newData.references,
          cin: newData.cin,
          creditDays: newData.creditDays,
          businessType: newData.businessType,
          incorporationDate: newData.incorporationDate,
          pan: newData.pan,
          gstin: newData.gstin,
          lastFYTurnover: newData.lastFYTurnover,
        });
        setValue("referenceID", newData.referenceID);
        setValue("contractorType", newData.contractorType);
        setValue("companyName", newData.companyName);
        setValue("contactPerson", newData.contactPerson);
        setValue(
          "siteAccessibility",
          data.siteAccessibility.map((item: any) => item.siteID)
        );
        setValue("email", newData.email);
        setValue("phoneNumber", newData.phoneNumber);
        setValue("country", newData.country);
        setValue("state", newData.state);
        setValue("district", newData.district);
        setValue("city", newData.city);
        setValue("area", newData.area);
        setValue("pincode", newData.pincode);
        setValue("mailingAddressLine1", newData.mailingAddressLine1);
        setValue("yearsInBusiness", newData.yearsInBusiness);
        setValue("references", newData.references);
        setValue("bussinessType", newData.bussinessType);
        setValue("incorporationDate", moment(newData.incorporationDate));
        setValue("pan", newData.pan);
        setValue("gstin", newData.gstin);
        setValue("cin", newData.cin);
        setValue("lastFYTurnover", newData.lastFYTurnover);
        setValue("emergencyContactPerson", newData.emergencyContactPerson);
        setValue("emergencyContactNumber", newData.emergencyContactNumber);
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      <Head title="Contractor Details"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Contractor Details -{" "}
                    <strong className="text-primary small">
                      {data["contactPerson"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      {/* <li>
                                                Site ID: <span className="text-base">{data.siteID}</span>
                                            </li> */}
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  {/* {submitEditData && (
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                      style={{ marginRight: "10px" }}
                      onClick={() => submitUpdates()}
                    >
                      <Icon name="check-round-cut"></Icon>
                      <span>Submit Updates</span>
                    </Button>
                  )} */}

                  {/* <Button outline className="bg-white d-none d-sm-inline-flex" style={{ marginRight: "10px" }} onClick={() => openEditModal()}>
                                        <Icon name="edit-alt-fill"></Icon>
                                        <span>Edit</span>
                                    </Button> */}
                  <Link to={`${process.env.PUBLIC_URL}/general-contractors`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  lg="5"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">General Information</BlockTitle>
                        <p>Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() =>
                              openEditModal("contractorInformation")
                            }
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contractor ID</div>
                          <div className="data-value">{data.referenceID}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contractor Type</div>
                          <div className="data-value">
                            {data.contractorType}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contractor Name</div>
                          <div className="data-value">{data.companyName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contact Person</div>
                          <div className="data-value">{data.contactPerson}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Site Accessibility</div>
                          <div className="data-value">
                            {data.siteAccessibility
                              .map(
                                (siteAccessibility: any) =>
                                  siteAccessibility.siteName
                              )
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email Address</div>
                          <div className="data-value">{data.email}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Phone Number</div>
                          <div className="data-value">{data.phoneNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Country</div>
                          <div className="data-value">
                            {
                              countries.find((c) => c.value == data.country)
                                ?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">State</div>
                          <div className="data-value">
                            {states.find((s) => s.value == data.state)?.label}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">District</div>
                          <div className="data-value">
                            {
                              districtOptions.find(
                                (s) => s.value == data.district
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">City</div>
                          <div className="data-value">{data.city}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Area</div>
                          <div className="data-value">{data.area}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Address Line 1</div>
                          <div className="data-value">
                            {data.mailingAddressLine1}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Zip/Pincode</div>
                          <div className="data-value">{data.pincode}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Years in Business</div>
                          <div className="data-value">
                            {data.yearsInBusiness}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">References</div>
                          <div className="data-value">{data.references}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Legal Information</BlockTitle>
                        <p>BusinessType, PAN, GSTIN etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("contractorProfile")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">BusinessType</div>
                          <div className="data-value">
                            {data.bussinessType || "N/A"}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">CIN</div>
                          <div className="data-value">{data.cin || "N/A"}</div>
                        </div>
                      </li>
                      {data.bussinessType == "registered" && (
                        <>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                Incorporation Date
                              </div>
                              <div className="data-value">
                                {FormatDate(data.incorporationDate || "N/A")}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">PAN</div>
                              <div className="data-value">
                                {data.pan || "N/A"}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">GSTIN</div>
                              <div className="data-value">
                                {data.gstin || "N/A"}
                              </div>
                            </div>
                          </li>
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                Last FY Turn over
                              </div>
                              <div className="data-value">
                                {data.lastFYTurnover || "N/A"}
                              </div>
                            </div>
                          </li>
                        </>
                      )}
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Emergency Response and Contingency Plans
                        </BlockTitle>
                        <p>
                          Here are the Emergency Response and Contingency Plans.
                        </p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() =>
                              openEditModal(
                                "EmergencyResponseAndContingencyPlan"
                              )
                            }
                          >
                            <Icon name="upload"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Emergency Contact Person
                          </div>
                          <div className="data-value">
                            {data.emergencyContactPerson}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Emergency Contact Number
                          </div>
                          <div className="data-value">
                            {data.emergencyContactNumber}
                          </div>
                        </div>
                      </li>
                      {docs?.map((document: any) => {
                        if (document.moduleFileType !== "emergencyProcedures")
                          return <></>;
                        return (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                {document.moduleFileType}
                              </div>
                              <span className="data-value">
                                <a
                                  href={document.filePath}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "default",
                                  }}
                                >
                                  {document.fileName}
                                </a>
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Scope of Work Compliance and Certifications
                        </BlockTitle>
                        <p>Some more Other details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() =>
                              openEditModal(
                                "ScopeOfWorkComplianceAndCertifications"
                              )
                            }
                          >
                            <Icon name="upload"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          title="Not Avilable for now"
                          disabled
                        ></Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    {/* {JSON.stringify(document)} */}
                    <ul className="data-list is-compact">
                      {docs?.map((document: any) => {
                        if (document.moduleFileType == "emergencyProcedures")
                          return <></>;
                        return (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                {document.moduleFileType}
                              </div>
                              <span className="data-value">
                                <a
                                  href={document.filePath}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "default",
                                  }}
                                >
                                  {document.fileName}
                                </a>
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              {modalEditBlockName ===
                "ScopeOfWorkComplianceAndCertifications" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        Edit Emergency Response and Contingency Plans
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(uploadDocsSubmit)}
                        >
                          <Row className="gy-4">
                            <Col md="4">
                              <FormInput
                                name="regulatoryCompliancedocsUpload"
                                label="Regulatory Compliance Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                              />
                              {regulatoryCompliancedocsUploadWatch && (
                                <div>
                                  {regulatoryCompliancedocsUploadWatch[0]?.name}
                                </div>
                              )}
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="licensesAndPermitsdocsUpload"
                                label="Licenses And Permits Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                              />
                              {licensesAndPermitsdocsUploadWatch && (
                                <div>
                                  {licensesAndPermitsdocsUploadWatch[0]?.name}
                                </div>
                              )}
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="insuranceCoveragedocsUpload"
                                label="Insurance Coverage Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                              />
                              {insuranceCoveragedocsUploadWatch && (
                                <div>
                                  {insuranceCoveragedocsUploadWatch[0]?.name}
                                </div>
                              )}
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="ScopeofWorkdocsUpload"
                                label="Scope of Work Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                                // disabled={action === "view"}
                              />
                              {ScopeofWorkdocsUploadWatch && (
                                <div>{ScopeofWorkdocsUploadWatch[0]?.name}</div>
                              )}
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="BillingandPaymentTermsdocsUpload"
                                label="Billing and Payment Terms Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                              />
                              {BillingandPaymentTermsdocsUploadWatch && (
                                <div>
                                  {
                                    BillingandPaymentTermsdocsUploadWatch[0]
                                      ?.name
                                  }
                                </div>
                              )}
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="contractTermsdocsUpload"
                                label="Contract Terms Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                              />
                              {contractTermsdocsUploadWatch && (
                                <div>
                                  {contractTermsdocsUploadWatch[0]?.name}
                                </div>
                              )}
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="environmentalCompliancedocsUpload"
                                label="Environmental Compliance Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                              />
                              {environmentalCompliancedocsUploadWatch && (
                                <div>
                                  {
                                    environmentalCompliancedocsUploadWatch[0]
                                      ?.name
                                  }
                                </div>
                              )}
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="wasteManagementProceduresdocsUpload"
                                label="Waste Management Procedures Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                              />
                              {wasteManagementProceduresdocsUploadWatch && (
                                <div>
                                  {
                                    wasteManagementProceduresdocsUploadWatch[0]
                                      ?.name
                                  }
                                </div>
                              )}
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="qualityAssurancePlandocsUpload"
                                label="Quality Assurance Plan Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                              />
                              {qualityAssuranceProceduresdocsUploadWatch && (
                                <div>
                                  {
                                    qualityAssuranceProceduresdocsUploadWatch[0]
                                      ?.name
                                  }
                                </div>
                              )}
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="disputeResolutionProcessdocsUpload"
                                label="Dispute Resolution Process Docs Upload"
                                type="file"
                                placeholder="Docs Upload"
                              />
                              {disputeResolutionProcessdocsUploadWatch && (
                                <div>
                                  {
                                    disputeResolutionProcessdocsUploadWatch[0]
                                      ?.name
                                  }
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row className="gy-4 mt-1">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}
              {modalEditBlockName === "EmergencyResponseAndContingencyPlan" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        Edit Emergency Response and Contingency Plans
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitUpdates)}
                        >
                          <Row className="gy-4">
                            <Col md="6">
                              <FormInput
                                name="emergencyContactPerson"
                                label="Contact Person"
                                type="text"
                                placeholder="Enter Contact Person"
                                // disabled={action === "view"}
                              />
                            </Col>
                            <Col md="6">
                              <FormInput
                                name="emergencyContactNumber"
                                // disabled={action === "view"}
                                label="Contact Number"
                                type="number"
                                placeholder="Enter Contact Information"
                                // pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
                              />
                            </Col>
                            <Col md="6">
                              <FormInput
                                name="emergencyProcedures"
                                label="Upload Emergency Procedures"
                                // disabled={action === "view"}
                                type="file"
                                placeholder="Upload Emergency Procedures"
                              />
                              {emergencyProceduresWatch && (
                                <div>{emergencyProceduresWatch[0]?.name}</div>
                              )}
                            </Col>
                          </Row>
                          <Row className="gy-4 mt-1">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "contractorInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit General Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitUpdates)}
                        >
                          <Row className="gy-4">
                            <Col md="3">
                              <FormInput
                                name="referenceID"
                                label="Contractor ID"
                                required={true}
                                disabled={true}
                                // maxLength={10}
                                type="text"
                                pattern={/^[a-zA-Z0-9./_-]+$/}
                                placeholder="Enter Contractor ID"
                              />
                            </Col>
                            <Col md="3">
                              <FormSelect
                                name="contractorType"
                                label="Contractor Type"
                                required={true}
                                options={[
                                  {
                                    label: "General Contractor",
                                    value: "General Contractor",
                                  },
                                  {
                                    label: "Sub Contractor",
                                    value: "Sub Contractor",
                                  },
                                  {
                                    label: "Self Construction",
                                    value: "Self Construction",
                                  },
                                ]}
                                placeholder="Enter Contractor Terms"
                              />
                            </Col>
                            <Col md="3">
                              <FormInput
                                name="companyName"
                                label="Contractor Name"
                                required={true}
                                placeholder="Enter Contractor Name"
                                pattern={/^[A-Za-z\s]+$/}
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^A-Za-z\s]/g,
                                    ""
                                  ); // Removes invalid characters
                                }}
                              />
                            </Col>
                            <Col md="3">
                              <FormInput
                                name="contactPerson"
                                // disabled={action === "view"}
                                label="Contact Person"
                                required={true}
                                type="text"
                                maxLength={50}
                                placeholder="Enter Contact Person"
                              />
                            </Col>
                            <Col md="3">
                              <FormMultiSelect
                                name="siteAccessibility"
                                label="Site Accessibility"
                                required={true}
                                options={workingSites}
                                placeholder="Select Site Accessibility"
                              />
                            </Col>
                            <Col md="3">
                              <FormInput
                                name="email"
                                // disabled={action === "view"}
                                required={true}
                                label="Email"
                                type="email"
                                placeholder="Enter Email"
                                pattern={
                                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/
                                }
                              />
                            </Col>
                            <Col md="3">
                              <FormInput
                                name="phoneNumber"
                                label="Phone Number"
                                // disabled={action === "view"}
                                required={true}
                                type="text"
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                                placeholder="Enter Phone Number"
                                // pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
                              />
                            </Col>
                            <Col md="3">
                              <FormSelect
                                name="country"
                                label="Country"
                                options={countries}
                                required={true}
                                placeholder="-Select-"
                                onChange={(e) => {
                                  fetchStatesByCountry(e);
                                }}
                              />
                            </Col>
                            <Col md="3">
                              <FormSelect
                                name="state"
                                label="State"
                                required={true}
                                options={states}
                                placeholder="Select State"
                              />
                            </Col>
                            <Col md="3">
                              <FormSelect
                                name="district"
                                label="District"
                                options={districtOptions}
                                required={true}
                                placeholder="Select District"
                              />
                            </Col>
                            <Col md="3">
                              <FormInput
                                name="city"
                                label="City"
                                required={true}
                                // disabled={action === "view"}
                                placeholder="Enter City"
                                type="text"
                              />
                            </Col>
                            <Col lg="3">
                              <FormInput
                                name="area"
                                // disabled={action === "view"}
                                label="Area"
                                required={true}
                                placeholder="Enter Area"
                              />
                            </Col>
                            <Col md="3">
                              <FormInput
                                name="mailingAddressLine1"
                                // disabled={action === "view"}
                                label="Address Line 1"
                                // required={true}
                                placeholder="Enter Area"
                              />
                            </Col>
                            <Col md="3">
                              <FormInput
                                name="pincode"
                                // disabled={action === "view"}
                                label="Zip/Pincode"
                                required={true}
                                type="text"
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                                placeholder="Enter Zip or pin code"
                              />
                            </Col>
                            <Col md="3">
                              <FormInput
                                name="yearsInBusiness"
                                // disabled={action === "view"}
                                label="Years in Business"
                                type="text"
                                placeholder="Enter Years in Business"
                                pattern={/^\d+$/} // Allows only digits
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Removes non-numeric characters
                                }}
                              />
                            </Col>
                            <Col md="3">
                              <FormInput
                                name="references"
                                label="References"
                                type="text"
                                // disabled={action === "view"}
                                placeholder="Enter References"
                              />
                            </Col>
                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "contractorProfile" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Legal Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <FormProvider {...methods}>
                        <form
                          className="content clearfix"
                          onSubmit={handleSubmit(submitUpdates)}
                        >
                          <Row className="gy-4">
                            <Col md="6">
                              <div className="form-group">
                                <FormSelect
                                  name="bussinessType"
                                  label="Business Type"
                                  options={[
                                    {
                                      label: "Registered",
                                      value: "registered",
                                    },
                                    {
                                      label: "UnRegistered",
                                      value: "unregistered",
                                    },
                                  ]}
                                  disabled={data.bussinessType === "registered"}
                                  // defaultValue={data.businessType}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="form-group">
                                <FormInput
                                  name="cin"
                                  label="CIN"
                                  type="text"
                                  disabled={formData.cin}
                                  placeholder="Enter pan"
                                />
                              </div>
                            </Col>

                            {businessType == "registered" && (
                              <>
                                <Col md="6">
                                  <div className="form-group">
                                    <FormInput
                                      type="text"
                                      label="GSTIN"
                                      disabled={formData.gstin}
                                      required={true}
                                      name="gstin"
                                      placeholder="Enter GSTIN"
                                      pattern={
                                        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="form-group">
                                    {/* <label
                                className="form-label"
                                htmlFor="incorporationDate"
                              >
                                Incorporation Date
                              </label> */}
                                    <FormDatePicker
                                      name="incorporationDate"
                                      label="Incorporation Date"
                                      disabled={businessType === "registered"}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="form-group">
                                    <FormInput
                                      type="text"
                                      label="PAN"
                                      disabled={formData.pan}
                                      required={true}
                                      name="pan"
                                      placeholder="Enter pan"
                                    />
                                  </div>
                                </Col>

                                <Col md="6">
                                  <div className="form-group">
                                    <FormInput
                                      type="number"
                                      label="Last FY Turn over"
                                      name="lastFYTurnover"
                                      // defaultValue={formData.lastFYTurnover}
                                      placeholder="Enter Last FY Turn over"
                                    />
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row className="gy-4 mt-1">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ViewContractorDetails;

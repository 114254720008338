import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

const statusMapper: any = {
  "Draft": {
    buyer: {
      title: "Draft",
      color: "blue",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "Draft",
      color: "blue",
      icon: <SyncOutlined />,
    }
  },
  "Pending Approval": {
    buyer: {
      title: "Pending Approval",
      color: "orange",
      icon: <ClockCircleOutlined />,
    },
    seller: {
      title: "Pending Approval",
      color: "orange",
      icon: <ClockCircleOutlined />,
    },
  },
  "Approved": {
    buyer: {
      title: "Approved",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
    seller: {
      title: "Pending",
      color: "orange",
      icon: <ClockCircleOutlined />,
    },
  },
  "Rejected": {
    buyer: {
      title: "Rejected",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
    seller: {
      title: "Rejected",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
  "To be Invoiced": {
    buyer: {
      title: "To be Invoiced",
      color: "purple",
      icon: <ExclamationCircleOutlined />,
    },
    seller: {
      title: "To be Invoiced",
      color: "purple",
      icon: <ExclamationCircleOutlined />,
    },
  },
  "Changes to Buyer": {
    buyer: {
      title: "Supplier Changes",
      color: "blue",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "Changes Requested",
      color: "blue",
      icon: <SyncOutlined />,
    },
  },
  "Rejected from Buyer": {
    buyer: {
      title: "Changes Rejected",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
    seller: {
      title: "Buyer Rejected Changes",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
  "Change request accepted by Buyer": {
    buyer: {
      title: "Change Accepted",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
    seller: {
      title: "Buyer Accepted Changes",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  },
  "To be Delivered": {
    buyer: {
      title: "To be Delivered",
      color: "purple",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "To be Delivered",
      color: "purple",
      icon: <SyncOutlined />,
    },
  },
  "Changes from Buyer": {
    buyer: {
      title: "Changes Requested",
      color: "blue",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "Changes from Buyer",
      color: "blue",
      icon: <SyncOutlined />,
    },
  },
  "Rejected by Supplier": {
    buyer: {
      title: "Supplier Rejected",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
    seller: {
      title: "Supplier Rejected",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
  "Partially Delivered": {
    buyer: {
      title: "Partially Delivered",
      color: "purple",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "Partially Delivered",
      color: "purple",
      icon: <SyncOutlined />,
    },
  },
  "To be Dispatched": {
    buyer: {
      title: "To be Dispatched",
      color: "purple",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "To be Dispatched",
      color: "purple",
      icon: <SyncOutlined />,
    },
  },
  "Fully Delivered": {
    buyer: {
      title: "Fully Delivered",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
    seller: {
      title: "Fully Delivered",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  }
  
};

export default statusMapper;

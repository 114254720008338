import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import quotationApis from "../../../../api/master/quotations";
import dayjs from "dayjs";
import { Alert, Button, Col, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import { Option } from "antd/es/mentions";
import {
  DeleteOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Collapse as AntCollapse,
  Spin,
  Flex,
  Drawer,
  Upload,
  Select,
  DatePicker,
  Button as AntButton,
  Divider,
  Space,
  Popconfirm,
  Descriptions,
} from "antd";
import { formatCurrency } from "../../../../utils/Utils";
import Misc from "../../../masters/Misc";
import { nanoid } from "nanoid";
import { Icon } from "../../../../components/Component";
import { FormProvider, useForm } from "react-hook-form";
import SignatureModal from "../../../purchase-orders/SignatureModal";
import CropperComponent from "../../../components/CropperComponent";
import FormTextArea from "../../../form-components/TextAreaComponent";
import BulkMaterialUpload from "../../../purchase-requests/NewIndent/BulkMaterialUpload";
import { useNavigate } from "react-router";
import exportFromJSON from "export-from-json";
import ExportToXlsx from "../../../../utils/exportToXlsx";
import FormInput from "../../../form-components/InputComponent";

const { Panel } = AntCollapse;

const { beforeUpload } = Misc;

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
}
interface AddSupplierQuoteDrawerInterface {
  supplierQuoteData: {
    materials: any;
    quotationHeader: any;
  };
  quotationId: any;
  visible: boolean;
  onClose: () => void;
  rfqId: any;
  onSubmit: (values: any) => void;
}

const AddSupplierQuoteDrawer: React.FC<AddSupplierQuoteDrawerInterface> = ({
  visible,
  quotationId,
  onClose,
  supplierQuoteData,
  rfqId,
  onSubmit,
}) => {
  const [form2] = Form.useForm();
  const [form] = Form.useForm();
  const methods = useForm({
    mode: "onChange",
  });
  const [gstType, setGstType] = useState<"cgst+sgst" | "igst">("cgst+sgst");
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const [invoiceSignature, setInvoiceSignature] = useState<any>({});
  const [additionalCharges, setAdditionalCharges] = useState<any>([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [approvalRequired, setApprovalRequired] = useState("false");
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const [invoiceDate, setInvoiceDate] = useState<any>();
  const [OriginalData, setOriginalData] = useState<any>({});
  const [paymentTerm, setPaymentTerm] = useState<string | undefined>(undefined);
  const [screenSize, setScreenSize] = useState(0);
  const [invoiceableLines, setInvoiceableLines] = useState<any>([]);
  const [editAdditionalCharges, setEditAdditionalCharges] = useState(true);
  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "",
    currency_symbol: "",
    currency_format: "",
    example: "",
  });

  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [showCropModel, setShowCropModel] = useState<boolean>(false);
  const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
  const [supplierTermsAndConditions, setsupplierTermsAndConditions] =
    useState<any>("");
  const [documentsAttached, setDocumentsAttached] = useState<any>([]);
  const [requiredDOC, setRequiredDOC]: any = useState();
  const [suppliers, setSuppliers] = useState<any[]>([]);

  const [showCreateButton, setShowCreateButton] = useState(false);

  const [editingKey, setEditingKey] = useState<any | null>(null);

  const [updatedLineItems, setUpdatedLineItems] = useState<any>([]);

  const [editedData, setEditedData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [data, setData] = useState<any>([]);
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);
  const [uploadedDataErrors, setUploadedDataErrors] = useState<any>([]);
  const [uploadedDataErrorModal, setUploadedDataErrorModal] = useState(false);
  const bulkUploadErrorsRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const [supplierId, setSupplierId] = useState(null);
  const [isNewSupplier, setIsNewSupplier] = useState<any>();

  const isEditing = (record: any) => record.key == editingKey;
  const termsAndConditionsWatch = watch("supplierTermsAndConditions");
  const navigate = useNavigate();
  useEffect(() => {
    if (termsAndConditionsWatch) {
      setsupplierTermsAndConditions(termsAndConditionsWatch);
    }
  }, [termsAndConditionsWatch]);

  useEffect(() => {
    setInvoiceSignature({
      isActive: false,
      image: null,
      label: "Authorised Signatory",
    });
  }, []);

  useEffect(() => {
    suppliersList();
    setIsLoading(false);
  }, [supplierQuoteData]);

  const suppliersList = async () => {
    try {
      const { response, status, message } =
        await quotationApis.getNotRespondedSuppliers(quotationId);
      if (status) {
        setSuppliers(
          response?.data.map((item: any) => ({
            supplierName: item.supplierName,
            supplierID: item?.supplierId?.toString(),
            isNewSupplier: item?.isNewSupplier,
          }))
        );
      } else {
        throw new Error(message);
      }
    } catch (error) {}
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }: any) => {
    const isHSNEditable = dataIndex === "hsn";

    return (
      <td {...restProps}>
        {editing && isHSNEditable ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: false,
                message: `${title} is required.`,
              },
              {
                pattern: /^[0-9]{6,8}$/,
                message: "HSN should be a number with 6 to 8 digits",
              },
            ]}
          >
            <InputNumber
              className="w-100"
              parser={(value: any) => value.replace(/\D/g, "")} // Removes non-numeric characters
              onKeyPress={(event) => {
                const regex = /^[0-9\b]+$/; // Restrict to numbers and backspace
                if (!regex.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        ) : editing && dataIndex !== "hsn" ? ( // For other editable fields
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: ["price", "leadDays"].includes(dataIndex),
                message: `${title} is required.`,
              },
              {
                validator: async (_: any, value: any) => {
                  if (
                    ["cgst", "igst", "sgst"].includes(dataIndex) &&
                    value === 0
                  ) {
                    return Promise.resolve();
                  }
                  if (dataIndex === "minQty") {
                    const supplyQuantity = form.getFieldValue("suppliedQty");
                    if (value > supplyQuantity) {
                      return Promise.reject(
                        `${title} should not be greater than Supply Quantity (${supplyQuantity})`
                      );
                    }
                    return Promise.resolve();
                  }
                  if (dataIndex === "leadDays" && (!value || value <= 0)) {
                    return Promise.reject(
                      new Error(`${title} must be greater than 0`)
                    );
                  }
                  if (
                    dataIndex === "cgst" ||
                    dataIndex === "igst" ||
                    dataIndex === "sgst"
                  ) {
                    return Promise.resolve();
                  }
                  if (!value || value < 0) {
                    return Promise.reject(
                      new Error(`${title} must be greater than or equal to 0`)
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              className="w-100"
              formatter={(value: any) => {
                if (value === undefined || value === null || isNaN(value)) {
                  return "";
                }
                const hasDecimal = value.toString().includes(".");
                return value
                  ? new Intl.NumberFormat("en-IN", {
                      style: "decimal",
                      minimumFractionDigits: hasDecimal ? 2 : 0,
                      maximumFractionDigits: hasDecimal ? 2 : 0,
                    }).format(value)
                  : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              min={
                ["cgst", "igst", "sgst", "minQty"].includes(dataIndex) ? 0 : 1
              }
              step={["price", "minQty"].includes(dataIndex) ? 0.01 : 1}
              onKeyPress={(event) => {
                if (
                  ["price", "minQty", "cgst", "igst", "sgst"].includes(
                    dataIndex
                  )
                ) {
                  if (!/^[0-9.]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                } else {
                  if (!/^[0-9]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }
              }}
            />
          </Form.Item>
        ) : (
          children // Display children when not editable
        )}
      </td>
    );
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      suppliedQty: Number(record.qty),
      minQty: Number(record.minQty),
      hsn: record.hsn,
      price: Number(record.price),
      sgst: Number(record.sgst),
      cgst: Number(record.cgst),
      igst: Number(record.igst),
      leadDays: Number(record.leadDays),
      ...record,
    });
    setEditingKey(record.key);
  };

  const save = async (key: React.Key) => {
    try {
      const row: any = await form.validateFields();
      const newData: any = [...data];
      const index = newData.findIndex(
        (item: any) => key == item.quotationLineId
      );
      if (index > -1) {
        setUpdatedLineItems((pre: any) => {
          if (!pre.includes(key)) {
            return pre.concat(key);
          }
          return pre;
        });
        const item: any = newData[index];
        const quantityInvoiced = row?.suppliedQty
          ? Number(row.suppliedQty)
          : "N/A";
        const unitPrice = row?.price ? Number(row.price) : "N/A";
        const basicAmount =
          quantityInvoiced !== "N/A" && unitPrice !== "N/A"
            ? quantityInvoiced * unitPrice
            : "N/A";
        const sgst = row?.sgst ? Number(row.sgst) : "N/A";
        const igst = row?.igst ? Number(row.igst) : "N/A";
        const cgst = row?.cgst ? Number(row.cgst) : "N/A";
        const sgstData =
          basicAmount !== "N/A" && sgst !== "N/A"
            ? (basicAmount * sgst) / 100
            : "N/A";
        const igstData =
          basicAmount !== "N/A" && igst !== "N/A"
            ? (basicAmount * igst) / 100
            : "N/A";
        const cgstData =
          basicAmount !== "N/A" && cgst !== "N/A"
            ? (basicAmount * cgst) / 100
            : "N/A";
        const hsn = row.hsn || item.hsn;
        const leadDays = row.leadDays;
        const minQty = row.minQty;
        const price = row.price;
        const suppliedQty = row.suppliedQty;

        const lineTotal =
          (basicAmount !== "N/A" ? basicAmount : 0) +
          (sgstData !== "N/A" ? sgstData : 0) +
          (igstData !== "N/A" ? igstData : 0) +
          (cgstData !== "N/A" ? cgstData : 0);

        const updatedRow = {
          ...item,
          quantityInvoiced,
          unitPrice,
          basicAmount,
          sgst,
          igst,
          cgst,
          sgstData,
          igstData,
          cgstData,
          hsn,
          leadDays,
          minQty,
          price,
          suppliedQty,
          grossTotal:
            basicAmount !== "N/A" &&
            sgst !== "N/A" &&
            igst !== "N/A" &&
            cgst !== "N/A"
              ? basicAmount + sgst + igst + cgst
              : "N/A",
          lineTotal,
        };

        newData.splice(index, 1, { ...updatedRow });
        setData(newData);
        setEditingKey(null);
        setEditedData(
          newData.filter((item: any) =>
            selectedRowKeys.includes(item.quotationLineId)
          )
        );
      }
      setShowSubmitBtm(true);
    } catch (errInfo) {
      console.log("Validate Failed:", JSON.stringify(errInfo, null, 2));
    }
  };

  const columns = [
    {
      title:"#",
      key: "index",
      width: 50,
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 200,
    },
    {
      title: "QTY",
      dataIndex: "qty",
      key: "qty",
      width: 150,
      render: (text: any, row: any) => {
        return Number(row?.qty) + " " + row?.uomName;
      },
    },
    {
      title: "Expected Delivery Date",
      dataIndex: "needByDate",
      width: 200,
      render: (text: any, record: any) =>
        `${dayjs(text).format("DD-MMM-YYYY")}`,
    },
    {
      title: "Supply Qty",
      dataIndex: `suppliedQty`,
      key: "suppliedQty",
      width: 120,
      editable: true,
      render: (text: any) => (isNaN(text) ? "0.00" : Number(text).toFixed(2)),
    },
    {
      title: "Min Purchase Qty",
      dataIndex: `minQty`,
      key: "minQty",
      width: 150,
      editable: true,
      render: (text: any) => (isNaN(text) ? "0.00" : Number(text).toFixed(2)),
    },
    {
      title: "HSN",
      dataIndex: `hsn`,
      key: "hsn",
      width: 120,
      editable: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 150,
      editable: true,
    },
    {
      title: "SGST (%)",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
      editable: true,
    },
    {
      title: "CGST (%)",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
      editable: true,
    },
    {
      title: "IGST (%)",
      dataIndex: "igst",
      key: "igst",
      width: 200,
      editable: true,
    },
    {
      title: "Lead Days",
      dataIndex: "leadDays",
      key: "leadDays",
      width: 250,
      editable: true,
    },
    {
      title: "Line Total",
      dataIndex: "lineTotal",
      key: "lineTotal",
      width: 250,
      render: (text: any, record: any) => {
        const value = Number(text) || 0;
        // Format the number to 2 decimal places and apply Indian locale formatting
        return value.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      // render: (text: any, record: any) => FormatCurrency(text, "INR"),
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      fixed: "right",
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="w-120 d-flex">
            <Button
              size="sm"
              color=""
              onClick={() => save(record.quotationLineId)}
            >
              <Icon name="save" />
            </Button>
            <Button size="sm" color="" onClick={cancel}>
              <Icon name="cross" />
            </Button>
          </div>
        ) : (
          <Button size="sm" color="" onClick={() => edit(record)}>
            <Icon name="edit" />
          </Button>
        );
      },
    },
  ];

  const cancel = () => {
    setEditingKey(null);
  };

  const mergedColumns = (columns: any, extra: any) => {
    const { gstType } = extra;
    return columns.map((col: any) => {
      if (
        gstType == "igst" &&
        (col.dataIndex == "sgst" || col.dataIndex == "cgst")
      ) {
        col = { ...col, hidden: true };
      } else if (gstType == "cgst+sgst" && col.dataIndex == "igst") {
        col = { ...col, hidden: true };
      }
      if (!col.editable) {
        return col; // Return columns that are not editable
      }

      // Return columns with editable logic
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          inputType: col.dataIndex === "quantity" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  };

  const ViewTableTotals = ({ data, additionalCharges, invoice }: any) => {
    let totalNetAmmount = 0,
      cgst = 0,
      sgst = 0,
      igst = 0,
      TotalAmount = 0;
    data?.forEach((item: any) => {
      const suppliedQty = isNaN(Number(item?.suppliedQty))
        ? 0
        : Number(item?.suppliedQty);
      const price = isNaN(Number(item?.price)) ? 0 : Number(item?.price);
      const cgstRate = isNaN(Number(item?.cgst)) ? 0 : Number(item?.cgst);
      const sgstRate = isNaN(Number(item?.sgst)) ? 0 : Number(item?.sgst);
      const igstRate = isNaN(Number(item?.igst)) ? 0 : Number(item?.igst);

      // Calculate the total amount for the current item
      const totalAmount = suppliedQty * price;

      // Accumulate values for total amounts and taxes
      totalNetAmmount += totalAmount;
      cgst += (totalAmount * cgstRate) / 100;
      sgst += (totalAmount * sgstRate) / 100;
      igst += (totalAmount * igstRate) / 100;
    });
    TotalAmount = totalNetAmmount + cgst + sgst + igst;

    additionalCharges?.forEach((item: any) => {
      TotalAmount = TotalAmount + Number(item.price);
    });
    return (
      <Row
        className={`${invoice && "w-50 mt-2"}`}
        style={{
          margin: "20px",
        }}
      >
        <Col className={`justify-end`}>
          <div className={`${invoice ? "w-100" : "w-40"}`}>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>Taxable Amount</strong>
              </div>
              <span className="r_align">
                {formatCurrency(totalNetAmmount, "INR")}
              </span>
            </div>
            {cgst && sgst ? (
              <>
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>CGST</strong>
                  </div>
                  <span className="r_align">{formatCurrency(cgst, "INR")}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>SGST</strong>
                  </div>
                  <span className="r_align">{formatCurrency(sgst, "INR")}</span>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-between">
                <div className="w-50 justify-end">
                  <strong>IGST</strong>
                </div>
                <span className="r_align">{formatCurrency(igst, "INR")}</span>
              </div>
            )}
            {additionalCharges?.map((item: any) => {
              if (!item.price || !item.price) return null;
              return (
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>{item.name}</strong>
                  </div>
                  <span className="r_align">
                    {formatCurrency(item.price, "INR")}
                  </span>
                </div>
              );
            })}
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "18px", marginTop: "10px" }}
            >
              <div className="w-50 justify-end">
                <strong>Total</strong>
              </div>
              <strong className="r_align">
                {formatCurrency(TotalAmount, "INR")}
              </strong>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const handlePaymentTermChange = (value: string) => {
    setPaymentTerm(value);
  };

  const uploadProps = {
    beforeUpload: (file: any) => {
      // Perform any file validation or processing here
      return false; // Return false to prevent auto-upload
    },
    maxCount: 1,
  };

  const createQuote = async (Data: any) => {
    setDisableSubmit(true);
    try {
      const _formData = new FormData();
      const filesData = [];
      if (supplierTermsAndConditions) {
        const termsAndConditionsFile = new Blob([supplierTermsAndConditions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsAndConditions.html", {
            type: "text/html",
          })
        );
        filesData.push({
          fileIndex: filesData.length,
          module: "Quotation",
          moduleFileType: "termsAndConditions",
          level: "HEADER_LEVEL",
        });
      }
      if (Data?.attachDC) {
        Data?.attachDC?.forEach((item: any) => {
          _formData.append(
            `files`,
            new File([item], item.name, {
              type: item.type,
            })
          );
          filesData.push({
            fileIndex: filesData.length,
            module: "Quotation",
            moduleFileType: "attachments",
            level: "HEADER_LEVEL",
          });
        });
      }

      const docEntries = Object.entries(Data).filter(([key]) =>
        key.startsWith("doc_")
      );
      docEntries.forEach(([key, value]: any, index) => {
        const docNumberStr = key.slice(4); // Remove 'doc_' prefix
        const docNumber = parseInt(docNumberStr, 10);
        if (!isNaN(docNumber) && value && value.length > 0) {
          filesData.push({
            quotationDocNameId: docNumber,
            fileIndex: filesData.length,
            module: "Quotation",
            moduleFileType: "Supplier Docs",
            level: "LINE_LEVEL",
          });
          if (value[0]) {
            _formData.append(`files`, new File([value[0]], value[0]?.name));
          } else {
            console.log(`Skipping ${key} because value is undefined or empty`);
          }
        }
      });
      delete Data.attachDC;
      const keysToDelete = Object.keys(Data).filter((key) =>
        key.startsWith("doc_")
      );
      keysToDelete.forEach((key) => {
        delete Data[key]; // Delete each key from the copied object
      });
      let validityDate = Data?.invoiceDate?.format("YYYY-MM-DD");
      let paymentPercentage = Data?.advancePercentage;
      let negotiable = Data?.negotiable;
      delete Data.invoiceDate;
      delete Data.supplierID;
      delete Data.advancePercentage;
      delete Data.negotiable;
      let totalNetAmount = 0,
        cgst = 0,
        sgst = 0,
        igst = 0,
        totalAmount = 0;

      invoiceableLines?.forEach((item: any) => {
        const totalAmountForItem = item.price * item.supplyQuantity;
        const itemCGST = (totalAmountForItem * item.cgstData) / 100;
        const itemSGST = (totalAmountForItem * item.sgstData) / 100;
        const itemIGST = (totalAmountForItem * item.igstData) / 100;

        totalNetAmount += totalAmountForItem;
        cgst += itemCGST;
        sgst += itemSGST;
        igst += itemIGST;
      });

      totalAmount = totalNetAmount + cgst + sgst + igst;
      console.log("isNewSupplier", isNewSupplier);
      const supplierData: any = {
        supplierHeader: {
          ...Data,
          validityDate,
          paymentPercentage,
          isNewSupplier: isNewSupplier,
          status: "QUOTED",
          signatureLabel: invoiceSignature?.label || "Authorised Signatory",
          quotationId: quotationId,
          rfqId: rfqId,
          supplierId,
          negotiable,
          additionalChargesArray: additionalCharges?.map((charge: any) => {
            return {
              name: charge?.name,
              price: charge?.price,
            };
          }),
        },
        supplierLines: data?.map((item: any) => {
          return {
            // supplierQuoteLineId:
            // supplierQuoteId: 123,
            // quotationId: quotationId,
            // rfqId: OriginalData.quotationInfo.quotationHeader.rfqId,
            // version: 1,
            quotationLineId: item.quotationLineId,
            minQty: item.minQty,
            suppliedQty: item.suppliedQty,
            hsn: item.hsn,
            uomId: item.uom,
            price: item.price,
            sgst: item.sgst == "N/A" ? 0 : item.sgst,
            cgst: item.cgst == "N/A" ? 0 : item.cgst,
            igst: item.igst == "N/A" ? 0 : item.igst,
            leadDays: item.leadDays,
            lineTotal: item.lineTotal,
          };
        }),
      };

      if (invoiceSignature?.image) {
        const image = base64ToFile(invoiceSignature?.image, "signature.png");
        supplierData.signatureLabel = invoiceSignature?.label;
        _formData.append("files", image);
        filesData.push({
          fileIndex: filesData.length,
          module: "quotations",
          moduleFileType: "signature",
          level: "HEADER_LEVEL",
          action: "Create",
        });
      }
      console.log("supplierData", supplierData.supplierHeader);
        // return

      _formData.append(
        "supplierHeader",
        JSON.stringify(supplierData.supplierHeader)
      );
      _formData.append(
        "supplierLines",
        JSON.stringify(supplierData.supplierLines)
      );
      _formData.append(
        "requiredDocs",
        JSON.stringify(OriginalData?.quotationInfo?.requiredDocs)
      );
      _formData.append("docKeys", JSON.stringify(filesData));
      const { status, message, response } = await quotationApis.selfQuote(
        _formData
      );
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Quoted Successfully",
        });
        // closeDrawer();
        // navigate("/view-rfqs");
        onSubmit("Quoted");
        form.resetFields();
        form2.resetFields();
        setInvoiceSignature({
          isActive: false,
          image: null,
          label: "Authorised Signatory",
        });
        setShowSignatureModel(false);
        setsupplierTermsAndConditions("");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: message,
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.log(error);
      setDisableSubmit(false);
    } finally {
      setDisableSubmit(false);
    }
    setDisableSubmit(false);
  };

  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }

  const handleChangeAttachment = (info: any) => {
    let files = info.fileList.slice(-3); // Limit to 3 files
    setDocumentsAttached(files);
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    // fetchCurrentUserInfo();
    // fetchOrderDetails();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const handelSupplierChange = async (e: any) => {
    const selectedSupplier = JSON.parse(e);
    setSupplierId(selectedSupplier.supplierId);
    setIsNewSupplier(selectedSupplier.isNewSupplier);
    try {
      const { response, status, message } =
        await quotationApis.latestBySupplierQuotationId(
          quotationId,
          selectedSupplier.supplierId,
          selectedSupplier.isNewSupplier
        );
      if (status) {
        setRequiredDOC(response?.data?.quotationInfo?.requiredDocs);
        setData(response.data.supplierInfo.supplierLines);
      } else {
        console.log(message);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  return (
    <>
      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>Create Quotation</strong>
            <div style={{ textAlign: "right" }}>
              {disableSubmit ? (
                <Button color="primary" type="button">
                  <Spinner size="sm" color="light" />
                </Button>
              ) : (
                showCreateButton && (
                  <Button
                    onClick={(e) => {
                      form2.submit();
                      e.preventDefault();
                      errorRef?.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }}
                    color="primary"
                    className="m-3 mt-0 mb-0"
                  >
                    Create Quotation
                  </Button>
                )
              )}
            </div>
          </div>
        }
        // onClose={onClose}
        onClose={() => {
          setShowCreateButton(false);
          setEditingKey(null);
          setRequiredDOC([]);
          form.resetFields();
          onClose();
          form2.resetFields();
        }}
        open={visible}
        width={"85vw"}
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
      >
        <Form
          initialValues={{
            approvals: "N", // Set the default value here
          }}
          form={form2}
          layout="vertical"
          onFinish={(data) => {
            Swal.fire({
              title: "Are you sure?",
              text: "You are about to create!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Create!",
            }).then((result) => {
              if (result.isConfirmed) {
                setDisableSubmit(true);
                createQuote(data);
              }
            });
          }}
          requiredMark={false}
        >
          <AntCollapse defaultActiveKey={["1", "2", "3", "4"]} className="mt-3">
            <Panel header={<h6>Supplier Information</h6>} key="1">
              <div className="justify-content-around ">
                <div>
                  <div className="justify-start w-100  flex-wrap pt-3">
                    <Form.Item
                      name="supplierID"
                      label={
                        <div className="data-label w-90 mb-1">Supplier</div>
                      }
                      className="w-30 p-3 paddingLeft10  pt-0 pb-0"
                      rules={[
                        {
                          required: true,
                          message: "Please select Supplier",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        onChange={(value) => {
                          setSupplierId(value.supplierId);
                          setShowCreateButton(true);
                          handelSupplierChange(value);
                          setUploadedDataErrors([]);
                        }}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space
                              style={{
                                padding: "0 8px 4px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Button
                                type="button"
                                color="primary"
                                size="small"
                                onClick={() => {
                                  navigate(`/addSuppliers`);
                                }}
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              >
                                <span>Add Supplier</span>
                              </Button>
                            </Space>
                          </>
                        )}
                      >
                        {suppliers.map((item: any) => (
                          <Option
                            key={item.supplierID}
                            value={JSON.stringify({
                              supplierId: item.supplierID,
                              isNewSupplier: item.isNewSupplier,
                            })}
                          >
                            {item.supplierName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="invoiceDate"
                      label={
                        <div className="data-label w-90 mb-1">
                          Validity Date
                        </div>
                      }
                      className="w-30 p-3 paddingLeft10  pt-0 pb-0"
                      rules={[
                        {
                          required: true,
                          message: "Please select Validity Date",
                        },
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        placeholder="Validity Date"
                        minDate={dayjs().add(0, "day")}
                        onChange={(e) => {
                          setInvoiceDate(e);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="negotiable"
                      label={
                        <div className="data-label w-100 mb-1">Negotiable</div>
                      }
                      className="w-30 p-2 paddingLeft10  pt-0 pb-0"
                      rules={[
                        {
                          required: true,
                          message: "Please select",
                        },
                      ]}
                    >
                      <Select placeholder="Select">
                        <Option value="Y">Yes</Option>
                        <Option value="N">No</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="paymentTerms"
                      label={
                        <div className="data-label w-100 mb-1">
                          Payment Terms
                        </div>
                      }
                      className="w-30 p-2 paddingLeft10  pt-0 pb-0"
                      rules={[
                        {
                          //   required: true,
                          message: "Please select Payment Terms",
                        },
                      ]}
                    >
                      <Select
                        defaultValue={
                          supplierQuoteData?.quotationHeader?.paymentTerms
                        }
                        disabled
                        options={[
                          {
                            label: "Cash & Carry",
                            value: "Cash and Carry",
                          },
                          // { label: "Advance", value: "Advance" },
                          { label: "Credit", value: "Credit" },
                        ]}
                        placeholder="Select Payment Terms"
                        onChange={handlePaymentTermChange}
                      />
                    </Form.Item>
                    {supplierQuoteData?.quotationHeader?.paymentTerms ===
                      "Credit" && (
                      <Form.Item
                        name="expectedCreditDays"
                        label={
                          <div className="data-label w-100 mb-1">
                            Credit Days
                          </div>
                        }
                        // defaultValue={supplierQuoteData?.quotationHeader?.expectedCreditDays}
                        className="w-30 p-2 paddingLeft10  pt-0 pb-0"
                      >
                        <Input
                          disabled
                          defaultValue={
                            supplierQuoteData?.quotationHeader
                              ?.expectedCreditDays
                          }
                          className="w-100"
                          type="number"
                          placeholder="Enter Credit Days"
                        />
                      </Form.Item>
                    )}
                    {supplierQuoteData?.quotationHeader?.paymentTerms ===
                      "Credit" && (
                      <Form.Item
                        name="paymentDays"
                        label={
                          <div className="data-label w-100 mb-1">
                            Supplier Credit Days
                          </div>
                        }
                        className="w-30 p-2 paddingLeft10  pt-0 pb-0"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Supplier Credit Days",
                          },
                        ]}
                      >
                        <Input
                          className="w-100"
                          type="number"
                          placeholder="Enter Credit Days"
                        />
                      </Form.Item>
                    )}
                  </div>
                </div>
              </div>
            </Panel>

            <Panel header={<h6>Material Information</h6>} key="2">
              {isLoading == true ? (
                <>
                  <Flex align="center" gap="middle" className="pageLoader">
                    <Spin size="large" className="m-auto" />
                  </Flex>
                </>
              ) : showCreateButton ? (
                <>
                  <span ref={bulkUploadErrorsRef}></span>
                  <Form form={form} component={false}>
                    <div className="d-flex justify-content-between align-items-center m-0 p-0">
                      <div className="w-70 d-flex align-items-center">
                        <strong className="ant-descriptions-item-content me-1">
                          GST Type
                        </strong>
                        <Form.Item
                          name="gstType"
                          label=""
                          className="mb-0 align-items-center"
                          style={{ width: 300 }}
                          rules={[{ required: true, message: "Required" }]}
                          initialValue="cgst+sgst"
                        >
                          <Select
                            allowClear={false}
                            placeholder="Select GST Type"
                            optionFilterProp="children"
                            defaultActiveFirstOption
                            onChange={(data: any) => {
                              setGstType(data);
                            }}
                            onSearch={(data: any) => {}}
                            filterOption={(input, option: any) =>
                              (option?.label ?? "")
                                ?.toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            <Option value={"cgst+sgst"}>CGST + SGST</Option>
                            <Option value={"igst"}>IGST</Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <BulkMaterialUpload
                        moduleKey={"quot-material-list"}
                        extra={{
                          igstFlag: gstType == "igst" ? 1 : 0,
                          quotationId: quotationId,
                          supplierId: Number(supplierId),
                        }}
                        append={(uploadedData: any) => {
                          console.clear();
                          const { errorFlag, lines } = uploadedData;
                          if (errorFlag) {
                            setUploadedDataErrors(lines);
                          } else {
                            setUploadedDataErrors([]);
                          }
                          const updatedRows: any = {};
                          lines?.forEach((row: any, index: any) => {
                            const {
                              quotationLineId,
                              suppliedQty,
                              minQty,
                              hsn,
                              price,
                              leadDays,
                              sgst,
                              cgst,
                              igst,
                              errorFlag,
                            } = row;
                            if (errorFlag) return;
                            let activeIndex: any = null;
                            const activeRecord = data.find(
                              (item: any, index: any) => {
                                if (quotationLineId == item.quotationLineId) {
                                  activeIndex = index;
                                  return true;
                                }
                              }
                            );
                            if (activeIndex == null) return;
                            const quantityInvoiced = suppliedQty
                              ? Number(suppliedQty)
                              : "N/A";
                            const unitPrice = price ? Number(price) : "N/A";
                            const basicAmount =
                              quantityInvoiced !== "N/A" && unitPrice !== "N/A"
                                ? quantityInvoiced * unitPrice
                                : "N/A";

                            const sgstData =
                              basicAmount !== "N/A" && sgst !== "N/A"
                                ? (basicAmount * sgst) / 100
                                : "N/A";
                            const igstData =
                              basicAmount !== "N/A" && igst !== "N/A"
                                ? (basicAmount * igst) / 100
                                : "N/A";
                            const cgstData =
                              basicAmount !== "N/A" && cgst !== "N/A"
                                ? (basicAmount * cgst) / 100
                                : "N/A";

                            const lineTotal =
                              (basicAmount !== "N/A" ? basicAmount : 0) +
                              (sgstData !== "N/A" ? sgstData : 0) +
                              (igstData !== "N/A" ? igstData : 0) +
                              (cgstData !== "N/A" ? cgstData : 0);

                            const updatedRow = {
                              ...activeRecord,
                              quotationLineId,
                              quantityInvoiced,
                              unitPrice,
                              basicAmount,
                              sgst,
                              igst,
                              cgst,
                              sgstData,
                              igstData,
                              cgstData,
                              hsn,
                              leadDays,
                              minQty,
                              price,
                              suppliedQty,
                              grossTotal:
                                basicAmount !== "N/A" &&
                                sgst !== "N/A" &&
                                igst !== "N/A" &&
                                cgst !== "N/A"
                                  ? basicAmount + sgst + igst + cgst
                                  : "N/A",
                              lineTotal,
                            };
                            updatedRows[quotationLineId] = updatedRow;
                          });
                          setData((oldData: any) => {
                            return oldData?.map((oldDataRow: any) => {
                              if (
                                oldDataRow?.quotationLineId &&
                                updatedRows[oldDataRow?.quotationLineId]
                              ) {
                                return updatedRows[oldDataRow?.quotationLineId];
                              }
                              return oldDataRow;
                            });
                          });
                        }}
                      />
                    </div>
                    <Alert
                      isOpen={uploadedDataErrors?.length > 0}
                      color="danger"
                      className="start-0 w-100 py-1 mt-2"
                      style={{
                        top: 0,
                        textAlign: "left",
                        fontSize: "14px",
                        cursor: "default",
                      }}
                    >
                      <Icon name={"info"} style={{ top: "0.6rem" }} />
                      <span>
                        Failed to prefill the data in the table due to an issue
                        with the inserted rows. You can{" "}
                        <Popconfirm
                          title={<></>}
                          icon={<></>}
                          showCancel={false}
                          open={uploadedDataErrorModal}
                          okButtonProps={{
                            style: {
                              display: "none",
                            },
                          }}
                          onConfirm={() => {
                            try {
                              console.log("confirm");
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                          onCancel={() => {
                            alert("cancel");
                          }}
                          onOpenChange={(visible) => {
                            setUploadedDataErrorModal(visible);
                          }}
                          // arrow={false}
                          description={
                            <div
                              style={{
                                width: "60vw",
                                height: "400px",
                                overflow: "scroll",
                              }}
                            >
                              {uploadedDataErrors?.map(
                                (row: any, index: any) => {
                                  const {
                                    errorMessage,
                                    materialName,
                                    materialId,
                                    errorFlag,
                                  } = row;
                                  if (errorFlag) {
                                    return (
                                      <Alert
                                        key={materialId + "_" + index}
                                        color="danger"
                                        className="start-0 w-100 py-1 mt-2"
                                        style={{
                                          top: 0,
                                          textAlign: "left",
                                          fontSize: "14px",
                                          cursor: "default",
                                          display: "flex",
                                        }}
                                      >
                                        <strong>
                                          {materialName} (Id: {materialId})
                                          &nbsp; &nbsp;
                                        </strong>
                                        <ol>
                                          {errorMessage?.map((error: any) => (
                                            <li>{error}</li>
                                          ))}
                                        </ol>
                                      </Alert>
                                    );
                                  }
                                  return (
                                    <Alert
                                      color="success"
                                      className="start-0 w-100 py-1 mt-2"
                                      style={{
                                        top: 0,
                                        textAlign: "left",
                                        fontSize: "14px",
                                        cursor: "default",
                                        display: "flex",
                                      }}
                                    >
                                      <strong>
                                        {materialName} (Id: {materialId}) &nbsp;
                                        &nbsp;
                                      </strong>
                                      <ol>
                                        <li>This is a Valid record </li>
                                      </ol>
                                    </Alert>
                                  );
                                }
                              )}
                            </div>
                          }
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              bulkUploadErrorsRef?.current?.scrollIntoView({
                                behavior: "smooth",
                              });
                              setUploadedDataErrorModal(true);
                            }}
                            target="_self"
                            style={{ textDecoration: "underline" }}
                          >
                            <strong>view the details</strong>
                          </a>
                        </Popconfirm>{" "}
                        , update the file and reupload it.
                      </span>
                    </Alert>
                    <p className="ant-descriptions-item-content mt-1 mb-2">
                      <strong>Note : </strong>
                      Please select the material and enter the required data.
                      Alternatively, you can{" "}
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();

                          // Prepating Excel obj
                          let excelData: any = [];
                          data?.forEach((row: any) => {
                            let obj: any = {
                              ID: row?.quotationLineId,
                              "Material Id": row?.materialId,
                              "Material Name": row?.materialName,
                              Specification: row?.specification,
                              Quantity:
                                Number(row?.quantity) + " " + row?.uomName,
                              "Supply Quantity": "",
                              "Min Purchase Quantity": "",
                              HSN: "",
                              "Price *(Without Tax)": "",
                              "Delivery Lead Days *": "",
                            };
                            if (
                              OriginalData?.quotationInfo?.quotationHeader
                                ?.deliveryDateType === "LINE_LEVEL"
                            ) {
                              obj["Need By Date"] = `${dayjs(
                                row?.needByDate
                              ).format("DD-MMM-YYYY")}`;
                            }
                            if (
                              OriginalData?.quotationInfo?.quotationHeader
                                ?.deliveryLevelType === "LINE_LEVEL"
                            ) {
                              try {
                                const {
                                  addressLine1,
                                  addressLine2,
                                  cityName,
                                  stateName,
                                  countryName,
                                  pincode,
                                } = row?.deliveryAddressName;
                                obj["Delivery Address"] = [
                                  addressLine1,
                                  addressLine2,
                                  cityName,
                                  stateName,
                                  countryName,
                                  pincode,
                                ]?.join(" ,");
                              } catch (error) {
                                console.log(
                                  "error while destructuring deliveryAddressName"
                                );
                              }
                            }
                            if (gstType == "cgst+sgst") {
                              obj["SGST (%)"] = "";
                              obj["CGST (%)"] = "";
                            } else {
                              obj["IGST (%)"] = "";
                            }
                            excelData.push(obj);
                          });

                          ExportToXlsx(excelData, rfqId||"Quotation");
                        }}
                        target="_self"
                        style={{ textDecoration: "underline" }}
                      >
                        download the template
                      </a>{" "}
                      to fill in the details and upload it.
                    </p>
                    <div className="table-responsive">
                      <div
                        style={{
                          width: screenSize - 350 + "px",
                          margin: "0px auto 15px auto",
                        }}
                      >
                        <Table
                          columns={mergedColumns(columns, { gstType })}
                          dataSource={data?.map((item: any) => ({
                            ...item,
                            key: item?.quotationLineId,
                          }))}
                          scroll={{ x: 1000 }}
                          components={{
                            body: {
                              cell: EditableCell,
                            },
                          }}
                          bordered
                          size="middle"
                          pagination={false}
                        />
                      </div>
                    </div>

                    <div className="d-flex">
                      <Row className="mt-2 w-50">
                        <div>
                          <div className="d-flex align-items-center">
                            <h6 className="m-1 mb-0">
                              Additional Charges (If Any)
                            </h6>
                            <div className="w-20 m-1 mb-0">
                              {editAdditionalCharges ? (
                                <a
                                  type={undefined}
                                  onClick={() => {
                                    if (
                                      additionalCharges.find(
                                        (field: any, ind: number) => {
                                          const name = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.name`
                                          );
                                          const price = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.price`
                                          );
                                          return !name || !price;
                                        }
                                      )
                                    ) {
                                      return;
                                    }

                                    setAdditionalCharges([
                                      ...additionalCharges,
                                      {
                                        name: "",
                                        price: null,
                                        id: nanoid(4),
                                      },
                                    ]);
                                  }}
                                  className="addNewItem"
                                >
                                  <PlusSquareOutlined />
                                </a>
                              ) : (
                                <a
                                  type={undefined}
                                  onClick={() => {
                                    setEditAdditionalCharges(true);
                                  }}
                                  className="addNewItem"
                                >
                                  <EditOutlined />
                                </a>
                              )}
                            </div>
                          </div>
                          {additionalCharges.map(
                            (field: any, index: number) => (
                              <Row key={field.id} className="m-2 mb-0 w-100">
                                <div className="w-50">
                                  {editAdditionalCharges ? (
                                    <Form.Item
                                      name={`AdditionalCharges.${field.id}.name`}
                                      label={
                                        <strong>Additional Charge Name</strong>
                                      }
                                      rules={[
                                        {
                                          required: true,
                                          message: "Charge Name is required",
                                        },
                                      ]}
                                      className="mb-0"
                                      initialValue={field.name}
                                    >
                                      <Input
                                        className="w-100"
                                        disabled={!editAdditionalCharges}
                                        autoComplete="off"
                                        defaultValue={field.name}
                                      />
                                    </Form.Item>
                                  ) : (
                                    <strong>{field.name}</strong>
                                  )}
                                </div>
                                <div className="w-30">
                                  {editAdditionalCharges ? (
                                    <Form.Item
                                      name={`AdditionalCharges.${field.id}.price`}
                                      label={<strong>Price (INR)</strong>}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Price is required",
                                        },
                                      ]}
                                      className="mb-0"
                                      initialValue={Number(field.price)}
                                    >
                                      <InputNumber
                                        min={0}
                                        step={0.01}
                                        disabled={!editAdditionalCharges}
                                        className="w-100"
                                        formatter={
                                          (value) =>
                                            `${value}`.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            ) // Adds commas for thousands
                                        }
                                        parser={
                                          (value: any) =>
                                            value!.replace(/\$\s?|(,*)/g, "") // Removes commas and symbols
                                        }
                                        onKeyPress={(event) => {
                                          if (!/^[0-9.]$/.test(event.key)) {
                                            event.preventDefault(); // Prevent non-numeric and non-decimal input
                                          }
                                        }}
                                        autoComplete="off"
                                      />
                                    </Form.Item>
                                  ) : (
                                    <strong>
                                      {formatCurrency(field.price, "INR")}
                                    </strong>
                                  )}
                                </div>
                                <Col className="d-flex align-items-end w-15">
                                  {editAdditionalCharges && (
                                    <a
                                      // href="#dropdownitem"
                                      onClick={() => {
                                        form2.setFieldsValue({
                                          [`AdditionalCharges.${field.id}.name`]:
                                            "",
                                          [`AdditionalCharges.${field.id}.price`]:
                                            null,
                                        });
                                        const newAdditionalCharges: any = [];
                                        additionalCharges.forEach(
                                          (fld: any, ind: number) => {
                                            if (fld.id != field.id) {
                                              const name = form2.getFieldValue(
                                                `AdditionalCharges.${fld.id}.name`
                                              );
                                              const price = form2.getFieldValue(
                                                `AdditionalCharges.${fld.id}.price`
                                              );
                                              if (name && price) {
                                                newAdditionalCharges.push({
                                                  name,
                                                  price,
                                                  id: fld.id,
                                                });
                                              }
                                            }
                                          }
                                        );
                                        setAdditionalCharges(
                                          newAdditionalCharges
                                        );
                                      }}
                                      className="addNewItem"
                                    >
                                      <DeleteOutlined />
                                    </a>
                                  )}
                                </Col>
                              </Row>
                            )
                          )}
                          {additionalCharges.length > 0 &&
                            editAdditionalCharges && (
                              <Button
                                className="m-4 mt-3 mb-0"
                                color="primary"
                                onClick={() => {
                                  const newAdditionalCharges: any = [];
                                  additionalCharges.forEach(
                                    (field: any, index: number) => {
                                      const name = form2.getFieldValue(
                                        `AdditionalCharges.${field?.id}.name`
                                      );
                                      const price = form2.getFieldValue(
                                        `AdditionalCharges.${field?.id}.price`
                                      );
                                      if (name && price) {
                                        newAdditionalCharges.push({
                                          name,
                                          price,
                                          id: field?.id,
                                        });
                                      }
                                    }
                                  );
                                  setAdditionalCharges(() => {
                                    return newAdditionalCharges;
                                  });
                                  if (newAdditionalCharges.length != 0) {
                                    setEditAdditionalCharges(false);
                                  }
                                }}
                              >
                                Save
                              </Button>
                            )}
                        </div>
                      </Row>

                      <ViewTableTotals
                        data={data}
                        additionalCharges={additionalCharges}
                        invoice={true}
                      />
                    </div>
                  </Form>
                </>
              ) : (
                <>
                  <p
                    className="ant-descriptions-item-content mb-2"
                    style={{ textAlign: "center" }}
                  >
                    Please select Supplier to see material Information
                  </p>
                </>
              )}
            </Panel>
            <Panel header={<h6>Terms and Conditions</h6>} key="3">
              <div>
                {/* <ReactQuill
                  theme="snow"
                  value={supplierTermsAndConditions}
                  onChange={(data) => {
                    setsupplierTermsAndConditions(data);
                  }}
                  className="mb-3"
                /> */}
                <FormProvider {...methods}>
                  <form>
                    <FormTextArea
                      name="supplierTermsAndConditions"
                      label={undefined}
                      placeholder="Enter Terms and Conditions"
                    />
                  </form>
                </FormProvider>
              </div>
            </Panel>
            <Panel header={<h6>Attachments (If Any)</h6>} key="4">
              <div className="w-100 d-flex m-2">
                <div className="w-45">
                  <Form.Item
                    name="attachDC"
                    label={<strong>Attach Documents</strong>}
                    valuePropName="fileList"
                    getValueFromEvent={(e: any) => e?.fileList}
                    rules={[
                      {
                        required: false,
                        // message: "Please upload at least one document.",
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={beforeUpload}
                      onChange={handleChangeAttachment}
                      multiple={true}
                      fileList={documentsAttached}
                      accept=".pdf,.jpg,.jpeg,.png"
                      className="w-100"
                      showUploadList={{ showPreviewIcon: false }}
                      itemRender={(originNode, file, fileList, actions) => {
                        // Customize the rendering of each file to remove the tooltip
                        return React.cloneElement(originNode, {
                          title: null, // Removes the tooltip entirely
                        });
                      }}
                    >
                      <Button type="button" icon={<UploadOutlined />}>
                        Click to Upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
                <div className="row">
                  <div className="col-md-12" style={{ marginBottom: 15 }}>
                    <h6>Required Docs</h6>
                  </div>
                  {requiredDOC &&
                    requiredDOC.length > 0 &&
                    requiredDOC.map((doc: any) => (
                      <div key={doc.quotationDocNameId} className="col-md-4">
                        <span ref={errorRef}></span>
                        <Form.Item
                          key={doc.quotationDocNameId}
                          name={`doc_${doc.quotationDocNameId}`}
                          label={
                            <strong>
                              {doc.documentName}{" "}
                              {doc.mandatory == 1 && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </strong>
                          }
                          valuePropName="fileList"
                          getValueFromEvent={(e: any) =>
                            Array.isArray(e) ? e : e?.fileList
                          }
                          rules={[
                            {
                              required: doc.mandatory == 1,
                              message: `${doc.documentName} is required`,
                            },
                          ]}
                        >
                          <Upload {...uploadProps} className="w-100">
                            <AntButton icon={<UploadOutlined />}>
                              Click to Upload
                            </AntButton>
                          </Upload>
                        </Form.Item>
                      </div>
                    ))}
                </div>
                {/* <Descriptions.Item>
                  <div>
                    <strong style={{ color: "#344357 !important" }}>
                      Required Documents : &nbsp;
                    </strong>
                    <div className="mt-2">
                      {requiredDOC && requiredDOC.length > 0 && (
                        <>
                          <Row>
                            <ul className="document-list">
                              {requiredDOC.map((doc: any) => (
                                <li
                                  key={doc.quotationDocNameId}
                                  className="document-item"
                                >
                                  <Form.Item
                                    name={`doc_${doc.quotationDocNameId}`}
                                    label={
                                      <strong>
                                        {doc.documentName}{" "}
                                        {doc.mandatory == 1 && (
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        )}
                                      </strong>
                                    }
                                    valuePropName="fileList"
                                    getValueFromEvent={(e: any) =>
                                      Array.isArray(e) ? e : e?.fileList
                                    }
                                    rules={[
                                      {
                                        required: doc.mandatory == 1,
                                        message: `${doc.documentName} is required`,
                                      },
                                    ]}
                                  >
                                    <Upload {...uploadProps} className="w-100">
                                      <Button icon={<UploadOutlined />}>
                                        Click to Upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                </li>
                              ))}
                            </ul>
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                </Descriptions.Item> */}

                {/* </div> */}
              </div>
            </Panel>
          </AntCollapse>
          {showCropModel && (
            <CropperComponent
              modal={showCropModel}
              toggle={() => setShowCropModel(!showCropModel)}
              onSave={(data: string) => {
                setInvoiceSignature((pre: any) => {
                  return { ...pre, image: data };
                });
                setShowCropModel(false);
              }}
            />
          )}
          {showSignatureModel && (
            <SignatureModal
              modal={showSignatureModel}
              toggle={() => setShowSignatureModel(!showSignatureModel)}
              onSave={(data: string) => {
                setInvoiceSignature((pre: any) => {
                  return { ...pre, image: data };
                });
                setShowSignatureModel(false);
              }}
            />
          )}
        </Form>
      </Drawer>
    </>
  );
};

export default AddSupplierQuoteDrawer;

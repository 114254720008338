import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Component";
import { Empty, Flex, Spin, Table } from "antd";
import FormSelect from "../form-components/SelectComponent";
import BOQAside from "./boqAside";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import boqApis from "../../api/boq/boq";
import { formatCurrency } from "../../utils/Utils";

function BoqReport() {
  const { data: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const [siteOptions, setSiteOptions] = useState<any>([]);
  const methods = useForm({
    mode: "onChange",
  });
  const [tab, setTab] = useState("1");
  const [sm, setSm] = useState(false);
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const [dataSource, setDataSource] = useState<any>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "40", "50"],

    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [screenSize, setScreenSize] = useState(0);

  const selectFilter1 = watch("siteIds");

  //---------------------------------------- Functions Start ----------------------------------------

  const fetchData = async (params: any, filters?: any) => {
    setLoading(true);
    try {
      const {
        sortOrder,
        pageSize,
        direction,
        pageDiff,
        paginationId,
        deviation,
        siteIds,
        blockIds,
      } = params;
      let apiResponse: any =
        await boqApis.fetchConsumptionTargetMaterialDeviationsList({
          sortOrder,
          pageSize,
          direction,
          pageDiff,
          paginationId,
          deviation,
          siteIds,
          // blockIds,
        });

      const { response, status } = apiResponse;
      if (status) {
        const { list, count } = response;
        setDataSource(list);
        setPagination((prev) => ({
          ...prev,
          total: Number(count),
        }));
      }
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  const tableOnChange = async (
    newPagination: any,
    filters: any,
    sorter: any
  ) => {
    const apiParams = {
      oldPage: pagination.current,
      current: newPagination.current?.toString(),
      sortOrder: "DESC",
      pageSize: pagination.pageSize,
      direction: "P",
      pageDiff: 0,
      paginationId: null,
      deviation: "N",
      siteIds: selectFilter1,
      blockIds: 0,
    };

    if (sorter.order) {
      apiParams.sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";
    } else if (newPagination.pageSize != pagination.pageSize) {
      // if page size is changed
      apiParams.pageSize = newPagination.pageSize;
      const _currentPage =
        Math.floor(
          ((Number(pagination.current) - 1) * Number(pagination.pageSize)) /
            Number(newPagination.pageSize)
        ) + 1; // calculating new current page based on new page size
      apiParams.current = _currentPage;
      apiParams.pageDiff = _currentPage - 1;
    } else {
      if (newPagination.current != pagination.current) {
        apiParams.direction =
          newPagination.current > pagination.current
            ? "F" // Forward
            : "B"; // Backward
        apiParams.paginationId =
          apiParams.direction == "F"
            ? dataSource[dataSource.length - 1]?.paginationId // Last item ID for backward direction
            : dataSource[0]?.paginationId; // First item ID for forward direction

        apiParams.pageDiff =
          Math.abs(newPagination.current - pagination.current) - 1;
      }
    }

    setPagination({
      ...pagination,
      current: apiParams.current,
      pageSize: apiParams.pageSize,
    });
    await fetchData(apiParams);
  };

  //---------------------------------------- Functions End----------------------------------------

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
    } else {
      setSm(false);
    }
  };
  useEffect(() => {
    viewChange();

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility) {
        const allSites: any = [];
        let _siteOptions: any = [];
        siteAccessibility?.forEach((item: any) => {
          allSites.push(item.siteID);
          _siteOptions.push({ label: item.siteName, value: item.siteID });
        });
        setValue("siteIds", allSites?.join(","));
        if (_siteOptions?.length > 1) {
          _siteOptions = [
            {
              label: "ALL",
              value: allSites?.join(","),
            },
            ..._siteOptions,
          ];
        }
        setSiteOptions(_siteOptions);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectFilter1) {
      switch (tab) {
        case "1":
          fetchData({
            oldPage: 1,
            current: 1,
            sortOrder: "DESC",
            pageSize: 5,
            direction: "P",
            pageDiff: 0,
            paginationId: null,
            deviation: "Y",
            siteIds: selectFilter1,
            blockIds: 0,
          });
          break;
        case "2":
          fetchData({
            oldPage: 1,
            current: 1,
            sortOrder: "ASC",
            pageSize: 5,
            direction: "P",
            pageDiff: 0,
            paginationId: null,
            deviation: "Y",
            siteIds: selectFilter1,
            blockIds: 0,
          });
          break;
        case "3":
          fetchData({
            oldPage: 1,
            current: 1,
            sortOrder: "DESC",
            pageSize: 10,
            direction: "P",
            pageDiff: 0,
            paginationId: null,
            deviation: "N",
            siteIds: selectFilter1,
            blockIds: 0,
          });
          break;
        default:
          alert("No Data Found");
      }
    }
  }, [selectFilter1, tab]);

  return (
    <>
      <Head title={"Consumption Report"} />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <BOQAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>Consumption Report</span>
                    </BlockTitle>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <FormProvider {...methods}>
                <form className="w-100 d-flex align-items-center justify-content-start">
                  <div className="w-75 ">
                    <div className="d-flex align-items-center flex-wrap mb-2">
                      <div
                        className="mt-1 mb-0 w-25"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name={`siteIds`}
                          label={"Select Site"}
                          required={true}
                          hideRequiredMark={true}
                          placeholder={""}
                          options={siteOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`w-25 mb-2 `}></div>
                </form>
              </FormProvider>
              <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs w-100 p-0 m-0 mb-3">
                <li className="nav-item me-5" onClick={() => setTab("1")}>
                  <a
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className={`nav-link${tab === "1" ? " active" : ""}`}
                    href="#top5deviations"
                  >
                    Top 5 Material Deviations
                  </a>
                </li>
                <li className="nav-item mx-5" onClick={() => setTab("2")}>
                  <a
                    href="#bottom5deviations"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className={`nav-link${tab === "2" ? " active" : ""}`}
                  >
                    Bottom 5 Material Deviations
                  </a>
                </li>
                <li className="nav-item mx-5" onClick={() => setTab("3")}>
                  <a
                    href="#materialWithoutDeviations"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className={`nav-link${tab === "3" ? " active" : ""}`}
                  >
                    Material Without Deviations
                  </a>
                </li>
              </ul>
              <div className="content clearfix">
                {loading == true ? (
                  <>
                    <Flex align="center" gap="middle" className="pageLoader">
                      <Spin size="large" className="m-auto" />
                    </Flex>
                  </>
                ) : dataSource?.length > 0 ? (
                  <div
                    style={{
                      width: screenSize - 330 + "px",
                      margin: "0px auto",
                    }}
                  >
                    <Table
                      className="customTable"
                      columns={[
                        {
                          title: "CATEGORY",
                          dataIndex: "categoryName",
                          key: "categoryName",
                          fixed: "left",
                        },
                        {
                          title: "MATERIAL",
                          dataIndex: "materialName",
                          key: "materialName",
                          fixed: "left",
                        },
                        {
                          title: "UOM",
                          key: "uomName",
                          dataIndex: "uomName",
                          width: 150,
                        },
                        {
                          title: "TOTAL TARGET",
                          dataIndex: "totalTarget",
                          key: "totalTarget",
                          fixed: "left",
                          render: (value, record) => (
                            <span>
                              {value} {record?.uom}
                            </span>
                          ),
                        },
                        {
                          title: "PER SFT TARGET",
                          dataIndex: "targetPerSft",
                          key: "targetPerSft",
                          // render: (value, record) => (
                          //   <div className="justify-end">
                          //     <span>
                          //       {formatCurrency(value, record?.currencyCode)}
                          //     </span>
                          //   </div>
                          // ),
                        },
                        {
                          title: "TOTAL PURCHASE",
                          dataIndex: "quantityPurchased",
                          key: "quantityPurchased",
                          // render: (value, record) => (
                          //   <div className="justify-end">
                          //     <span>
                          //       {formatCurrency(value, record?.currencyCode)}
                          //     </span>
                          //   </div>
                          // ),
                        },
                        {
                          title: "PURCHASE PER SFT",
                          dataIndex: "purchasePerSft",
                          key: "purchasePerSft",
                          // render: (value, record) => (
                          //   <div className="justify-end">
                          //     <span>
                          //       {formatCurrency(value, record?.currencyCode)}
                          //     </span>
                          //   </div>
                          // ),
                        },
                        {
                          title: "DEVIATION %",
                          dataIndex: "deviation",
                          key: "deviation",
                          render: (value) => <span>{value}%</span>,
                        },
                      ]}
                      dataSource={dataSource}
                      pagination={tab == "3" ? pagination : false}
                      onChange={tableOnChange}
                      bordered
                      size="middle"
                      scroll={{
                        x: 1500,
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Empty description="No Data Found " />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
}

export default BoqReport;

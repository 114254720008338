import React, { useEffect, useState } from "react";
import { Row, Col, Button, Alert } from "reactstrap";
import { useFormContext, Controller, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { useNavigate } from "react-router";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import moment from "moment";
import { Icon, TooltipComponent } from "../../../components/Component";
import { isArray } from "chart.js/dist/helpers/helpers.core";

const BankAccountDetails: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [alertInfo, setAlertInfo] = useState<any>({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
    control,
  } = useFormContext();
  const files = watch("files");
  useEffect(() => {
    if (files && files[0]?.size && files[0].size > 2097152) {
      setValue("files", null);
      Swal.fire({
        icon: "error",
        title: "File size should be less than 2MB.",
      });
    }
  }, [files]);

  const submitForm = (formData: any) => {
    console.log("Final form data:", formData);
    try {
      delete formData.files;
      const supplierFormData: any = new FormData();
      if (files[0]) {
        supplierFormData.append("files", files[0]);
        supplierFormData.append(
          "filesData",
          JSON.stringify([
            {
              indexOfFileLocated: 0,
              module: "supplier",
              purpose: "cancled bank cheque",
              level: "Header Level",
              uniqueId: "",
              action: "Create",
            },
          ])
        );
      }
      let submitData: any = {
        ...formData,
        supplyStates: [],
        lastFYTurnover: Number(formData.lastFYTurnover),
        incorporationDate: moment(formData.incorporationDate).format(
          "YYYY-MM-DD"
        ),
      };
      delete submitData.files;
      supplierFormData.append("supplierData", JSON.stringify(submitData));
      createSupplier(supplierFormData);
    } catch (error) {
      console.log(error);
    }
  };
  const createSupplier = async (data: any) => {
    try {
      const response = await supplierApis.createSupplier(data);
      console.log("response", response);
      const { status, message } = response;

      if (status) {
        Swal.fire("Created!", "Supplier has been created", "success");
        navigate(`/suppliers`);
      } else {
        let errorMessage = "An error occurred";
        let fullErrorMessage;
        if (typeof message == "string") {
          fullErrorMessage = `${errorMessage}\n${"-"}\n${message}`;
        } else {
          fullErrorMessage = `${errorMessage}\n${"-"}\n${message?.join(" ")}`;
        }
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const fileValidation: any = {
    required: "Please upload a cheque",
    validate: {
      fileFormat: (files: any) =>
        (files.length &&
          Array.from(files).every((file: any) =>
            /\.(png|jpg|jpeg)$/i.test(file.name)
          )) ||
        "Only PNG,JPG and jpeg files are allowed!",
    },
  };

  useEffect(() => {
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
  }, []);

  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row>
          <Col lg="4">
            <FormInput
              name="accountHolderName"
              type="text"
              label="Company Account Name"
              required={true}
              placeholder="Enter Company Account Name"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="accountNumber"
              // disabled={action === "edit"}
              type="number"
              label="Account Number"
              required={true}
              placeholder="Enter Account Number"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="bankName"
              // disabled={action === "edit"}
              label="Bank Name"
              required={true}
              placeholder="Enter Bank Name"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="4">
            <FormInput
              name="branch"
              // disabled={action === "edit"}
              label="Branch"
              required={true}
              placeholder="Enter Branch"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="ifscCode"
              // disabled={action === "edit"}
              label="IFSC Code"
              pattern={/^[A-Z]{4}0[A-Z0-9]{6}$/}
              maxLength={11}
              required={true}
              placeholder="Enter IFSC Code"
            />
          </Col>
          <Col lg="4">
            {files && files[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label className="form-label" htmlFor={"file"}>
                    Upload Cancelled Cheque <span className="mandatory">*</span>
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`file-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{files[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("files", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
            <div
              style={{
                display: `${files && files[0]?.name ? "none" : "block"}`,
              }}
            >
              <FormInput
                name="files"
                label="Upload Cancelled Cheque"
                type="file"
                required={files && files[0]?.name ? false : true}
                placeholder="Upload Cancelled Cheque"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4"></Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Submit Form
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default BankAccountDetails;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSequenceAccess } from "../../../store/slices/misc/sequenceAccess";
import { useFormContext } from "react-hook-form";
import { Button, Col, FormGroup, Row } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import usersApis from "../../../api/master/users";
import sites from "../../../api/master/sites";
import contractorsApis from "../../../api/master/contractors";
import boqApis from "../../../api/boq/boq";
import Swal from "sweetalert2";
import FormMultiSelect from "../../form-components/MultiSelectComponent";



interface SelectOptions {
  label: string;
  value: string;
}
const ConsumptionTarget_GeneralDetails: React.FC = (props: any) => {
  const { data: sequenceAccess } = useSelector(
    (state: any) => state.sequenceAccess
  );
  const dispatch: any = useDispatch();
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const [contractorOptions, setContractorOptions] = useState<SelectOptions[]>(
    []
  );
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [blockOptions, setBlockOptions] = useState<SelectOptions[]>([]);
  const [blockData, setBlockData] = useState<any>([]);
  const siteIdWatch = watch("siteId");
  const blockIdWatch = watch("blockId");


  useEffect(() => {
    if (siteIdWatch) {
      fetchBlockList(siteIdWatch);
      fetchContractorList(siteIdWatch);
    }
  }, [siteIdWatch]);

  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);

  useEffect(() => {
    if (blockIdWatch) {
      const matchingBlock = blockData?.find(
        (item: any) => item.blockID === Number(blockIdWatch)
      );
      if (matchingBlock) {
        console.log(
          "blockIdWatch",
          blockIdWatch,
          blockData,
          matchingBlock.squareFeet
        );
        setValue("blockArea", matchingBlock.squareFeet);
      }
    }
  }, [blockIdWatch]);

  useEffect(() => {
    fetchSites();
  }, []);

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBlockList = async (siteId: any) => {
    try {
      const { data, status } = await sites.fetchBlockById(siteId);
      if (status) {
        let blockOptions = data.map((item: any) => ({
          label: item.blockName,
          value: item.blockID.toString(),
        }));
        setBlockOptions(blockOptions);
        setBlockData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContractorList = async (siteId: any) => {
    try {
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: siteId,
      });
      if (status) {
        let contractorOptions = data.map((item: any) => ({
          label: item?.companyName,
          value: item?.contractorID?.toString(),
        }));
        setContractorOptions(contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const checkDuplicateEntry = async (data: any) => {
    try {
      const response = await boqApis.checkConsumptionTargetValidity({
        siteId: data.siteId,
        blockId: data.blockId,
        contractorId: data.contractorId,
      });
      const{ status} = response;
      if (!status) {
        console.log("response.status", response.status)
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: "Target already set for selected block & contractor. Add material targets in #BOQ"
        });
       
      }
      return status
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while checking the duplicate entry.'
      });
    }
  };


  const submitForm = async (data: any) => {
    const isUnique = await checkDuplicateEntry(data);

    if (!isUnique) return;
    const payload = {
      
      
    };
  
    console.log("Payload:", payload);
  
    

    console.log("data", data);
    props.next(payload);
  };

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          {!sequenceAccess?.includes("BOQ") ? (
            <Col lg="4">
              <FormInput
                name="targetNumber"
                label={`Target Number`}
                required={true}
                placeholder="Enter Target Number"
              />
            </Col>
          ) : (
            <></>
          )}
          <Col lg="4">
            <FormSelect
              name="siteId"
              label={`Site`}
              options={sitesOptions}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the site where the materials will be used."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="blockId"
              label={`Block`}
              options={blockOptions}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the Block where the materials will be used."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="contractorId"
              label={`Contractor`}
              required={true}
              options={contractorOptions}
              placeholder="-Select-"
              tooltipText="Select the contractor for this purchase order."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="limitPostTargetConsumption"
              label={`Limit Material Issue`}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="-Select-"
              tooltipText="Material cannot be Issued without approvals to site post BOQ Consumption."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="limitPostTargetOrder"
              label={`Limit PO?`}
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="-Select-"
              tooltipText="'Purchase Orders cannot be Released without approvals post Exceeding BOQ Purchases"
            />
          </Col>
          <Col lg="4">
            <FormMultiSelect
              name="setAlerts"
              label={`Set Alerts
                
                
                `}
              options={Array.from({ length: 10 }, (_, i) => {
                const value = (i + 1) * 5;
                return { label: value.toString(), value: value.toString() };
              })}
              placeholder="-Select-"
              tooltipText="Select Limit."
            />
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default ConsumptionTarget_GeneralDetails;

import React from "react";
import ReportTemplate2 from "../reportTemplate2";
import dayjs from "dayjs";

function InventoryTrackReport() {
  const columns: any = [
    {
      title: "Type",
      key: "actionType",
      dataIndex: "actionType",
      width: 100,
    },
    {
      title: "Date",
      key: "actionDate",
      dataIndex: "actionDate",
      width: 100,
      render: (date: string) => dayjs(date).format("DD-MMM-YYYY"),
    },
    {
      title: "Ref #",
      key: "referenceId",
      dataIndex: "referenceId",
      width: 100,
      render: (text: any) => {
        return <span>{!text ? "N/A" : text}</span>;
      },
    },
    {
      title: "Supplier",
      key: "supplierName",
      dataIndex: "supplierName",
      width: 250,
      render: (text: any) => {
        return <span>{!text ? "N/A" : text}</span>;
      },
    },
    {
      title: "Material",
      key: "materialName",
      dataIndex: "materialName",
      width: 100,
    },
    {
      title: "UOM",
      key: "primaryUomName",
      dataIndex: "primaryUomName",
      width: 100,
    },
    {
      title: "Inward QTY",
      key: "inwardqty",
      dataIndex: "",
      width: 100,
      render: (text: any, record: any) => {
        if (record.actionType !== "handover") {
          return (
            <div>
              <span>{record?.actualQuantity}</span>
            </div>
          );
        }
      },
    },
    {
      title: "OutWard QTY",
      key: "outwardqty",
      dataIndex: "",
      width: 100,
      render: (text: any, record: any) => {
        if (record.actionType == "handover") {
          return (
            <div>
              <span>{record?.actualQuantity}</span>
            </div>
          );
        }
      },
    },
    // {
    //   title: "Qty",
    //   dataIndex: "actualQuantity",
    //   key: "actualQuantity",
    //   width: 220,
    //   // render: (text: number, record: any) => {
    //   //   <div>
    //   //     <span>{text?.toLocaleString("en-IN")}</span>
    //   //   </div>;
    //   // },
    // },
  ];
  return (
    <ReportTemplate2
      pageTitle="Material Movement"
      pageDescription="	Detailed tracking of materials transferred between locations or sites"
      moduleKey="InventoryTrackReport"
      columns={columns}
      filtersOption={{
        site: true,
        // contractor: true,
        material: true,
        category: true,
        date: true,
      }}
      previousPath="/view-inventory-reports"
    />
  );
}

export default InventoryTrackReport;

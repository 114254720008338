import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Label, Input, Row, Col, Alert } from "reactstrap";
import FormSelect from "../../form-components/SelectComponent";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
import { Form } from "react-router-dom";
import Swal from "sweetalert2";
const AdditionalContactInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
    control,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "additionalContacts",
  });
  const submitForm = (data: any) => {
    props.next();
  };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row>
        {fields.map((field, index) => (
          <div key={field.id} className="row mb-3">
            <div className="col-md-3">
              <FormInput
                name={`additionalContacts.${index}.name`}
                label="Name"
                type="text"
                placeholder="Enter Name"
                required={true}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                name={`additionalContacts.${index}.designation`}
                label="Designation"
                type="text"
                required={true}
                placeholder="Select Designation"
              />
            </div>
            <div className="col-md-2">
              <FormInput
                name={`additionalContacts.${index}.phoneNumber`}
                label="Phone Number"
                required={true}
                type="text"
                pattern={/^\d+$/} // Allows only digits
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
                }}
                placeholder="Enter Phone Number"
              />
            </div>
            <div className="col-md-2">
              <FormInput
                name={`additionalContacts.${index}.email`}
                label="Email"
                required={true}
                // disabled={action === "view"}
                type="email"
                maxLength={50}
                placeholder="Enter Email address"
                pattern={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/}
              />
            </div>
            <div className="col-md-2">
              <div style={{ marginTop: 30 }}>
                <button
                  type="button"
                  className="btn btn-success ms-2"
                  onClick={() =>
                    append({
                      name: "",
                      designation: "",
                      phoneNumber: "",
                      email: "",
                    })
                  }
                >
                  +
                </button>
                &nbsp;
                {index > 0 && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => remove(index)}
                  >
                    -
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default AdditionalContactInformation;

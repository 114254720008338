import React, { FC } from "react";
import classNames from "classnames";
import ChatDropdown from "./dropdown/chat/Chat";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import User from "./dropdown/user/User";
import MobileMenu from "../menu/MobileMenu";
import LanguageHead from "./dropdown/language/Language";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import DownloadDropdown from "./dropdown/download/Download";

interface HeaderProps {
  fixed?: boolean;
  className?: string;
  [key: string]: any; // For any additional props
}

const Header: FC<HeaderProps> = ({ fixed, className, ...props }) => {
  const {
    data: menu,
    loading,
    error,
  } = useSelector((state: RootState) => state.menu);
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const headerClass = classNames({
    "nk-header is-regular": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]:
      theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  return (
    <div className={headerClass} {...props}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger me-sm-2 d-lg-none">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon"
              icon="menu"
              click={themeUpdate.sidebarVisibility}
            />
          </div>
          <div className="nk-header-brand">
            <Logo />
          </div>
          <div
            className={`nk-header-menu ms-auto ${
              theme.sidebarMobile ? "mobile-menu" : ""
            } ${theme.sidebarVisibility ? "nk-header-active" : ""}`}
          >
            <div className="nk-header-mobile">
              <div className="nk-header-brand">
                <Logo />
              </div>
              <div className="nk-menu-trigger me-n2">
                <Toggle
                  className="nk-nav-toggle nk-quick-nav-icon"
                  icon="arrow-left"
                  click={themeUpdate.sidebarVisibility}
                />
              </div>
            </div>
            {theme.sidebarMobile ? (
              <MobileMenu
                data={menu}
                sidebarToggle={themeUpdate.sidebarVisibility}
                mobileView={theme.sidebarMobile}
              />
            ) : (
              <Menu sidebarToggle={themeUpdate.sidebarVisibility} />
            )}
          </div>
          {theme.sidebarVisibility && (
            <div
              className="nk-header-overlay"
              onClick={themeUpdate.sidebarVisibility}
            ></div>
          )}
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="language-dropdown d-none d-sm-block me-n1">
                <LanguageHead />
              </li>
              {/* <li className="chats-dropdown">
                <DownloadDropdown />
              </li> */}
              <li className="chats-dropdown">
                <ChatDropdown />
              </li>
              <li className="user-dropdown">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

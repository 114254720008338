import { Table } from "antd";
import { FC, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Misc from "../masters/Misc";
const { FormatCurrency, beforeUpload } = Misc;
const VersionControl_Materails: FC<any> = ({
  columns,
  latestMaterialData,
  expandedRowRender,
  orderAmountDetails,
  _signature,
  extraMaterialInfo,
}) => {
  const [signature, setSignature] = useState<any>();
  useEffect(() => {
    if (_signature && extraMaterialInfo) {
      console.log("signature", _signature);
      setSignature({
        docId: _signature[0]?.docId,
        fileName: _signature[0]?.fileName,
        image: _signature[0]?.filePath,
        moduleFileType: _signature[0]?.moduleFileType,
        module: _signature[0]?.module,
        label: _signature[0].signatureLabel,
        isActive: true,
      });
    }
  }, []);

  return (
    <>
      <Table
        columns={columns}
        dataSource={latestMaterialData}
        expandable={{ expandedRowRender }}
        rowKey="key"
        pagination={false}
        bordered
      />
      {extraMaterialInfo ? (
        <>
          <Row
            style={{
              margin: "20px",
            }}
          >
            <Col
              className={`${
                // poStatus == "Pending Approval" && allowedActions?.edit == 1
                //   ? "justify-between"
                //   :
                "justify-end"
              }`}
            >
              <div className="w-40">
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>Taxable Amount</strong>
                  </div>
                  <span className="r_align">
                    {FormatCurrency(orderAmountDetails?.netAmount, "INR")}
                  </span>
                </div>
                {orderAmountDetails?.igst == 0 ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="w-50 justify-end">
                        <strong>CGST</strong>
                      </div>
                      <span className="r_align">
                        {FormatCurrency(orderAmountDetails?.cgst, "INR")}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="w-50 justify-end">
                        <strong>SGST</strong>
                      </div>
                      <span className="r_align">
                        {FormatCurrency(orderAmountDetails?.sgst, "INR")}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between">
                    <div className="w-50 justify-end">
                      <strong>IGST</strong>
                    </div>
                    <span className="r_align">
                      {FormatCurrency(orderAmountDetails?.igst, "INR")}
                    </span>
                  </div>
                )}
                <div
                  className="d-flex justify-content-between"
                  style={{
                    fontSize: "18px",
                    marginTop: "10px",
                  }}
                >
                  <div className="w-50 justify-end">
                    <strong>Total</strong>
                  </div>
                  <strong className="r_align">
                    {FormatCurrency(orderAmountDetails?.grossTotal, "INR")}
                  </strong>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-4 justify-content-end ">
            {/* {signature?.isActive}
        {signature?.isActive && ( */}
            <div className="signatureBtnWrapper">
              <div className="d-flex justify-content-between align-items-center">
                <strong>Signature</strong>
              </div>
              <div
                style={{
                  minHeight: signature?.image ? "0px" : "120px",
                  display: signature?.image ? "block" : "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {signature?.image && (
                  <>
                    <div className="signatueImgWrapper border">
                      <img
                        src={signature?.image}
                        alt="Signed"
                        style={{ width: "160px" }}
                      />
                    </div>

                    <div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="signatureLabel">
                          Signature Label
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="signatureLabel"
                          onChange={(e) => {}}
                          disabled
                          defaultValue={
                            signature?.label || "Authorised Signatory"
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* )} */}
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default VersionControl_Materails;

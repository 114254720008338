import React, { useEffect, useState } from "react";
import VersionControlDefaultTemplate from "../version-control/versionControlDefaultTemplate";
import FormatDate from "../masters/DateFormater";
import { formatCurrency } from "../../utils/Utils";
import Misc from "../masters/Misc";
import data from "../app/file-manager/Data";
import { render } from "@testing-library/react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Empty, Flex, Popover, Spin, Timeline } from "antd";
import { Badge } from "reactstrap";
import { useParams } from "react-router";
import { use } from "echarts";
import Swal from "sweetalert2";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
const { FormatCurrency, beforeUpload } = Misc;

const POVersionControl = () => {
  let { orderNumber }: any = useParams();
  const [apiResponse, setApiResponse] = React.useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (orderNumber) {
      fetchVersionControlData(orderNumber);
    }
  }, []);

  const fetchVersionControlData = async (orderNumber: any) => {
    setLoading(true);
    try {
      const res = await purchaseOrderApis.fetchOrderVersions(orderNumber);
      const { data, status, message } = res;
      if (status) {
        setApiResponse(data);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  const ApprovalTimelineContent = (orderApprovalsData: any) => {
    const approvalsData = orderApprovalsData;

    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "Approved";
              const isRejected = approval.status == "Rejected";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Badge color="outline-success">Yes</Badge>
        </span>
      </Popover>
    );
  };
  const displayFields: any = [
    {
      label: "Order Date",
      key: "orderDate",
      render: (text: any) => {
        return dayjs(text).format("DD-MMM-YYYY");
      },
    },
    { label: "Site", key: "siteName" },
    { label: "Budget", key: "budgetName" },
    { label: "Contractor", key: "contractorName" },
    { label: "Description", key: "description" },
    { label: "Payment Terms", key: "paymentTerms" },
    {
      label: "Approvals",
      key: "orderApprovals",
      render: (text: any) => {
        return ApprovalTimelineContent(text);
      },
    },
    { label: "Cost Center", key: "costCenterName" },
    { label: "Ledger", key: "ledgerName" },
    { label: "Department", key: "departmentName" },
    {
      label: "Inward Required",
      key: "inwardRequired",
      render: (text: any) => {
        return <div className="data-value w-50">{text ? "Yes" : "No"}</div>;
      },
    },
    { label: "Line Level Delivery", key: "needByDateType" },
    { label: "Delivery Type", key: "deliveryAddressType" },
    { label: "Delivery Date", key: "needByDate" },
    {
      label: "Billing Address",
      key: "billingAddressId",
      render: (text: any, record: any) => {
        return (
          <Popover
            content={
              <div className="billingAddress_align">
                <strong>{record.billingAddress?.deliveryLocationName}</strong>
                <br></br>
                <p>
                  {record.billingAddress?.addressLine1},{" "}
                  {record.billingAddress?.addressLine2}
                </p>
                <p>
                  {record.billingAddress?.city},{" "}
                  {record.billingAddress?.district?.districtName},{" "}
                  {record.billingAddress?.state?.stateName},{" "}
                  {record.billingAddress?.countryInfo?.countryName} -{" "}
                  {record.billingAddress?.pincode}
                </p>
                <p>
                  <strong>GSTIN</strong> : {record?.orgDetails?.gstin}
                </p>
                <p>
                  <strong>CIN</strong> : {record?.orgDetails?.cin}
                </p>
                <p>
                  <strong>PAN</strong> : {record?.orgDetails?.pan}
                </p>
                <p>
                  <strong>Phone</strong> : {record?.orgDetails?.phoneNumber}
                </p>
              </div>
            }
            title={<span style={{ color: "#2263b3" }}>Billing Address</span>}
          >
            <span
              style={{
                color: "#2263b3",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {record.billingAddress?.deliveryLocationName}
            </span>
          </Popover>
        );
      },
    },
    {
      label: "Delivery Address",
      key: "deliveryAddress",
      render: (text: any, record: any) => {
        return (
          <Popover
            content={
              <div className="billingAddress_align">
                <strong>{record.deliveryAddress?.deliveryLocationName}</strong>
                <br></br>
                <p>
                  {record.deliveryAddress?.addressLine1},{" "}
                  {record.deliveryAddress?.addressLine2}
                </p>
                <p>
                  {record.deliveryAddress?.city},{" "}
                  {record.deliveryAddress?.district?.districtName},{" "}
                  {record.deliveryAddress?.state?.stateName},{" "}
                  {record.deliveryAddress?.countryInfo?.countryName} -{" "}
                  {record.deliveryAddress?.pincode}
                </p>
                <p>
                  <strong>Contact Person</strong> :{" "}
                  {record.deliveryAddress?.phoneNumber}
                </p>
                <p>
                  <strong>Contact Person</strong> :{" "}
                  {record.deliveryAddress?.phoneNumber}
                </p>
              </div>
            }
            title={<span style={{ color: "#2263b3" }}>Delivery Address</span>}
          >
            <span
              style={{
                color: "#2263b3",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {record.deliveryAddress?.deliveryLocationName}
            </span>
          </Popover>
        );
      },
    },
    {
      label: "Supplier Info",
      key: "supplierInfo",
      render: (text: any, record: any) => {
        return (
          <Popover
            content={
              <ul>
                <li>
                  <strong>Name</strong> : {record?.supplierName}
                </li>
                <li>
                  <strong>GSTIN</strong> : {record?.supplierDetails?.gstin}
                </li>
                <li>
                  <strong>PAN</strong> : {record?.supplierDetails?.pan}
                </li>
                <li>
                  <strong>CIN</strong> : {record?.supplierDetails?.cin}
                </li>
              </ul>
            }
            title={<span style={{ color: "#2263b3" }}>Supplier Info</span>}
          >
            <span
              style={{
                color: "#2263b3",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              View
            </span>
          </Popover>
        );
      },
    },
  ];

  const materialColumns = [
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      fixed: "left",
      width: 150,
      render: (text: any) => `Version ${text}`,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      render: (value: any, record: any) => {
        const isModified = record?.isModified?.needByDate;
        const formattedValue = value
          ? dayjs(value).format("DD-MMM-YYYY")
          : "N/A";

        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {formattedValue}
          </strong>
        ) : (
          formattedValue
        );
      },
      // render: (text: any) => FormatDate(text),
    },
    {
      title: "HSN / SAC",
      dataIndex: "hsnCode",
      key: "hsnCode",
      width: 150,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text: any, record: any) => {
        const isModified =
          record?.isModified?.quantity || record?.isModified?.uom;
        const formattedText = `${Number(text).toFixed(2)} ${record?.uom || ""}`;

        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {formattedText}
          </strong>
        ) : (
          formattedText // ✅ Return as a string directly without `{}` wrapping
        );
      },
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      render: (value: any, record: any) => {
        const isModified = record?.isModified?.unitPrice;
        const formattedValue = value
          ? formatCurrency(parseFloat(value), "INR")
          : "N/A";

        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {formattedValue}
          </strong>
        ) : (
          formattedValue
        );
      },
    },
    {
      title: "Basic Amount",
      key: "basicAmount",
      dataIndex: "basicAmount",
      width: 150,
      render: (value: any, record: any) => {
        const isModified = record?.isModified?.basicAmount;
        const formattedValue = value
          ? formatCurrency(parseFloat(value), "INR")
          : "N/A";

        return isModified ? (
          <strong style={{ background: "#e4cfcf", padding: "2px" }}>
            {formattedValue}
          </strong>
        ) : (
          formattedValue
        );
      },
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any, record: any) => {
            const isModified = record?.isModified?.cgstPercentage;
            const value = !Number(record?.igst) ? `${Number(text)}%` : "N/A";

            return isModified ? (
              <strong style={{ background: "#e4cfcf", padding: "2px" }}>
                {value}
              </strong>
            ) : (
              value
            );
          },
        },

        {
          title: "Amount",
          dataIndex: "cgst",
          key: "cgst",
          width: 150,
          render: (text: any, record: any) => {
            const isModified = record?.isModified?.cgst;
            const value = !Number(record?.igst)
              ? formatCurrency(text, "INR")
              : "N/A";

            return (
              <div style={{ textAlign: "right" }}>
                {isModified ? (
                  <strong style={{ background: "#e4cfcf", padding: "2px" }}>
                    {value}
                  </strong>
                ) : (
                  <span>{value}</span>
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any, record: any) => {
            const isModified = record?.isModified?.sgstPercentage;
            const value = !Number(record?.igst) ? `${Number(text)}%` : "N/A";

            return isModified ? (
              <strong style={{ background: "#e4cfcf", padding: "2px" }}>
                {value}
              </strong>
            ) : (
              value
            );
          },
        },

        {
          title: "Amount",
          dataIndex: "sgst",
          key: "sgst",
          width: 150,
          render: (text: any, record: any) => {
            const isModified = record?.isModified?.sgst;
            const value = !Number(record?.igst)
              ? formatCurrency(text, "INR")
              : "N/A";

            return (
              <div style={{ textAlign: "right" }}>
                {isModified ? (
                  <strong style={{ background: "#e4cfcf", padding: "2px" }}>
                    {value}
                  </strong>
                ) : (
                  <span>{value}</span>
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "%",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          width: 100,
          render: (text: any, record: any) => {
            const isModified = record?.isModified?.igstPercentage;
            const value = Number(record?.igst) ? `${Number(text)}%` : "N/A";

            return isModified ? (
              <strong style={{ background: "#e4cfcf", padding: "2px" }}>
                {value}
              </strong>
            ) : (
              value
            );
          },
        },

        {
          title: "Amount",
          dataIndex: "igst",
          key: "igst",
          width: 150,
          render: (text: any, record: any) => {
            const isModified = record?.isModified?.igst;
            const value = Number(record?.igst)
              ? formatCurrency(text, "INR")
              : "N/A";

            return (
              <div style={{ textAlign: "right" }}>
                {isModified ? (
                  <strong style={{ background: "#e4cfcf", padding: "2px" }}>
                    {value}
                  </strong>
                ) : (
                  <span>{value}</span>
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Line Total",
      key: "lineTotal",
      dataIndex: "lineTotal",
      width: 150,
      render: (text: any, record: any) => {
        const isModified = record?.isModified?.grossTotal;
        const value = FormatCurrency(Number(record?.grossTotal), "INR");

        return (
          <p className="text-right">
            {isModified ? (
              <strong style={{ background: "#e4cfcf", padding: "2px" }}>
                {value}
              </strong>
            ) : (
              value
            )}
          </p>
        );
      },
    },
  ];

  return (
    <>
      {loading == true ? (
        <>
          <Flex align="center" gap="middle" className="pageLoader">
            <Spin size="large" className="m-auto" />
          </Flex>
        </>
      ) : apiResponse ? (
        <VersionControlDefaultTemplate
          displayFields={displayFields}
          title="Purchase Orders Version History"
          backPath="/view-purchase-order"
          apiResponse={apiResponse}
          materialColumns={materialColumns}
          extraMaterialInfo={true}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Empty description="No Data Found " />
        </div>
      )}
    </>
  );
};
export default POVersionControl;

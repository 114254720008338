import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import { formatCurrency } from "../../../utils/Utils";

function PurchaseBySiteReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      fixed: "left",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "Suppliers",
      dataIndex: "uniqueSuppliers",
      key: "uniqueSuppliers",
    },
    {
      title: "PO Count",
      dataIndex: "poCount",
      key: "poCount",
    },
    {
      title: "Invoice Count",
      dataIndex: "invoiceCount",
      key: "invoiceCount",
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithOutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/order-details-by-site-report/${record?.siteId}`, {
                state: {
                  name: record?.siteName,
                },
              });
            }}
          >
            {" "}
            {formatCurrency(text?.toString(),record?.currencyCode)}{" "}
          </span>
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/order-details-by-site-report/${record?.siteId}`, {
                state: {
                  name: record?.siteName,
                },
              });
            }}
          >
            {" "}
            {formatCurrency(text?.toString(),record?.currencyCode)}{" "}
          </span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Purchase By Site"
      pageDescription="Overview of purchases categorized by construction site locations"
      moduleKey="purchaseBySiteReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-purchase-reports"
      extra={{
        tableScrollX: 1000,
      }}
    />
  );
}

export default PurchaseBySiteReport;

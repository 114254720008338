import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { ChatItemHeader } from "../../../../pages/app/chat/ChatPartials";
import { chatData } from "../../../../pages/app/chat/ChatData";
import { Link } from "react-router-dom";
import { RootState } from "../../../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { clearNotification } from "../../../../store/slices/misc/notificationSlice";
import miscService from "../../../../api/misc/misc";
import { Flex, Spin } from "antd";

const ChatDropdown = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const toggle = () => {
    setOpen(!open);
    if (!open && hasNewNotification) {
      dispatch(clearNotification());
    }
  };
  const hasNewNotification = useSelector(
    (state) => state.notification.hasNewNotification
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      fetchNotifications();
    }
  }, [open]);

  const fetchNotifications = async () => {
    setLoading(true);
    setError(null);
    let paramData = {
      sortOrder: "DESC",
      direction: "P",
      pageSize: 5,
      pageDiff: 0,
      viewed: 0,
    };
    try {
      const { response, status } = await miscService.notificationsList(
        paramData
      );
      if (status) {
        console.log("response", response.list);
        setNotifications(response.list);
      }
    } catch (err) {
      setError("Failed to load notifications.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={(ev) => ev.preventDefault()}
        className="nk-quick-nav-icon"
      >
        <div className={`icon-status ${hasNewNotification ? "vibrating" : ""}`}>
          <Icon name="ni ni-bell" />
          {hasNewNotification && <span className="notification-dot"></span>}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl">
        <div className="dropdown-head" onClick={() => toggle()}>
          <span className="sub-title nk-dropdown-title">Notifications</span>
          {/* <Link to={`${process.env.PUBLIC_URL}/user-profile-setting`}>Setting</Link> */}
        </div>
        <div className="dropdown-body">
          {loading ? (
            <Flex
              align="center"
              gap="middle"
              style={{ height: "360px", margin: "auto" }}
            >
              <Spin size="large" className="m-auto" />
            </Flex>
          ) : error ? (
            <div className="text-center text-danger">{error}</div>
          ) : notifications && notifications.length > 0 ? (
            <ul className="chat-list">
              {notifications?.map((item, i) => {
                return (
                  <ChatItemHeader
                    key={item.notificationId}
                    item={item}
                    toggle={toggle}
                  />
                );
              })}
            </ul>
          ) : (
            <div className="text-center">No notifications available.</div>
          )}
        </div>
        <div className="dropdown-foot center" onClick={() => toggle()}>
          <Link to={`${process.env.PUBLIC_URL}/notifications`}>View All</Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ChatDropdown;

import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useLocation, useNavigate, useParams } from "react-router";
import FormatDate from "../../masters/DateFormater";
import { formatCurrency} from "../../../utils/Utils";

function PurchaseByMaterialDetailReport() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const name = location.state?.name;
  const uomId = location.state?.uomId;

  const columns: any = [
    {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: {
                  previousPath: `/purchase-by-material-reports-detail-report/${id}`,
                  name,
                  uomId
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width:150,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/purchase-by-material-reports-detail-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width:150,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 250,
    },
    // {
    //   key: "unitPrice",
    //   title: "Unit Price",
    //   dataIndex: "unitPrice",
    //   render: (text: any, record: any) => (
    //     <div style={{ textAlign: "right" }}>
    //       <Tooltip title={formatCurrency(text?.toString(),record?.currencyCode)}>
    //         {formatCurrency2(text?.toString(), record?.currencyCode)}
    //         <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
    //       </Tooltip>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Quantity",
    //   dataIndex: "quantity",
    //   key: "quantity",
    //   render: (quantity: string, record: any) => (
    //     <span>
    //       {quantity} {record?.quantityUom}
    //     </span>
    //   ),
    // },
    {
      title: "Value Without Tax",
      dataIndex: "amountWithoutTax",
      key: "amountWithOutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Value With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Purchases, Detail Report`}
      moduleKey="purchaseByMaterialDetailReport"
      columns={columns}
      filtersOption={{
        site: false,
        contractor: false,
        dateRange: false,
      }}
      previousPath="/purchase-by-material-reports-list"
      extra={{
        id: id,
        tableScrollX: 1300,
        customData:{
          uomId
        }
      }}
    />
  );
}

export default PurchaseByMaterialDetailReport;

import React, { useState, useEffect, FC, useCallback, useRef } from "react";
import {
  Badge,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Card,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import costCenterApis from "../../api/master/costCenter";
import GeneralLedgerApis from "../../api/master/generalLedger";
import departments from "../../api/master/departments";
import { FormProvider, useForm } from "react-hook-form";
import {
  PaperClipOutlined,
  DownloadOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Form,
  TableColumnsType,
  Input,
  Table,
  Timeline,
  Collapse as AntCollapse,
  Descriptions,
  Modal as AntModal,
  Upload,
  Flex,
  Spin,
  Empty,
} from "antd";
import { nanoid } from "nanoid";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import { Popover, Steps } from "antd";
import Swal from "sweetalert2";
import CropperComponent from "../components/CropperComponent";
import SignatureModal from "./SignatureModal";
import dayjs from "dayjs";
import ChangeToBuyerDrawer from "./ChangeToBuyerModal";
import Misc from "../masters/Misc";
import { UploadFile } from "antd/es/upload/interface";
import PurchaseOrderAside from "./PurchaseOrderAside";
import statusMapper from "./StatusMapper";
import { useNavigate } from "react-router";
import usersApis from "../../api/master/users";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import budgetApis from "../../api/master/budget";
import purchaseOrderCreation from "../../api/purchase-orders/pruchaseOrderCreation";
import { useSelector } from "react-redux";
import PdfGenerator from "./TestPDF";
import { formatCurrency, formatDecimal } from "../../utils/Utils";
import FormTextArea from "../form-components/TextAreaComponent";
import EmailCreationModal from "../EmailCreationModal";
import contractorsApis from "../../api/master/contractors";
import supplierApis from "../../api/master/supplier";
import MaterialModal from "./EditPOMaterialModal";
const { Dragger } = Upload;
const { Panel } = AntCollapse;
const { FormatCurrency, beforeUpload } = Misc;

interface SelectOptions {
  label: string;
  value: string;
}

type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
}

const ViewOrderDetails: FC<any> = ({ orderNumber, previousPath, extra }) => {
  const pdfBlobData: any = useRef(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [shareOptionsToggle, setShareOptionsToggle] = useState(false);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const { data: currentUser } = useSelector((state: any) => state.currentUser);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableApprove, setDisableApprove] = useState(false);
  const [disableReject, setDisableReject] = useState(false);
  const [disableAccept, setDisableAccept] = useState(false);
  const [disableRejectS, setDisableRejectS] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [OriginalData, setOriginalData] = useState<any>({});
  const [poStatus, setPoStatus] = useState<any>("");
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [supplierList, setSupplierList] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [deletedAttachments, setDeletedAttachments] = useState<any>([]);
  const [newAttachments, setNewAttachments] = useState<any>([]);
  const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
  const [showCropModel, setShowCropModel] = useState<boolean>(false);
  const [signature, setSignature] = useState<any>([]);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [crRejectModal, setCrRejectModal] = useState<boolean>(false);
  const [disapprovePO, setDisapprovePO] = useState<boolean>(false);
  const [supplierOptions, setSupplierOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [generalLedgerOptions, setGeneralLedgerOptions] = useState<
    SelectOptions[]
  >([]);
  const [departmentOptions, setDepartmentOptions] = useState<SelectOptions[]>(
    []
  );
  const [budgetOptions, setBudgetOptions] = useState<SelectOptions[]>([]);
  const [contractorOptions, setContractorOptions] = useState<SelectOptions[]>(
    []
  );

  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "",
    currency_symbol: "",
    currency_format: "",
    example: "",
  });

  const [DMS, setDMS] = useState<any>([]);
  const [editLineRow, setEditLineRow] = useState<any>();
  const [changeToBuyerData, setChangeToBuyerData] = useState<any>();
  const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [newTermsAndConditions, setNewTermsAndConditions] = useState<any>("");
  const [instructions, setInstructions] = useState("");
  const [newInstructions, setNewInstructions] = useState<any>("");
  const [isInstructionsChanged, setIsInstructionsChanged] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverIds, setApproverIds] = useState<any>([]);
  const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
    useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const siteIdWatch = watch("siteId");
  const termsAndConditionsWatch = watch("termsAndConditions");
  const instructionsWatch = watch("instructions");
  useEffect(() => {
    if (termsAndConditionsWatch) {
      setNewTermsAndConditions(termsAndConditionsWatch);
    }
  }, [termsAndConditionsWatch]);

  useEffect(() => {
    if (instructionsWatch) {
      setNewInstructions(instructionsWatch);
    }
  }, [instructionsWatch]);

  useEffect(() => {
    if (siteIdWatch) {
      fetchBudgetDetails(siteIdWatch);
      fetchContractorList(siteIdWatch + "");
    }
  }, [siteIdWatch]);

  const updatePdfBlobDataRef = (newData: any) => {
    try {
      pdfBlobData.current = newData;
    } catch (error) {}
  };

  const TriggerEmail = async (emailData: any, _methods: any) => {
    try {
      const formData = new FormData();
      formData.append("msgType", "purchaseOrderCreated");
      formData.append("logo", currentUser?.orgLogo);
      formData.append("email", emailData?.to);
      formData.append("cc", emailData?.cc);
      formData.append("bcc", emailData?.bcc);
      if (emailData?.attachments?.length > 0) {
        emailData?.attachments?.forEach((attachment: any, index: number) => {
          if (!attachment) {
            return;
          }
          let file: any = null;
          if (index == 0) {
            file = new File(
              [pdfBlobData.current],
              OriginalData?.orderId + ".pdf",
              {
                type: "application/pdf",
              }
            );
          } else {
            file = attachment?.originFileObj;
          }
          formData.append(`files`, file);
        });
      }
      const response: any = await purchaseOrderApis.sendOrderEmailNotification(
        orderNumber,
        formData
      );
      const { data, status } = response;
      if (status) {
        Swal.fire("Success", "Email has been sent successfully", "success");
        _methods.reset();
        setShowEmailModal(false);
      }
    } catch (error) {
      console.log(error);
      setShowEmailModal(false);
    }
  };

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Purchase Order"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);

  useEffect(() => {
    if (disapprovePO == false) {
      setDisableReject(false);
    }
  }, [disapprovePO]);
  useEffect(() => {
    if (crRejectModal == false) {
      setDisableRejectS(false);
    }
  }, [crRejectModal]);
  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setMaterialCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBudgetDetails = async (siteId: any) => {
    try {
      const { data, status } = await budgetApis.getBudgetBySite(siteId);

      if (status) {
        let budgetOptions = data.map((item: any) => ({
          label: item.budgetName,
          value: item.headerId,
        }));
        setBudgetOptions(budgetOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchContractorList = async (siteId: any) => {
    try {
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: siteId,
      });
      if (status) {
        let contractorOptions = data.map((item: any) => ({
          label: item?.companyName,
          value: item?.contractorID?.toString(),
        }));
        setContractorOptions(contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
  // const [data, setData] = useState<any>([]);

  useEffect(() => {
    fetchCategorys();
    fetchOrderDetails();
    fetchDeliveryAddress();
    fetchSupplier();
    fetchCostCenter();
    fetchSites();
    fetchGeneralLedger();
    fetchDepartment();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const handleAttachmentChange = (info: { fileList: UploadFile[] }) => {
    setNewAttachments((pre: any) => {
      return [...pre, ...info.fileList];
    });
    setShowSubmitBtm(true);
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const fetchOrderDetails = async () => {
    try {
      const res = await purchaseOrderApis.fetchOrder(orderNumber);
      const { data, status, message } = res;
      if (status) {
        setOriginalData(data);
        setPoStatus(data?.orderStatus);
        if (data?.orderApprovals) {
          let ids = data?.orderApprovals
            ?.filter((item: any) => item?.status != "Approved")
            ?.map((item: any) => item?.userId);
          setApproverIds(ids);
        }

        const {
          currencyCode,
          currencySymbol,
          currencyFormat,
          dms,
          signatureLabel,
          igst,
        } = data;
        if (Number(igst) == 0) {
          setTaxConfiguration({
            taxType: "GST",
            gstType: "CGST+SGST",
            cess: [
              // {
              //   cessName: "Cess",
              //   cessType: "CGST",
              //   cessPercentage: "0",
              //   cessAmount: "0",
              // },
              // {
              //   cessName: "Cess",
              //   cessType: "SGST",
              //   cessPercentage: "0",
              //   cessAmount: "0",
              // },
            ],
          });
        } else {
          setTaxConfiguration({
            taxType: "GST",
            gstType: "IGST",
            cess: [
              // {
              //   cessName: "Cess",
              //   cessType: "IGST",
              //   cessPercentage: "0",
              //   cessAmount: "0",
              // },
            ],
          });
        }

        if (dms?.length > 0) {
          setDMS(dms);
          const _signature = dms?.find(
            (item: any) => item.moduleFileType == "signature"
          );
          if (_signature) {
            setSignature({
              docId: _signature?.docId,
              fileName: _signature?.fileName,
              image: _signature?.filePath,
              moduleFileType: _signature?.moduleFileType,
              module: _signature?.module,
              label: signatureLabel,
              isActive: true,
            });
          }
          const _termsAndConditions = dms?.find(
            (item: any) => item.moduleFileType == "termsAndConditions"
          );
          if (_termsAndConditions) {
            const data: any = await fetchHtmlContentFromUrl(
              _termsAndConditions?.filePath
            );
            setTermsAndConditions(data);
            setNewTermsAndConditions(data);
          }
          const _instructions = dms?.find(
            (item: any) => item.moduleFileType == "instructions"
          );
          if (_instructions) {
            const data: any = await fetchHtmlContentFromUrl(
              _instructions?.filePath
            );
            setInstructions(data);
            setNewInstructions(data);
          }
          const _attachments = dms?.filter(
            (item: any) => item.moduleFileType == "attachments" && item.docHash
          );
          if (_attachments) {
            setAttachments(
              _attachments.map((attachment: any) => ({
                docId: attachment?.docId,
                name: attachment?.fileName,
                link: attachment?.filePath,
                moduleFileType: attachment?.moduleFileType,
                module: attachment?.module,
                label: "attachments",
                isActive: true,
              }))
            );
          }
        }
        const LineItems = data?.lineItems?.map((item: any) => {
          const file = dms?.find((doc: any) => doc.lineNo == item.lineNo);
          if (file) {
            item.file = file;
            item.attachment = true;
          } else item.attachment = false;
          return item;
        });
        setMaterialData({ ...data, lineItems: LineItems });
        // setData(LineItems);

        setShowSubmitBtm(false);
        setDeletedAttachments([]);
        setNewAttachments([]);
        setCurrency({
          description: currencyCode,
          currency_code: currencyCode,
          currency_symbol: currencySymbol,
          currency_format: currencyFormat,
          example: currencyCode,
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ShowAddressPopOver = ({ type, id }: any) => {
    if (!id) {
      return <></>;
    }
    let address = deliveryAddressList?.find((item: any) => item?.id == id);
    if (!address) {
      return <></>;
    }
    const { cin, gstin, pan, phoneNumber } = OriginalData?.orgDetails;
    return (
      <ul>
        <li>
          <strong style={{ color: "#2263b3" }}>
            {address?.deliveryLocationName}
          </strong>
        </li>
        <li>{address?.addressLine1}</li>
        <li>{address?.addressLine2}</li>
        <li>
          {address?.city}, {address?.stateName}, {address?.countryName} -{" "}
          {address?.pincode}
        </li>
        {type == "billing" && (
          <>
            <li>
              <strong>GSTIN</strong> : {gstin}
            </li>
            <li>
              <strong>CIN</strong> : {cin}
            </li>
            <li>
              <strong>PAN</strong> : {pan}
            </li>
            <li>
              <strong>Phone</strong> : {phoneNumber}
            </li>
          </>
        )}
        {type == "delivery" && (
          <>
            <li>
              <strong>Contact Person</strong> : {address?.contactPersonName}
            </li>
            <li>
              <strong>Mobile Number</strong> : {address?.mobileNumber}
            </li>
          </>
        )}
      </ul>
    );
  };

  const fetchSupplier = async () => {
    try {
      const res: any =
        await purchaseOrderCreation.fetchBuyerLinkedSupplierListMethod();
      const { data, status } = res;
      if (status) {
        let supplierOptions = data.map((item: any) => ({
          label: item?.sellerOrg?.name,
          value: item?.sellerOrg?.orgId?.toString(),
        }));
        setSupplierOptions(supplierOptions);
        setSupplierList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCostCenter = async () => {
    try {
      const { data, status, message } = await costCenterApis.fetchCostCenter();
      if (status) {
        let costCenterOptions = data.map((item: any) => {
          return { label: item.costCenterName, value: item.id.toString() };
        });
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        setCurrentUserId(data?.id);
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchGeneralLedger = async () => {
    try {
      const { data, status, message } = await GeneralLedgerApis.fetchLedger();
      if (status) {
        let generalLedgerOptions = data.map((item: any) => {
          return { label: item.ledgerName, value: item.ledgerID.toString() };
        });
        setGeneralLedgerOptions(generalLedgerOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDepartment = async () => {
    try {
      const { data, status, message } = await departments.fetchDeptData();
      if (status) {
        let departmentOptions = data.map((item: any) => {
          return { label: item.deptName, value: item.deptID.toString() };
        });
        setDepartmentOptions(departmentOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      ...Table.EXPAND_COLUMN,
      fixed: "left",
    },
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      render: (text) => FormatDate(text),
    },
    {
      title: "HSN / SAC",
      dataIndex: "hsnCode",
      key: "hsnCode",
      width: 100,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record: any) =>
        `${Number(text).toFixed(2)} ${record?.uom}`,
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      render: (text) => FormatCurrency(text, ""),
    },
    {
      title: "Basic Amount",
      key: "amount",
      width: 150,
      render: (text, record) => (
        <p className="text-right">
          {FormatCurrency(
            Number(record?.unitPrice) * Number(record?.quantity),
            ""
          )}
        </p>
      ),
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any, record: any) =>
            `${!Number(record?.igst) ? Number(text) + "%" : "N/A"}`,
        },
        {
          title: "Amount",
          dataIndex: "cgst",
          key: "cgst",
          width: 150,
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>
                {!Number(record?.igst) ? formatCurrency(text, "INR") : "N/A"}
              </span>
            </div>
          ),
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any, record: any) =>
            `${!Number(record?.igst) ? Number(text) + "%" : "N/A"}`,
        },
        {
          title: "Amount",
          dataIndex: "sgst",
          key: "sgst",
          width: 150,
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>
                {!Number(record?.igst) ? formatCurrency(text, "INR") : "N/A"}
              </span>
            </div>
          ),
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "%",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          width: 100,
          render: (text: any, record: any) =>
            `${Number(record?.igst) ? Number(text) + "%" : "N/A"}`,
        },
        {
          title: "Amount",
          dataIndex: "igst",
          key: "igst",
          width: 150,
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>
                {Number(record?.igst) ? formatCurrency(text, "INR") : "N/A"}
              </span>
            </div>
          ),
        },
      ],
    },
    {
      title: "Line Total",
      key: "lineTotal",
      width: 150,
      render: (text, record: any) => {
        return (
          <p className="text-right">
            {FormatCurrency(Number(record?.grossTotal), "")}
          </p>
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      hidden: [
        "Rejected",
        "Rejected by Supplier",
        "Short Closed",
        "To be Dispatched",
        "To be Delivered",
        "Partially Delivered",
        "Fully Delivered",
      ].includes(poStatus),
      render: (text, record: any, index) =>
        [
          "Rejected",
          "Rejected by Supplier",
          "Short Closed",
          "To be Dispatched",
          "To be Delivered",
          "Partially Delivered",
          "Fully Delivered",
        ].includes(poStatus) ? (
          <>
            <Button
              size="sm"
              color=""
              onClick={() => {
                setEditLineRow(record);
              }}
            >
              <Icon name="focus" />
            </Button>
          </>
        ) : ["Changes to Buyer"].includes(poStatus) ? (
          <Button
            size="sm"
            color=""
            onClick={() => {
              setChangeToBuyerData(record);
            }}
          >
            <Icon name="focus" />
          </Button>
        ) : (
          <Button
            size="sm"
            color=""
            onClick={() => {
              setEditLineRow(record);
            }}
          >
            <Icon name="edit" />
          </Button>
        ),
    },
  ]);

  useEffect(() => {
    if (editLineRow) {
      setAddNewRowModel(true);
    }
  }, [editLineRow]);

  const ShowSupplierInfo = ({ id }: any) => {
    let supplier = OriginalData?.supplierDetails;
    if (!supplier) {
      return <></>;
    }
    return (
      <ul>
        <li>
          <strong>Name</strong> : {OriginalData?.supplierName}
        </li>
        <li>
          <strong>GSTIN</strong> : {supplier.gstin}
        </li>
        <li>
          <strong>PAN</strong> : {supplier.pan}
        </li>
        <li>
          <strong>CIN</strong> : {supplier.cin}
        </li>
      </ul>
    );
  };

  const updateColumnsWithCurrencySymbol = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (col.dataIndex == "unitPrice" || col.dataIndex == "netAmount") {
          return {
            ...col,
            render: (text: any) => (
              <div className="justify-end">
                {FormatCurrency(text, currency?.currency_code)}
              </div>
            ),
          };
        }
        if (col.key == "amount") {
          return {
            title: "Basic Amount",
            key: "amount",
            width: 150,
            render: (text: any, record: any) => (
              <p className="justify-end">
                {FormatCurrency(
                  Number(record?.unitPrice) * Number(record?.quantity),
                  currency?.currency_code
                )}
              </p>
            ),
          };
        }
        if (col.key == "lineTotal") {
          return {
            title: "Line Total",
            key: "lineTotal",
            width: 150,
            fixed: "right",
            render: (text: any, record: any) => {
              return (
                <div className="justify-end">
                  {FormatCurrency(
                    Number(record?.grossTotal),
                    currency?.currency_code
                  )}
                </div>
              );
            },
          };
        }
        if (col.children) {
          let children = col.children.map((child: any) => {
            if (child.title == "Amount") {
              return {
                ...child,
                // render: (text: any) => (
                //   <div className="justify-end">
                //     {FormatCurrency(text, currency?.currency_code)}
                //   </div>
                // ),
              };
            }
            return child;
          });
          return {
            ...col,
            children,
          };
        }
        if (
          OriginalData?.needByDateType == "Order Level" &&
          col.dataIndex == "needByDate"
        ) {
          return {
            ...col,
            hidden: true,
          };
        } else if (col.dataIndex == "needByDate") {
          return {
            ...col,
            hidden: false,
          };
        }

        return col;
      });
    });
  }, [currency]);

  useEffect(() => {
    updateColumnsWithCurrencySymbol();
  }, [currency, updateColumnsWithCurrencySymbol]);

  const updateColumnsWithPoStatus = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (col.key == "operation") {
          return {
            title: "Action",
            key: "operation",
            fixed: "right",
            width: 80,
            hidden:
              [
                "Rejected",
                "Rejected by Supplier",
                "Short Closed",
                "To be Dispatched",
                "To be Delivered",
                "Partially Delivered",
                "Fully Delivered",
              ].includes(poStatus) || allowedActions?.edit != 1,
            render: (text: any, record: any, index: any) =>
              [
                "Rejected",
                "Rejected by Supplier",
                "Short Closed",
                "To be Dispatched",
                "To be Delivered",
                "Partially Delivered",
                "Fully Delivered",
              ].includes(poStatus) ? (
                <>
                  <Button
                    size="sm"
                    color=""
                    onClick={() => {
                      setEditLineRow(record);
                    }}
                  >
                    <Icon name="focus" />
                  </Button>
                </>
              ) : ["Changes to Buyer"].includes(poStatus) ? (
                <Button
                  size="sm"
                  color=""
                  onClick={() => {
                    setChangeToBuyerData(record);
                  }}
                >
                  <Icon name="focus" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  color=""
                  onClick={() => {
                    setEditLineRow(record);
                  }}
                >
                  <Icon name="edit" />
                </Button>
              ),
          };
        }
        return col;
      });
    });
  }, [poStatus]);

  useEffect(() => {
    updateColumnsWithPoStatus();
  }, [poStatus, updateColumnsWithPoStatus]);

  const updateColmnWithTaxConfiguration = useCallback(() => {
    // alert(taxConfiguration?.taxType);
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (taxConfiguration?.gstType == "IGST") {
          if (col.title == "CGST" || col.title == "SGST") {
            return {
              ...col,
              hidden: true,
            };
          }
          if (col.title == "IGST") {
            return {
              ...col,
              hidden: false,
            };
          }
        } else {
          if (col.title == "IGST") {
            return {
              ...col,
              hidden: true,
            };
          }
          if (col.title == "CGST" || col.title == "SGST") {
            return {
              ...col,
              hidden: false,
            };
          }
        }
        return col;
      });
    });
  }, [taxConfiguration]);

  useEffect(() => {
    updateColmnWithTaxConfiguration();
  }, [taxConfiguration, updateColmnWithTaxConfiguration]);

  const ApprovalTimelineContent = () => {
    const approvalsData = materialData?.orderApprovals;

    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "Approved";
              const isRejected = approval.status == "Rejected";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Badge color="outline-success">Yes</Badge>
        </span>
      </Popover>
    );
  };

  const SubmitChanges = async () => {
    try {
      const _formData = new FormData();
      const updatedLineItems: any = [];
      const newLineItems: any = [];
      const deletedLineItems: any = [];

      const newfiledata: any = [];
      const deletedFileData: any = [...deletedAttachments];
      const updatedFileData: any = [];

      materialData?.lineItems?.forEach((data: any) => {
        if (!data?.lineNo) {
          // new item
          newLineItems.push({
            uuid: data.uuid,
            needByDate: dayjs(data?.needByDate).format("YYYY-MM-DD"),
            materialId: Number(data.materialId),
            hsnCode: data?.hsnCode ? Number(data.hsnCode) : null,
            categoryId: Number(data.categoryId),
            description: data.description,
            deliveryAddressId: Number(data.deliveryAddressId),
            quantity: Number(data.quantity),
            uomId: Number(data.uomId),
            unitPrice: Number(data.unitPrice),
            discountPercentage: 0,
            sgstPercentage: Number(data.sgstPercentage),
            cgstPercentage: Number(data.cgstPercentage),
            igstPercentage: Number(data.igstPercentage),
            additionalCharges: 0,
          });
          if (data?.file && data?.file[0]) {
            _formData.append("files", data?.file[0]);
            newfiledata.push({
              indexOfFileLocated: newfiledata.length,
              module: "po",
              purpose: "special instructions",
              level: "Line Level",
              uniqueId: data.uuid,
              action: "Create",
            });
          }
        } else {
          // check for update
          const OData = OriginalData?.lineItems?.find(
            (item: any) => item.lineNo == data.lineNo
          );

          let updatedItem: any = {};

          if (Number(OData.quantity) !== Number(data.quantity)) {
            updatedItem.quantity = Number(data.quantity);
          }

          if (Number(OData.unitPrice) !== Number(data.unitPrice)) {
            updatedItem.unitPrice = Number(data.unitPrice);
          }

          if (Number(OData.hsnCode) !== Number(data.hsnCode)) {
            updatedItem.hsnCode = data.hsnCode;
          }

          if (Number(OData.categoryId) !== Number(data.categoryId)) {
            updatedItem.categoryId = Number(data.categoryId);
          }

          if (OData.description !== data.description) {
            updatedItem.description = data.description;
          }

          if (Number(OData.uomId) !== Number(data.uomId)) {
            updatedItem.uomId = Number(data.uomId);
          }

          if (Number(OData.discountPercentage) !== 0) {
            updatedItem.discountPercentage = 0;
          }

          if (Number(OData.sgstPercentage) !== Number(data.sgstPercentage)) {
            updatedItem.sgstPercentage = Number(data.sgstPercentage);
          }

          if (Number(OData.cgstPercentage) !== Number(data.cgstPercentage)) {
            updatedItem.cgstPercentage = Number(data.cgstPercentage);
          }

          if (Number(OData.igstPercentage) !== Number(data.igstPercentage)) {
            updatedItem.igstPercentage = Number(data.igstPercentage);
          }

          if (Number(OData.additionalCharges) !== 0) {
            updatedItem.additionalCharges = 0;
          }

          if (Object.keys(updatedItem).length > 0) {
            if (OriginalData?.needByDateType == "Line Level") {
              updatedItem.needByDate = dayjs(data.needByDate).format(
                "YYYY-MM-DD"
              );
            }
            if (OriginalData?.deliveryAddressType == "Line Level") {
              updatedItem.deliveryAddressId = Number(data.deliveryAddressId);
            }
            updatedItem.materialId = Number(data.materialId);
            updatedItem.lineNo = data.lineNo;
            updatedItem.uuid = OData.uuid;
            updatedLineItems.push(updatedItem);
          }

          if (data?.file && data?.file[0]) {
            if (OData?.file?.docId) {
              deletedFileData.push({
                docId: OData?.file?.docId,
              });
            }
            newfiledata.push({
              indexOfFileLocated: newfiledata.length,
              module: "po",
              purpose: "special instructions",
              level: "Line Level",
              uniqueId: OData.uuid,
              action: "Create",
            });
            console.log("newfiledata");

            _formData.append("files", data.file[0]);
          }
        }
      });

      newAttachments.forEach((attachment: any, index: number) => {
        if (!attachment) {
          return;
        }
        const file = attachment?.originFileObj;

        _formData.append("files", file);
        newfiledata.push({
          indexOfFileLocated: newfiledata.length,
          module: "po",
          purpose: "attachments",
          level: "Header Level",
          uniqueId: "",
          action: "Create",
        });
      });

      const existingLineItems: any = [];
      OriginalData?.lineItems?.forEach((item: any) => {
        if (item?.lineNo) {
          const obj: any = {
            lineNo: item.lineNo,
            uuid: item.uuid,
            materialId: item.materialId,
          };
          if (OriginalData?.needByDateType == "Line Level") {
            obj.needByDate = item.needByDate;
          }
          if (OriginalData?.deliveryAddressType == "Line Level") {
            obj.deliveryAddressId = item.deliveryAddressId;
          }
          existingLineItems.push(obj);
        }
      });

      const submitData: any = {
        mode: ["Pending Approval", "Approved"].includes(
          OriginalData?.orderStatus
        )
          ? "U"
          : "UCR",
        needByDateType: OriginalData?.needByDateType,
        deliveryAddressType: OriginalData?.deliveryAddressType,
        combinedLineItems: {
          existingLineItems,
          updatedLineItems,
          newLineItems,
          deletedLineItems,
        },
      };
      if (OriginalData?.needByDateType != "Line Level") {
        submitData.needByDate = OriginalData?.needByDate;
      }
      if (OriginalData?.deliveryAddressType != "Line Level") {
        submitData.deliveryAddressId = OriginalData?.deliveryAddressId;
      }

      const addIfDifferent = (
        key: string,
        dataValue: any,
        materialValue: any
      ) => {
        if (dataValue != materialValue) {
          submitData[key] = dataValue;
        }
      };

      const checkAndAdd = (key: string, dataValue: any, materialValue: any) => {
        // if (OriginalData.hasOwnProperty(key)) {
        addIfDifferent(key, dataValue, materialValue);
        // }
      };

      checkAndAdd(
        "supplierId",
        Number(materialData?.supplierId),
        Number(OriginalData?.supplierId)
      );
      checkAndAdd(
        "description",
        materialData?.description,
        OriginalData?.description
      );
      checkAndAdd(
        "costCenterId",
        materialData?.costCenterId ? Number(materialData?.costCenterId) : null,
        OriginalData?.costCenterId ? Number(OriginalData?.costCenterId) : null
      );
      checkAndAdd(
        "siteId",
        Number(materialData?.siteId),
        Number(OriginalData?.siteId)
      );
      checkAndAdd(
        "ledgerId",
        materialData?.ledgerId ? Number(materialData?.ledgerId) : null,
        OriginalData?.ledgerId ? Number(OriginalData?.ledgerId) : null
      );
      checkAndAdd(
        "departmentId",
        Number(materialData?.departmentId),
        Number(OriginalData?.departmentId)
      );
      checkAndAdd(
        "budgetId",
        Number(materialData?.budgetId),
        Number(OriginalData?.budgetId)
      );
      checkAndAdd(
        "contractorId",
        materialData?.contractorId ? Number(materialData?.contractorId) : null,
        OriginalData?.contractorId ? Number(OriginalData?.contractorId) : null
      );
      if (
        materialData?.paymentTerms != OriginalData?.paymentTerms ||
        materialData?.advancePercentage != OriginalData?.advancePercentage ||
        materialData?.creditDays != OriginalData?.creditDays
      ) {
        if (materialData?.paymentTerms == "Cash and Carry") {
          submitData["paymentTerms"] = materialData?.paymentTerms;
        } else if (materialData?.paymentTerms == "Credit") {
          submitData["paymentTerms"] = materialData?.paymentTerms;
          submitData["creditDays"] = Number(materialData?.creditDays);
        } else if (materialData?.paymentTerms == "Advance") {
          submitData["paymentTerms"] = materialData?.paymentTerms;
          submitData["advancePercentage"] = Number(
            materialData?.advancePercentage
          );
          submitData["creditDays"] = Number(materialData?.creditDays);
        }
      }
      checkAndAdd(
        "inwardRequired",
        materialData?.inwardRequired,
        OriginalData?.inwardRequired
      );
      if (
        dayjs(OriginalData?.needByDate).format("YYYY-MM-DD") !==
        dayjs(materialData?.needByDate).format("YYYY-MM-DD")
      ) {
        submitData.needByDate = dayjs(materialData?.needByDate).format(
          "YYYY-MM-DD"
        );
      }
      checkAndAdd(
        "needByDateType",
        materialData?.needByDateType,
        OriginalData?.needByDateType
      );
      if (OriginalData?.needByDateType == "order Level") {
        checkAndAdd(
          "needByDate",
          materialData?.needByDate,
          OriginalData?.needByDate
        );
      }
      checkAndAdd(
        "billingAddressId",
        Number(materialData?.billingAddressId),
        Number(OriginalData?.billingAddressId)
      );
      checkAndAdd(
        "deliveryAddressType",
        materialData?.deliveryAddressType,
        OriginalData?.deliveryAddressType
      );

      if (isInstructionsChanged) {
        const instructionsFile = new Blob([newInstructions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([instructionsFile], "instructions.html", {
            type: "text/html",
          })
        );
        newfiledata.push({
          indexOfFileLocated: newfiledata.length,
          module: "po",
          purpose: "instructions",
          level: "Header Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
        setIsInstructionsChanged(false);
        const _instructions = DMS?.filter(
          (item: any) => item.moduleFileType == "instructions"
        );
        if (_instructions?.length > 0) {
          _instructions.map((oldFile: any) => {
            deletedFileData.push({
              docId: oldFile.docId,
            });
          });
        }
      }
      if (isTermsAndConditionsChanged) {
        const termsAndConditionsFile = new Blob([newTermsAndConditions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsAndConditions.html", {
            type: "text/html",
          })
        );
        newfiledata.push({
          indexOfFileLocated: newfiledata.length,
          module: "po",
          purpose: "termsAndConditions",
          level: "Header Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
        setIsTermsAndConditionsChanged(false);
        const _termsAndConditions = DMS?.filter(
          (item: any) => item.moduleFileType == "termsAndConditions"
        );
        if (_termsAndConditions?.length > 0) {
          _termsAndConditions.map((oldFile: any) => {
            deletedFileData.push({
              docId: oldFile.docId,
            });
          });
        }
      }

      const filesData = {
        newfiledata,
        deletedFileData,
        updatedFileData,
      };

      _formData.append("filesData", JSON.stringify(filesData));

      _formData.append("files", JSON.stringify([]));
      _formData.append("orderData", JSON.stringify(submitData));
      console.log("submitData", submitData);
      const res = await purchaseOrderApis.purchaseOrderUpdate(
        orderNumber,
        _formData
      );
      const { status, message } = res;
      if (status) {
        fetchOrderDetails();
        Swal.fire("Updated!", message, "success");
        navigate(previousPath, { state: { ...extra?.previousState } });
      } else {
        Swal.fire("Error!", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableSubmit(false);
  };

  const ApprovePo = async () => {
    try {
      const _formData = new FormData();
      _formData.append("filesData", JSON.stringify([]));
      _formData.append("files", JSON.stringify([]));
      const res = await purchaseOrderApis.approvePo(orderNumber, _formData);
      const { status, message } = res;
      if (status) {
        // //refresh();
        fetchOrderDetails();
        Swal.fire("Approved!", message, "success");
        navigate(previousPath, { state: { ...extra?.previousState } });
      } else {
        Swal.fire("Error!", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableApprove(false);
  };

  const disapprovePo = async (rejectedReason: string) => {
    try {
      const res = await purchaseOrderApis.disapprovePo(
        orderNumber,
        rejectedReason
      );
      const { status, message } = res;
      if (status) {
        //refresh();
        fetchOrderDetails();
        Swal.fire("Disapproved!", message, "success");
        navigate(previousPath, { state: { ...extra?.previousState } });
      } else {
        Swal.fire("Error!", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableReject(false);
  };

  const rejectCR = async (reason: string) => {
    try {
      const res = await purchaseOrderApis.rejectChangeRequestOrder(
        orderNumber,
        reason
      );
      const { status, message } = res;
      if (status) {
        fetchOrderDetails();
        Swal.fire("Rejected!", message, "success");
        navigate(previousPath, { state: { ...extra?.previousState } });
      } else {
        Swal.fire("Error!", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AcceptCR = async () => {
    try {
      const res = await purchaseOrderApis.acceptChangeRequestOrder(orderNumber);
      const { status, message } = res;
      if (status) {
        fetchOrderDetails();
        Swal.fire("Accepted!", message, "success");
        navigate(previousPath, { state: { ...extra?.previousState } });
      } else {
        Swal.fire("Error!", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableAccept(false);
  };

  const returnBillingDetails = () => {
    const id = materialData?.billingAddressId;
    if (!id) {
      return {};
    }
    let address = deliveryAddressList?.find((item: any) => item?.id == id);
    if (!address) {
      return {};
    }
    return {
      name: currentUser?.orgName || "",
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      state: address.stateName,
      pincode: address.pincode,
      country: address.countryName,
      gstin: OriginalData?.orgDetails?.gstin || "N/A",
      pan: currentUser?.pan || "",
      cin: currentUser?.cin || "",
    };
  };

  const returnSupplierDetails = () => {
    let seller = OriginalData?.supplierDetails;
    if (!seller) {
      return {};
    }
    console.log("seller::::", seller);
    const {
      city,
      stateInfo,
      countryInfo,
      pincode,
      addressline1,
      addressline2,
    } = seller?.address || {};
    return {
      name: OriginalData?.supplierName || "",
      addressLine1: addressline1 || "",
      addressLine2: addressline2 || "",
      city: city || "",
      state: stateInfo?.stateName || "",
      pincode: pincode || "",
      country: countryInfo?.countryName || "",
      gstin: seller?.gstin || "",
      pan: seller?.pan || "",
      cin: seller?.cin || "",
    };
  };

  const returnPoDetails = () => {
    const id = materialData?.deliveryAddressId;
    let address: any = {};
    if (!id) {
      address = {};
    }
    address = deliveryAddressList?.find((item: any) => item?.id == id);
    return {
      poNumber: OriginalData?.orderId,
      poDate: dayjs(OriginalData?.orderDate).format("DD-MMM-YYYY"),
      terms:
        materialData?.paymentTerms +
        (materialData?.advancePercentage
          ? `(${materialData?.advancePercentage}%)`
          : ""),
      expectedShipmentDate:
        materialData?.needByDateType == "Order Level"
          ? dayjs(materialData?.needByDate).format("DD-MMM-YYYY")
          : "Line Level Date",
      placeofSupply: address?.city || "--",
      contactPersonName: address?.contactPersonName,
      mobileNumber: address?.mobileNumber,
      creditDays:
        materialData?.paymentTerms != "Cash and Carry"
          ? materialData?.creditDays
          : 0,
      deliveryAddressString:
        [
          address?.addressLine1,
          address?.addressLine2,
          address?.city,
          address?.stateName,
          address?.pincode?.toString(),
          address?.countryName,
        ]
          ?.filter(Boolean)
          ?.join(", ") || "--",
      totals: {
        taxableAmount: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.quantity) * Number(b.unitPrice),
            0
          )
        ),
        cgst: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.cgst),
            0
          )
        ),
        sgst: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.sgst),
            0
          )
        ),
        igst: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.igst),
            0
          )
        ),
        total: formatDecimal(
          materialData?.lineItems?.reduce(
            (a: any, b: any) => a + Number(b.grossTotal),
            0
          )
        ),
      },
      lines: materialData?.lineItems?.map((item: any, index: any) => {
        let itemDescription = item?.materialName;
        if (item?.description) {
          itemDescription += "\t" + item?.description;
        }
        if (materialData?.deliveryAddressType === "Line Level") {
          let _address = deliveryAddressList?.find(
            (_item: any) => _item?.id == item?.deliveryAddressId
          );
          if (_address) {
            const addressString = [
              _address.addressLine1,
              _address.addressLine2,
              _address.city,
              _address.stateName,
              _address.pincode?.toString(),
              _address.countryName,
            ]
              .filter(Boolean)
              .join(", ");
            itemDescription += "\n\nDelivery Address: " + addressString;
          }
        }
        if (materialData?.needByDateType != "Order Level") {
          itemDescription +=
            "\n\nNeed By Date: " +
            dayjs(item?.needByDate).format("DD-MMM-YYYY");
        }
        return {
          sno: index + 1,
          itemDescription,
          hsnSac: item?.hsnCode || "",
          rate: formatDecimal(item?.unitPrice),
          cgstPercentage: Number(item?.cgstPercentage),
          cgstAmount: formatDecimal(Number(item?.cgst)),
          sgstPercentage: Number(item?.sgstPercentage),
          sgstAmount: formatDecimal(Number(item?.sgst)),
          igstPercentage: Number(item?.igstPercentage),
          igstAmount: formatDecimal(Number(item?.igst)),
          taxableAmount: formatDecimal(Number(item?.grossTotal)),
          qty: Number(item?.quantity),
          uom: item?.uomLabel,
        };
      }),
    };
  };

  const rejectedData = OriginalData?.orderApprovals?.find(
    (item: any) => item?.status == "Rejected"
  );

  return (
    <>
      <Head title="Purchase Order - Track" />
      <Modal
        isOpen={generalInformationEdit}
        toggle={() => setGeneralInformationEdit(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setGeneralInformationEdit(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>
          {tab === "1" && (
            <>
              <h5 className="title">Update General Information</h5>
              <div style={{ marginTop: "15px" }}></div>

              <FormProvider {...methods}>
                <form
                  className="content clearfix"
                  onSubmit={handleSubmit((data) => {
                    setMaterialData((pre: any) => {
                      const obj: any = {};

                      obj.siteName = sitesOptions.find(
                        (item) => item.value == data?.siteId
                      )?.label;

                      obj.orgName = supplierOptions.find(
                        (item) => item.value == data?.supplierId
                      )?.label;

                      obj.budgetName = budgetOptions.find(
                        (item) => item.value == data?.budgetId
                      )?.label;

                      obj.contractorName = contractorOptions.find(
                        (item) => item.value == data?.contractId
                      )?.label;

                      obj.costCenterName = costCenterOptions.find(
                        (item) => item.value == data?.costCenterId
                      )?.label;

                      obj.ledgerName = generalLedgerOptions.find(
                        (item) => item.value == data?.ledgerId
                      )?.label;

                      obj.departmentName = departmentOptions.find(
                        (item) => item.value == data?.departmentId
                      )?.label;

                      return {
                        ...pre,
                        ...data,
                        ...obj,
                      };
                    });
                    setGeneralInformationEdit(false);
                    setAddNewRowModel(false);
                    setEditLineRow(null);
                    console.log(data);
                    setShowSubmitBtm(true);
                  })}
                >
                  <Row className="gy-4">
                    <Col lg="4">
                      <FormSelect
                        name="supplierId"
                        label={`Supplier/Vendor`}
                        options={supplierOptions}
                        required={true}
                        placeholder="-Select-"
                        disabled={true}
                        tooltipText="Select the supplier or vendor for the purchase order."
                      />
                    </Col>
                    <Col lg="8">
                      <FormInput
                        name="description"
                        label="PO Description"
                        required={true}
                        type="text"
                        minLength={2}
                        maxLength={200}
                        placeholder="Enter Description"
                        tooltipText="Enter a brief description of the purchase order."
                      />
                    </Col>
                  </Row>
                  <Row className="gy-4 mt-1">
                    <Col lg="4" style={{ display: "none" }}>
                      <FormSelect
                        name="costCenterId"
                        label={`Cost Center`}
                        options={costCenterOptions}
                        placeholder="-Select-"
                        tooltipText="Select the cost center associated with this purchase order."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="siteId"
                        label={`Site`}
                        options={sitesOptions}
                        required={true}
                        placeholder="-Select-"
                        disabled={true}
                        tooltipText="Select the site where the materials will be used."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="ledgerId"
                        label={`General Ledger`}
                        options={generalLedgerOptions}
                        placeholder="-Select-"
                        tooltipText="Select the general ledger account for financial tracking."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="departmentId"
                        label={`Department`}
                        options={departmentOptions}
                        required={true}
                        placeholder="-Select-"
                        tooltipText="Select the department responsible for the purchase order."
                      />
                    </Col>
                  </Row>
                  <Row className="gy-4 mt-1">
                    <Col lg="4">
                      <FormSelect
                        name="budgetId"
                        label={`Budget`}
                        options={budgetOptions}
                        required={true}
                        placeholder="-Select-"
                        tooltipText="Select the budget for this purchase order."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="contractorId"
                        label={`Contractor`}
                        options={contractorOptions}
                        placeholder="-Select-"
                        tooltipText="Select the contractor for this purchase order."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="paymentTerms"
                        label="Payment Terms"
                        options={[
                          {
                            label: "Cash & Carry",
                            value: "Cash and Carry",
                          },
                          { label: "Advance", value: "Advance" },
                          { label: "Credit", value: "Credit" },
                        ]}
                        required={true}
                        placeholder="Select Payment Terms"
                        tooltipText="Select the payment terms for the purchase order."
                      />
                    </Col>
                    {watch("paymentTerms") === "Advance" && (
                      <>
                        <Col lg="4">
                          <FormInput
                            name="advancePercentage"
                            label="Advance Percentage"
                            required={true}
                            type="number"
                            placeholder="Enter Advance Percentage"
                            tooltipText="Enter the advance percentage for the purchase order."
                            pattern={/^(0|[1-9][0-9]?|100)$/}
                          />
                        </Col>
                        {watch("advancePercentage") < 100 && (
                          <Col lg="4">
                            <FormInput
                              name="creditDays"
                              label="Credit Days"
                              required={true}
                              type="number"
                              placeholder="Enter Credit Days"
                              tooltipText="Enter the credit days for the purchase order."
                              pattern={/^[1-9][0-9]*$/}
                            />
                          </Col>
                        )}
                      </>
                    )}
                    {watch("paymentTerms") === "Credit" && (
                      <Col lg="4">
                        <FormInput
                          name="creditDays"
                          label="Credit Days"
                          required={true}
                          type="number"
                          placeholder="Enter Credit Days"
                          tooltipText="Enter the credit days for the purchase order."
                          pattern={/^[1-9][0-9]*$/}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-4">
                    <Col lg="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            className="btn btn-primary btn-md"
                            type="submit"
                            color="primary"
                          >
                            Save
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setGeneralInformationEdit(false);
                              reset();
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </form>
              </FormProvider>
            </>
          )}
          {tab == "2" && (
            <>
              <h5 className="title"> Update Delivery Info</h5>
              <div style={{ marginTop: "15px" }}></div>
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit((data) => {
                    setMaterialData((pre: any) => {
                      return {
                        ...pre,
                        ...data,
                      };
                    });
                    setGeneralInformationEdit(false);
                    setAddNewRowModel(false);
                    setEditLineRow(null);
                    console.log(data);
                    setShowSubmitBtm(true);
                  })}
                >
                  <Row className="gy-4 mt-1">
                    <Col lg="4">
                      <FormSelect
                        name="inwardRequired"
                        label="Inward Required ?"
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                        placeholder="-Select-"
                        tooltipText=" If GRN (Inward Process) is required for the line items then you can select 'Yes' otherwise select 'No'."
                        disabled={!["Pending Approval"].includes(poStatus)}
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="needByDateType"
                        disabled
                        label="Line Level Delivery ?"
                        options={[
                          { label: "Yes", value: "Line Level" },
                          { label: "No", value: "Order Level" },
                        ]}
                        placeholder="-Select-"
                        tooltipText="delivery date will be based on the line level. If you select 'No' then delivery date will be based on the Order Level."
                      />
                    </Col>
                    {watch("needByDateType") == "Order Level" && (
                      <Col lg="4">
                        <FormDatePicker
                          name="needByDate"
                          label="Delivery Date"
                          required={true}
                          minDate={new Date()}
                        />
                      </Col>
                    )}
                    <Col lg="4">
                      <FormSelect
                        name="billingAddressId"
                        label={`Billing Address`}
                        options={deliveryAddressOptions}
                        disabled={!["Pending Approval"].includes(poStatus)}
                        placeholder="-Select-"
                        tooltipText="Select the billing address for the purchase order."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="deliveryAddressType"
                        label={`Delivery Address`}
                        disabled
                        options={[
                          { label: "Order Level", value: "Order Level" },
                          { label: "Line Level", value: "Line Level" },
                        ]}
                        placeholder="-Select-"
                        tooltipText="Select the delivery address type for the purchase order."
                      />
                    </Col>
                    {watch("deliveryAddressType") === "Order Level" && (
                      <Col lg="4">
                        <FormSelect
                          name="deliveryAddressId"
                          label="Delivery Address"
                          options={deliveryAddressOptions}
                          required={true}
                          placeholder="-Select-"
                          tooltipText="Select the delivery address for the purchase order."
                          dropdownRender={(menu) => <>{menu}</>}
                          disabled={!["Pending Approval"].includes(poStatus)}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-4">
                    <Col lg="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            className="btn btn-primary btn-md"
                            type="submit"
                            color="primary"
                          >
                            Save
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setGeneralInformationEdit(false);
                              reset();
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </form>
              </FormProvider>
            </>
          )}
        </ModalBody>
      </Modal>

      <RejectCR
        isModalVisible={crRejectModal}
        setIsModalVisible={setCrRejectModal}
        rejectCR={rejectCR}
      />
      <RejectPo
        isModalVisible={disapprovePO}
        setIsModalVisible={setDisapprovePO}
        rejectPo={disapprovePo}
      />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            {!extra?.hideAside && (
              <div
                className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                  sm ? "content-active" : ""
                }`}
              >
                <PurchaseOrderAside updateSm={setSm} sm={sm} />
              </div>
            )}
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">
                        <span>
                          {OriginalData.orderId} |{" "}
                          {(statusMapper[poStatus] &&
                            statusMapper[poStatus]?.buyer?.title) ||
                            poStatus}{" "}
                        </span>
                      </BlockTitle>
                    </BlockHeadContent>
                    <div className="d-flex">
                      {["Pending Approval"].includes(poStatus) &&
                        allowedActions?.approve == 1 &&
                        approverIds.includes(currentUserId) &&
                        !showSubmitBtm && (
                          <div className="actions clearfix">
                            <ul
                              className="m-auto d-flex align-items-center justify-content-center p-0"
                              style={{
                                flexDirection: "row-reverse",
                              }}
                            >
                              <li className="pt-0 pb-0">
                                {disableApprove ? (
                                  <Button color="success" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                ) : (
                                  <Button
                                    color="success"
                                    type="button"
                                    onClick={() => {
                                      setDisableApprove(true);
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You will not be able to recover this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Yes, Approve it!",
                                        cancelButtonText: "No, keep it",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          ApprovePo();
                                        } else {
                                          setDisableApprove(false);
                                        }
                                      });
                                    }}
                                  >
                                    Approve
                                  </Button>
                                )}
                              </li>
                              <li className="pt-0 pb-0">
                                {disableReject ? (
                                  <Button color="danger" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                ) : (
                                  <Button
                                    color="danger"
                                    onClick={() => {
                                      setDisableReject(true);
                                      setDisapprovePO(true);
                                    }}
                                    type="button"
                                    className="btn-dim btn btn-secondary"
                                  >
                                    Reject
                                  </Button>
                                )}
                              </li>
                            </ul>
                          </div>
                        )}

                      {["Changes to Buyer"].includes(poStatus) &&
                        allowedActions?.approve == 1 &&
                        !showSubmitBtm && (
                          <div className="actions clearfix">
                            <ul
                              className="m-auto d-flex align-items-center justify-content-center p-0"
                              style={{
                                flexDirection: "row-reverse",
                              }}
                            >
                              <li className="pt-0 pb-0">
                                {disableAccept ? (
                                  <Button color="primary" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                      setDisableAccept(true);
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "Do you want to accept the changes?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, Accept it!",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          AcceptCR();
                                        } else {
                                          setDisableAccept(false);
                                        }
                                      });
                                    }}
                                  >
                                    Accept Change Request
                                  </Button>
                                )}
                              </li>
                              <li className="pt-0 pb-0">
                                {disableRejectS ? (
                                  <Button color="secondary" type="button">
                                    <Spinner size="sm" color="light" />
                                  </Button>
                                ) : (
                                  <Button
                                    color="secondary"
                                    onClick={() => {
                                      setDisableRejectS(true);
                                      setCrRejectModal(true);
                                    }}
                                    type="button"
                                    className="btn-dim btn btn-secondary"
                                  >
                                    Reject Change Request
                                  </Button>
                                )}
                              </li>
                            </ul>
                          </div>
                        )}
                      {showSubmitBtm && (
                        <div className="actions clearfix">
                          <ul
                            className="m-auto d-flex align-items-center justify-content-center p-0"
                            style={{
                              flexDirection: "row-reverse",
                            }}
                          >
                            <li className="pt-0 pb-0">
                              {disableSubmit ? (
                                <Button color="primary" type="button">
                                  <Spinner size="sm" color="light" />
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="button"
                                  onClick={() => {
                                    setDisableSubmit(true);
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Do you want to submit the changes?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, submit it!",
                                      cancelButtonText: "No, cancel",
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {
                                        await SubmitChanges();
                                      } else {
                                        setDisableSubmit(false);
                                      }
                                    });
                                  }}
                                >
                                  Save Changes
                                </Button>
                              )}
                            </li>
                            <li className="pt-0 pb-0">
                              <Button
                                color="secondary"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "Do you want to revert the changes?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, revert it!",
                                    cancelButtonText: "No, cancel",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      fetchOrderDetails();
                                      Swal.fire(
                                        "Reverted!",
                                        "Your changes have been reverted.",
                                        "success"
                                      );
                                    }
                                  });
                                }}
                                type="button"
                                className="btn-dim btn btn-secondary"
                              >
                                Revert Chnages
                              </Button>
                            </li>
                          </ul>
                        </div>
                      )}

                      {!showSubmitBtm && (
                        <div className="actions clearfix">
                          <ul
                            className="m-auto d-flex align-items-center justify-content-center p-0"
                            style={{
                              flexDirection: "row-reverse",
                            }}
                          >
                            {[
                              "Approved",
                              "Partially Delivered",
                              "Fully Delivered",
                            ]?.includes(poStatus) ? (
                              <li className="pt-0 pb-0">
                                <Button
                                  color="primary"
                                  type="button"
                                  onClick={() => {
                                    navigate(
                                      `/add-purchase-invoice/${orderNumber}`
                                    );
                                  }}
                                >
                                  {poStatus == "Fully Delivered"
                                    ? "View Invoice Details"
                                    : "Convert To Invoice"}
                                </Button>
                              </li>
                            ) : (
                              <></>
                            )}

                            {OriginalData.version > 1 ? (
                              <li className="pt-0 pb-0">
                                <Button
                                  color="primary"
                                  type="button"
                                  onClick={() => {
                                    navigate(
                                      `/poHistory/${OriginalData.orderNumber}`
                                    );
                                  }}
                                >
                                  Version History
                                </Button>
                              </li>
                            ) : (
                              <></>
                            )}
                            <li className="pt-0 pb-0">
                              <Dropdown
                                isOpen={shareOptionsToggle}
                                toggle={() => {
                                  setShareOptionsToggle(!shareOptionsToggle);
                                }}
                              >
                                <DropdownToggle className="btn btn-white btn-outline-light">
                                  <Icon name="share"></Icon>
                                  <span>Send</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {/* <DropdownItem onClick={() => {}}>
                                    <Icon name="archive"></Icon>
                                    <span>Send SMS</span>
                                  </DropdownItem> */}
                                  <DropdownItem
                                    onClick={() => {
                                      setShowEmailModal(true);
                                    }}
                                  >
                                    <Icon name="mail"></Icon>
                                    <span>Send Mail</span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="pt-0 pb-0">
                              <PdfGenerator
                                buyerDetails={returnBillingDetails()}
                                poDetails={returnPoDetails()}
                                supplierDetails={returnSupplierDetails()}
                                buyerOrgLogo={currentUser?.orgLogo}
                                hasIGST={taxConfiguration?.gstType == "IGST"}
                                TermsAndConditions={termsAndConditions}
                                getPdfUrl={updatePdfBlobDataRef}
                              />
                            </li>

                            <li className="pt-0 pb-0">
                              <Button
                                className="toggle"
                                color="primary"
                                onClick={async () => {
                                  navigate(previousPath, {
                                    state: { ...extra?.previousState },
                                  });
                                }}
                              >
                                <Icon name="arrow-left" />
                                <span>Back</span>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>

                <Alert color={"danger"} isOpen={poStatus == "Rejected"}>
                  <strong>
                    {rejectedData?.userName} ({rejectedData?.roleName}) :{" "}
                  </strong>{" "}
                  {OriginalData?.rejectedReason}
                </Alert>

                <Alert
                  color={"danger"}
                  isOpen={poStatus == "Rejected by Supplier"}
                >
                  <strong>{OriginalData?.rejectedReason}</strong>
                </Alert>

                <div className="content clearfix">
                  <AntCollapse defaultActiveKey={["1", "2", "3"]}>
                    <Panel
                      header={
                        <div className="d-flex align-items-center justify-content-between">
                          <>General Information</>
                        </div>
                      }
                      key="1"
                    >
                      <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs w-100 p-0 m-0 position-relative">
                        <li className="nav-item" onClick={() => setTab("1")}>
                          <a
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className={`nav-link${
                              tab === "1" ? " active" : ""
                            }`}
                            href="#year"
                          >
                            General Info
                          </a>
                        </li>
                        <li className="nav-item" onClick={() => setTab("2")}>
                          <a
                            href="#overview"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className={`nav-link${
                              tab === "2" ? " active" : ""
                            }`}
                          >
                            Delivery Info
                          </a>
                        </li>
                        {(["Pending Approval"].includes(poStatus) ||
                          (tab == "2" &&
                            ["Pending Approval", "Approved"].includes(
                              poStatus
                            ) &&
                            OriginalData?.needByDateType == "Order Level")) &&
                        allowedActions?.edit == 1 ? (
                          <span
                            className="cursor-pointer position-absolute"
                            style={{
                              right: 20,
                              top: 10,
                              fontSize: "18px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              // if(["Pending Approval"].includes(poStatus)){
                              if (tab === "1") {
                                setValue("siteId", materialData?.siteId);
                                setValue(
                                  "supplierId",
                                  materialData?.supplierId
                                );
                                setValue(
                                  "description",
                                  materialData?.description
                                );
                                setValue("budgetId", materialData?.budgetId);
                                setValue(
                                  "contractorId",
                                  materialData?.contractorId
                                );
                                setValue(
                                  "paymentTerms",
                                  materialData?.paymentTerms
                                );
                                setValue(
                                  "advancePercentage",
                                  materialData?.advancePercentage
                                );
                                setValue(
                                  "creditDays",
                                  materialData?.creditDays
                                );

                                setValue(
                                  "costCenterId",
                                  materialData?.costCenterId
                                );
                                setValue("ledgerId", materialData?.ledgerId);
                                setValue(
                                  "departmentId",
                                  materialData?.departmentId
                                );
                              } else if (tab === "2") {
                                setValue(
                                  "inwardRequired",
                                  materialData?.inwardRequired
                                );
                                setValue(
                                  "needByDateType",
                                  materialData?.needByDateType
                                );
                                setValue(
                                  "needByDate",
                                  dayjs(materialData?.needByDate)
                                );
                                setValue(
                                  "billingAddressId",
                                  materialData?.billingAddressId
                                );
                                setValue(
                                  "deliveryAddressType",
                                  materialData?.deliveryAddressType
                                );
                                setValue(
                                  "deliveryAddressId",
                                  materialData?.deliveryAddressId
                                );
                              }
                              // }else if(tab=="2"&&OriginalData?.needByDateType=="Order Level"){

                              // }
                              setGeneralInformationEdit(true);
                            }}
                          >
                            <Icon name="edit" />
                          </span>
                        ) : (
                          <></>
                        )}
                      </ul>

                      <div className="tab-content mt-0">
                        {tab === "1" && (
                          <div
                            className={`tab-pane${tab === "1" && " active"}`}
                          >
                            <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                              <div className="w-30 border-end ">
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-30">
                                    Order Date
                                  </div>
                                  <div className="data-value w-70">
                                    {OriginalData?.orderDate
                                      ? dayjs(OriginalData?.orderDate)?.format(
                                          "DD-MMM-YYYY"
                                        )
                                      : "N/A"}
                                  </div>
                                </div>
                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-30">Site</div>
                                  <div className="data-value w-70 ">
                                    {materialData?.siteName}
                                  </div>
                                </div>
                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-30">
                                    Contractor
                                  </div>
                                  <div className="data-value w-70 ">
                                    {materialData?.contractorName || "--"}
                                  </div>
                                </div>
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-30">
                                    Description
                                  </div>
                                  <div className="data-value w-70">
                                    {materialData?.description || "--"}
                                  </div>
                                </div>
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-30">
                                    Order Date
                                  </div>
                                  <div className="data-value w-70">
                                    {OriginalData?.orderDate
                                      ? dayjs(OriginalData?.orderDate)?.format(
                                          "DD-MMM-YYYY"
                                        )
                                      : "N/A"}
                                  </div>
                                </div>
                              </div>
                              <div className="w-30 border-end paddingLeft10 ">
                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-40">Budget</div>
                                  <div className="data-value w-60 ">
                                    {materialData?.budgetName}
                                  </div>
                                </div>
                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-40">
                                    Payment Terms
                                  </div>
                                  <div className="data-value w-60 ">
                                    <Badge color="outline-success">
                                      {materialData?.paymentTerms || "--"}
                                    </Badge>
                                  </div>
                                </div>
                                {materialData?.paymentTerms == "Advance" && (
                                  <div className="data-col align-center pt-1 ">
                                    <div className="data-label w-40">
                                      Advance
                                    </div>
                                    <div className="data-value w-60 ">
                                      <span className="change up text-danger">
                                        {materialData?.advancePercentage ||
                                          "--"}
                                        %
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {materialData?.paymentTerms !=
                                  "Cash and Carry" &&
                                materialData?.creditDays ? (
                                  <div className="data-col align-center pt-1 ">
                                    <div className="data-label w-40">
                                      Credit Days
                                    </div>
                                    <div className="data-value w-60 ">
                                      <Badge color="outline-primary">
                                        {materialData?.creditDays || "--"} days
                                      </Badge>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-40">
                                    Approvals
                                  </div>
                                  <div className="data-value w-60">
                                    <ApprovalTimelineContent />
                                  </div>
                                </div>
                              </div>
                              <div className="w-30 paddingLeft10">
                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-30">
                                    Cost Center
                                  </div>
                                  <div className="data-value w-70 ">
                                    {materialData?.costCenterName || "--"}
                                  </div>
                                </div>

                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-30">Ledger</div>
                                  <div className="data-value w-70 ">
                                    {materialData?.ledgerName || "--"}
                                  </div>
                                </div>

                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-30">
                                    Department
                                  </div>
                                  <div className="data-value w-70 ">
                                    {materialData?.departmentName || "--"}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </div>
                        )}
                        {tab === "2" && (
                          <div
                            className={`tab-pane${tab === "2" && " active"}`}
                          >
                            <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                              <div className="w-25 border-end">
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-50">
                                    Inward Required
                                  </div>
                                  <div className="data-value w-50">
                                    {materialData?.inwardRequired ? (
                                      <Badge color="outline-success">Yes</Badge>
                                    ) : (
                                      <Badge color="outline-danger">No</Badge>
                                    )}
                                  </div>
                                </div>
                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-50">
                                    Line Level Delivery
                                  </div>
                                  <div className="data-value w-50">
                                    {materialData?.needByDateType !=
                                    "Order Level" ? (
                                      <Badge color="outline-success">Yes</Badge>
                                    ) : (
                                      <Badge color="outline-danger">No</Badge>
                                    )}
                                  </div>
                                </div>

                                <div className="data-col align-center pt-1">
                                  <div className="data-label w-50">
                                    Delivery Type
                                  </div>
                                  <div className="data-value w-50">
                                    {materialData?.deliveryAddressType || "--"}
                                  </div>
                                </div>

                                {materialData?.needByDateType ==
                                  "Order Level" && (
                                  <div className="data-col pt-1">
                                    <div className="data-label w-50">
                                      Delivery Date
                                    </div>
                                    <div className="data-value w-50">
                                      {/* <Badge color="outline-primary"> */}
                                      {dayjs(materialData?.needByDate).format(
                                        "DD-MMM-YYYY"
                                      )}
                                      {/* </Badge> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="w-25 border-end paddingLeft10">
                                <div className=" pt-1">
                                  <div className="data-label w-90 mb-1">
                                    Billing Address
                                  </div>
                                  <div className="data-value w-90">
                                    <ShowAddressPopOver
                                      type={"billing"}
                                      id={materialData?.billingAddressId}
                                    />
                                  </div>
                                </div>
                              </div>
                              {materialData?.deliveryAddressId && (
                                <div className="w-25 border-end paddingLeft10 ">
                                  <div className=" pt-1">
                                    <div className="data-label w-90 mb-1">
                                      Delivery Address
                                    </div>
                                    <div className="data-value w-90">
                                      <ShowAddressPopOver
                                        type={"delivery"}
                                        id={materialData?.deliveryAddressId}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="w-25 paddingLeft10">
                                <div className="  pt-1">
                                  <div className="data-label w-90 mb-1">
                                    Supplier Info
                                  </div>
                                  <div className="data-value w-100">
                                    <ShowSupplierInfo
                                      id={materialData?.supplierId}
                                    />
                                  </div>
                                </div>
                              </div>{" "}
                            </Col>
                          </div>
                        )}
                      </div>
                    </Panel>
                    <Panel header="Material Information" key="2">
                      <div className="table-responsive poTable">
                        <div
                          style={{
                            width:
                              screenSize -
                              (extra?.reduceTableWidth || 380) +
                              "px",
                            margin: "15px auto",
                          }}
                        >
                          <Table
                            className="customTable"
                            columns={columns}
                            dataSource={materialData?.lineItems?.map(
                              (item: any, index: any) => ({
                                ...item,
                                key: index,
                              })
                            )}
                            scroll={{ x: 1000 }}
                            expandable={{
                              expandedRowRender: (record: any) => (
                                <div className="expandableBody">
                                  <div style={{ flex: 1, textAlign: "left" }}>
                                    <p style={{ margin: 0 }}>
                                      {record.description}
                                    </p>
                                    {record.attachment && (
                                      <div>
                                        <PaperClipOutlined /> &nbsp;
                                        {record?.file[0]?.name ? (
                                          <a
                                            href={URL.createObjectURL(
                                              record.file[0]
                                            )}
                                            download={record.file[0]?.name}
                                          >
                                            {record.file[0]?.name}
                                          </a>
                                        ) : (
                                          <a
                                            href={record?.file?.filePath}
                                            download={record?.file?.filePath}
                                            target="_blank"
                                          >
                                            {record?.file?.fileName}
                                          </a>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {materialData?.deliveryAddressType ===
                                    "Line Level" &&
                                    record.deliveryAddressId && (
                                      <div
                                        className="lineAddress"
                                        style={{ flex: 1 }}
                                      >
                                        <h6>Delivery Address</h6>
                                        <ShowAddressPopOver
                                          id={record.deliveryAddressId}
                                        />
                                      </div>
                                    )}
                                </div>
                              ),
                            }}
                            locale={{
                              emptyText: (
                                <span
                                  style={{
                                    height: "300px",
                                    color: "#333",
                                    fontSize: "16px",
                                  }}
                                >
                                  Please click on "Add New Item" to add purchase
                                  order lines.
                                </span>
                              ),
                            }}
                            bordered
                            size="middle"
                            pagination={false}
                          />
                        </div>
                        <Row
                          style={{
                            margin: "20px",
                          }}
                        >
                          <Col
                            className={`${
                              poStatus == "Pending Approval" &&
                              allowedActions?.edit == 1
                                ? "justify-between"
                                : "justify-end"
                            }`}
                          >
                            {poStatus == "Pending Approval" &&
                            allowedActions?.edit == 1 ? (
                              <a
                                onClick={() => {
                                  setAddNewRowModel(true);
                                  setEditLineRow(null);
                                }}
                                href="#add"
                                className="addNewItem"
                              >
                                <Icon name="plus-round" />
                                <span>Add New Item</span>
                              </a>
                            ) : (
                              <></>
                            )}
                            <div className="w-40">
                              <div className="d-flex justify-content-between">
                                <div className="w-50 justify-end">
                                  <strong>Taxable Amount</strong>
                                </div>
                                <span className="r_align">
                                  {FormatCurrency(
                                    materialData?.lineItems?.reduce(
                                      (a: any, b: any) =>
                                        a +
                                        Number(b.quantity) *
                                          Number(b.unitPrice),
                                      0
                                    ),
                                    currency?.currency_code
                                  )}
                                </span>
                              </div>
                              {taxConfiguration?.gstType == "CGST+SGST" ? (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <div className="w-50 justify-end">
                                      <strong>CGST</strong>
                                    </div>
                                    <span className="r_align">
                                      {FormatCurrency(
                                        materialData?.lineItems?.reduce(
                                          (a: any, b: any) =>
                                            a + Number(b.cgst),
                                          0
                                        ),
                                        currency?.currency_code
                                      )}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <div className="w-50 justify-end">
                                      <strong>SGST</strong>
                                    </div>
                                    <span className="r_align">
                                      {FormatCurrency(
                                        materialData?.lineItems?.reduce(
                                          (a: any, b: any) =>
                                            a + Number(b.sgst),
                                          0
                                        ),
                                        currency?.currency_code
                                      )}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <div className="d-flex justify-content-between">
                                  <div className="w-50 justify-end">
                                    <strong>IGST</strong>
                                  </div>
                                  <span className="r_align">
                                    {FormatCurrency(
                                      materialData?.lineItems?.reduce(
                                        (a: any, b: any) => a + Number(b.igst),
                                        0
                                      ),
                                      currency?.currency_code
                                    )}
                                  </span>
                                </div>
                              )}
                              <div
                                className="d-flex justify-content-between"
                                style={{
                                  fontSize: "18px",
                                  marginTop: "10px",
                                }}
                              >
                                <div className="w-50 justify-end">
                                  <strong>Total</strong>
                                </div>
                                <strong className="r_align">
                                  {FormatCurrency(
                                    materialData?.lineItems?.reduce(
                                      (a: any, b: any) =>
                                        a + Number(b.grossTotal),
                                      0
                                    ),
                                    currency?.currency_code
                                  )}
                                </strong>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="m-4 justify-content-end ">
                          {signature?.isActive && (
                            <div className="signatureBtnWrapper">
                              <div className="d-flex justify-content-between align-items-center">
                                <strong>Signature</strong>
                              </div>
                              <div
                                style={{
                                  minHeight: signature?.image ? "0px" : "120px",
                                  display: signature?.image ? "block" : "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {signature?.image && (
                                  <>
                                    <div className="signatueImgWrapper border">
                                      <img
                                        src={signature?.image}
                                        alt="Signed"
                                        style={{ width: "160px" }}
                                      />
                                    </div>

                                    <div>
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="signatureLabel"
                                        >
                                          Signature Label
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="signatureLabel"
                                          onChange={(e) => {}}
                                          disabled
                                          defaultValue={
                                            signature?.label ||
                                            "Authorised Signatory"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="Other Information" key="3">
                      <Descriptions
                        title=""
                        className="custom-description-title"
                      >
                        <Descriptions.Item label="Instructions">
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setInstructionsVisible(true)}
                          >
                            View
                          </span>
                        </Descriptions.Item>
                        <Descriptions.Item label="Terms and Conditions">
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setTermsVisible(true)}
                          >
                            View
                          </span>
                        </Descriptions.Item>

                        <Descriptions.Item label="">
                          <div>
                            <strong className="">
                              Attachments &nbsp;
                              <PaperClipOutlined />
                            </strong>
                            <div>
                              {attachments?.map(
                                (attachment: any, index: number) => (
                                  <div
                                    key={index}
                                    className="w-100 d-flex justify-content-between"
                                  >
                                    <div>
                                      <strong>{index + 1}.</strong>
                                      &nbsp;
                                      {attachment.name}
                                      &nbsp; &nbsp; &nbsp; &nbsp;
                                    </div>
                                    <div>
                                      <a
                                        href={attachment.link}
                                        download={attachment.link}
                                        target="_blank" // Opens in a new tab
                                        rel="noopener noreferrer" // Adds security
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <DownloadOutlined />
                                      </a>
                                      &nbsp; &nbsp;
                                      {["Pending Approval"].includes(
                                        poStatus
                                      ) && allowedActions?.edit == 1 ? (
                                        <a
                                          style={{
                                            color: "red",
                                            fontSize: "16px",
                                          }}
                                          onClick={() => {
                                            setDeletedAttachments(
                                              (prev: any) => [
                                                ...prev,
                                                { docId: attachment?.docId },
                                              ]
                                            );
                                            let updatedAttachmentsList =
                                              attachments?.filter(
                                                (item: any, i: number) =>
                                                  i != index
                                              );
                                            setAttachments(
                                              updatedAttachmentsList
                                            );
                                            setShowSubmitBtm(true);
                                          }}
                                        >
                                          <DeleteOutlined />
                                        </a>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                              {newAttachments?.map(
                                (attachment: any, index: number) => (
                                  <div
                                    key={index}
                                    className="w-100 d-flex justify-content-between"
                                  >
                                    <div>
                                      <strong>
                                        {attachments?.length + index + 1}.
                                      </strong>
                                      &nbsp;
                                      {attachment?.name}
                                      &nbsp; &nbsp; &nbsp; &nbsp;
                                    </div>
                                    <div>
                                      <a
                                        href={attachment.link}
                                        download={attachment.link}
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <DownloadOutlined />
                                      </a>
                                      &nbsp; &nbsp;
                                      <a
                                        style={{
                                          color: "red",
                                          fontSize: "16px",
                                        }}
                                        onClick={() => {
                                          setNewAttachments((prev: any) =>
                                            prev.filter(
                                              (item: any, i: number) =>
                                                i != index
                                            )
                                          );
                                          setShowSubmitBtm(true);
                                        }}
                                      >
                                        <DeleteOutlined />
                                      </a>
                                    </div>
                                  </div>
                                )
                              )}
                              {["Pending Approval"].includes(poStatus) &&
                              allowedActions?.edit == 1 ? (
                                <div className="mt-1">
                                  <Dragger
                                    beforeUpload={beforeUpload}
                                    fileList={[]}
                                    onChange={handleAttachmentChange}
                                  >
                                    <a
                                      onClick={() => {}}
                                      className="addNewItem"
                                    >
                                      <Icon name="plus-round" />
                                      <span>Add Attachment</span>
                                    </a>
                                  </Dragger>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Descriptions.Item>
                      </Descriptions>
                    </Panel>
                  </AntCollapse>

                  <Block>
                    {showSignatureModel && (
                      <SignatureModal
                        modal={showSignatureModel}
                        toggle={() =>
                          setShowSignatureModel(!showSignatureModel)
                        }
                        onSave={(data: string) => {
                          setSignature((pre: any) => {
                            return { ...pre, image: data };
                          });
                          setShowSignatureModel(false);
                        }}
                      />
                    )}
                    {showCropModel && (
                      <CropperComponent
                        modal={showCropModel}
                        toggle={() => setShowCropModel(!showCropModel)}
                        onSave={(data: string) => {
                          setSignature((pre: any) => {
                            return { ...pre, image: data };
                          });
                          setShowCropModel(false);
                        }}
                      />
                    )}
                    {addNewRowModel && (
                      <MaterialModal
                        modal={addNewRowModel}
                        closeModal={() => {
                          setAddNewRowModel(false);
                          setEditLineRow(null);
                        }}
                        materialCategoryOptions={materialCategoryOptions}
                        status={materialData?.orderStatus}
                        formData={{
                          action: editLineRow == null ? "add" : "edit",
                          index: editLineRow?.uuid,
                          item: editLineRow,
                        }}
                        taxConfiguration={taxConfiguration}
                        append={(data: any) => {
                          let newLineItems = materialData?.lineItems;
                          const _data = newLineItems.find((item: any) => {
                            if (
                              item.materialId == data.materialId &&
                              dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                                dayjs(data.needByDate).format("DD-MMM-YYYY") &&
                              item.deliveryAddressId == data.deliveryAddressId
                            )
                              return true;
                          });

                          if (_data) {
                            Swal.fire({
                              icon: "error",
                              title: "Error",
                              text: "Material Already Added",
                            });
                            return;
                          }

                          setMaterialData((pre: any) => {
                            let { lineItems } = pre;
                            lineItems?.push(data);
                            return { ...pre, lineItems };
                          });
                          setShowSubmitBtm(true);
                        }}
                        
                        update={(id: any, data: any) => {
                          let newLineItems = materialData?.lineItems;
                          const _data = newLineItems
                            .filter((item: any) => item.uuid != id)
                            .find((item: any) => {
                              console.log(
                                item.materialId,
                                data.materialId,
                                item.needByDate,
                                data.needByDate,
                                item.deliveryAddressId,
                                item.deliveryAddressId
                              );

                              if (
                                item.materialId == data.materialId &&
                                dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                                  dayjs(data.needByDate).format(
                                    "DD-MMM-YYYY"
                                  ) &&
                                item.deliveryAddressId == data.deliveryAddressId
                              )
                                return true;
                            });

                          if (_data) {
                            Swal.fire({
                              icon: "error",
                              title: "Error",
                              text: " Material Already Added",
                            });
                            return;
                          }

                          newLineItems = newLineItems.map((item: any) => {
                            if (item.uuid == id) {
                              return data;
                            }
                            return item;
                          });
                          setMaterialData((pre: any) => {
                            let newData = { ...pre, lineItems: newLineItems };
                            return newData;
                          });
                          setShowSubmitBtm(true);
                        }}
                        materialsList={materialData?.lineItems?.map((row: any) => row?.materialId?.toString())}
                        needByDateType={materialData?.needByDateType}
                        deliveryAddressType={materialData?.deliveryAddressType}
                      />
                    )}
                    {changeToBuyerData && (
                      <ChangeToBuyerDrawer
                        changeToBuyerData={changeToBuyerData}
                        needByDateType={materialData?.needByDateType}
                        drawerVisible={changeToBuyerData != null}
                        closeDrawer={() => {
                          setChangeToBuyerData(null);
                        }}
                        FormatCurrency={(value: any) =>
                          FormatCurrency(value, currency?.currency_code)
                        }
                      />
                    )}
                  </Block>
                </div>
              </div>
            )}
          </div>
        </Card>
      </Content>
      {showEmailModal && (
        <EmailCreationModal
          modal={showEmailModal}
          toggle={() => setShowEmailModal(!showEmailModal)}
          sendEmail={TriggerEmail}
          body={
            <div className="w-100 p-2">
              <div className="m-auto">
                <img
                  src={currentUser?.orgLogo}
                  alt={currentUser?.orgName}
                  width="200"
                  style={{ marginTop: "20px" }}
                />
                {/* <p style={{ fontStyle: "italic", color: "#2263b3" }}>
                    Simplifying Complexities
                  </p> */}
                <div style={{ backgroundColor: "#fff", padding: "15px" }}>
                  {/* <h2 style={{ color: "#2263b3" }}>New Purchase Order</h2> */}
                  <p style={{ color: "#1ee0ac", fontSize: "20px" }}>
                    Dear {OriginalData?.supplierName},
                  </p>
                  <p>
                    We are pleased to inform you that a new Purchase Order (
                    <strong>{OriginalData?.orderId}</strong>) has been generated
                    for your review.
                  </p>

                  <p>
                    <strong>Order Summary: </strong>
                  </p>
                  <p>
                    <strong>PO Number: </strong>
                    {OriginalData?.orderId}
                  </p>
                  <p>
                    <strong>Order Date: </strong>
                    {dayjs(OriginalData?.orderDate).format("DD-MMM-YYYY")}
                  </p>
                  <p>
                    <strong>Delivery Address: </strong>{" "}
                    {returnPoDetails()?.deliveryAddressString}
                  </p>
                  <p>
                    <strong>Total Amount: </strong>{" "}
                    {FormatCurrency(
                      OriginalData?.netAmount,
                      currency?.currency_code
                    )}
                  </p>

                  <p>
                    Please review the attached document for full order details
                    and confirm receipt.
                  </p>
                  <p>
                    If you have any questions or need further assistance, feel
                    free to reach out to us at{" "}
                    <a href={`mailto:{${currentUser?.email}}}`}>
                      {currentUser?.email}
                    </a>
                    .
                  </p>

                  <p>
                    This is an automatically generated email. Please do not
                    reply directly to this email.
                  </p>
                </div>
                {/* <p
                  style={{
                    marginTop: "20px",
                    fontSize: "12px",
                    color: "#000",
                  }}
                >
                  Copyright © {dayjs().format("DD-MMM-YYYY")} Procurex
                  Technologies PVT LTD. All rights reserved.
                </p> */}
              </div>
            </div>
          }
          defaultAttachments={Object.assign({}, pdfBlobData.current, {
            name: OriginalData?.orderId + ".pdf",
            type: "application/pdf",
          })}
        />
      )}

      <AntModal
        title={undefined}
        visible={instructionsVisible}
        footer={null}
        onCancel={() => {
          setIsInstructionsChanged(false);
          setInstructionsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Instructions </h5>
          {!isInstructionsChanged &&
            ["Pending Approval"].includes(poStatus) &&
            allowedActions?.edit == 1 && (
              <EditOutlined onClick={() => setIsInstructionsChanged(true)} />
            )}
        </div>
        {["Pending Approval"].includes(poStatus) &&
        allowedActions?.edit == 1 &&
        isInstructionsChanged ? (
          <FormProvider {...methods}>
            <form>
              <div className="mt-1 mb-3">
                <FormTextArea
                  name="instructions"
                  label=""
                  placeholder="Enter Instructions"
                  required
                  rows={10}
                  defaultValue={newInstructions}
                />
              </div>
            </form>
          </FormProvider>
        ) : instructions ? (
          <pre className="m-3">{instructions || ""}</pre>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Instructions Available for this Purchase Order" />
          </div>
        )}
        {isInstructionsChanged && (
          <ul className="actions clearfix d-flex">
            <li>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setInstructionsVisible(false);
                  setIsInstructionsChanged(true);
                  setShowSubmitBtm(true);
                }}
              >
                Submit
              </Button>
            </li>
            <li className="m-3 mt-0">
              <Button
                color="primary"
                onClick={() => {
                  setIsInstructionsChanged(false);
                  setNewInstructions(instructions);
                }}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        )}
      </AntModal>
      <AntModal
        title={undefined}
        visible={termsVisible}
        footer={null}
        onCancel={() => {
          setIsTermsAndConditionsChanged(false);
          setTermsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Terms and Conditions</h5>
          {!isTermsAndConditionsChanged &&
            ["Pending Approval"].includes(poStatus) &&
            allowedActions?.edit == 1 && (
              <EditOutlined
                onClick={() => setIsTermsAndConditionsChanged(true)}
              />
            )}
        </div>
        {["Pending Approval"].includes(poStatus) &&
        allowedActions?.edit == 1 &&
        isTermsAndConditionsChanged ? (
          <FormProvider {...methods}>
            <form>
              <div className="mt-1 mb-3">
                <FormTextArea
                  defaultValue={newTermsAndConditions}
                  name="termsAndConditions"
                  label=""
                  placeholder="Enter Terms and Conditions"
                  required
                  rows={10}
                />
              </div>
            </form>
          </FormProvider>
        ) : termsAndConditions ? (
          <pre className="m-3">{termsAndConditions || ""}</pre>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Terms and Conditions Available for this Purchase Order" />
          </div>
        )}

        {isTermsAndConditionsChanged && (
          <ul className="actions clearfix d-flex">
            <li>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setTermsVisible(false);
                  setIsTermsAndConditionsChanged(true);
                  setShowSubmitBtm(true);
                }}
              >
                Submit
              </Button>
            </li>
            <li className="m-3 mt-0">
              <Button
                color="primary"
                onClick={() => {
                  setIsTermsAndConditionsChanged(false);
                  setNewTermsAndConditions(termsAndConditions);
                }}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        )}
      </AntModal>
    </>
  );
};

const RejectCR = ({ isModalVisible, setIsModalVisible, rejectCR }: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        rejectCR(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <AntModal
      title="Reject Change Request"
      visible={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="rejectForm">
        <Form.Item
          name="reason"
          label="Reason for rejection"
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </AntModal>
  );
};

const RejectPo = ({ isModalVisible, setIsModalVisible, rejectPo }: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        rejectPo(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <AntModal
      title="Reject Purchase Order"
      visible={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="rejectForm">
        <Form.Item
          name="reason"
          label="Reason for rejection"
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </AntModal>
  );
};

export default ViewOrderDetails;

import React, { useEffect, useState } from "react";
import { Badge, Button, Spinner } from "reactstrap";
import {
  Collapse,
  Descriptions,
  Popover,
  Timeline,
  Table,
  List,
  Modal,
  TableColumnsType,
  Empty,
} from "antd";
import { PaperClipOutlined, DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { useForm, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchSequenceAccess } from "../../../store/slices/misc/sequenceAccess";
import rolesApi from "../../../api/master/roles";
import costCenterApis from "../../../api/master/costCenter";
import usersApis from "../../../api/master/users";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import adminOrgApis from "../../../api/master/adminOrg";
import Head from "../../../layout/head/Head";
import { newOrder, saveDraft } from "../draft-utility/saveDraft";

interface SelectOptions {
  label: string;
  value: string;
}

const { Panel } = Collapse;

const RFQPreview: React.FC<{ prev: () => void }> = (props) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableSubmitDraft, setDisableSubmitDraft] = useState(false);
  const dispatch: any = useDispatch();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const navigate = useNavigate();
  const { watch, register } = useFormContext();
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const { control, handleSubmit } = useForm();
  const previousFormData = watch();
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [contractorOptions, setContractorOptions] = useState<SelectOptions[]>(
    []
  );
  const { data: currentUser } = useSelector((state: any) => state.currentUser);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);

  const onSubmit = async (data: any) => {
    console.log("previousFormData", previousFormData);
    try {
      if (previousFormData?.type == "draft") {
        saveDraft(
          previousFormData,
          previousFormData?.type == "draft",
          navigate,
          setDisableSubmit,
          "F"
        );
      } else {
        await newOrder(previousFormData, navigate, setDisableSubmit, "Submit");
      }
    } catch (error) {}
  };

  const requiredDocsContent = previousFormData?.supplierDocsRequired ? (
    <Table
      dataSource={previousFormData?.documents?.documents.map(
        (doc: any, index: number) => ({
          key: index + 1,
          sNo: index + 1,
          documentName: doc.documentName,
          mandatory: doc.mandatory ? "Yes" : "No",
        })
      )}
      columns={[
        {
          title: "S.No",
          dataIndex: "sNo",
          key: "sNo",
        },
        {
          title: "Document Name",
          dataIndex: "documentName",
          key: "documentName",
        },
        {
          title: "Mandatory to Upload",
          dataIndex: "mandatory",
          key: "mandatory",
        },
      ]}
      pagination={false}
      size="small"
      style={{ minWidth: "200px" }}
    />
  ) : null;

  const [columns] = useState<TableColumnsType<any>>([
    // {
    //   title: "MATERIAL ID",
    //   dataIndex: "materialID",
    //   key: "materialID",
    //   fixed: "left" as const,
    //   // width: 120,
    // },
    {
      title: "Category",
      dataIndex: "categoryLabel",
      key: "categoryLabel",
      fixed: "left" as const,
      // width: 120,
    },
    {
      title: "Material Name",
      dataIndex: "materialLabel",
      key: "materialLabel",
      // width: 150,
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 200,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Uom",
      dataIndex: "uomLabel",
      key: "uomLabel",
    },
    // {
    //   title: "NEED BY DATE",
    //   dataIndex: "needByDate",
    //   key: "needByDate",
    // },
  ]);

  // const suppliers = Object.keys(quotationData || {})
  //   .filter(
  //     (key) => key.startsWith("supplier_") && quotationData[key]
  //   )
  //   .map((key) => key.replace("supplier_", "Supplier "));

  const suppliers: any = previousFormData.SupplierNamesSelected;

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const fetchCostCenter = async () => {
    try {
      const { data, status, message } = await costCenterApis.fetchCostCenter();
      if (status) {
        let costCenterOptions = data.map((item: any) => {
          return { label: item.costCenterName, value: item.id.toString() };
        });
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContractor = async () => {
    try {
      const data = await usersApis.fetchContractor();
      if (data) {
        let contractorOptions = data.data?.map((item: any) => ({
          label: item.companyName,
          value: item.contractorID.toString(),
        }));
        setContractorOptions(contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchApprovalHierarchyUsersByModuleId = async (moduleID: number) => {
    try {
      const res = await rolesApi.fetchHierarchyUsersByModuleId(
        moduleID,
        Number(previousFormData.siteId)
      );
      if (!res) return;
      const { data, status } = res;
      if (status) {
        setRoles(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const moduleID = res?.data?.find(
        (x: any) => x.moduleName == "Quotation"
      )?.moduleID;
      console.log(moduleID);
      await fetchApprovalHierarchyUsersByModuleId(moduleID);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    fetchModuleConfig();
    fetchDeliveryAddress();
    fetchCostCenter();
    fetchSites();
    fetchContractor();
  }, []);
  const ShowAddressPopOver = ({ type, id }: any) => {
    if (!id) {
      return <></>;
    }
    let address = deliveryAddressList?.find((item: any) => item?.id == id);
    if (!address) {
      return <></>;
    }
    return (
      <ul>
        <li>
          <strong style={{ color: "#2263b3" }}>
            {address?.deliveryLocationName}
          </strong>
        </li>
        <li>{address?.addressLine1}</li>
        <li>{address?.addressLine2}</li>
        <li>
          {address?.city}, {address?.stateName}, {address?.countryName} -{" "}
          {address?.pincode}
        </li>
        {type == "billing" && (
          <>
            <li>
              <strong>GSTIN</strong> :{" "}
              {
                currentUser.siteAccessibility.find(
                  (item: any) => item.siteID == previousFormData.siteId
                )?.gstin
              }
            </li>
          </>
        )}
        {type == "delivery" && (
          <>
            <li>
              <strong>Contact Person</strong> : {address?.contactPersonName}
            </li>
            <li>
              <strong>Mobile Number</strong> : {address?.mobileNumber}
            </li>
          </>
        )}
      </ul>
    );
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = JSON.parse(previousFormData?.approvalsData);
    if (
      !approvalsData ||
      approvalsData?.data?.length === 0 ||
      previousFormData?.approvalRequired == "false"
    )
      return <Badge color="outline-danger">No</Badge>;
    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.data?.map((approval: any, index: number) => {
              const Role = roles.find(
                (role: any) => role?.roleID == approval?.roleId
              );
              return (
                <Timeline.Item key={index}>
                  {
                    Role?.employees?.find((e: any) => e?.id == approval?.userId)
                      ?.name
                  }
                  <div className="designation">{Role?.roleName}</div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Yes
        </span>
      </Popover>
    );
  };
  return (
    <>
      <Head title="New - Quotation " />
      <div className="content clearfix">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="General Details" key="1">
              <Descriptions title="" className="custom-description-title">
                <Descriptions.Item label="Quotation For">
                  {previousFormData.quotationFor}
                </Descriptions.Item>
                <Descriptions.Item label="Preferred Brands / Make">
                  {previousFormData.preferredBrands}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  {previousFormData.quotationDescription}
                </Descriptions.Item>
                <Descriptions.Item label="Cost Center">
                  {costCenterOptions.find(
                    (item) => item.value == previousFormData.costCenter
                  )?.label || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Last Submission Date">
                  <span className="badge-dim badge bg-primary rounded-pill">
                    <strong>
                      {dayjs(previousFormData.lastSubmissionDate).format(
                        "DD-MMM-YYYY"
                      )}
                    </strong>
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Time Limit">
                  {previousFormData.deadlineType}
                </Descriptions.Item>
                <Descriptions.Item label="Site Name">
                  {
                    sitesOptions.find(
                      (item) => item.value == previousFormData.siteId
                    )?.label
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Contractor Name">
                  {contractorOptions.find(
                    (item) => item.value == previousFormData.contractorId
                  )?.label || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Approvals">
                  <ApprovalTimelineContent />
                </Descriptions.Item>
                <Descriptions.Item label="Additional Charges">
                  {" "}
                  {previousFormData.additionalCharges}
                </Descriptions.Item>
                <Descriptions.Item label="Auto Release">
                  {previousFormData.autoRelease === "Y" ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Billing Address">
                  <ShowAddressPopOver
                    type={"billing"}
                    id={previousFormData?.billingAddressId}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Delivery Address">
                  {previousFormData.deliveryAddressType === "LINE_LEVEL"
                    ? "Line Level"
                    : "Order Level"}
                </Descriptions.Item>
                <Descriptions.Item label="Documents Required From Supplier">
                  {previousFormData.supplierDocsRequired === "Y" ? (
                    <Popover
                      content={requiredDocsContent}
                      title={
                        <span style={{ color: "#2263b3" }}>
                          Required Documents
                        </span>
                      }
                    >
                      <span
                        style={{
                          color: "#2263b3",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Yes
                      </span>
                    </Popover>
                  ) : (
                    "No"
                  )}
                </Descriptions.Item>
                {previousFormData.deliveryAddressType === "ORDER_LEVEL" && (
                  <>
                    <Descriptions.Item label="Delivery Address">
                      <ShowAddressPopOver
                        type={"delivery"}
                        id={previousFormData?.deliveryAddressId}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Need By Date">
                      <span className="badge-dim badge bg-primary rounded-pill">
                        <strong>
                          {dayjs(previousFormData.needByDate).format(
                            "DD-MMM-YYYY"
                          )}
                        </strong>
                      </span>
                    </Descriptions.Item>
                  </>
                )}
                <Descriptions.Item label="Payment Terms">
                  {previousFormData.paymentTerms}
                </Descriptions.Item>
                {previousFormData.paymentTerms == "Credit" && (
                  <>
                    <Descriptions.Item label="Credit Days">
                      {previousFormData.creditDays}
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
            </Panel>
            <Panel header="Materials" key="2">
              <Table
                columns={columns}
                dataSource={previousFormData?.items}
                scroll={{ x: "max-content" }}
                expandable={{
                  expandedRowRender: (record: any) => (
                    <div className="expandableBody">
                      <div style={{ flex: 1, textAlign: "left" }}>
                        <p style={{ margin: 0 }}>{record.description}</p>
                        {record.file && record.file[0] && (
                          <div>
                            <PaperClipOutlined /> &nbsp;
                            <a
                              href={URL.createObjectURL(record.file[0])}
                              download={record.file[0]?.name}
                            >
                              {record.file[0]?.name}
                            </a>
                          </div>
                        )}
                      </div>
                      {previousFormData?.deliveryAddressType === "LINE_LEVEL" &&
                        record.deliveryAddressId && (
                          <div className="lineAddress" style={{ flex: 1 }}>
                            <h6>Delivery Address</h6>
                            <ShowAddressPopOver id={record.deliveryAddressId} />
                          </div>
                        )}
                    </div>
                  ),
                }}
                bordered
                size="small"
                pagination={false}
                locale={{ emptyText: "No materials added" }}
              />
            </Panel>
            <Panel header="Other Information" key="3">
              <Descriptions
                title="Other Information"
                className="custom-description-title"
              >
                <Descriptions.Item label="Suppliers">
                  <List
                    size="small"
                    dataSource={suppliers}
                    renderItem={(item: any) => <List.Item>{item}</List.Item>}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Instructions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setInstructionsVisible(true)}
                  >
                    View Instructions
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Terms and Conditions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setTermsVisible(true)}
                  >
                    View Terms and Conditions
                  </span>
                </Descriptions.Item>
                {previousFormData?.attachments?.length > 0 && (
                  <Descriptions.Item label="">
                    <div>
                      <div className="">
                        Attachments &nbsp;
                        <PaperClipOutlined />
                      </div>
                      <div>
                        {previousFormData.attachments?.map(
                          (attachment: any, index: number) => (
                            <div
                              key={index}
                              className="w-100 d-flex justify-content-between"
                            >
                              <div>
                                <strong>{index + 1}.</strong>
                                &nbsp;
                                {attachment.name}
                                &nbsp; &nbsp; &nbsp; &nbsp;
                              </div>
                              <div>
                                <a
                                  href={URL.createObjectURL(
                                    attachment?.originFileObj
                                  )}
                                  download={attachment.name}
                                  style={{ color: "black", fontSize: "18px" }}
                                >
                                  <DownloadOutlined />
                                </a>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
          </Collapse>
          <Modal
            title="Instructions"
            visible={instructionsVisible}
            footer={null}
            onCancel={() => setInstructionsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.instructions ? (
              <pre>{previousFormData?.instructions}</pre>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No instructions provided for this Quotation" />
              </div>
            )}
          </Modal>
          <Modal
            title="Terms and Conditions"
            visible={termsVisible}
            footer={null}
            onCancel={() => setTermsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.termsAndConditions ? (
              <pre>{previousFormData.termsAndConditions}</pre>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No terms and conditions provided for this Quotation" />
              </div>
            )}
          </Modal>
          <div className="actions clearfix">
            <ul>
              <li>
                {disableSubmit ? (
                  <Button color="primary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                )}
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={() => {
                    props.prev();
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
              <li>
                {disableSubmitDraft ? (
                  <Button color="secondary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    onClick={() =>
                      saveDraft(
                        previousFormData,
                        previousFormData?.type == "draft",
                        navigate,
                        setDisableSubmitDraft
                      )
                    }
                    className="btn btn-secondary"
                  >
                    Save as Draft
                  </Button>
                )}
              </li>
            </ul>
          </div>
        </form>
      </div>
    </>
  );
};

export default React.memo(RFQPreview);

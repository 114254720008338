import React, {useState } from "react";
import {
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import { Icon } from "../Component";
import miscService from "../../api/misc/misc";
import Swal from "sweetalert2";
import { Form, Modal as AntModal, Input } from "antd";
import { useDispatch } from "react-redux";
import { setHighlight } from "../../store/slices/misc/highlightSlice";
import { Link } from "react-router-dom";

const reportConfiguration: any = {
  "view-purchase-order": {
    reportType: "Orders",
    reportModule: "orderBilling",
  },
  "view-invoice-list": {
    reportType: "Invoices",
    reportModule: "orderBilling",
  },
  "view-payments": {
    reportType: "Payments",
    reportModule: "orderBilling",
  },
  "material-list": {
    reportType: "Materials",
    reportModule: "masters",
  },
  "suppliers-list": {
    reportType: "Suppliers",
    reportModule: "masters",
  },
  "view-purchase-requests-list": {
    reportType: "Purchase Requests",
    reportModule: "transactions",
  },
  "view-budget-list": {
    reportType: "Budgets",
    reportModule: "transactions",
  },
  "view-rfqs": {
    reportType: "Quotations",
    reportModule: "transactions",
  },
  purchaseBySiteReport: {
    reportType: "Purchase by Site",
    reportModule: "reports",
  },
  purchaseBySiteDetailReport: {
    reportType: "Purchase by Site Details",
    reportModule: "reports",
  },
  purchaseBySupplier: {
    reportType: "Awarded To Supplier",
    reportModule: "reports",
  },
  purchaseBySupplierDetailReport: {
    reportType: "Awarded To Supplier Details",
    reportModule: "reports",
  },
  purchaseOrdersReport: {
    reportType: "Purchase Order",
    reportModule: "reports",
  },
  invoiceBySupplierReport: {
    reportType: "Invoice by Supplier",
    reportModule: "reports",
  },
  purchaseByMaterialReport: {
    reportType: "Purchase by Material",
    reportModule: "reports",
  },
  purchaseByMaterialDetailReport: {
    reportType: "Purchase by Material Details",
    reportModule: "reports",
  },
  purchaseByCategoryReport: {
    reportType: "Purchase by Category",
    reportModule: "reports",
  },
  purchaseByCategoryDetailReport: {
    reportType: "Purchase by Category Details",
    reportModule: "reports",
  },
  deliveryNoteDetailsReport: {
    reportType: "GRN Details List",
    reportModule: "reports",
  },
  supplierBalancesReport: {
    reportType: "Supplier Balances",
    reportModule: "reports",
  },
  supplierBalancesDetailReport: {
    reportType: "Supplier Balances Details",
    reportModule: "reports",
  },
  paymentsAgingSummaryReport: {
    reportType: "Payments Aging Summary",
    reportModule: "reports",
  },
  paymentsAgingSummaryInvoiceReport: {
    reportType: "Payments Aging Summary Details",
    reportModule: "reports",
  },
  paymentsAgingReport: {
    reportType: "Payments Aging",
    reportModule: "reports",
  },
  paymentsReport: {
    reportType: "Payments Report",
    reportModule: "reports",
  },
  invoiceDetailsForPaymentsReport: {
    reportType: "Payments Report Details",
    reportModule: "reports",
  },
  materialsIssuedToSite: {
    reportType: "Materials Issued To Site",
    reportModule: "reports",
  },
  InventorySummaryReport: {
    reportType: "Inventory Summary",
    reportModule: "reports",
  },
  InventoryValueReport: {
    reportType: "Inventory Value",
    reportModule: "reports",
  },
  InventoryTrackReport: {
    reportType: "Material Moment",
    reportModule: "reports",
  },
  "/material-tracking-report-list": {
    reportType: "Budget Tracking",
    reportModule: "reports",
  },
  "/material-consumption-report-list": {
    reportType: "Material Utilization Summary",
    reportModule: "reports",
  },
  materialUtilizationSummaryDetailReport: {
    reportType: "Avg Material Utilized Breakup",
    reportModule: "reports",
  },
  "/material-request-summary-list": {
    reportType: "Material Request Summary",
    reportModule: "reports",
  },
  "/material-issue-summary-list": {
    reportType: "Material Issue Summary",
    reportModule: "reports",
  },
  "/material-utilized-summary-list": {
    reportType: "Material Utilized Summary",
    reportModule: "reports",
  },
  "/material-damage-summary-list": {
    reportType: "Material Damage Summary",
    reportModule: "reports",
  },
  "/material-summary-list": {
    reportType: "Material Movement Summary",
    reportModule: "reports",
  },
  MaterialMovementSummaryDetailReport: {
    reportType: "Avg Material Movement Breakup",
    reportModule: "reports",
  },
};

const Export = ({
  data,
  moduleKey,
  reportType = "transactionFlow",
  showAsButton = true,
}: {
  data: any;
  moduleKey?: any;
  reportType?: any;
  showAsButton?: boolean;
}) => {
  const dispatch = useDispatch();
  const params = data;
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const handelExcelClick = () => {
    setShowCommentsModal(true);
  };

  const getExtraPaginationParams = (moduleKey: any) => {
    console.log("moduleKey", moduleKey);
    const {
      selectFilter1,
      selectFilter2,
      selectFilter3,
      siteId,
      materialId,
      categoryId,
      contractorId,
      supplierId,
    } = params;
    const extraparmsobj: any = {
      "view-purchase-order": {
        orderStatus: selectFilter1,
      },
      "view-invoice-list": {
        invoiceStatus: selectFilter1,
      },
      "material-list": {
        categoryFilters: selectFilter1,
      },
      "suppliers-list": {
        categoryFilters: selectFilter1,
      },
      "view-purchase-requests-list": {
        status: selectFilter1,
      },
      "view-budget-list": {
        status: selectFilter1,
      },
      "view-rfqs": {
        overallStatus: selectFilter1,
      },
    };
    console.log("extraparmsobj", extraparmsobj[moduleKey]);
    return extraparmsobj[moduleKey];
  };

  const comments = async (comments: any) => {
    try {
      const {
        sortOrder,
        pageSize,
        direction,
        pageDiff,
        searchQuery,
        dateRange,
        customDateRange,
        siteId,
        materialId,
        categoryId,
        contractorId,
        supplierId,
        page, //reports
      } = params;

      const apiResponse = await miscService.excelExportRequest({
        ...reportConfiguration[moduleKey],
        comments,
        paginationData: {
          ...getExtraPaginationParams(moduleKey),
          pageSize: pageSize,
          startDate: dateRange?.from,
          endDate: dateRange?.to,
          ...(reportType == "transactionFlow"
            ? {
                sortOrder: sortOrder,
                direction: direction,
                pageDiff: pageDiff,
                searchQuery: searchQuery,
              }
            : {
                page: page,
                contractorIds: contractorId ? contractorId : undefined,
                siteIds: siteId ? siteId : undefined,
                supplierIdS: supplierId ? supplierId : undefined,
                materialIds: materialId ? materialId : undefined,
                categoryIds: categoryId ? categoryId : undefined,
              }),
        },
      });
      const { status } = apiResponse;
      if (status) {
        dispatch(setHighlight(true));
        Swal.fire({
          title: "Success",
          text: "Request Submited Successfully",
          icon: "success",
        });
      }
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  return (
    <>
      {showAsButton ? (
        <UncontrolledDropdown size={"md"}>
          {" "}
          <div
            onClick={() => handelExcelClick()}
            className="btn btn-white btn-outline-light"
          >
            <Icon name="download-cloud"></Icon>
            <span>Export </span>
          </div>
        </UncontrolledDropdown>
      ) : (
        <Link to={`#`} onClick={() => handelExcelClick()}>
          <Icon name="download-cloud"></Icon>
          <span>Export</span>
        </Link>
      )}
      <CommentsModal
        isModalVisible={showCommentsModal}
        setIsModalVisible={setShowCommentsModal}
        comments={comments}
      />
    </>
  );
};

const CommentsModal = ({
  isModalVisible,
  setIsModalVisible,
  comments,
}: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        comments(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <AntModal
      title="Comments"
      visible={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="rejectForm">
        <Form.Item
          name="reason"
          label="Comments"
          rules={[{ required: true, message: "Please input your Comments!" }]}
        >
          <Input.TextArea placeholder="Type your Comments here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Request
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </AntModal>
  );
};

export default Export;

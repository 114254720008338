import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Card, Col, Row } from "reactstrap";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { DatePicker, Empty, Flex, Spin, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import contractorsApis from "../../api/master/contractors";
import dayjs from "dayjs";
import reportApis from "../../api/reports";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import FormSelect from "../form-components/SelectComponent";
import supplierApis from "../../api/master/supplier";
import misc from "../../api/misc/dropDowns";
import FormDatePicker from "../form-components/DatePicker";
import FormInput from "../form-components/InputComponent";
import Export from "../../components/export/Export";
const { RangePicker } = DatePicker;

//  This template has site, contractor, supplier, dateRange, customDateRange filters with table pagination

function ReportTemplate2({
  pageTitle,
  moduleKey,
  columns,
  filtersOption,
  previousPath,
  extra,
  pageDescription
}: {
  pageTitle: string;
  pageDescription?: string;
  moduleKey: string;
  columns: any;
  filtersOption: {
    site?: boolean;
    contractor?: boolean;
    dateRange?: boolean;
    supplier?: boolean;
    aging?: boolean;
    material?: boolean;
    category?: boolean;
    stockAvailability?: boolean;
    date?: boolean;
    classPercentage?: boolean;
  };
  previousPath: string;
  extra?: any;
}) {
  const [loading, setLoading] = useState(!filtersOption?.classPercentage);
  const navigate = useNavigate();
  const { data: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [siteOptions, setSiteOptions] = useState<any>([]);
  const [materialOptions, setMaterialOptions] = useState<any>([]);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [supplierOptions, setSupplierOptions] = useState<any>([]);
  const stockAvailabilityOptions = [
      {
        label: "= Zero",
        value: "nil",
      },
      {
        label: "> Zero",
        value: "notNil",
      },
    ]
  const [contractorOptions, setContractorOptions] = useState<any>([]);
  const [customDateRange, setCustomDateRange] = useState<any>();
  const [dataSource, setDataSource] = useState([]);
  const [currentAvailability, setCurrentAvailability] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "40", "50"],
  });
  const [classPercentageInitialValue, setClassPercentageInitialValue] =
    useState<any>(null);

  const methods = useForm({
    mode: "onChange",
  });

  const {
    watch,
    setValue,
  } = methods;

  const supplier = watch("supplier");
  const stockAvailability = watch("stockAvailability");
  const site = watch("site");
  const contractor = watch("contractor");
  const dateRange = watch("dateRange");
  const date = watch("date");
  const aging = watch("aging");
  const material = watch("material");
  const category = watch("category");
  const classPercentage = watch("classPercentage");
  const intervalOfDays = watch("intervalOfDays");
  const classPercentageA = watch("classPercentageA");
  const classPercentageB = watch("classPercentageB");
  const classPercentageC = watch("classPercentageC");

  // const fetchContractorList = async (siteId: any) => {
  //   try {
  //     if (!filtersOption.contractor || !siteId) return;
  //     const { data, status } = await contractorsApis.fetchContractorBySiteId({
  //       id: siteId + "",
  //     });
  //     if (status) {
  //       let allContractors: any = [];
  //       let _contractorOptions: any = [];
  //       data.forEach((item: any) => {
  //         allContractors.push(item?.contractorID?.toString());
  //         _contractorOptions.push({
  //           label: item?.companyName,
  //           value: item?.contractorID?.toString(),
  //         });
  //       });

  //       // setContractor(allContractors?.join(","));
  //       setValue("contractor", allContractors?.join(","));
  //       if (_contractorOptions.length > 1) {
  //         _contractorOptions = [
  //           {
  //             label: "ALL",
  //             value: allContractors?.join(","),
  //           },
  //           ..._contractorOptions,
  //         ];
  //       }
  //       setContractorOptions(_contractorOptions);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchData = async (params: any) => {
    console.log("params", params, date);
    const { siteId: site, categoryId: category, materialId: material } = params;
    try {
      let response: any = {};
      switch (moduleKey) {
        case "InventorySummaryReport":
          response = await reportApis.getInventorySummaryReport({
            page: params.page,
            pageSize: params.pageSize,
            siteIds: site,
            endDate: date !== null ? dayjs(date).format("YYYY-MM-DD") : null,
            stockAvailability: stockAvailability,
          });
          break;

        case "InventoryAgingReport":
          response = await reportApis.fetchInventoryAgingMaterials({
            page: params.page,
            sortOrder: "ASC",
            pageSize: params.pageSize,
            siteIds: site,
            endDate: date !== null ? dayjs(date).format("YYYY-MM-DD") : null,
            materialIds: material,
            intervalCount: params.interval,
            daysGap: params.intervalDays,
          });
          break;

        case "InventoryValueReport":
          response = await reportApis.fetchInventoryValueReport({
            page: params.page,
            pageSize: params.pageSize,
            siteIds: site,
            endDate: date !== null ? dayjs(date).format("YYYY-MM-DD") : null,
          });
          break;

        case "materialsIssuedToSite":
          response = await reportApis.fetchMaterialIssueToSite({
            page: params.page,
            pageSize: params.pageSize,
            siteIds: site,
          });
          break;
        case "inventoryMaterialUtilizationReportList":
          response = await reportApis.fetchInventoryMaterialUtilization({
            page: params.page,
            pageSize: params.pageSize,
            siteIds: site,
          });
          break;
        case "InventoryValueDetailReport":
          response = await reportApis.fetchInventoryValueInvoiceReport(
            {
              inventoryId: Number(extra?.id),
              historyId:
                extra?.historyId == "null" ? null : Number(extra?.historyId),
            },
            { page: params.page, pageSize: params.pageSize }
          );
          break;

        case "committedStockReport":
          response = await reportApis.fetchCommittedStockReport(
            {
              inventoryId: Number(extra?.id),
              historyId:
                extra?.historyId == "null" ? null : Number(extra?.historyId),
            },
            { page: params.page, pageSize: params.pageSize }
          );
          break;

        case "ABCClassification":
          response = await reportApis.fetchMaterialAbcClassificationList({
            // page: params.page,
            // pageSize: params.pageSize,
            siteIds: site,
            startDate: params.startDate,
            endDate: params.endDate,
            A: classPercentageA,
            B: classPercentageB,
            C: classPercentageC,
          });
          break;

        case "InventoryTrackReport":
          response = await reportApis.fetchInventoryTrackReport({
            page: params.page,
            pageSize: params.pageSize,
            endDate: date !== null ? dayjs(date).format("YYYY-MM-DD") : null,
            categoryId: category,
            materialId: material,
            siteId: site,
          });
          break;

        case "/material-tracking-report-list":
          response = await reportApis.fetchMaterialTrackingReportList({
            page: params.page,
            pageSize: params.pageSize,
            siteId: site,
            startDate: params.startDate,
            endDate: params.endDate,
            materialIds: material,
            materialId: material,
            categoryId: category,
          });
          break;
        case "/material-consumption-report-list":
          response = await reportApis.fetchMaterialConsumptionReportList({
            page: params.page,
            pageSize: params.pageSize,
            siteId: site,
            // startDate: params.startDate,
            // endDate: params.endDate,
            materialId: material,
            categoryId: category,
          });
          break;
        case "/material-request-summary-list":
          response = await reportApis.fetchMaterialRequestSummaryList({
            page: params.page,
            pageSize: params.pageSize,
            siteId: site,
            // startDate: params.startDate,
            // endDate: params.endDate,
            materialId: material,
            categoryId: category,
          });
          break;
        case "/material-issue-summary-list":
          response = await reportApis.fetchMaterialIssueSummaryList({
            page: params.page,
            pageSize: params.pageSize,
            siteId: site,
            // startDate: params.startDate,
            // endDate: params.endDate,
            materialId: material,
            categoryId: category,
          });
          break;
        case "/material-utilized-summary-list":
          response = await reportApis.fetchMaterialUtilizedSummaryList({
            page: params.page,
            pageSize: params.pageSize,
            siteId: site,
            // startDate: params.startDate,
            // endDate: params.endDate,
            materialId: material,
            categoryId: category,
          });
          break;
        case "/material-damage-summary-list":
          response = await reportApis.fetchMaterialDamageSummaryList({
            page: params.page,
            pageSize: params.pageSize,
            siteId: site,
            // startDate: params.startDate,
            // endDate: params.endDate,
            materialId: material,
            categoryId: category,
          });
          break;
        case "/material-summary-list":
          response = await reportApis.fetchMaterialSummaryList({
            page: params.page,
            pageSize: params.pageSize,
            siteId: site,
            // startDate: params.startDate,
            // endDate: params.endDate,
            materialId: material,
            categoryId: category,
          });
          break;
        case "MaterialMovementSummaryDetailReport":
          response = await reportApis.fetchMaterialSummaryDetailList({
            page: params.page,
            pageSize: params.pageSize,
            siteId: extra?.customData?.siteId,
            // startDate: params.startDate,
            // endDate: params.endDate,
            materialId: extra?.customData?.materialId,
            categoryId: extra?.customData?.categoryId,
          });
          break;
        case "materialUtilizationSummaryDetailReport":
          response = await reportApis.fetchAvgMaterialUtilizedBreakup({
            page: params.page,
            pageSize: params.pageSize,
            siteId: extra?.customData?.siteId,
            // startDate: params.startDate,
            // endDate: params.endDate,
            materialId: extra?.customData?.materialId,
            categoryId: extra?.customData?.categoryId,
          });
          break;
        default:
          alert("Invalid Module Key" + moduleKey);
      }
      const { response: _response, status }: any = response;
      if (status) {
        setDataSource(_response?.data || _response);
        if (moduleKey === "InventoryTrackReport") {
          setCurrentAvailability(_response?.closingStock);
        }
        setPagination((pre: any) => ({
          ...pre,
          current: _response?.pagination?.currentPage,
          pageSize: _response?.pagination?.pageSize,
          total: Number(_response?.pagination?.totalItems),
        }));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const convertToDates = (dateType: string) => {
    try {
      switch (dateType) {
        case "Month to Date":
          return {
            from: dayjs().startOf("month").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        case "Quarter to Date":
          return {
            from: dayjs()
              .subtract(3, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        case "Year to Date":
          return {
            from: dayjs().startOf("year").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        default:
          if (!customDateRange)
            return {
              from: dayjs()?.format("YYYY-MM-DD"),
              to: dayjs()?.format("YYYY-MM-DD"),
            };
          return {
            from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
            to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
          };
      }
    } catch (error) {
      return {
        from: dayjs()?.format("YYYY-MM-DD"),
        to: dayjs()?.format("YYYY-MM-DD"),
      };
    }
  };
  const formatParamsForFetchData = ({
    supplier,
    site,
    contractor,
    dateRange,
    customDateRange,
    date,
    pagination,
    aging,
    material,
    category,
    stockAvailability,
    classPercentage,
    intervalOfDays,
    classPercentageA,
    classPercentageB,
    classPercentageC,
  }: any) => {
    if (site) {
      if (
        (dateRange == "Custom Date" && !customDateRange) ||
        (filtersOption?.contractor && !contractor) ||
        (filtersOption?.supplier && !supplier) ||
        (filtersOption?.material &&
          !material &&
          moduleKey == "InventoryTrackReport") ||
        (filtersOption?.category &&
          !category &&
          moduleKey == "InventoryTrackReport") ||
        (filtersOption?.classPercentage && !classPercentageA) ||
        (filtersOption?.classPercentage && !classPercentageB) ||
        (filtersOption?.classPercentage && !classPercentageC) ||
        (filtersOption?.classPercentage &&
          Number(classPercentageA) +
          Number(classPercentageB) +
          Number(classPercentageC) !=
          100) ||
        (filtersOption?.site && !site) ||
        (filtersOption?.aging && !aging) ||
        (filtersOption?.aging && !intervalOfDays)
      ) {
        // conditions to return if any of the above condition fails
        setClassPercentageInitialValue(null);
        return;
      }
      if (!classPercentageInitialValue) setClassPercentageInitialValue("... ");
      setLoading(true);
      const { from, to } = convertToDates(dateRange);
      let paramsObj: any = {};
      if (filtersOption?.site) paramsObj.siteId = site + "";
      if (filtersOption?.supplier) paramsObj.supplierId = supplier;
      if (filtersOption?.material) paramsObj.materialId = material;
      if (filtersOption?.category) paramsObj.categoryId = category;
      if (filtersOption?.stockAvailability)
        paramsObj.stockAvailability = stockAvailability;
      if (filtersOption?.contractor) paramsObj.contractorId = contractor;
      if (filtersOption?.aging) paramsObj.interval = aging;
      if (filtersOption?.aging) paramsObj.intervalDays = intervalOfDays;
      console.log("params:::qqq");
      fetchData({
        ...paramsObj,
        contractorId: null, // remove it once the bug is fixed
        startDate: from,
        endDate: to,
        page: pagination.current,
        pageSize: pagination.pageSize,
      });
    } else if (!filtersOption?.site) {
      setLoading(true);
      fetchData({
        page: pagination.current,
        pageSize: pagination.pageSize,
      });
    }
  };
  useEffect(() => {
    formatParamsForFetchData({
      classPercentageA,
      classPercentageB,
      classPercentageC,
      aging,
      intervalOfDays,
      site,
      supplier,
      material,
      category,
      stockAvailability,
      contractor,
      dateRange,
      customDateRange,
      date,
      pagination: {
        current: 1,
        pageSize: pagination?.pageSize,
      },
    });
  }, [
    site,
    supplier,
    stockAvailability,
    contractor,
    dateRange,
    customDateRange,
    date,
    aging,
    material,
    // category,
    classPercentage,
    intervalOfDays,
    classPercentageA,
    classPercentageB,
    classPercentageC,
  ]);
  // }, [site, supplier, contractor, dateRange, customDateRange,aging,intervalOfDays]);

  useEffect(() => {
    setValue("material", "");
    if (category) {
      fetchMaterialFilter(category);
    }
  }, [category]);
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth >= 990) {
      setSm(false);
    }
  };

  const fetchSupplierFilter = async () => {
    try {
      if (!filtersOption.supplier) return;
      const { data, status } = await supplierApis.fetchSupplierFilter();
      if (status) {
        let _supplierOptions: any = [];
        let allSuppliers: any = [];
        data.forEach((item: any) => {
          allSuppliers.push(item?.supplierOrgID?.toString());
          _supplierOptions.push({
            label: item?.supplierName,
            value: item?.supplierOrgID?.toString(),
          });
        });
        setValue("supplier", allSuppliers?.join(","));
        if (_supplierOptions.length > 1) {
          _supplierOptions = [
            {
              label: "ALL",
              value: allSuppliers?.join(","),
            },
            ..._supplierOptions,
          ];
        }
        setSupplierOptions(_supplierOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterialFilter = async (categoryId: any) => {
    console.log("categoryId", categoryId);
    try {
      if (!filtersOption.material || !categoryId) return;
      const { data, status } = await misc.fetchProductByCategoryId(categoryId);
      if (status) {
        let _materialOptions: any = [];
        let allMaterial: any = [];
        data.forEach((item: any) => {
          allMaterial.push(item?.materialID?.toString());
          _materialOptions.push({
            label: item?.productName,
            value: item?.materialID?.toString(),
          });
        });
        // setValue("material", allMaterial?.join(","));
        if (_materialOptions.length > 1) {
          _materialOptions = [
            // {
            //   label: "ALL",
            //   value: allMaterial?.join(","),
            // },
            ..._materialOptions,
          ];
        }
        setMaterialOptions(_materialOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategoryFilter = async () => {
    try {
      if (!filtersOption.category) return;
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        let _categoryOptions: any = [];
        let allCategory: any = [];
        data.forEach((item: any) => {
          allCategory.push(item?.id?.toString());
          _categoryOptions.push({
            label: item?.category,
            value: item?.id?.toString(),
          });
        });
        // setValue("category", allCategory?.join(","));
        if (_categoryOptions.length > 1) {
          _categoryOptions = [
            // {
            //   label: "ALL",
            //   value: allCategory?.join(","),
            // },
            ..._categoryOptions,
          ];
        }
        setCategoryOptions(_categoryOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    viewChange();
    fetchSupplierFilter();
    // fetchMaterialFilter();
    fetchCategoryFilter();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility && filtersOption?.site) {
        const allSites: any = [];
        let _siteOptions: any = [];
        siteAccessibility?.forEach((item: any) => {
          allSites.push(item.siteID);
          _siteOptions.push({ label: item.siteName, value: item.siteID });
        });
        if (moduleKey !== "InventoryTrackReport") {
          setValue("site", allSites?.join(","));
          // setSite(allSites.join(","));
          formatParamsForFetchData({
            classPercentageA,
            classPercentageB,
            classPercentageC,
            aging,
            intervalOfDays,
            site: allSites?.join(","),
            supplier,
            material,
            category,
            stockAvailability,
            contractor,
            dateRange,
            customDateRange,
            date,
            pagination: {
              current: 1,
              pageSize: pagination?.pageSize,
            },
          });
        }

        if (_siteOptions?.length > 1) {
          if (moduleKey == "InventoryTrackReport") {
            _siteOptions = [
              // {
              //   label: "ALL",
              //   value: allSites?.join(","),
              // },
              ..._siteOptions,
            ];
          } else {
            _siteOptions = [
              {
                label: "ALL",
                value: allSites?.join(","),
              },
              ..._siteOptions,
            ];
          }
        }
        setSiteOptions(_siteOptions);
      }
      if (filtersOption?.dateRange) {
        setValue("dateRange", "Quarter to Date");
      }
      if (filtersOption?.aging) {
        setValue("aging", 3);
        setValue("intervalOfDays", 15);
      }
    }
  }, [currentUser]);

  const getAgingIntervalColumns = () => {
    if (aging == 1) {
      return [
        {
          title: ">= 1 days",
          dataIndex: ">= 1 days",
          key: ">= 1 days",
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: ">= 1 Value",
          dataIndex: ">= 1 value",
          key: ">= 1 value",
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>{text}</span>
            </div>
          ),
        },
      ];
    } else {
      let newColumns: any = [];
      let i = 0,
        intervalCount = 0;
      for (i = 0; i < aging - 1; i++) {
        newColumns.push({
          title: `${intervalCount + 1}-${intervalCount + intervalOfDays} days`,
          dataIndex: `${intervalCount + 1}-${intervalCount + intervalOfDays
            } days`,
          key: `${intervalCount + 1}-${intervalCount + intervalOfDays} days`,
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>{text}</span>
            </div>
          ),
        });
        newColumns.push({
          title: `${intervalCount + 1}-${intervalCount + intervalOfDays} value`,
          dataIndex: `${intervalCount + 1}-${intervalCount + intervalOfDays
            } value`,
          key: `${intervalCount + 1}-${intervalCount + intervalOfDays} value`,
          render: (text: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>{text}</span>
            </div>
          ),
        });
        intervalCount += intervalOfDays;
      }
      newColumns.push({
        title: `> ${intervalCount} days`,
        dataIndex: `>= ${intervalCount + 1} days`,
        key: `>= ${intervalCount + 1} days`,
        render: (text: any, record: any) => (
          <div style={{ textAlign: "right" }}>
            <span>{text}</span>
          </div>
        ),
      });
      newColumns.push({
        title: `> ${intervalCount} value`,
        dataIndex: `>= ${intervalCount + 1} value`,
        key: `>= ${intervalCount + 1} value`,
        render: (text: any, record: any) => (
          <div style={{ textAlign: "right" }}>
            <span>{text}</span>
          </div>
        ),
      });
      return newColumns;
    }
  };

  return (
    <>
      <Head title="View All Reports" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className="card-inner card-inner-lg"
              style={{ minHeight: "75vh" }}
            >
              {sm && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>{pageTitle}</span>
                    </BlockTitle>
                    <BlockDes className="text-soft">
                      <p>{pageDescription}</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <div className="actions clearfix">
                    <ul
                      className="m-auto d-flex align-items-center justify-content-center p-0"
                      style={{
                        flexDirection: "row-reverse",
                      }}
                    >
                      <li>
                        <Export
                          data={{
                            supplierId: supplier,
                            contractorId: contractor,
                            dateRange: dateRange && convertToDates(dateRange),
                            customDateRange,
                            aging,
                            intervalOfDays,
                            siteId: site,
                            materialId: material,
                            categoryId: category,
                            page: pagination.current,
                            pageSize: pagination.pageSize,
                          }}
                          moduleKey={moduleKey}
                          reportType="Reports"
                        />
                      </li>
                      <li className="pt-0 pb-0">
                        <Button
                          className="toggle"
                          color="primary"
                          size="sm"
                          onClick={async () => {
                            navigate(previousPath);
                          }}
                        >
                          <Icon name="arrow-left" />
                          <span>Back</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </BlockBetween>
              </BlockHead>
              {/* content start here */}
              <div className="d-flex align-items-center flex-wrap mb-2">
                <FormProvider {...methods}>
                  <form className="w-100 d-flex align-items-center justify-content-start">
                    {filtersOption?.site && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="site"
                          label="Site Filter"
                          required={true}
                          hideRequiredMark={true}
                          placeholder="Select Site"
                          options={siteOptions}
                        />
                      </div>
                    )}

                    {filtersOption?.stockAvailability && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="stockAvailability"
                          required={true}
                          hideRequiredMark={true}
                          label="Stock Availability Filter"
                          placeholder="Select Stock Availability"
                          options={stockAvailabilityOptions}
                        />
                      </div>
                    )}

                    {filtersOption?.contractor && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="contractor"
                          required={true}
                          hideRequiredMark={true}
                          label="Contractor Filter"
                          placeholder="Select Contractor"
                          options={contractorOptions}
                        />
                      </div>
                    )}
                    {filtersOption?.supplier && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="supplier"
                          required={true}
                          hideRequiredMark={true}
                          label="Supplier Filter"
                          placeholder="Select Supplier"
                          options={supplierOptions}
                        />
                      </div>
                    )}

                    {filtersOption?.category && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="category"
                          // required={true}
                          hideRequiredMark={true}
                          label="Category Filter"
                          placeholder="Select Category"
                          options={categoryOptions}
                          onChange={(value: any) => {
                            formatParamsForFetchData({
                              classPercentageA,
                              classPercentageB,
                              classPercentageC,
                              aging,
                              intervalOfDays,
                              site,
                              supplier,
                              material,
                              category: value,
                              stockAvailability,
                              contractor,
                              dateRange,
                              customDateRange,
                              date,
                              pagination: {
                                current: 1,
                                pageSize: pagination?.pageSize,
                              },
                            });
                          }}
                        />
                      </div>
                    )}

                    {filtersOption?.material && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="material"
                          // required={true}
                          hideRequiredMark={true}
                          label="Material Filter"
                          placeholder="Select Material"
                          options={materialOptions}
                        />
                      </div>
                    )}

                    {filtersOption?.date && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormDatePicker
                          name="date"
                          label="Date Filter"
                          // required={true}
                          placeholder="Select Date"
                        />
                      </div>
                    )}

                    {filtersOption?.dateRange && (
                      <div className="w-50 d-flex align-items-end justify-content-start">
                        <div
                          className="mt-1 mb-0 w-40"
                          style={{ marginRight: 10 }}
                        >
                          <FormSelect
                            name="dateRange"
                            required={true}
                            hideRequiredMark={true}
                            // label=""
                            label={"Date Range Filter"}
                            // optionLabel="Date Range"
                            placeholder="Select Date Range"
                            options={[
                              {
                                value: "Month to Date",
                                label: "Month to Date",
                              },
                              {
                                value: "Quarter to Date",
                                label: "Quarter to Date",
                              },
                              { value: "Year to Date", label: "Year to Date" },
                              { value: "Custom Date", label: "Custom Date" },
                            ]}
                          />
                        </div>
                        <div className="mt-1 mb-0 w-50">
                          {dateRange === "Custom Date" && (
                            <RangePicker
                              value={
                                customDateRange
                                  ? [
                                    dayjs(customDateRange[0].toDate()),
                                    dayjs(customDateRange[1].toDate()),
                                  ]
                                  : null
                              }
                              onChange={(dates) => {
                                if (dates && dates[0] && dates[1]) {
                                  setCustomDateRange([dates[0], dates[1]]);
                                } else {
                                  setCustomDateRange(null);
                                }
                              }}
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    {filtersOption?.classPercentage && (
                      <div className="mt-1 mb-4" style={{ marginRight: 10 }}>
                        <label
                          className=""
                          style={{
                            fontWeight: "500",
                            color: "#344357",
                            fontSize: "0.875rem",
                          }}
                        >
                          Class Percentage Filter
                        </label>
                        <Row>
                          <Col>
                            <FormInput
                              name="classPercentageA"
                              required={true}
                              label={"A"}
                              placeholder="A"
                              type="number"
                            // className="w-full"
                            />
                          </Col>
                          <Col>
                            <FormInput
                              name="classPercentageB"
                              required={true}
                              label={"B"}
                              placeholder="B"
                              type="number"
                            // className="w-full"
                            />
                          </Col>
                          <Col>
                            <FormInput
                              name="classPercentageC"
                              required={true}
                              label={"C"}
                              placeholder="C"
                              type="number"
                            // className="w-full"
                            />
                          </Col>
                        </Row>
                      </div>
                    )}

                    {filtersOption?.aging && (
                      <>
                        <div
                          className="mt-1 mb-0 w-20"
                          style={{ marginRight: 10 }}
                        >
                          <FormSelect
                            name="aging"
                            required={true}
                            hideRequiredMark={true}
                            label="Aging Filter"
                            placeholder="Select Aging"
                            options={[
                              { label: "1 Interval", value: 1 },
                              ...Array.from({ length: 11 }, (_, i) => ({
                                label: i + 2 + " Intervals",
                                value: i + 2,
                              })),
                            ]}
                          />
                        </div>
                        <div
                          className="mt-1 mb-0 w-20"
                          style={{ marginRight: 10 }}
                        >
                          <FormSelect
                            name="intervalOfDays"
                            required={true}
                            hideRequiredMark={true}
                            label="Interval Of Days Filter"
                            placeholder="Select Interval Of Days"
                            options={[
                              { label: "1 day", value: 1 },
                              ...Array.from({ length: 29 }, (_, i) => ({
                                label: i + 2 + " days",
                                value: i + 2,
                              })),
                            ]}
                          />
                        </div>
                      </>
                    )}
                  </form>
                </FormProvider>
              </div>
              {(filtersOption?.site &&
                !site &&
                moduleKey == "InventoryTrackReport") ||
                (filtersOption?.category &&
                  !category &&
                  moduleKey == "InventoryTrackReport") ||
                (filtersOption?.material &&
                  !material &&
                  moduleKey == "InventoryTrackReport") ? (
                <div className="content clearfix">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Empty
                      description={`Please Select ${[
                        filtersOption?.site && !site ? "Site" : "",
                        filtersOption?.category && !category ? "Category" : "",
                        filtersOption?.material && !material ? "Material" : "",
                      ]
                        ?.filter(Boolean)
                        ?.join(", ")}`}
                    />
                  </div>
                </div>
              ) : (
                <div className="content clearfix">
                  {loading == true ? (
                    <>
                      <Flex align="center" gap="middle" className="pageLoader">
                        <Spin size="large" className="m-auto" />
                      </Flex>
                    </>
                  ) : !classPercentageInitialValue &&
                    filtersOption?.classPercentage ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Empty description="Please Enter Class Percentages, that should sum to only 100  ... " />
                    </div>
                  ) : dataSource?.length > 0 ? (
                    <div
                      style={{
                        width: screenSize - 100 + "px",
                        margin: "0px auto",
                      }}
                    >
                      <Table
                        className="customTable"
                        columns={
                          filtersOption?.aging
                            ? [...columns, ...getAgingIntervalColumns()]
                            : columns
                        }
                        dataSource={
                          filtersOption?.aging
                            ? dataSource?.map((oldObj: any) => {
                              return {
                                ...oldObj,
                                ...oldObj?.agingDetails,
                              };
                            })
                            : dataSource
                        }
                        bordered
                        scroll={{ x: extra?.tableScrollX || 1500 }}
                        size="middle"
                        pagination={pagination}
                        onChange={(newPagination, filters, sorter, extra) => {
                          formatParamsForFetchData({
                            aging,
                            intervalOfDays,
                            material,
                            category,
                            classPercentage,
                            site,
                            supplier,
                            contractor,
                            dateRange,
                            customDateRange,
                            pagination: {
                              ...pagination,
                              current: newPagination.current,
                              pageSize: newPagination.pageSize,
                            },
                          });
                        }}
                        summary={
                          moduleKey === "InventoryTrackReport"
                            ? () => (
                              <Table.Summary.Row>
                                <Table.Summary.Cell
                                  index={0}
                                  colSpan={columns.length}
                                >
                                  <div
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                      marginRight: "8%",
                                    }}
                                  >
                                    Current Availability :{" "}
                                    {currentAvailability}
                                    {dataSource[0]["primaryUomName"]}
                                  </div>
                                </Table.Summary.Cell>
                                {/* {
                                  da
                                } */}
                              </Table.Summary.Row>
                            )
                            : undefined
                        }
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Empty description="No Data Found " />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* content end here */}
          </div>
        </Card>
      </Content>
    </>
  );
}

export default ReportTemplate2;

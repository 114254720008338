import React, { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  Button,
  Row,
  Col,
  Spinner,
  Alert,
} from "reactstrap";
import { Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { FormProvider, useForm } from "react-hook-form";
import miscService from "../../../api/misc/misc";
import { Icon } from "../../../components/Component";
import FormInput from "../../form-components/InputComponent";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import purchaseRequestApis from "../../../api/master/purchaseRequest";
import quotationApis from "../../../api/master/quotations";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import ExportToXlsx from "../../../utils/exportToXlsx";
const Configuration: any = {
  "add-new-purchase-request": {
    title: "Bulk Material Upload",
    requestType: "purchaseRequestCreate",
  },
  "rfq-creation": {
    title: "Bulk Material Upload",
    requestType: "rfqCreation",
  },
  "quot-material-list": {
    title: "Bulk Material Upload",
    requestType: "quotMaterialList",
  },
};

const BulkMaterialUpload = ({
  moduleKey,
  append,
  extra,
}: {
  moduleKey?: any;
  append?: any;
  extra?: any;
}) => {
  const [deliveryAddressList, setDeliveryAddressList] = useState([]);
  const [materials, setMaterials] = useState<any>([]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [blockEdit, setBlockEdit] = useState(false);
  const [response, setResponse] = useState({
    status: "",
    message: "",
  });

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = methods;
  const files = watch("attachment");

  useEffect(() => {
    setResponse({ status: "", message: "" });
  }, [files]);

  const [showModal, setShowModal] = useState(false);
  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((address: any) => {
          const {
            id,
            deliveryLocationName,
            city,
            addressLine1,
            addressLine2,
            pincode,
            countryName,
            stateName,
            districtName,
          } = address;
          return {
            Id: id,
            deliveryLocationName,
            addressLine1,
            addressLine2,
            city,
            districtName,
            stateName,
            countryName,
            pincode,
          };
        });

        setDeliveryAddressList(deliveryAddressOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchMaterialList = async () => {
    try {
      const { data, status } = await miscService.fetchProductFiltered();
      if (status) {
        const materialOption = data.map((item: any) => ({
          "Category Name": item?.categoryName,
          materialName: item?.productName,
          materialId: item?.materialID?.toString(),
        }));
        setMaterials(materialOption);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMaterialList();
    fetchDeliveryAddress();
  }, [moduleKey]);

  const handleUpload = async () => {
    try {
      const attachment = files;
      if (!attachment || !attachment[0]) {
        setResponse({
          status: "error",
          message: "Please select a file",
        });
        setBlockEdit(false);
        setLoading(false);
        return;
      }
      uploadFile(
        attachment,
        moduleKey,
        setResponse,
        "File Uploaded Successfully"
      );
    } catch (error) {}
  };

  const uploadFile = async (
    attachment: any,
    moduleKey: string,
    setResponse: any,
    successResponse: any
  ) => {
    try {
      const _file = attachment?.[0];
      const formData: any = new FormData();
      formData.append("files", _file);
      let request: any = {
        deliveryLevelType:
          extra?.formData?.deliveryAddressType || "ORDER_LEVEL",
        deliveryDateType: extra?.formData?.needByDateType || "ORDER_LEVEL",
      };
      let lines: any = [];
      extra?.fields?.forEach((item: any) => {
        let obj: {
          materialId: number;
          categoryId: number;
          quantity?: number;
          uomId?: number;
          deliveryDate?: any;
          deliveryAddressId?: Number;
        } = {
          materialId: item?.materialName,
          categoryId: item?.materialCategory,
        };
        if (item?.quantity) obj.quantity = item?.quantity;
        if (item?.uomId) obj.uomId = item?.uomId;
        if (extra?.formData?.deliveryAddressType == "LINE_LEVEL")
          obj.deliveryAddressId = item?.deliveryAddressId;

        if (extra?.formData?.needByDateType == "LINE_LEVEL")
          obj.deliveryDate = dayjs(item?.needByDate).format("DD-MMM-YYYY");

        lines.push(obj);
      });

      request.lines = lines;
      if (moduleKey == "quot-material-list") request = { ...extra };
      formData.append("request", JSON.stringify(request));
      let res;
      switch (moduleKey) {
        case "add-new-purchase-request":
          res = await purchaseRequestApis.materialBulkUploadCreate(formData);
          break;
        case "rfq-creation":
          res = await quotationApis.materialBulkUploadCreate(formData);
          break;
        case "quot-material-list":
          res = await quotationApis.rfqPriceBulkUpload(formData);
          break;
        default:
          res = {
            status: true,
            message: "sucess",
            response: {
              total: "number",
              isError: 1,
              errorCount: "number",
              lines: [
                {
                  materialId: 63,
                  materialName: "some material name 1",
                  categoryId: 43,
                  categoryName: "some category name ",
                  quantity: 100,
                  uomId: 27,
                  uomName: "Bags",
                  remarks: "String(optional)",
                  specification: "String(optional)",
                  errorFlag: true,
                  errorMessage: "String(optional)",
                  deliveryDate: "24-03-2025",
                  deliveryAddressId: "Number (optional)",
                },
                {
                  materialId: 63,
                  materialName: "some material name 2",
                  categoryId: 43,
                  categoryName: "some category name ",
                  quantity: 100,
                  uomId: 27,
                  uomName: "Bags",
                  remarks: "String(optional)",
                  specification: "String(optional)",
                  errorFlag: true,
                  errorMessage: "String(optional)",
                },
                {
                  materialId: 63,
                  materialName: "some material name 3",
                  categoryId: 43,
                  categoryName: "some category name ",
                  quantity: 100,
                  uomId: 27,
                  uomName: "Bags",
                  remarks: "String(optional)",
                  specification: "String(optional)",
                  errorFlag: true,
                  errorMessage: "String(optional)",
                },
              ],
            },
            error: null,
          };
      }
      const { status, error, message, response: data } = res || {};
      console.log(data);
      if (status) {
        setResponse({
          status: "success",
          message: successResponse,
        });
        if (moduleKey == "quot-material-list") {
          append({
            ...data,
          });
        } else {
          const { lines } = data || [];
          lines?.forEach((item: any) => {
            const {
              materialId: materialName,
              materialName: materialLabel,
              categoryId: materialCategory,
              categoryName: categoryLabel,
              quantity,
              specification,
              errorFlag,
              errorMessage,
              remarks,
              uomId,
              uomName: uomLabel,
            } = item;
            let obj: any = {
              key: nanoid(),
              materialCategory,
              categoryLabel,
              materialName,
              materialLabel,
              quantity,
              specification,
              remarks,
              uomId,
              uomLabel,
              errorFlag,
              errorMessage,
            };
            if (extra?.formData?.deliveryAddressType == "LINE_LEVEL")
              obj.deliveryAddressId = item?.deliveryAddressId;
            if (extra?.formData?.needByDateType == "LINE_LEVEL")
              obj.needByDate = dayjs(item?.deliveryDate);
            append(obj);
          });
        }
        return;
      }
      setResponse({
        status: "error",
        message: error,
      });
      setBlockEdit(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ModalContent = ({ moduleKey }: any) => {
    switch (moduleKey) {
      case "quot-material-list":
        return (
          <>
            <p>
              You're about to submit a quotation for multiple materials. To
              ensure a smooth import, please upload the file below.
            </p>

            <div className="text-right mt-3"></div>
            <p>
              <strong>Note : </strong> Do not modify column names or any
              existing data. All fields in the template are mandatory.
            </p>
            <Col md={4}>
              {files && files[0]?.name && (
                <div className="form-group m-0">
                  <div className="form-label-wrapper">
                    <label className="form-label" htmlFor={"attachment"}>
                      File Upload Area
                    </label>
                  </div>
                  <div
                    className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                    style={{ borderRadius: "3px" }}
                  >
                    <span>{files && files[0]?.name}</span>
                    {!blockEdit && (
                      <span
                        onClick={() => {
                          setValue("attachment", null);
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          display: "block",
                          fontSize: 14,
                        }}
                      >
                        <Icon name="cross"></Icon> &nbsp;
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: `${files && files[0]?.name ? "none" : "block"}`,
                }}
              >
                <FormInput
                  type="file"
                  name="attachment"
                  label="File Upload Area"
                  placeholder="File Upload Area"
                  accept=".xlsx" // ,.xls,.csv
                />
              </div>
            </Col>
          </>
        );
      case "add-new-purchase-request":
        return (
          <>
            <p>
              You're about to create multiple Lines of Materials at once. To
              ensure a smooth import, please{" "}
              <a
                href="https://procurexuatstg.blob.core.windows.net/procurexuatblob/PR-Material-Bulk-Upload.xlsx"
                target="_blank"
                style={{
                  textDecoration: "underline",
                }}
              >
                Download Template
              </a>{" "}
              and fill in the required supplier data accurately and completely,
              then upload the file below. you can check{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  ExportToXlsx(materials, "Materials");
                }}
                target="_self"
                style={{ textDecoration: "underline" }}
              >
                Materials
              </a>{" "}
              for reference
            </p>

            <div className="text-right mt-3"></div>
            <p>
              <strong>Note : </strong>
              All fields in the template are mandatory. Each supplier must have
              unique information to avoid errors during the import process.
            </p>
            <Col md={4}>
              {files && files[0]?.name && (
                <div className="form-group m-0">
                  <div className="form-label-wrapper">
                    <label className="form-label" htmlFor={"attachment"}>
                      File Upload Area
                    </label>
                  </div>
                  <div
                    className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                    style={{ borderRadius: "3px" }}
                  >
                    <span>{files && files[0]?.name}</span>
                    {!blockEdit && (
                      <span
                        onClick={() => {
                          setValue("attachment", null);
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          display: "block",
                          fontSize: 14,
                        }}
                      >
                        <Icon name="cross"></Icon> &nbsp;
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: `${files && files[0]?.name ? "none" : "block"}`,
                }}
              >
                <FormInput
                  type="file"
                  name="attachment"
                  label="File Upload Area"
                  placeholder="File Upload Area"
                  accept=".xlsx" // ,.xls,.csv
                />
              </div>
            </Col>
          </>
        );
      case "rfq-creation":
        const { deliveryAddressType, needByDateType } = extra?.formData;

        let url =
          "https://procurexuatstg.blob.core.windows.net/procurexuatblob/RFQBulkUpload.xlsx";
        if (deliveryAddressType == "LINE_LEVEL") {
          url =
            "https://procurexuatstg.blob.core.windows.net/procurexuatblob/RFQBulkUploadWithID.xlsx";
        }
        if (needByDateType == "LINE_LEVEL") {
          url =
            "https://procurexuatstg.blob.core.windows.net/procurexuatblob/RFQBulkUploadWithDate.xlsx";
        }
        if (
          deliveryAddressType == "LINE_LEVEL" &&
          needByDateType == "LINE_LEVEL"
        ) {
          url =
            "https://procurexuatstg.blob.core.windows.net/procurexuatblob/RFQBulkUploadWithDateAndID.xlsx";
        }

        return (
          <>
            <p>
              You're about to create multiple supplier records at once. To
              ensure a smooth import, please{" "}
              <a
                href={url}
                target="_blank"
                style={{
                  textDecoration: "underline",
                }}
              >
                Download Template
              </a>{" "}
              and fill in the required supplier data accurately and completely,
              then upload the file below. you can check{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  ExportToXlsx(deliveryAddressList, "Addresses");
                }}
                target="_self"
                style={{ textDecoration: "underline" }}
              >
                Addresses List
              </a>{" "}
              and{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  ExportToXlsx(materials, "Materials");
                }}
                target="_self"
                style={{ textDecoration: "underline" }}
              >
                Materials
              </a>{" "}
              for reference
            </p>

            <div className="text-right mt-3"></div>
            <p>
              <strong>Note : </strong>
              All fields in the template are mandatory. Each supplier must have
              unique information to avoid errors during the import process.
            </p>
            <Col md={4}>
              {files && files[0]?.name && (
                <div className="form-group m-0">
                  <div className="form-label-wrapper">
                    <label className="form-label" htmlFor={"attachment"}>
                      File Upload Area
                    </label>
                  </div>
                  <div
                    className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                    style={{ borderRadius: "3px" }}
                  >
                    <span>{files && files[0]?.name}</span>
                    {!blockEdit && (
                      <span
                        onClick={() => {
                          setValue("attachment", null);
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          display: "block",
                          fontSize: 14,
                        }}
                      >
                        <Icon name="cross"></Icon> &nbsp;
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: `${files && files[0]?.name ? "none" : "block"}`,
                }}
              >
                <FormInput
                  type="file"
                  name="attachment"
                  label="File Upload Area"
                  placeholder="File Upload Area"
                  accept=".xlsx" // ,.xls,.csv
                />
              </div>
            </Col>
          </>
        );
      default:
        <></>;
    }
    return <></>;
  };

  return (
    <>
      <UncontrolledDropdown size={"md"}>
        {" "}
        <div
          onClick={() => {
            setShowModal(true);
          }}
          className="btn btn-white btn-outline-light"
        >
          <Icon name="upload-cloud"></Icon>
          <span>Import</span>
        </div>
      </UncontrolledDropdown>

      <Modal
        title={Configuration[moduleKey]?.title}
        open={showModal}
        footer={null}
        onCancel={() => {
          setShowModal(false);
          setBlockEdit(false);
          // methods.reset()
          setValue("attachment", null);
          setLoading(false);
          setResponse(() => ({
            status: "",
            message: "",
          }));
        }}
        width={"60%"}
      >
        <FormProvider {...methods}>
          <form>
            <ModalContent moduleKey={moduleKey} />
            <Alert
              isOpen={response?.status === "error"}
              color="danger"
              className="start-0 w-100 py-1 mt-2"
              style={{
                top: 0,
                textAlign: "left",
                fontSize: "14px",
                cursor: "default",
              }}
            >
              <ReactTyped
                strings={[response?.message]}
                typeSpeed={30}
                showCursor={false}
              />
            </Alert>

            <Alert
              isOpen={response?.status === "success"}
              color="success"
              className="start-0 w-100 py-1 mt-2"
              style={{
                top: 0,
                textAlign: "left",
                fontSize: "14px",
                cursor: "default",
              }}
            >
              <p>
                {response?.message}, You can{" "}
                <span
                  className="inlineTableCellLink"
                  onClick={() => {
                    setShowModal(false);
                    setBlockEdit(false);
                    // methods.reset();
                    setValue("attachment", null);
                    setLoading(false);
                    setResponse(() => ({
                      status: "",
                      message: "",
                    }));
                  }}
                >
                  close
                </span>{" "}
                this popup
              </p>
            </Alert>

            <Row className="mt-4">
              <Col>
                {response?.status == "success" ? (
                  <></>
                ) : (
                  <ul className="actions clearfix d-flex">
                    <li>
                      {loading ? (
                        <Button color="primary" type="button" size="md">
                          <Spinner size="sm" color="light" />
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          type="button"
                          disabled={!files?.[0]}
                          onClick={() => {
                            setResponse(() => ({
                              status: "",
                              message: "",
                            }));
                            setBlockEdit(true);
                            setLoading(true);
                            handleUpload();
                          }}
                        >
                          Upload
                        </Button>
                      )}
                    </li>
                    {!loading && (
                      <li className="m-3 mt-0">
                        <Button
                          color="primary"
                          onClick={() => {
                            setShowModal(false);
                            // methods.reset();
                            setValue("attachment", null);
                          }}
                          className="btn-dim btn btn-secondary"
                        >
                          Cancel
                        </Button>
                      </li>
                    )}
                  </ul>
                )}
              </Col>
            </Row>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default BulkMaterialUpload;

import Swal from "sweetalert2";
import quotationApis from "../../../api/master/quotations";
import dayjs from "dayjs";

export const saveDraft = async (
  data: any,
  newDraft: any,
  navigate: any,
  setDisableSubmit: any,
  mode?: string
) => {
  console.log("data", data);
  // return;
  if (!newDraft) {
    await newOrder(data, navigate, setDisableSubmit, "DRAFT");
  }
  if (newDraft) {
    // await editDraftRFQ(data, navigate, setDisableSubmit, "DRAFT");
  }
};

export const newOrder = async (
  previousFormData: any,
  navigate: any,
  setDisableSubmit: any,
  mode: any
) => {
  console.log("DRAFT", mode);
  const _formData: any = new FormData();
  let _filesData: any = [];
  let quotationData: any = {
    ...previousFormData,
  };
  try {
    const quotationLines = await Promise.all(
      previousFormData?.items.map(async (lineItem: any, index: number) => {
        let obj = { ...lineItem };
        let finalObj: any = {
          quotationLineId: obj?.quotationLineId,
          materialId: Number(obj?.materialName),
          materialName: obj?.materialLabel || "",
          categoryId: Number(obj?.materialCategory),
          categoryName: obj?.categoryLabel || "",
          uomId: Number(obj?.uomId),
          quantity: Number(obj?.quantity),
          specification: obj?.specification || "",
          deliveryAddressId: Number(obj?.deliveryAddressId) || null,
          deliveryDate:
            (quotationData?.needByDateType === "LINE_LEVEL" &&
              obj?.needByDate &&
              dayjs(obj?.needByDate).format("DD-MMM-YYYY")) ||
            "",
          fileIndex: _filesData.length, // Only include fileIndex if there's a file
        };
        if (obj?.file && obj?.file[0]) {
          _formData.append("files", obj?.file[0]);
          _filesData.push({
            fileIndex: _filesData.length,
            module: "Quotation",
            moduleFileType: "Special Instructions",
            level: "LINE_LEVEL",
          });
        } else if (obj?.dms?.length > 0) {
          _filesData.push({
            fileIndex: _filesData.length,
            module: "Quotation",
            moduleFileType: "Special Instructions",
            level: "LINE_LEVEL",
          });
        }
        if (previousFormData?.needByDateType === "Line Level") {
          finalObj.needByDate = dayjs(obj?.needByDate).format("YYYY-MM-DD");
        }
        if (previousFormData?.deliveryAddressType == "Line Level") {
          finalObj.deliveryAddressId = Number(obj?.deliveryAddressId);
        }
        if (obj?.prLineId) {
          finalObj.prLineId = Number(obj?.prLineId);
        }
        return finalObj;
      })
    );

    const quotationHeader = {
      prId: quotationData?.prId || null,
      quotationId: quotationData?.quotationId || null,
      rfqId: quotationData?.rfqId || null,
      billingAddressId: quotationData?.billingAddressId || null,
      quotationFor: quotationData?.quotationFor || "",
      quotationDescription: quotationData?.quotationDescription || "",
      costCenter: quotationData?.costCenter || undefined,
      lastSubmissionDate:
        dayjs(quotationData?.lastSubmissionDate).format("YYYY-MM-DD") || "",
      deadlineType: quotationData?.deadlineType || "SOFT",
      siteId: quotationData?.siteId || null,
      contractorId: quotationData?.contractorId || null,
      additionalCharges: quotationData?.additionalCharges || "Flexible",
      deliveryLevelType: quotationData?.deliveryAddressType || "",
      deliveryAddressId:
        quotationData?.deliveryAddressType === "ORDER_LEVEL"
          ? quotationData?.deliveryAddressId
          : null,
      deliveryDateType: quotationData?.needByDateType || "",
      deliveryDate:
        quotationData?.needByDateType == "LINE_LEVEL"
          ? undefined
          : quotationData?.needByDate || "",
      autoRelease: quotationData?.autoRelease || "",
      paymentTerms: quotationData?.paymentTerms || "",
      preferredBrands: quotationData?.preferredBrands || "",
      expectedCreditDays: quotationData?.creditDays || null,

      approvalRequired: quotationData?.approvalRequired || "",
      supplierDocsRequired: quotationData?.supplierDocsRequired || "",
      status: mode == "DRAFT" ? "DRAFT" : null,
    };

    let additionalData = {
      selectedSuppliers: Object.keys(quotationData)
        .filter((key) => key.startsWith("supplier_") && quotationData[key])
        .map((key) => parseInt(key.replace("supplier_", ""))),
      publishTo: "MARKET_PLACE",
    };

    const approvals = JSON.parse(quotationData.approvalsData);
    if (approvals?.data?.length > 0 && quotationHeader.approvalRequired) {
      _formData.append("quotationApprovals", JSON.stringify(approvals?.data));
    } else {
      _formData.append("quotationApprovals", JSON.stringify([]));
    }
    if (quotationData.documents !== undefined) {
      let requiredDocs = quotationData.documents.documents?.map(
        (doc: any, index: number) => ({
          documentName: doc.documentName || "",
          mandatory: doc.mandatory || 0,
        })
      );
      console.log("requiredDocs", requiredDocs);
      _formData.append("requiredDocs", JSON.stringify(requiredDocs));
    } else {
      _formData.append("requiredDocs", JSON.stringify([]));
    }

    console.log("_formData", _formData);

    if (quotationData.attachments) {
      quotationData.attachments.forEach((attachment: any, index: number) => {
        if (!attachment) {
          return;
        }
        const file = attachment?.originFileObj;
        _formData.append(`files`, file);
        _filesData.push({
          fileIndex: _filesData.length,
          module: "Quotation",
          moduleFileType: "attachments",
          level: "HEADER_LEVEL",
        });
      });
    }
    console.log("_formData", _formData);

    if (quotationData.instructions) {
      const instructionsFile = new Blob([quotationData.instructions], {
        type: "text/html",
      });
      _formData.append(
        `files`,
        new File([instructionsFile], "instructions.html", {
          type: "text/html",
        })
      );
      _filesData.push({
        fileIndex: _filesData.length,
        module: "Quotation",
        moduleFileType: "instructions",
        level: "HEADER_LEVEL",
      });
    }

    console.log("_formData", _formData);

    if (quotationData.termsAndConditions) {
      const termsAndConditionsFile = new Blob(
        [quotationData.termsAndConditions],
        {
          type: "text/html",
        }
      );
      _formData.append(
        `files`,
        new File([termsAndConditionsFile], "termsandconditions.html", {
          type: "text/html",
        })
      );
      _filesData.push({
        fileIndex: _filesData.length,
        module: "Quotation",
        moduleFileType: "termsAndConditions",
        level: "HEADER_LEVEL",
      });
    }
    // if (quotationData.deliveryAddressType != "Line Level") {
    //   quotationData.deliveryAddressId = quotationDatadeliveryAddressId;
    // }
    if (previousFormData?.needByDateType == "Order Level") {
      quotationData.needByDate = dayjs(quotationData.needByDate).format(
        "YYYY-MM-DD"
      );
    }

    let selectedSuppliers = Object.keys(quotationData)
      .filter((key) => key.startsWith("supplier_") && quotationData[key])
      .map((key) => parseInt(key.replace("supplier_", "")));

    _formData.append("quotationHeader", JSON.stringify(quotationHeader));
    _formData.append("quotationLines", JSON.stringify(quotationLines));
    _formData.append("docKeys", JSON.stringify(_filesData));

    console.log("selectedSuppliers", selectedSuppliers);

    _formData.append("additionalData", JSON.stringify(additionalData));
    console.log("_formData", _formData);
    createQuotations(_formData, navigate, setDisableSubmit, mode);
  } catch (error) {
    console.log(error);
    setDisableSubmit(false);
  }
};

// export const editDraftRFQ = async (
//   previousFormData: any,
//   navigate: any,
//   setDisableSubmit: any,
//   mode: any
// ) => {
//   console.log("DRAFT", mode);
//   const _formData: any = new FormData();
//   let _filesData: any = [];
//   let quotationData: any = {
//     ...previousFormData,
//   };
//   try {
//     const _formData = new FormData();
//     const updatedLineItems: any = [];
//     const newfiledata: any = [];
//     const deletedFileData: any = [...deletedAttachments];
//     const updatedFileData: any = [];
//     materialData.addtionalData.selectedSuppliers = selectedArrayData;
//     materialData?.quotationLines?.forEach((data: any) => {
//       if (!data?.quotationLineId) {
//         if (data?.file && data?.file[0]) {
//           data.fileIndex = newfiledata.length;
//           const fileLine = new File([data.file[0]], data.file[0].name);
//           _formData.append("files", fileLine);
//           newfiledata.push({
//             fileIndex: newfiledata.length,
//             module: "Quotation",
//             moduleFileType: "Special Instructions",
//             level: "LINE_LEVEL",
//           });
//         }
//       } else {
//         const OData = OriginalData?.quotationLines?.find(
//           (item: any) => item.quotationLineId == data.quotationLineId
//         );
//         console.log("OData", OData);
//         let updatedItem: any = {};
//         updatedItem.quotationLineId = data.quotationLineId;
//         // if (Number(OData.quantity) !== Number(data.quantity)) {
//         //     updatedItem.quantity = Number(data.quantity);
//         // }

//         // if (Number(OData.categoryId) !== Number(data.categoryId)) {
//         //     updatedItem.categoryId = Number(data.categoryId);
//         // }

//         // if (OData.description !== data.description) {
//         //     updatedItem.description = data.description;
//         // }

//         if (Object.keys(updatedItem).length > 0) {
//           //     if (OriginalData?.needByDateType == "Line Level") {
//           //         updatedItem.needByDate = dayjs(data.needByDate).format(
//           //             "YYYY-MM-DD"
//           //         );
//           //     }
//           //     if (OriginalData?.deliveryAddressType == "Line Level") {
//           //         updatedItem.deliveryAddressId = Number(data.deliveryAddressId);
//           //     }
//           //     updatedItem.materialId = Number(data.materialId);
//           updatedItem.quotationLineId = data.quotationLineId;
//           //     updatedItem.uuid = OData.uuid;
//           updatedLineItems.push(updatedItem);
//         }

//         if (data?.file && data?.file[0]) {
//           if (OData?.dms[0]?.docId) {
//             deletedFileData.push({
//               docId: OData?.dms[0]?.docId,
//             });
//           }
//           data.fileIndex = newfiledata.length;
//           newfiledata.push({
//             fileIndex: newfiledata.length,
//             module: "Quotation",
//             moduleFileType: "Special Instructions",
//             quotationLineId: OData.quotationLineId,
//             level: "LINE_LEVEL",
//           });
//           const fileLine = new File([data.file[0]], data.file[0].name);
//           _formData.append("files", fileLine);
//         }
//       }
//     });

//     newAttachments.forEach((attachment: any, index: number) => {
//       if (!attachment) {
//         return;
//       }
//       const file = attachment?.originFileObj;

//       _formData.append("files", file);
//       newfiledata.push({
//         fileIndex: newfiledata.length,
//         module: "Quotations",
//         moduleFileType: "attachments",
//         level: "HEADER_LEVEL",
//       });
//     });

//     const existingLineItems: any = [];
//     OriginalData?.quotationLines?.forEach((item: any) => {
//       if (item?.quotationLineId) {
//         const obj: any = {
//           quotationLineId: item.quotationLineId,
//           materialId: item.materialId,
//         };
//         if (OriginalData?.needByDateType == "Line Level") {
//           obj.needByDate = item.needByDate;
//         }
//         if (OriginalData?.deliveryAddressType == "Line Level") {
//           obj.deliveryAddressId = item.deliveryAddressId;
//         }
//         existingLineItems.push(obj);
//       }
//     });

//     if (isInstructionsChanged) {
//       const instructionsFile = new Blob([newInstructions], {
//         type: "text/html",
//       });
//       _formData.append(
//         `files`,
//         new File([instructionsFile], "instructions.html", {
//           type: "text/html",
//         })
//       );
//       newfiledata.push({
//         fileIndex: newfiledata.length,
//         module: "Quotations",
//         moduleFileType: "instructions",
//         level: "HEADER_LEVEL",
//       });
//       setIsInstructionsChanged(false);
//       const _instructions = DMS?.find(
//         (item: any) => item.moduleFileType == "instructions"
//       );
//       if (_instructions?.docId) {
//         deletedFileData.push({
//           docId: _instructions.docId,
//         });
//       }
//     }
//     if (isTermsAndConditionsChanged) {
//       const termsAndConditionsFile = new Blob([newTermsAndConditions], {
//         type: "text/html",
//       });
//       _formData.append(
//         `files`,
//         new File([termsAndConditionsFile], "termsAndConditions.html", {
//           type: "text/html",
//         })
//       );
//       newfiledata.push({
//         fileIndex: newfiledata.length,
//         module: "Quotations",
//         moduleFileType: "termsAndConditions",
//         level: "HEADER_LEVEL",
//       });
//       setIsTermsAndConditionsChanged(false);
//       const _termsAndConditions = DMS?.find(
//         (item: any) => item.moduleFileType == "termsAndConditions"
//       );
//       if (_termsAndConditions?.docId) {
//         deletedFileData.push({
//           docId: _termsAndConditions.docId,
//         });
//       }
//     }

//     let lastSubmissionDate = dayjs(
//       materialData.quotationHeader?.lastSubmissionDate
//     ).format("YYYY-MM-DD");
//     materialData.quotationHeader.lastSubmissionDate = lastSubmissionDate;
//     const filesData = {
//       newfiledata,
//       deletedFileData,
//       updatedFileData,
//     };

//     materialData.quotationLines.forEach((item: any) => {
//       if (materialData.quotationHeader.deliveryLevelType === "ORDER_LEVEL") {
//         item.deliveryAddressId = null;
//         item.deliveryAddressName = null;
//       }
//       if (materialData.quotationHeader.deliveryDateType === "ORDER_LEVEL") {
//         item.deliveryDate = null;
//       }
//     });

//     _formData.append("filesData", JSON.stringify(filesData));

//     // _formData.append("files", JSON.stringify([]));

//     _formData.append(
//       "quotationHeader",
//       JSON.stringify(materialData.quotationHeader)
//     );
//     _formData.append(
//       "quotationLines",
//       JSON.stringify(materialData.quotationLines)
//     );
//     _formData.append("requiredDocs", JSON.stringify(materialData.requiredDocs));
//     _formData.append(
//       "quotationApprovals",
//       JSON.stringify(materialData.quotationApprovals)
//     );

//     _formData.append(
//       "additionalData",
//       JSON.stringify(materialData.addtionalData)
//     );
//     console.log("MaterialDAta::::", materialData);

//     // return

//     const res = await quotationApis.newVersion(_formData);
//     const { response, status, message }: any = res;
//     if (status === true) {
//       Swal.fire("Created!", response.rfqId, "success");
//       navigate(`/view-rfqs`);
//     } else {
//       Swal.fire("Error!", message, "error");
//     }
//   } catch (error) {
//     console.log(error);
//   }
//   setDisableSubmit(false);
// };

const createQuotations = async (
  data: any,
  navigate: any,
  setDisableSubmit: any,
  mode?: string
) => {
  try {
    const dataApI: any = await quotationApis.quotationsCreationMethod(data);
    const { status, error, response, message }: any = dataApI;
    if (status === true) {
      Swal.fire("Created!", response.rfqId, "success");
      navigate(`/view-rfqs`);
    } else {
      Swal.fire("Error!", message, "error");
    }
  } catch (error) {
    console.error("Submission error:", error);
  }
  setDisableSubmit(false);
};

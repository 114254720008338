import React, { useState, useEffect, FC } from "react";
import { Button, Badge } from "reactstrap";
import {
  Table,
  Input,
  Flex,
  Spin,
  Tooltip,
  Drawer,
  Modal,
  Form,
  Popover,
  Timeline,
} from "antd";
import { Icon, TooltipComponent } from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { useLocation } from "react-router";
import DispatchAside from "./paymentsNav";
import paymentApis from "../../api/payments/payments";
import { formatCurrency } from "../../utils/Utils";

import dayjs from "dayjs";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
const ViewPayments: FC = () => {
  const location = useLocation();
  const id = location?.state?.id;
  const [widgetData, setWidgetData] = useState<any>([]);
  const [sm, setSm] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const columns: any = [
    {
      title: "#",
      key: "#",
      width: 50,
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 250,
      fixed: "left",
      render: (text: any) => (
        <div className="align-left">
          <span>{text}</span>
        </div>
      ),
    },

    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`supplierName-tooltip-${index}`}>
              {text?.length > 20 ? `${text?.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`supplierName-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Gross Amount",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 140,
      render: (text: any, record: any) => {
        return (
          <div style={{ textAlign: "right" }}>
            <span>
              {formatCurrency(Number(text), "INR")}
              {/* <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip> */}
            </span>
          </div>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 150,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: any) => <span>{FormatDate(date)}</span>,
      width: 150,
    },
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
      width: 100,
    },
    {
      title: "Created By",
      dataIndex: "createdUserName",
      key: "createdUserName",
      width: 200,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 180,
      fixed: "right",
      render: (text: any, record: any) => {
        const paymentStatus: any = {
          FullyPaid: "FULLY PAID",
          PartiallyPaid: "PARTIALLY PAID",
        };
        if (text == "Pending") {
          const { days } = record?.due;
          if (days <= 0) {
            return (
              <p
                style={{ color: "blue", fontSize: "14px" }}
                className="tb-status"
              >
                {" "}
                DUE IN {days * -1} DAYS
              </p>
            );
          }
          return (
            <p className="text-danger"> OVERDUE BY {record?.due?.days} DAYS</p>
          );
        }
        return (
          <p style={{ color: "green", fontSize: "14px" }} className="tb-status">
            {paymentStatus[text] || text}
          </p>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      width: 60,
      render: (text: any, record: any) => (
        <>
          <Button
            size="sm"
            color=""
            onClick={() => {
              setViewDrawer(true);
              fetchPaymentDetails(record?.invoiceNumber);
            }}
          >
            <Icon name="focus" />
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      setViewDrawer(true);
      fetchPaymentDetails(id);
    }
  }, [id]);

  const fetchPaymentDetails = async (invoiceNumber: any) => {
    try {
      const res = await paymentApis.fetchAllInvoicePaymentLines(invoiceNumber);
      console.log(res);

      const { data, status, message } = res;
      if (status) {
        setDrawerData(data);
      } else {
        setViewDrawer(false);
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const ApprovalTimelineContent = (approvalsData: any) => {
    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No Approvals</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "Approved";
              const isRejected = approval.status == "Rejected";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Badge color="outline-success">View</Badge>
        </span>
      </Popover>
    );
  };

  const drawerColumns: any = [
    {
      title: "Payment #",
      key: "paymentId",
      width: 200,
      fixed: "left",
      render: (text: any, record: any) => (
        <div className="align-left">
          <span>{record?.payment?.paymentId}</span>
        </div>
      ),
    },
    {
      title: "Payment Date",
      key: "paymentDate",
      width: 150,
      render: (date: any, record: any) => (
        <span>{FormatDate(record?.payment?.paymentDate)}</span>
      ),
    },
    {
      title: "Payment Mode",
      key: "paymentType",
      width: 120,
      render: (text: any, record: any) => (
        <span>{record?.payment?.paymentMode}</span>
      ),
    },
    {
      title: "Transaction #",
      key: "transactionNumber",
      width: 120,
      render: (text: any, record: any) => {
        const number = record?.payment?.transactionNumber;
        return number ? (
          <span>
            {number?.substring(0, 8)}...
            {number?.length > 8 ? (
              <Tooltip title={number}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            ) : (
              <></>
            )}
          </span>
        ) : (
          <span>--</span>
        );
      },
    },
    {
      title: "Payable Amount",
      key: "payableAmount",
      width: 100,
      render: (_text: any, record: any) => {
        const text = record?.payment?.payableAmount;
        return (
          <div style={{ textAlign: "right" }}>
            <span>
              {formatCurrency(Number(text), "INR")}
              {/* <Tooltip title={formatCurrency(Number(text), "INR")}>
              <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
            </Tooltip> */}
            </span>
          </div>
        );
      },
    },
    {
      title: "Track Approvals",
      key: "Approvals",
      width: 100,
      render: (text: any, record: any) => {
        const paymentApprovals = record?.payment?.paymentApprovals;
        return <span>{ApprovalTimelineContent(paymentApprovals)}</span>;
      },
    },
  ];

  useEffect(() => {
    fetchWidgetCount();
  }, []);

  const fetchWidgetCount = async () => {
    try {
      const res = await paymentApis.fetchPayableInvoicesWidgetCounts();
      const { data, status, message } = res;
      if (status) {
        setWidgetData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {}
  };

  const disapprovePayment = async (reason: any) => {
    try {
      const res = await paymentApis.disapprovePayment(
        drawerData?.paymentNumber,
        reason
      );
      const { status, message } = res;
      if (status) {
        Swal.fire("Success", message, "success");
        // fetchData();
      }
    } catch (error) {}
  };

  return (
    <>
      <TransactionFlowDefaultTemplate
        moduleKey="view-payments"
        title="All Payments"
        columns={columns}
        sideNavigation={
          <div
            className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
              sm ? "content-active" : ""
            }`}
          >
            <DispatchAside updateSm={setSm} sm={sm} />
          </div>
        }
        extra={{
          tableScrollX: 1100,
          // excelExport: true,
        }}
        widget={{
          isActive: true,
          list: [
            {
              title: "Outstanding",
              value: `${widgetData?.totalOutstanding || 0}`,
              percentage: `${widgetData?.totalOutstandingPercentage || 0}`,
              color: "#OO65BD",
            },
            {
              title: "Overdue By 7 Days",
              value: `${widgetData?.overdueBy7Days || 0}`,
              percentage: `${widgetData?.overdueBy7DaysPercentage || 0}`,
              color: "#20639B",
            },
            {
              title: "Overdue By 15 Days",
              value: `${widgetData?.overdueBy15Days || 0}`,
              percentage: `${widgetData?.overdueBy15DaysPercentage || 0}`,
              color: "#FA991C",
            },
            {
              title: "Overdue By Month",
              value: `${widgetData?.overdueByMonth || 0}`,
              percentage: `${widgetData?.overdueByMonthPercentage || 0}`,
              color: "#F6D55C",
            },
            {
              title: "Overdue By >1 Month",
              value: `${widgetData?.overdueGreaterThanMonth || 0}`,
              percentage: `${
                widgetData?.overdueGreaterThanMonthPercentage || 0
              }`,
              color: "#OOCC99",
            },
          ],
        }}
      />
      <Drawer
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>Payment's Summary </strong>
          </div>
        }
        placement="right"
        onClose={() => setViewDrawer(false)}
        open={viewDrawer}
        width="85vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
      >
        {drawerData ? (
          <>
            <div className="table-responsive poTable">
              <div
                style={{
                  margin: "0px auto 15px auto",
                }}
              >
                <Table
                  className="customTable"
                  columns={drawerColumns}
                  dataSource={drawerData}
                  scroll={{ x: 1000 }}
                  bordered
                  size="middle"
                  pagination={false}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Flex align="center" gap="middle" className="pageLoader">
              <Spin size="large" className="m-auto" />
            </Flex>
          </>
        )}
      </Drawer>
      <DisapprovePayment
        isModalVisible={showRejectModal}
        setIsModalVisible={setShowRejectModal}
        reject={disapprovePayment}
      />
    </>
  );
};

const DisapprovePayment = ({
  isModalVisible,
  setIsModalVisible,
  reject,
}: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        reject(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Reason for Rejection"
      open={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="rejectForm"
        requiredMark={false}
      >
        <Form.Item
          name="reason"
          label={undefined}
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </Modal>
  );
};

export default ViewPayments;

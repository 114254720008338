import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../components/Component";
import { Steps, Step } from "react-step-builder";
import Header_Buyer from "./Header_PurchaseOrder";
import PurchaseOrder_GeneralDetails from "./PurchaseOrder_GeneralDetails";
import PurchaseOrder_Materails from "./PurchaseOrder_Materails";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import PurchaseOrder_Preview from "./PurchaseOrder_Preview";
import { Link } from "react-router-dom";
import PurchaseOrderOthers from "./PurchaseOrderOthers";
import dayjs from "dayjs";
import axios from "axios";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import { set } from "date-fns";
import { Flex, Spin } from "antd";
const TAX_SLAP_DROPDOWN = [
  {
    label: "0%",
    value: "0",
  },
  {
    label: "5%",
    value: "1",
  },
  {
    label: "12%",
    value: "2",
  },
  {
    label: "18%",
    value: "3",
  },
  {
    label: "28%",
    value: "4",
  },
];
const config = {
  before: Header_Buyer as React.ComponentType<{}> as () => React.ReactElement,
};

const PurchaseOrderMain: FC = () => {
  const [sm, setSm] = useState(false);
  const [draftState, setDraftState] = useState({
    poId: "",
    draft: false,
  });
  const [mobileView, setMobileView] = useState(false);
  const formMethods = useForm({
    mode: "onChange",
  });
  const { watch, register, reset, setValue } = formMethods;
  const location = useLocation();
  const convertionData = location.state?.data;
  const draftId = location.state?.draftId;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (draftId) {
      fetchDraftData(draftId);
    }
  }, [draftId]);

  const fetchDraftData = async (id: any) => {
    try {
      setLoading(true);
      const res = await purchaseOrderApis.fetchOrder(id);
      const { data, status, message } = res;
      if (status) {
        setDraftStateData(data);
      }
    } catch (error) {
      console.log("Error While Loading Draft Data");
    } finally {
      setLoading(false);
    }
  };

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const setDraftStateData = async (draftData: any) => {
    try {
      const {
        orderNumber,
        quotationNumber,
        supplierQuoteId,
        orderId,
        referenceId,
        mode,
        version,
        orderDate,
        needByDateType,
        needByDate,
        changeRequestNeedByDate,
        orgId,
        siteId,
        supplierId,
        costCenterId,
        contractorId,
        description,
        ledgerId,
        departmentId,
        budgetId,
        paymentTerms,
        advancePercentage,
        creditDays,
        paymentTermsDescription,
        inwardRequired,
        autoRelease,
        autoPoDoc,
        billingAddressId,
        deliveryAddressId,
        gstin,
        currencyId,
        basicAmount,
        discount,
        netAmount,
        sgst,
        cgst,
        igst,
        additionalCharges,
        grossTotal,
        createdDate,
        createdBy,
        deletedAt,
        deliveryAddressType,
        approvalRequired,
        invoiceStatus,
        deliveryStatus,
        status,
        signatureLabel,
        lineItems,
        dms,
        orderApprovals,
        deliveryAddress,
      } = draftData;
      const originalDocId: any = {};
      setDraftState({ poId: orderId, draft: true });
      setValue("type", "draft");
      setValue("orderId", orderId);
      setValue("orderNumber", orderNumber);
      if (supplierId) setValue("supplierId", supplierId);
      if (description) setValue("description", description);
      if (budgetId) setValue("budgetId", budgetId);
      if (departmentId) setValue("departmentId", departmentId);
      if (ledgerId) setValue("ledgerId", ledgerId);
      if (orderApprovals?.length > 0) {
        setValue("autoRelease", "false");
        setValue("approvalRequired", "true");
        setValue(
          "approvalsData",
          JSON.stringify({
            open: false,
            data: orderApprovals,
          })
        );
      } else {
        setValue("autoRelease", "true");
        setValue("approvalRequired", "false");
      }
      setValue("inwardRequired", inwardRequired ? "true" : "false");
      setValue("autoPoDoc", autoPoDoc ? "true" : "false");
      if (costCenterId) setValue("costCenterId", costCenterId);
      if (siteId) setValue("siteId", siteId);
      if (contractorId) setValue("contractorId", contractorId);
      if (billingAddressId) setValue("billingAddressId", billingAddressId);
      if (deliveryAddressType)
        setValue("deliveryAddressType", deliveryAddressType);
      if (deliveryAddressId) setValue("deliveryAddressId", deliveryAddressId);
      if (needByDate) setValue("needByDate", dayjs(needByDate));
      if (needByDateType) setValue("needByDateType", needByDateType);
      if (paymentTerms) setValue("paymentTerms", paymentTerms);
      if (advancePercentage) setValue("advancePercentage", advancePercentage);
      if (creditDays) setValue("creditDays", creditDays);
      if (Number(igst) == 0)
        setValue(
          "taxConfigurationObj",
          JSON.stringify({ gstType: "CGST+SGST", taxType: "GST", cess: [] })
        );
      else
        setValue(
          "taxConfigurationObj",
          JSON.stringify({ gstType: "IGST", taxType: "GST", cess: [] })
        );
      const signature = dms?.find((i: any) => i.moduleFileType == "signature");
      if (signature) {
        originalDocId.signatureDocId = signature.docId;
        setValue(
          "signatureObj",
          JSON.stringify({
            isActive: true,
            label: signatureLabel,
            image: signature.filePath,
            docId: signature.docId,
          })
        );
      }
      const _termsAndConditions = dms?.find(
        (item: any) => item.moduleFileType == "termsAndConditions"
      );
      if (_termsAndConditions) {
        const data: any = await fetchHtmlContentFromUrl(
          _termsAndConditions?.filePath
        );
        originalDocId.termsAndConditionsId = _termsAndConditions.docId;
        setValue("termsAndConditions", data);
      }
      const _instructions = dms?.find(
        (item: any) => item.moduleFileType == "instructions"
      );
      if (_instructions) {
        const data: any = await fetchHtmlContentFromUrl(
          _instructions?.filePath
        );
        originalDocId.instructionsId = _instructions.docId;
        setValue("instructions", data);
      }
      const _attachments = dms?.filter(
        (item: any) => item.moduleFileType == "attachments" && item.docHash
      );
      if (_attachments) {
        originalDocId.attachmentsId = _attachments.map((i: any) => i.docId);
        setValue(
          "attachments",
          _attachments.map((attachment: any) => {
            return Object.assign({}, attachment, {
              name: attachment.fileName, // You can map the name from fileName or modify as needed
              type: "application/pdf",
            });
          })
        );
      }
      if (lineItems) {
        const originalLines: any = [];
        setValue(
          "items",
          lineItems?.map((item: any) => {
            const obj: any = {
              lineNo: item.lineNo,
              uuid: item.uuid,
              materialId: item.materialId,
              dms: dms?.filter((i: any) => i.lineNo == item.lineNo),
            };
            if (needByDateType == "Line Level") {
              obj.needByDate = item.needByDate;
            }
            if (deliveryAddressType == "Line Level") {
              obj.deliveryAddressId = item.deliveryAddressId;
            }
            originalLines.push(obj);
            let _item: any = {
              ...item,
              key: item?.lineNo,
              materialLabel: item.materialName,
              materialName: item.materialId,
              materialCategory: item.categoryId,
              quantity: Number(item?.quantity),
              uomLabel: item.uom,
              dms: dms?.filter((i: any) => i.lineNo == item.lineNo),
              supplierQuoteLineId: item.supplierQuoteLineId,
              igstAmount: item?.igst,
              igst: TAX_SLAP_DROPDOWN?.find(
                (i: any) => i.label == Number(item.igstPercentage) + "%"
              )?.value,
              sgstAmount: item?.sgst,
              sgst: TAX_SLAP_DROPDOWN?.find(
                (i: any) =>
                  i.label ==
                  Number(item.sgstPercentage) +
                    Number(item.cgstPercentage) +
                    "%"
              )?.value,
              cgstAmount: item?.cgst,
              cgst: TAX_SLAP_DROPDOWN?.find(
                (i: any) =>
                  i.label ==
                  Number(item.sgstPercentage) +
                    Number(item.cgstPercentage) +
                    "%"
              )?.value,
              amount: item?.netAmount,
              lineTotal: item?.grossTotal,
            };
            if (item.needByDate) _item.needByDate = dayjs(item.needByDate);
            if (item.deliveryAddressId)
              _item.deliveryAddressId = item.deliveryAddressId;
            return _item;
          })
        );
        setValue("originalLines", originalLines);
      }
      setValue("originalDocId", JSON.stringify(originalDocId));
      return;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      const _data = convertionData;
      if (!_data) {
        return;
      }
      console.log("convertionData", _data);
      const {
        quotationId,
        supplierQuoteId,
        supplierId,
        contractorId,
        costCenterId,
        siteId,
        billingAddressId,
        deliveryAddressType,
        deliveryAddressId,
        lineLevelDelivery,
        needByDate,
        paymentTerms,
        paymentPercentage,
        paymentDays,
        igstValue,
      }: any = _data?.header;
      const lines = _data?.lines;
      if (quotationId) setValue("quotationId", quotationId);
      if (supplierQuoteId) setValue("supplierQuoteId", supplierQuoteId);
      if (supplierId) setValue("supplierId", supplierId);
      if (costCenterId) setValue("costCenterId", costCenterId);
      if (siteId) setValue("siteId", siteId?.toString());
      if (contractorId) setValue("contractorId", contractorId);
      if (billingAddressId) setValue("billingAddressId", billingAddressId);
      if (deliveryAddressType)
        setValue(
          "deliveryAddressType",
          deliveryAddressType == "ORDER_LEVEL" ? "Order Level" : "Line Level"
        );
      if (deliveryAddressId) setValue("deliveryAddressId", deliveryAddressId);
      if (needByDate) setValue("needByDate", dayjs(needByDate));
      if (lineLevelDelivery)
        setValue(
          "needByDateType",
          lineLevelDelivery == "ORDER_LEVEL" ? "Order Level" : "Line Level"
        );
      if (paymentTerms) setValue("paymentTerms", paymentTerms);
      if (paymentPercentage) setValue("advancePercentage", paymentPercentage);
      if (paymentDays) setValue("creditDays", paymentDays);
      if (igstValue == "0")
        setValue(
          "taxConfigurationObj",
          JSON.stringify({ gstType: "CGST+SGST", taxType: "GST", cess: [] })
        );

      if (igstValue != "0")
        setValue(
          "taxConfigurationObj",
          JSON.stringify({ gstType: "IGST", taxType: "GST", cess: [] })
        );
      if (lines)
        setValue(
          "items",
          lines?.map((item: any) => {
            let _item: any = {
              ...item,
              key: item?.quotationLineId,
              materialLabel: item.materialName,
              materialName: item.materialId,
              materialCategory: item.categoryId,
              uomLabel: item.uomName,
              dms: item.dms,
              quoteShortListId: item.shortListId,
              supplierQuoteLineId: item.supplierQuoteLineId,
              igst: TAX_SLAP_DROPDOWN?.find(
                (i: any) => i.label == item.igstPercentage + "%"
              )?.value,
              sgst: TAX_SLAP_DROPDOWN?.find(
                (i: any) =>
                  i.label ==
                  Number(item.sgstPercentage) +
                    Number(item.cgstPercentage) +
                    "%"
              )?.value,
              cgst: TAX_SLAP_DROPDOWN?.find(
                (i: any) =>
                  i.label ==
                  Number(item.sgstPercentage) +
                    Number(item.cgstPercentage) +
                    "%"
              )?.value,
            };
            if (item.needByDate) _item.needByDate = dayjs(item.needByDate);
            console.log(_item);

            return _item;
          })
        );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  return (
    <>
      <Head title="User List - Profile" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      {draftState?.draft
                        ? draftState?.poId
                        : "New Purchase Order"}
                    </BlockTitle>
                  </BlockHeadContent>
                  <Link to={`${process.env.PUBLIC_URL}/view-purchase-order`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block className="mt-1">
                {loading == true ? (
                  <>
                    <Flex align="center" gap="middle" className="pageLoader">
                      <Spin size="large" className="m-auto" />
                    </Flex>
                  </>
                ) : (
                  <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                    <FormProvider {...formMethods}>
                      <Steps config={config}>
                        <Step component={PurchaseOrder_GeneralDetails} />
                        <Step component={PurchaseOrder_Materails} />
                        <Step component={PurchaseOrderOthers} />
                        <Step component={PurchaseOrder_Preview} />
                      </Steps>
                    </FormProvider>
                  </div>
                )}
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default PurchaseOrderMain;

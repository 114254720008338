import "reactflow/dist/style.css";
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  Background,
  Handle,
  Position,
  ReactFlowProvider,
  useReactFlow,
} from "reactflow";
import dagre from "dagre";
import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../components/Component";
import purchaseRequestApis from "../../api/master/purchaseRequest";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, Spin } from "antd";
import dayjs from "dayjs";
import Swal from "sweetalert2";

// Custom node component
const CustomNode = ({ data }: any) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        border: "1px solid black",
        fontSize: "16px",
        padding: "10px",
        borderRadius: "5px",
        width: "250px",
        backgroundColor: "white",
        cursor: "pointer",
      }}
      onClick={() => {
        if (data.label === "Purchase Request") navigate(`/view_pr/${data.id}`);
        if (data.label === "Quotation") navigate(`/view/${data.id}`);
        if (data.label === "Purchase Order") navigate(`/view-purchase-order/${data.id}`);
      }}
    >
      <Handle type="target" position={Position.Top} />
      <div style={{ backgroundColor: "rgba(0, 0, 255, 0.16)", color: "blue", padding: "5px", borderRadius: "5px", textAlign: "center" }}>
        <strong>{data.label}</strong>
      </div>
      <div><strong>Sequence ID:</strong> {data.trackingId}</div>
      <div><strong>Created At:</strong> {dayjs(data.createdAt).format("DD-MMM-YYYY")}</div>
      <div><strong>Created By:</strong> {data.createdBy}</div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

const nodeTypes = {
  selectorNode: CustomNode,
};

// Function to calculate layout
const dagreLayout = (nodes: any[], edges: any[]) => {
  const g = new dagre.graphlib.Graph();
  g.setGraph({});
  g.setDefaultEdgeLabel(() => ({}));

  nodes.forEach((node) => g.setNode(node.id, { width: 250, height: 200 }));
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));

  dagre.layout(g);

  return nodes.map((node) => {
    const nodeData = g.node(node.id);
    return {
      ...node,
      position: { x: nodeData.x - 125, y: nodeData.y - 75 }, // Center nodes
      type: "selectorNode",
    };
  });
};

const TrackPurchaseRequests = () => {
  let { prId }: any = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    getPRTracking();
  }, []);

  const getPRTracking = async () => {
    try {
      const { response, status, message } = await purchaseRequestApis.prTracking(prId);
      if (status) {
        setNodes(dagreLayout(response.initialNodes, response.initialEdges));
        setEdges(response.initialEdges);
      } else {
        Swal.fire("Error", message, "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching PR tracking data:", error);
    }
  };

  const onFlowInit = (instance: any) => {
    if (!isLoading && nodes.length > 0 && edges.length > 0) {
      instance.fitView({ padding: 0.1, includeHiddenNodes: true });
    }
  };

  const onConnect = (params: any) => setEdges((eds) => addEdge(params, eds));

  return (
    <>
      <Head title="Purchase Requests - Track" />
      <Content>
        <Card className="card-bordered">
          <div className="card-inner card-inner-lg">
            <BlockHead size="lg">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle tag="h4">Track Purchase Request </BlockTitle>
                  <BlockDes><p>PR ID - {prId}</p></BlockDes>
                </BlockHeadContent>
                <div className="d-flex">
                  <Button color="primary" onClick={() => navigate(-1)}>
                    <Icon name="arrow-left" />
                    <span>Back</span>
                  </Button>
                </div>
              </BlockBetween>
            </BlockHead>
            <Block>
              <Card className="card-bordered" style={{ height: "500px", overflow: "hidden" }}>
                {isLoading ? (
                  <Flex align="center" className="pageLoader">
                    <Spin size="large" className="m-auto" />
                  </Flex>
                ) : (
                  <div style={{ width: "100%", height: "500px", overflow: "auto", padding: "10px" }}>
                    <ReactFlowProvider>
                      <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        onInit={onFlowInit}
                        minZoom={0.5} 
                        maxZoom={1.5} 
                        translateExtent={[
                          [-1000, -1000], 
                          [1000, 1000],  
                        ]}
                        zoomOnScroll={true}
                      >
                        <Background color="#aaa" gap={16} />
                      </ReactFlow>
                    </ReactFlowProvider>
                  </div>
                )}
              </Card>
            </Block>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default TrackPurchaseRequests;

import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormTextArea from "../../form-components/TextAreaComponent";
import { Icon, Button } from "../../../components/Component";
import { SelectOptions } from "./types"; // Import your types
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import supplierApis from "../../../api/master/supplier";
import dayjs from "dayjs";
import Swal from "sweetalert2";

interface bomLine {
  categoryId: string;
  categoryName: string;
  materialId: string;
  materialName: string;
  quantity: string;
  uomId: string;
  uomName: string;
  allocated: string;
}

interface FormData {
  budgetLineName: string;
  budgetValue: string;
  startDate: any;
  endDate: any;
  bom: string;
  allocation: string;
  lineDescription: string;
  currencyCode: string;
  bomLines: bomLine[];
  materialCategoryOptions: any;
}

const AddNewMaterial: FC<any> = ({
  modal,
  closeModal,
  addRow,
  currentMaterial,
  formData,
  budgetStartDate,
  budgetEndDate,
  materialCategoryOptions,
}) => {
  const methods = useForm<FormData>({
    defaultValues: {
      budgetLineName: "",
      budgetValue: "",
      startDate: "",
      endDate: "",
      bom: "",
      allocation: "",
      lineDescription: "",
      currencyCode: "",
      bomLines: [],
    },
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bomLines",
  });

  const billOfMaterialWatch = watch("bom");

  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialData, setMaterialData] = useState<any>();
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);

  const addNewDocument = () => {
    if (fields.length < 10) {
      append({
        categoryId: "",
        categoryName: "",
        materialId: "",
        materialName: "",
        quantity: "",
        uomId: "",
        uomName: "",
        allocated: "",
      });
    }
  };

  useEffect(() => {
    if (currentMaterial) {
      reset({
        budgetLineName: currentMaterial.budgetLineName,
        budgetValue: currentMaterial.budgetValue,
        startDate: dayjs(currentMaterial.startDate),
        endDate: dayjs(currentMaterial.endDate),
        bom: currentMaterial.bom,
        currencyCode: currentMaterial.currencyCode,
        allocation: currentMaterial.allocation,
        lineDescription: currentMaterial.lineDescription,
        bomLines: currentMaterial.bomLines,
      });
    } else {
      reset({
        budgetLineName: "",
        budgetValue: "",
        startDate: "",
        endDate: "",
        bom: "",
        allocation: "",
        lineDescription: "",
        currencyCode: "",
        bomLines: [],
      });
    }
  }, [currentMaterial, reset]);

  useEffect(() => {
    console.log("billOfMaterialWatch", billOfMaterialWatch);
    if (billOfMaterialWatch === "Y" && fields.length === 0) {
      append({
        categoryId: "",
        categoryName: "",
        materialId: "",
        materialName: "",
        quantity: "",
        uomId: "",
        uomName: "",
        allocated: "",
      });
    } else if (billOfMaterialWatch === "Y" && fields.length > 0) {
      (async () => {
        const { data, materialOption }: any = await fetchMaterials(
          fields[fields.length - 1].categoryId,
          fields.length - 1
        );
        await fetchUom(
          fields[fields.length - 1].materialId,
          data,
          fields.length - 1
        );
      })();
    }
  }, [billOfMaterialWatch, append, fields.length]);

  const fetchUom = async (materialId: any, materialData: any, index: any) => {
    if (!materialId) return [];
    try {
      const materialUom = materialData?.find(
        (item: any) => item.materialId == materialId
      );
      if (!materialUom) return;
      const _uomOptions = [
        {
          value: materialUom?.primaryUomId,
          label: materialUom?.PrimaryUomName,
        },
      ];
      if (
        materialUom?.secondaryUomId &&
        materialUom?.primaryUomId != materialUom?.secondaryUomId && materialUom?.PrimaryUomName!=materialUom?.secondaryUomName
      ) {
        _uomOptions.push({
          value: materialUom?.secondaryUomId,
          label: materialUom?.secondaryUomName,
        });
      }
      setUomOptions(_uomOptions);
      return _uomOptions;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterials = async (categoryId: any, index: any) => {
    if (!categoryId) return { data: [], materialOption: [] };
    try {
      const { data, status } = await supplierApis.fetchCategoryProducts(
        categoryId
      );
      if (status) {
        const materialOption = data.map((item: any) => ({
          label: item?.materialName,
          value: item?.materialId?.toString(),
        }));
        setMaterialData(data);
        setMaterialOptions(materialOption);
        return {
          data,
          materialOption,
        };
      }
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const submitAction = (data: FormData) => {
    try {
      const totalAllocated = data.bomLines.reduce((sum, item) => {
        return (
          sum +
          parseFloat(item?.allocated?.toString()?.replace(/,/g, "") || "0")
        );
      }, 0);
      const budgetValue = parseFloat(data.budgetValue.replace(/,/g, ""));
      if (data.allocation === "value") {
        if (data.bom === "Y" && totalAllocated > budgetValue) {
          Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: "Total Allocated value in BOM Lines cannot exceed the Budget Value.",
          });
          return;
        }
      } else if (data.allocation === "percentage") {
        const totalPercentage = totalAllocated;
        if (data.bom === "Y" && totalPercentage > 100) {
          Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: "Total Allocated % in BOM Lines cannot exceed the more than 100%.",
          });
          return;
        }
      }

      data.startDate = dayjs(data.startDate).format("YYYY-MM-DD");
      data.endDate = dayjs(data.endDate).format("YYYY-MM-DD");
      data.currencyCode = "INR";
      addRow(data);
      reset({
        budgetLineName: "",
        budgetValue: "",
        startDate: "",
        endDate: "",
        bom: "",
        allocation: "",
        lineDescription: "",
        currencyCode: "",
        bomLines: [],
      });
    } catch (error) {
      console.log("Error in submitAction", error);
    }
  };

  const startDate = watch("startDate");

  return (
    <Modal
      isOpen={modal}
      toggle={closeModal}
      className="modal-dialog-centered"
      size="xl"
      backdrop="static"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">
            {currentMaterial ? "Update Budget Line" : "Add New Budget Line"}
          </h5>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitAction)}>
              <Row className="gy-4 mt-2">
                <Col lg="6">
                  <FormInput
                    name="budgetLineName"
                    label="Budget Line Name"
                    required={true}
                    placeholder="Enter Budget Line Name"
                  />
                </Col>

                <Col lg="6">
                  <FormInput
                    min={1}
                    name="budgetValue"
                    type="text"
                    label="Budget Value"
                    required={true}
                    placeholder="Enter Budget Value"
                    pattern={/^[0-9,]+$/} // Allow only digits (0-9) and commas
                    onInput={(e: any) => {
                      const val = e.target.value.replace(/[^\d.]/g, ""); // Remove all non-digit and non-decimal characters
                      if (val.length > 0 && !/^[0-9]+(\.[0-9]+)?$/.test(val)) {
                        e.target.value = val.replace(/[^\d.]/g, ""); // Remove non-digit and non-decimal characters
                      } else {
                        const n = Number(val);
                        const formattedValue = isNaN(n)
                          ? ""
                          : n.toLocaleString("en-IN"); // Format with Indian numbering system
                        e.target.value = formattedValue;
                      }
                    }}
                  />
                </Col>
                <Col lg="6">
                  <FormDatePicker
                    name="startDate"
                    label="Planned Start Date"
                    placeholder="Select Start Date"
                    required={true}
                    minDate={new Date(budgetStartDate)}
                    maxDate={new Date(budgetEndDate)}
                  />
                </Col>
                <Col lg="6">
                  <FormDatePicker
                    name="endDate"
                    label="Planned End Date"
                    placeholder="Select End Date"
                    required={true}
                    minDate={startDate}
                    maxDate={new Date(budgetEndDate)}
                  />
                </Col>
                <Col lg="6">
                  <FormSelect
                    name="bom"
                    label="Bill Of Material (BOM)"
                    options={[
                      { label: "Yes", value: "Y" },
                      { label: "No", value: "N" },
                    ]}
                    required={true}
                    placeholder="Select BOM"
                    onChange={(e) => {
                      console.log("e.target.value", e);
                      if (e === "Y" && fields.length === 0) {
                        append({
                          categoryId: "",
                          categoryName: "",
                          materialId: "",
                          materialName: "",
                          quantity: "",
                          allocated: "",
                          uomName: "",
                          uomId: "",
                        });
                      } else if (e === "N") {
                        reset({
                          ...methods.getValues(),
                          bomLines: [],
                        });
                      }
                    }}
                  />
                </Col>
                {billOfMaterialWatch === "Y" && (
                  <Col lg="6">
                    <FormSelect
                      name="allocation"
                      label="BOM Allocation"
                      options={[
                        { label: "Value", value: "value" },
                        { label: "%", value: "percentage" },
                      ]}
                      required={true}
                      placeholder="Select BOM Allocation"
                    />
                  </Col>
                )}
                <Col lg="12">
                  <FormTextArea
                    name="lineDescription"
                    label="Budget Line Description"
                    required={true}
                    placeholder="Enter Budget Line Description"
                  />
                </Col>
              </Row>

              {billOfMaterialWatch === "Y" && (
                <Row className="gy-4 mt-2">
                  {fields.map((field, index, fieldArray) => {
                    return (
                      <FormProvider key={field.id} {...methods}>
                        <form>
                          <div
                            className="mt-2 d-flex align-items-center justify-content-around"
                            key={field.id}
                          >
                            <Row
                              style={{
                                width: "50%",
                                display:
                                  index < fieldArray.length - 1 ? "none" : "",
                              }}
                            >
                              <Col lg="4">
                                <FormSelect
                                  name={`bomLines.${index}.categoryId`}
                                  label="Category"
                                  options={materialCategoryOptions}
                                  required={true}
                                  onChange={(value) => {
                                    const categoryName =
                                      materialCategoryOptions.find(
                                        (category: any) =>
                                          category.value == value
                                      )?.label || value;
                                    setValue(
                                      `bomLines.${index}.categoryName`,
                                      categoryName
                                    );
                                    fetchMaterials(value, index);
                                  }}
                                />
                              </Col>
                              <Col lg="4">
                                <FormSelect
                                  name={`bomLines.${index}.materialId`}
                                  label="Material Name"
                                  options={materialOptions}
                                  required={true}
                                  onChange={(value) => {
                                    const materialName =
                                      materialOptions.find(
                                        (material: any) =>
                                          material.value == value
                                      )?.label || value;
                                    setValue(
                                      `bomLines.${index}.materialName`,
                                      materialName
                                    );
                                    fetchUom(value, materialData, index);
                                  }}
                                />
                              </Col>
                              <Col lg="4">
                                <FormSelect
                                  name={`bomLines.${index}.uomId`}
                                  label="UOM"
                                  options={uomOptions}
                                  required={true}
                                  onChange={(value) => {
                                    const uomName =
                                      uomOptions.find(
                                        (uom: any) => uom.value == value
                                      )?.label || value;
                                    setValue(
                                      `bomLines.${index}.uomName`,
                                      uomName
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row
                              style={{
                                width: "50%",
                                display:
                                  fieldArray.length == index + 1 ? "none" : "",
                              }}
                            >
                              <Col lg="4">
                                <FormInput
                                  name={`bomLines.${index}.categoryName`}
                                  label="Category"
                                  required={true}
                                  disabled
                                />
                              </Col>
                              <Col lg="4">
                                <FormInput
                                  name={`bomLines.${index}.materialName`}
                                  label="Material Name"
                                  required={true}
                                  disabled
                                />
                              </Col>
                              <Col lg="4">
                                <FormInput
                                  name={`bomLines.${index}.uomName`}
                                  label="UOM"
                                  required={true}
                                  disabled
                                />
                              </Col>
                            </Row>
                            <Row style={{ width: "50%" }}>
                              <Col lg="4">
                                <FormInput
                                  min={1}
                                  name={`bomLines.${index}.quantity`}
                                  label="Qty"
                                  type="number"
                                  required={true}
                                />
                              </Col>
                              <Col lg="4">
                                <FormInput
                                  min={1}
                                  name={`bomLines.${index}.allocated`}
                                  label="Allocation"
                                  type="text"
                                  required={true}
                                  pattern={/^[0-9,]+$/} // Allow only digits (0-9) and commas
                                  onInput={(e: any) => {
                                    const val = e.target.value.replace(
                                      /[^\d.]/g,
                                      ""
                                    ); // Remove all non-digit and non-decimal characters
                                    if (
                                      val.length > 0 &&
                                      !/^[0-9]+(\.[0-9]+)?$/.test(val)
                                    ) {
                                      e.target.value = val.replace(
                                        /[^\d.]/g,
                                        ""
                                      ); // Remove non-digit and non-decimal characters
                                    } else {
                                      const n = Number(val);
                                      const formattedValue = isNaN(n)
                                        ? ""
                                        : n.toLocaleString("en-IN"); // Format with Indian numbering system
                                      e.target.value = formattedValue;
                                    }
                                  }}
                                />
                              </Col>

                              <Col lg="4" className="d-flex align-items-center">
                                <a
                                  onClick={() => {
                                    try {
                                      remove(index);
                                    } catch (error) {
                                      console.log(error);
                                    }
                                  }}
                                  className="danger"
                                  style={{
                                    fontSize: 16,
                                    color: "red",
                                    marginTop: 14,
                                    cursor: "pointer",
                                  }}
                                >
                                  <MinusCircleOutlined /> Remove
                                </a>
                              </Col>
                            </Row>
                          </div>
                        </form>
                      </FormProvider>
                    );
                  })}
                  <Row className="mt-2">
                    <Col lg="12">
                      <a
                        onClick={() => {
                          handleSubmit(
                            (data) => {
                              addNewDocument(); // Add new row after successful submission
                            },
                            (err) => {
                              console.log("error occured", err);
                            }
                          )();
                        }}
                        className="primary"
                        style={{ fontSize: 16, cursor: "pointer" }}
                      >
                        <PlusCircleOutlined /> Add More
                      </a>
                    </Col>
                  </Row>
                </Row>
              )}

              <Row className="mt-4">
                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        <span>Submit</span>
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          closeModal();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </form>
          </FormProvider>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddNewMaterial;

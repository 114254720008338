import React, { useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface PurchaseRequestAsideProps {
  updateSm: (value: boolean) => void; // Function to update state
  sm: boolean; // Boolean state
}

const PurchaseOrderAside: FC<PurchaseRequestAsideProps> = ({
  updateSm,
  sm,
}) => {
  const { data: allowedRoutes }: any = useSelector(
    (state: RootState) => state.allowedRoutes
  );
  const location = useLocation();

  useEffect(() => {
    sm
      ? document.body.classList.add("toggle-shown")
      : document.body.classList.remove("toggle-shown");
  }, [sm]);

  const isActive = (path: string): string => {
    return location.pathname === path ? "active" : "";
  };

  const LinkTo = ({ path }: any) => {
    if (!allowedRoutes.includes(path)) {
      return null;
    }
    switch (path) {
      case "view-purchase-order":
        return (
          <Link
            to="/view-purchase-order"
            className={isActive(
              `${process.env.PUBLIC_URL}/view-purchase-order`
            )}
          >
            <Icon name="eye-fill" />
            <span>View</span>
          </Link>
        );
      case "purchase-order-new":
        return (
          <Link
            to="/purchase-order-new"
            className={isActive(`${process.env.PUBLIC_URL}/purchase-order-new`)}
          >
            <Icon name="plus-fill-c" />
            <span>Add New</span>
          </Link>
        );
      case "approvals-purchase-order":
        return (
          <Link
            to="/approvals-purchase-order"
            className={isActive(
              `${process.env.PUBLIC_URL}/approvals-purchase-order`
            )}
          >
            <Icon name="check-fill-c" />
            <span>Pending Approvals</span>
          </Link>
        );
      case "track-purchase-order":
        return (
          <Link
            to="/track-purchase-order"
            className={isActive(
              `${process.env.PUBLIC_URL}/track-purchase-order`
            )}
          >
            <Icon name="eye-fill" />
            <span>Track</span>
          </Link>
        );
      case "analytics-purchase-order":
        return (
          <Link
            to="/analytics-purchase-order"
            className={isActive(
              `${process.env.PUBLIC_URL}/analytics-purchase-order`
            )}
          >
            <Icon name="growth-fill" />
            <span>Analytics</span>
          </Link>
        );
      default:
        return null;
    }
  };

  return (
    <div className="card-inner-group">
      <div className="card-inner">
        <div className="user-account-info py-0">
          <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
            Purchase Order
          </h6>
        </div>
      </div>
      <div className="card-inner p-0">
        <ul className="link-list-menu">
          {[
            "view-purchase-order",
            "purchase-order-new",
            "approvals-purchase-order",
          ].map((item) => (
            <li key={item} onClick={() => updateSm(false)}>
              <LinkTo path={item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PurchaseOrderAside;

import React, { useState, useEffect, FC } from "react";
import Content from "../../layout/content/Content";
import { Card, Row, Col, Button } from "reactstrap";
import {
  TableColumnsType,
  Table,
  Progress,
  Select,
  Input,
  Flex,
  Spin,
  Empty,
} from "antd";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import FormatDate from "../masters/DateFormater";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import InventoryAside from "./InventoryAside";
import invoiceApis from "../../api/Invoice/invoice";
import ServerSideTable from "../form-components/PaginationTableComponent";
import purchaseOrderApis from "../../api/master/PurchaseOrder";
import inventoryCreation from "../../api/inventory/inventoryCreation";
import dayjs from "dayjs";
import StockAvailabilityAside from "./stockAvailability/stockAvailabilityAside";
import TransactionFlowDefaultTemplate from "../others/transactionFlowDefaultTemplate";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import supplierApis from "../../api/master/supplier";
const { Option } = Select;
interface WidgetData {
  title: string;
  count: number;
  progress: number;
  color: string;
}
function StockAvailabilityReport() {
  const [sm, setSm] = useState(false);

  const [filterStatus, setfilterStatus] = useState<any>([]);
  const [site, setSite] = useState("");
  const [siteOptions, setSiteOptions] = useState<any>([]);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const { data: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      title: "#",
      key: "#",
      width: 50,
      render: (text: any, record: any, index: any) => index + 1,
      sorter: true,
    },
    {
      title: "Site",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Material Category",
      dataIndex: "categoryName",
      key: "categoryName",
      sorter: true,
      width: 200,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      sorter: true,
      width: 200,
    },
    {
      title: "UOM",
      dataIndex: "primaryUomName",
      key: "primaryUomName",
      sorter: true,
      width: 200,
    },
    {
      title: "Qty Available For Utilization",
      dataIndex: "quantityAvailable",
      key: "quantityAvailable",
      sorter: true,
      width: 200,
      render: (text: number, record: any) => (
        <div>
          <span>{text.toLocaleString("en-IN")}</span>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    fetchCategoryFilter();
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility) {
        const options = currentUser.siteAccessibility.map((item: any) => ({
          label: item.siteName,
          value: item.siteID,
        }));
        setSiteOptions(options);
      }
    }
  }, [currentUser]);

  const fetchCategoryFilter = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        let _categoryOptions: any = [];
        let allCategory: any = [];
        data.forEach((item: any) => {
          allCategory.push(item?.id?.toString());
          _categoryOptions.push({
            label: item?.category,
            value: item?.id?.toString(),
          });
        });
        // setValue("category", allCategory?.join(","));
        if (_categoryOptions.length > 1) {
          _categoryOptions = [
            // {
            //   label: "ALL",
            //   value: allCategory?.join(","),
            // },
            ..._categoryOptions,
          ];
        }
        setCategoryOptions(_categoryOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TransactionFlowDefaultTemplate
      moduleKey="stock-availability"
      title="Stock Availability Report"
      columns={columns}
      sideNavigation={
        <div
          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
            sm ? "content-active" : ""
          }`}
        >
          <StockAvailabilityAside updateSm={setSm} sm={sm} />
        </div>
      }
      selectFilters={[
        {
          title: "Site",
          placeholder: "Select Site",
          options: siteOptions,
        },
        {
          title: "Category",
          placeholder: "Select Category",
          options: categoryOptions,
        },
      ]}
      extra={{
        tableScrollX: 1100,
        dateRange: false,
        excelExport: false,
        dataFilterTootip: "Filtering based on quotation creation date",
      }}
    />
  );
}

export default StockAvailabilityReport;

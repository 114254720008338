import React, { FC, useEffect, useState } from "react";
import * as echarts from "echarts";
import { Button, Empty, Select } from "antd";
import supplierApis from "../../api/master/supplier";
import InventoryAnalyticsAPI from "../../api/inventory/InventoryAnalytics";
import { useNavigate } from "react-router";
const { Option } = Select;
const AverageInventoryHoldingDaysByCategory: FC<any> = ({ dates, sites }) => {
  const navigate = useNavigate();
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [category, setCategory] = useState("");
  const [chartData, setChartData] = useState<any | null>(null);
  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    if (sites && category) {
      fetchData();
    }
  }, [sites, category, dates]);

  const fetchData = async () => {
    try {
      const { from, to } = dates;
      const payload: any = {
        siteId: sites,
        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res =
        await InventoryAnalyticsAPI.fetchAverageInventoryHoldingDaysByMaterial(
          payload
        );
      const { response, status } = res;
      if (status) {
        console.log(
          "response:::AverageInventoryHoldingDaysByMaterial",
          response
        );
        const materials = response.map((item: any) => item.materialName);
        const holdingDays = response.map((item: any) =>
          parseInt(item.avg_holding_days)
        );
        setChartData({
          materials,
          holdingDays,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchCategories = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const allCategory: any = [];
        const _categoryOptions: any = [];
        data?.forEach((item: any) => {
          allCategory.push(item.id);
          _categoryOptions.push({ label: item.category, value: item.id });
        });
        setCategory(allCategory.join(","));
        setCategoryOptions([
          {
            label: "All Categories",
            value: allCategory?.join(","),
          },
          ..._categoryOptions,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (chartData) {
      const chartDom = document.getElementById(
        "averageInventoryHoldingDaysByCategory-chart"
      ) as HTMLElement;
      if (chartDom) {
        const myChart = echarts.init(chartDom);

        const option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            name: "Holding Days",
            nameLocation: "middle",
            nameGap: 25,
            axisLabel: {
              formatter: "{value} days", // Format as days
            },
          },
          yAxis: {
            type: "category",
            name: "Material",
            data: chartData?.materials,
          },
          series: [
            {
              name: "Holding Days",
              position: "left",
              type: "bar",
              data: chartData?.holdingDays, // Example data
              itemStyle: {
                color: "#73c0de", // Customize bar color
              },
              barWidth: "40%", // Adjust bar width
            },
          ],
        };

        myChart.setOption(option);

        return () => {
          myChart.dispose();
        };
      }
    }
  }, [chartData]);

  const handleCategoryChange = (value: string) => {
    setCategory(value.toString());
  };

  return (
    <div>
      <div
        className="card-title-group mb-1"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="card-title">
          <h6 className="title">Average Inventory Holding Days By Material</h6>
          <p>
            <Button
              type="link"
              onClick={() => {
                navigate("/inventory-aging-report-list", {
                  state: { originPath: "InventoryAnalytics" },
                });
              }}
            >
              Click here to View Details
            </Button>
          </p>
        </div>

        <div>
          <span style={{ fontWeight: "bold" }}> Filter by Category: </span>
          <Select
            defaultValue="ALL"
            style={{ width: 150 }}
            onChange={handleCategoryChange}
          >
            {categoryOptions?.map((item: any) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </div>
      </div>
      {chartData && (
        <div
          id="averageInventoryHoldingDaysByCategory-chart"
          style={{
            width: "100%",
            height: "400px",
            display: `${chartData?.materials?.length > 0 ? "block" : "none"}`,
          }}
        />
      )}
      {chartData?.materials?.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Empty description="No data available to generate or display the graph." />
        </div>
      )}
    </div>
  );
};

export default AverageInventoryHoldingDaysByCategory;

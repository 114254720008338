import React, { useState, useEffect } from "react";
import { useFormContext, FormProvider, Controller } from "react-hook-form";
import { Button, Spinner } from "reactstrap";
import { Collapse, Upload, message } from "antd";
import "react-quill/dist/quill.snow.css";
import { UploadFile } from "antd/es/upload/interface";
import FormTextArea from "../form-components/TextAreaComponent";
import { AllowedFileTypes } from "../../fileAccess";
import { saveDraft } from "./draft-utility/saveDraft";
import { useNavigate } from "react-router";

const { Panel } = Collapse;
const { Dragger } = Upload;

const PurchaseOrderOthers: React.FC<{ prev: () => void; next: () => void }> = (
  props: any
) => {
  const methods = useFormContext();
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { setValue, watch } = methods;
  let formData: any = {
    attachments: [],
  };
  const previousFormDate = watch();
  const [fileList, setFileList] = useState<UploadFile[]>(
    formData.attachments || []
  );
  const attachmentsWatch = watch("attachments");
  useEffect(() => {
    console.log("attachmentsWatch", attachmentsWatch);
    if (attachmentsWatch) {
      setFileList(attachmentsWatch);
    }
  }, [attachmentsWatch]);

  const submitForm = (data: any) => {
    props.next();
  };

  const beforeUpload = (file: UploadFile) => {
    const isAllowedType = Object.values(AllowedFileTypes)
      .filter((value) => typeof value === "string")
      .includes(file.type as AllowedFileTypes);
    // const isAllowedType = AllowedFileTypes
    if (!isAllowedType) {
      // message.error(
      //   "You can only upload PDF, JPG, JPEG, PNG, GIF, DOC, DOCX files!"
      // );
      message.error("Invalid file type!");
      // return Upload.LIST_IGNORE;
      return false;
    }
    if (file.size && file.size / 1024 / 1024 >= 2) {
      message.error("File must be smaller than 5MB!");
      // return Upload.LIST_IGNORE;
      return false;
    }
    return true;
  };

  const handleChange = (info: { fileList: UploadFile[] }) => {
    setFileList(info.fileList);
    setValue("attachments", info.fileList);
  };

  return (
    <FormProvider {...methods}>
      <form
        className="content clearfix"
        onSubmit={methods.handleSubmit(submitForm)}
      >
        <Collapse defaultActiveKey={["1", "2", "3"]}>
          <Panel header="Instructions (if any)" key="1">
            <FormTextArea
              name="instructions"
              label={undefined}
              placeholder="Enter Instructions"
            />
          </Panel>
          <Panel header="Terms and Conditions (if any)" key="2">
            <FormTextArea
              name="termsAndConditions"
              label={undefined}
              placeholder="Enter Terms and Conditions"
            />
          </Panel>
          <Panel header="Attachments (if any)" key="3">
            <Controller
              name="attachments"
              control={methods.control}
              render={({ field }) => (
                <Dragger
                  beforeUpload={beforeUpload}
                  fileList={fileList}
                  onChange={handleChange}
                  className="ant-upload-drag-custom"
                  action="/purchase-order-new"
                  showUploadList={{ showPreviewIcon: false }}
                  itemRender={(originNode, file, fileList, actions) => {
                    // Customize the rendering of each file to remove the tooltip
                    return React.cloneElement(originNode, {
                      title: null, // Removes the tooltip entirely
                    });
                  }}
                >
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              )}
            />
          </Panel>
        </Collapse>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Next
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
            <li>
              {disableSubmit ? (
                <Button color="secondary" type="button">
                  <Spinner size="sm" color="light" />
                </Button>
              ) : (
                <Button
                  color="secondary"
                  onClick={() =>
                    saveDraft(
                      previousFormDate,
                      previousFormDate?.type == "draft",
                      navigate,
                      setDisableSubmit
                    )
                  }
                  className="btn btn-secondary"
                >
                  Save as Draft
                </Button>
              )}

            </li>
          </ul>
        </div>
      </form>
    </FormProvider>
  );
};

export default React.memo(PurchaseOrderOthers);
